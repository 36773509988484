import { IStore } from "@src/interface/Store";
import { IAvisStar, IStoreAvisVerifie } from "@src/interface/StoreAvisVerifie";
import { cartSlice } from "@src/store/reducers/CartSlice";
import { RollingStartEndpointBuilder } from "..";

export const createStoreEndpoints = (builder: RollingStartEndpointBuilder) => ({
  getStoreAvisVerifie: builder.query<
    IStoreAvisVerifie,
    { id_store: string; stars?: IAvisStar }
  >({
    query: ({ id_store, stars }) => ({
      url: `/avisverifie/store/${id_store}`,
      method: "GET",
      params: {
        stars,
      },
    }),
  }),
  getStoreItem: builder.query<IStore, { id_store: string }>({
    query: ({ id_store }) => ({
      url: `/store/${id_store}`,
      method: "GET",
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      try {
        const res = await queryFulfilled;
        if (res.data && res.data.modifiers) {
          dispatch(cartSlice.actions.setModifiers(res.data.modifiers));
        }
      } catch (error) {}
    },
  }),
});
