import styled from "styled-components";

const _SpanButtonRoot = styled.button.attrs({ type: "button" })`
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  color: inherit;
`;

export { _SpanButtonRoot };
