import ObjectCollection from "../prototypes/ObjectCollection";
import { app } from "./AppModel";
import ProductModel from "./ProductModel";
import CartModel from "./CartModel";
import { urlWithSyncVersion } from "@src/backbone/prototypes/ObjectPrototype";
import { defaultCart } from "../provider/ModelProvider";

class ProductCollection extends ObjectCollection<ProductModel> {
  name = "ProductCollection";
  idAttribute = "id_product";
  route = "apiProductCollection";

  initialize(
    models?: Record<string, any>[] | ProductModel[],
    options?: any
  ): void {
    // ObjectCollection constructor heritage
    super.initialize.apply(this, [models, options]);
  }

  getProductsByIds(
    productsId: string[] = [],
    inContext: boolean = false,
    inContextBuyable: boolean = false,
    withFilters: boolean = false,
    cart?: CartModel
  ): ProductCollection {
    const sourceCollection = this,
      productCollection = new ProductCollection();
    productCollection.reset();

    //
    withFilters =
      !!withFilters &&
      !(
        app.getTags().isFilterChecked() &&
        app.getAllergenGroups().isFilterChecked()
      );
    //
    productsId.forEach(function (id) {
      const product = sourceCollection.get(id);
      if (product) {
        if (withFilters && !product.isFiltred(cart)) {
          // si on a des filtres et que le produit n'est pas filtré
          return;
        }
        if (inContext && !cart) {
          cart = defaultCart();
        }
        if (
          inContext &&
          // inContextBuyable distingue un tri par visibilité ou par achetable
          // par défaut, on est en visibilité
          !(inContextBuyable
            ? product.isBuyableInContext(cart)
            : product.isVisibleInContext(false, cart))
        ) {
          return;
        } else {
          productCollection.models.push(product);
          productCollection.length++;
        }
      }
    });
    return productCollection;
  }
  inContext(inContextBuyable: boolean = false, cart?: CartModel) {
    var filteredCollection = this.filter((product) => {
      // inContextBuyable distingue un tri par visibilité ou par achetable
      // par défaut, on est en visibilité
      return inContextBuyable
        ? product.isBuyableInContext(cart)
        : product.isVisibleInContext(false, cart);
    });
    return new ProductCollection(filteredCollection);
  }
  clearSidedishes() {
    // fonction qui invalide les cross-selling (sidedish) lors de changement de contexte panier
    // ils seront automatiquement repeuplé via product.getSidedishes()
    this.each(function (product) {
      delete product.sidedishes;
    });
  }
}

ProductCollection.prototype.model = ProductModel;
ProductCollection.prototype.name = "ProductCollection";
ProductCollection.prototype.idAttribute = "id_product";
ProductCollection.prototype.route = "apiProductCollection";
ProductCollection.prototype.url = () => urlWithSyncVersion("/product");

export default ProductCollection;
