import { setCurrentCustomer } from "@src/store/reducers/CustomerSlice";
import { RollingStartEndpointBuilder } from "..";

export interface ISigninResponse {
  requireRGPD?: boolean;
  connectionStatus?: boolean;
  created?: boolean;
  field_missing?: boolean;
}

interface SignInSharedArgs {
  validateByCustomerRGP?: boolean;
  /* Recaptcha token */
  token?: string;
}
export interface ISignInArgs extends SignInSharedArgs {
  email: string;
  password: string;
}
export interface ISignInFacebookArgs extends SignInSharedArgs {
  facebook_token?: string;
}
export interface ISignInGoogleArgs extends SignInSharedArgs {
  id_token?: string;
}

export const createAuthEndpoints = (builder: RollingStartEndpointBuilder) => ({
  postSignin: builder.query<ISigninResponse, { body: ISignInArgs }>({
    query: ({ body }) => ({
      url: "/signin",
      method: "POST",
      body,
    }),
  }),
  postSignout: builder.mutation<
    {
      message: string;
      status: string;
    },
    void
  >({
    query: () => ({
      url: "/signout",
      method: "POST",
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      try {
        const res = await queryFulfilled;
        if (res.data.status === "success") {
          dispatch(setCurrentCustomer({}));
        }
      } catch (error) {}
    },
  }),
  postSignInFacebook: builder.query<ISigninResponse, ISignInFacebookArgs>({
    query: () => ({
      url: "/signin/facebook",
      method: "POST",
    }),
  }),
  postSignInGoogle: builder.query<ISigninResponse, ISignInGoogleArgs>({
    query: () => ({
      url: "/signin/google",
      method: "POST",
    }),
  }),
});
