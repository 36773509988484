import React from "react";

import DesignSystemImageResponsive, {
  IImageResponsiveProps,
} from "design-system/src/Components/Image/ImageResponsive";

const ImageResponsive = (
  props: Omit<IImageResponsiveProps, "generateImageUrl">
) => {
  return <DesignSystemImageResponsive {...props} />;
};

export default ImageResponsive;
