import { configureStore } from "@reduxjs/toolkit";
import { rolligStartApi } from "@src/services/rollingStartApi";
import rootReducer from "./reducers";

function configureAppStore() {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware({
        serializableCheck: false,
      }),
      rolligStartApi.middleware,
    ],
  });

  return store;
}
const store = configureAppStore();
export type RootState = ReturnType<typeof store.getState>;

export default store;
