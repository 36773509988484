import Dropdown from "../../Core/Dropdown/Dropdown";
import FlexBox from "../../Components/FlexBox/FlexBox";
import { _PhoneNumberInputOpion } from "./PhoneNumberInput.styled";
import Typography from "../../Foundation/Typography/Typography";
import Icon from "../../Foundation/Icon/Icon";
import React, { useState } from "react";
import { useTheme } from "../../Themes/defaultTheme";
import { CountryCode } from "../../lib/Flag/images";
import { TCountryCode, ICountryOption } from "./PhoneNumberInput.d";
import LazyFlag from "../../lib/Flag/LazyFlag";

export type PhoneNumberDropdownProps = {
  selectedCountry: CountryCode;
  prefixLabel?: string;
  options?: ICountryOption[];
  handleChangeCountry?: (value: TCountryCode) => void;
};

const PhoneNumberDropdown = (props: PhoneNumberDropdownProps) => {
  const {
    selectedCountry,
    handleChangeCountry,
    options = [],
    prefixLabel = "",
  } = props;
  const { color } = useTheme();
  const [show, setShow] = useState(false);

  return (
    <Dropdown
      popupVisible={show}
      onVisibleChange={setShow}
      contentStyle={{ padding: "22px 0px" }}
      content={
        <FlexBox
          direction="column"
          style={{ maxHeight: 196, overflow: "auto" }}
          onClick={() => setShow(false)}
        >
          {options.map((optionItem, index) => {
            switch (optionItem.type) {
              case "group":
                return (
                  <FlexBox key={index} direction="column" mb={16}>
                    {optionItem.children.map(({ value, label }) => (
                      <_PhoneNumberInputOpion
                        align="center"
                        key={value}
                        gap={8}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleChangeCountry && handleChangeCountry(value);
                        }}
                      >
                        <LazyFlag countryCode={value} />
                        <Typography
                          variant="button"
                          color="greyText"
                          style={{
                            textTransform: "capitalize",
                            whiteSpace: "nowrap",
                            fontWeight:
                              value === selectedCountry ? "bold" : undefined,
                          }}
                        >
                          {label}
                        </Typography>
                      </_PhoneNumberInputOpion>
                    ))}
                  </FlexBox>
                );
              case "option":
              default:
                const { value, label } = optionItem;
                return (
                  <_PhoneNumberInputOpion
                    align="center"
                    key={value}
                    gap={8}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleChangeCountry && handleChangeCountry(value);
                    }}
                  >
                    <LazyFlag countryCode={value} />
                    <Typography
                      variant="button"
                      color="greyText"
                      style={{
                        textTransform: "capitalize",
                        fontWeight:
                          value === selectedCountry ? "bold" : undefined,
                      }}
                    >
                      {label}
                    </Typography>
                  </_PhoneNumberInputOpion>
                );
            }
          })}
        </FlexBox>
      }
    >
      <FlexBox gap={8} align="center" style={{ cursor: "pointer" }}>
        <LazyFlag countryCode={selectedCountry} />
        <Typography
          variant="button"
          color="greyText"
          style={{
            textTransform: "capitalize",
            transform: "translateY(-2px)",
          }}
        >
          {prefixLabel}
        </Typography>
        <FlexBox
          style={{
            marginLeft: "auto",
            justifyContent: "center",
            alignItems: "center",
            transform: show ? "rotate(-180deg)" : "rotate(0deg)",
            transition: "all 0.3s ease-in-out",
          }}
        >
          <Icon icon="caret_down_16" size={16} color={color.greyText} />
        </FlexBox>
      </FlexBox>
    </Dropdown>
  );
};

export default PhoneNumberDropdown;
