import React, {
  useEffect,
  useId,
  forwardRef,
  ReactNode,
  useState,
  CSSProperties,
} from "react";
import Typography from "../../Foundation/Typography/Typography";
import Icon from "../../Foundation/Icon/Icon";
import { useTheme } from "../../Themes/defaultTheme";
import { _CheckBoxRoot } from "./CheckBox.styled";
import { ITypographyProps } from "../../Foundation/Typography/Typography";

export interface CheckBoxProps {
  label?: ReactNode;
  checked?: boolean;
  disabled?: boolean;
  required?: boolean;
  name: string;
  className?: string;
  onChange?: (checked: boolean) => void;
  children?: ReactNode | ((args: ITypographyProps) => ReactNode);
  align?: "middle" | "top";
  paddingLeft?: number;
  linkInLabel?: boolean;
  id?: string;
  style?: CSSProperties;
  checkmarkStyle?: CSSProperties;
  typographyProps?: ITypographyProps;
}

const CheckBox = forwardRef<HTMLInputElement, CheckBoxProps>(
  (props: CheckBoxProps, inputRef) => {
    const {
      label,
      checked: checkedDefault = false,
      required = false,
      name,
      children,
      disabled,
      align = "middle",
      linkInLabel = false,
      style,
      onChange,
      id: defaultId,
      typographyProps,
      checkmarkStyle,
      paddingLeft,
    } = props;
    const [checked, setChecked] = useState<boolean>(checkedDefault);
    const { color } = useTheme();
    const checkmarkTagStyle: React.CSSProperties = {
      ...checkmarkStyle,
      cursor: "pointer",
    };
    const CheckmarkTag = (props: any) =>
      linkInLabel ? (
        <label {...props} style={checkmarkTagStyle} />
      ) : (
        <div {...props} style={checkmarkTagStyle} />
      );

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      const value = e.currentTarget.checked;
      setChecked(value);
      onChange && onChange(value);
    };

    const id = defaultId || useId();

    useEffect(() => {
      if (typeof checkedDefault !== "undefined" && checkedDefault !== checked) {
        setChecked(checkedDefault);
      }
    }, [checkedDefault]);

    return (
      <_CheckBoxRoot
        $disabled={disabled}
        $align={align}
        $linkInLabel={linkInLabel}
        $paddingLeft={paddingLeft}
        style={style}
        {...(linkInLabel && { as: "div" })}
      >
        {children
          ? typeof children === "function"
            ? children({
                as: "label",
                style: { display: "block", cursor: "pointer" },
                htmlFor: id,
              })
            : children
          : label && <Typography {...typographyProps}>{label}</Typography>}
        <input
          ref={inputRef}
          type="checkbox"
          checked={checked}
          disabled={disabled}
          required={required}
          name={name}
          id={id}
          onChange={handleChange}
        />
        <CheckmarkTag
          className="checkmark"
          {...(linkInLabel && { htmlFor: id })}
          style={{ cursor: "pointer" }}
        >
          <Icon
            className="checkmark_icon"
            icon="checkmark_fat"
            size={24}
            color={color.champagne7}
          />
        </CheckmarkTag>
      </_CheckBoxRoot>
    );
  }
);

export default CheckBox;
