import { RollingStartEndpointBuilder } from "..";
import { WidgetInstance } from "@src/../graphql-types";

export const createWidgetEndpoint = (builder: RollingStartEndpointBuilder) => ({
  getWidgetInstance: builder.query<
    Omit<WidgetInstance, "children">,
    string | number
  >({
    query: (id_instance: number) => ({
      url: `/widgetinstance/${id_instance}`,
      method: "GET",
    }),
  }),
});
