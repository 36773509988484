import React, { ReactNode, Suspense, useEffect, useState } from "react";

//
// Permet de charger le fallback dans le DOM initial,
// en évitant le phénomène de "flash" lors du chargement de la page
// voir l'article ci-dessous pour plus d'infos
// https://www.codeandweb.com/tutorials/gatsby-lazy-loading-with-react-lazy
//

type Props = {
  fallback?: ReactNode;
  children: ReactNode;
};

export const SuspenseHelper: React.FC<Props> = ({
  fallback = <></>,
  children,
}) => {
  const [isMounted, setMounted] = useState<boolean>(false);

  useEffect(() => {
    if (!isMounted) {
      setMounted(true);
    }
  });

  return (
    <Suspense fallback={fallback}>{!isMounted ? fallback : children}</Suspense>
  );
};
