import React, { ReactNode } from "react";
import useBreakpoint from "../../../Components/Grid/hooks/useBreakpoint";
import Typography from "../../../Foundation/Typography/Typography";
import {
  _ProductDetailsInfoRoot,
  _ProductDetailsInfo_MainList,
  _ProductDetailsInfo_MainList_Separator,
  _ProductBoostBannerRoot,
} from "./ProductDetailsInfo.styled";
import htmlParse from "html-react-parser";
import Button from "../../../Foundation/Button/Button";
import { ITheme, ThemeMode } from "../../../Themes/defaultTheme";
import Image from "../../../Components/Image/Image";
import { CSSProperties } from "styled-components";

export interface IProductBoost {
  title: string;
  cta: string;
  message: string;
  theme: ITheme;
  bg_color: string;
  bg_align: string;
  id_picture: string;
  bg_picture: string;
  onClick?: () => void;
}
export interface IProductDetailsInfoProps {
  category: ReactNode;
  name: ReactNode;
  subtitle?: string[];
  weightPrice?: string;
  description?: string;
  product_boost?: null | IProductBoost;
}

const ProductBoostBanner = (product_boost: IProductBoost) => {
  const {
    title,
    cta,
    message,
    theme,
    bg_color = "none",
    id_picture,
    bg_picture,
    bg_align = "center",
    onClick,
  } = product_boost;
  const mode = ThemeMode(theme);

  return (
    <_ProductBoostBannerRoot
      direction={"column"}
      style={{ background: bg_color }}
      $bgPosition={bg_align as CSSProperties["backgroundPosition"]}
      onClick={onClick}
    >
      {bg_picture && parseInt(bg_picture) > 0 && (
        <Image image={{ id: bg_picture }} />
      )}
      <Typography variant="h6" theme={mode}>
        {title}
      </Typography>
      <Typography as={"p"} variant="body" theme={mode}>
        {message}
      </Typography>
      <Button {...{ onClick, theme }}>{cta}</Button>
    </_ProductBoostBannerRoot>
  );
};
const ProductDetailsInfo = (props: IProductDetailsInfoProps) => {
  const { category, name, subtitle, weightPrice, description, product_boost } =
    props;
  const { isMobile } = useBreakpoint();
  return (
    <_ProductDetailsInfoRoot>
      <Typography
        variant="ui3"
        color="black"
        style={{ textTransform: "uppercase" }}
      >
        {category}
      </Typography>
      <Typography
        mt={16}
        variant={isMobile ? "h2" : "h4"}
        color="black"
        as={"h1"}
        style={{ textTransform: "uppercase" }}
      >
        {name}
      </Typography>
      {subtitle && (
        <_ProductDetailsInfo_MainList>
          {subtitle?.map((info, index) => {
            return (
              <React.Fragment key={index}>
                {index > 0 && <_ProductDetailsInfo_MainList_Separator />}
                <Typography variant="subtitle2">{info}</Typography>
              </React.Fragment>
            );
          })}
        </_ProductDetailsInfo_MainList>
      )}
      {weightPrice && (
        <Typography variant="ui3" color={"greyText2"}>
          {weightPrice}
        </Typography>
      )}
      {description && (
        <Typography mt={15} variant="body">
          {htmlParse(description)}
        </Typography>
      )}
      {product_boost && <ProductBoostBanner {...product_boost} />}
    </_ProductDetailsInfoRoot>
  );
};

export default ProductDetailsInfo;
