//
// Model Order
//

import ApiRequest from "../prototypes/ApiRequest";
import LocalStorageModel from "../prototypes/LocalStorageModel";
import { app } from "./AppModel";
import _ from "underscore";
import moment from "moment";
import MysqlDatetimeFormat from "../prototypes/MysqlDatetimeFormat";
import momentFormats from "../prototypes/momentFormats";
import formatPrice from "../prototypes/formatPrice";
import CartProductCollection from "./CartProductCollection";
import { IOrder } from "@src/interface/Customer";
import {
  GATSBY_CONF_COMEIN_RECUP_VALUE,
  GATSBY_CONF_ENABLE_INVOICE,
  GATSBY_CONF_ORDER_STATE_CANCELED_DELIVERY,
  GATSBY_CONF_ORDER_STATE_CANCELED_PICKUP,
} from "@src/utils/constants";

const ORDER_TRANSLATION_CONTEXT = "Order";

export interface IOrderModelAttributes extends Backbone.ObjectHash, IOrder {
  payment_modes: IPaymentMode[];
  extraPrice: IExtraPrice[];
  discounts: IDiscount[];
  total_discount: string;
}

export interface IOrderStep {
  label: string;
  finished: boolean;
  progress: boolean;
  complete: boolean;
  highlight: boolean;
  isNext: boolean;
  finishedNextLabel?: string;
}

interface IDiscount {
  id_cart_discount: string;
  description: string;
  invalid: "0" | "1";
  auto?: boolean;
  name: string;
  force_cart_apear: string;
}
interface IPaymentMode {
  amount?: string;
  brand?: string;
  id_caisse?: string;
  id_payment_mode?: string;
  name?: string;
  v2?: string;
  is_payed?: "0" | "1";
  allow_not_payed?: "0" | "1";
}
interface IExtraPrice {
  free: boolean;
  value?: string;
  freeLabel?: string;
  name: string;
  price_ttc: string;
  price_ht: string;
}

class OrderModel extends LocalStorageModel<IOrderModelAttributes> {
  name = "OrderModel";
  route = "apiOrderModel";
  idAttribute = "id_order";
  products: any;

  defaults() {
    return { has_invoice: true };
  }

  initialize(attributes?: any, options?: any): void {
    // ObjectModel constructor heritage
    super.initialize.apply(this, [attributes, options]);
    //
  }
  testOrderExist(id_cart: string) {
    ApiRequest("OrderValidate", "", { id_cart: id_cart }).then(
      (data: unknown) => {
        app.trigger("newOrderCreate");
      }
    );
  }
  //
  isVAE() {
    return parseInt(this.get("vae") + "") === 1;
  }
  formatDeliveryMode() {
    return this.isVAE()
      ? app.t("Click and Collect", ORDER_TRANSLATION_CONTEXT)
      : app.t("Livraison", ORDER_TRANSLATION_CONTEXT);
  }
  getName() {
    return this.get("name");
  }
  isWeb() {
    return this.get("canal") === "WEB";
  }
  isDesk() {
    // commande effectuée en boutique
    return this.get("canal") === "DESK";
  }
  isDesktop() {
    const model = this;
    let isWeb = model.isWeb(),
      isDesktop;
    // origin
    // 0, desktop
    // 1, app ios
    // 2, app android
    // 3, app ipad
    // 4, web mobile
    // 11,
    switch (model.get("origin")) {
      case "0":
        isDesktop = isWeb && true;
        break;
      default:
        isDesktop = false;
    }
    return isDesktop;
  }

  getTypeIcon() {
    var model = this,
      icon;
    if (model.isDesk()) {
      icon = "clickandco";
    } else if (model.isDesktop()) {
      icon = "screen";
    } else {
      icon = "phone";
    }
    return icon;
  }

  getYear() {
    return this.getDate()?.getFullYear();
  }

  getType() {
    var model = this;
    if (model.isDesk()) {
      return 1;
    } else if (model.isDesktop()) {
      return 2;
    } else {
      return 3;
    }
  }

  getFullTypeLabel() {
    var model = this,
      typeLabel = model.getTypeLabel(),
      deliveryLabel = model.getDeliveryLabel();
    return typeLabel + " " + deliveryLabel.toLowerCase();
  }

  getTypeLabel() {
    var model = this,
      label;
    if (model.isDesk()) {
      label = "Commande en boutique";
    } else if (model.isDesktop()) {
      label = "Commande desktop";
    } else {
      label = "Commande mobile";
    }
    return app.t(label, "Account");
  }

  getDeliveryLabel() {
    var model = this,
      label;
    if (model.isVAE()) {
      label = "À emporter";
    } else {
      label = "En livraison";
    }
    return app.t(label, "Account");
  }

  getProgressSteps() {
    const steps: IOrderStep[] = [];
    let model = this,
      isVAE = model.isVAE(),
      isVAD = !isVAE,
      stepCount = 3,
      percent = model.getPercent(),
      isPrevHighLight = false,
      currentStep = Math.round((stepCount * percent) / 100);

    function AddStep(text: string, VAD: number, VAE: number) {
      if ((isVAE && !!VAE) || (isVAD && !!VAD)) {
        var n = steps.length,
          finished = percent === 100 && n + 1 === stepCount,
          complete = n < currentStep || finished,
          progress = n === currentStep && !complete,
          highlight = progress || finished,
          translatedText = app.t(text, "Order"),
          label = highlight
            ? model.getStatusName() || translatedText
            : translatedText,
          step = {
            label: label,
            finished: finished,
            progress: progress,
            complete: complete,
            highlight: highlight,
            isNext: isPrevHighLight,
            finishedNextLabel:
              n + 1 === stepCount
                ? !isVAE
                  ? app.t("Livrée", "Order")
                  : app.t("Commande Retirée", "Order")
                : undefined,
          };
        isPrevHighLight = highlight;
        steps.push(step);
      }
    }
    AddStep("Validée", 1, 1);
    AddStep("En cours de préparation", 1, 1);
    AddStep("Expédiée", 1, 0);
    // AddStep("Livrée", 1, 0);
    AddStep("Commande prête", 0, 1);
    return steps;
  }

  getLocationStatus() {
    const model = this;
    let location = "",
      locationStatus = "",
      isVAE = model.isVAE();
    if (model.isCanceled()) {
      locationStatus = "Votre commande a été annulée";
    } else if (model.isComplete()) {
      locationStatus = "Bonne dégustation et à bientôt chez Sushi Shop !";
    } else if (isVAE) {
      location = model.formatStore() || "";
      locationStatus = "La commande sera disponible à %s";
    } else {
      location = model.formatAddress();
      locationStatus = "Livraison prévue à %s";
    }
    return app.t(locationStatus, "Order", location);
  }

  getTimingStatus(splitTimeAndDate: boolean = false, separator: string = "|") {
    var model = this,
      timingStatus = "",
      is100Percent = model.is100Percent(),
      isNow = model.isNow();
    if (isNow && is100Percent) {
      timingStatus = model.formatDate("LT", "date_change_status");
    } else if (isNow) {
      timingStatus =
        model.formatDate("LT", "delivery_date_desired_min") +
        " - " +
        model.formatDate("LT", "delivery_date_desired_max");
    } else {
      var isSameDay = model
        .getDateMoment("delivery_date_desired")
        .isSame(new Date(), "d");
      if (isSameDay) {
        timingStatus = model.formatDate("LT", "delivery_date_desired");
      } else {
        if (splitTimeAndDate) {
          timingStatus =
            model.formatDate("DD MMM YYYY", "delivery_date_desired") +
            separator +
            model.formatDate("LT", "delivery_date_desired");
        } else {
          timingStatus = model.formatDate("LLL", "delivery_date_desired");
        }
      }
    }
    return timingStatus;
  }

  getTrackingStatus() {
    let label;
    const isVAE = this.isVAE(),
      isDiffer = this.isDiffer();
    if (isVAE) {
      if (isDiffer) {
        label = "La commande sera disponible le";
      } else {
        label = "La commande sera disponible à";
      }
    } else {
      if (isDiffer) {
        label = "Livraison prévue le";
      } else {
        label = "Livraison prévue à";
      }
    }
    return app.t(label, "Order");
  }

  formatQuantity(offset: number) {
    offset = offset || 0;
    var model = this,
      quantity = offset + (model.get("products_quantity") || 0),
      plural = Math.abs(quantity) !== 1,
      res = "";
    if (quantity !== 0) {
      if (offset > 0) {
        res += "-";
      } else if (offset < 0) {
        res += "+";
      }
      res += quantity + " " + app.t("produit" + (plural ? "s" : ""), "UI");
    } else {
      res = " ";
    }
    return res;
  }

  formatName() {
    var name = this.getName();
    switch (name) {
      case undefined:
      case "":
        return app.t(
          "Commande du %s",
          ORDER_TRANSLATION_CONTEXT,
          moment(this.getDate("date_add")).format("LLLL")
        );
      default:
        if (/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/.test(name)) {
          return app.t(
            "Commande du %s",
            ORDER_TRANSLATION_CONTEXT,
            this.formatDate("LL")
          );
        }
        return name;
    }
  }
  getDate(type?: string) {
    type = !type ? "date_add" : type;
    var date = this.get(type) || "";
    try {
      return MysqlDatetimeFormat(date);
    } catch (err) {}
  }
  getDateMoment(type?: string) {
    return moment(this.getDate(type));
  }
  formatDate(format?: string, type?: string) {
    var m = this.getDateMoment(type);
    return m.isValid() ? m.format(format) : "-";
  }
  formatDateCalendar(type?: string) {
    var m = this.getDateMoment(type);
    return m.isValid()
      ? m.calendar(new Date(), momentFormats.getFormats())
      : "-";
  }
  formatDateShortCalendar(type?: string) {
    var m = this.getDateMoment(type);
    return m.isValid() ? m.format("ddd DD MMMM") : "-";
  }
  getOrderDate() {
    //
    // TODO incompatibilité Safari, lié au format de date Mysql
    var date = this.get("date_add");
    if (date) {
      function pad(s: string | number) {
        return +s < 10 ? "0" + s : s;
      }
      let d = new Date();
      try {
        const _d = new Date(date);
        d = _d;
      } catch (error) {
        d = new Date();
      }
      var day = [
        pad(d?.getDate()),
        pad(d?.getMonth() + 1),
        d?.getFullYear(),
      ].join("-");
      var hour = [pad(d?.getHours()), pad(d?.getMinutes() + 1)].join(":");
      // TODO traduction
      return day + " à " + hour;
    } else {
      return "";
    }
  }
  isNow() {
    return this.get("now") === 1;
  }
  isDiffer() {
    return this.get("now") === -1;
  }
  getPercent() {
    var percent = parseFloat(this.get("pourcent") + "");
    return percent >= 0 ? percent : 0;
  }
  is100Percent() {
    return this.getPercent() === 100;
  }
  isWebOrder() {
    var is_web = this.get("is_web");
    return is_web;
  }
  isCanceled() {
    return _([
      GATSBY_CONF_ORDER_STATE_CANCELED_DELIVERY,
      GATSBY_CONF_ORDER_STATE_CANCELED_PICKUP,
    ]).contains(this.get("id_order_state"));
  }
  isComplete() {
    return this.get("completed");
  }
  getStatusName() {
    var status_name = this.get("status_name");
    return status_name;
  }
  getOrderState() {
    var state = this.get("id_order_state");
    return state || 0;
  }
  getDeliveryAddress() {
    return app.getAddress(this.get("id_address_delivery") || "1");
  }
  getStore() {
    return app.getStore(this.get("id_store") + "");
  }
  getMoment(type?: string) {
    if (!type) type = "delivery_date_desired";
    return moment(this.get(type), "YYYY-MM-DD HH:mm:ss");
  }
  isLunchService() {
    // est-ce que la commande est "du service du midi"
    //var _LUNCH_DINER_SERVICE_THRESHOLD_TIME_ = parseInt(app.c('_LUNCH_DINER_SERVICE_THRESHOLD_TIME_',16));
    return this.get("service") === "0"; //this.getMoment().hours() < _LUNCH_DINER_SERVICE_THRESHOLD_TIME_;
  }
  isNowService() {
    // est-ce que la commande est du même service qu'en ce moment
    return app.isNowLunch() !== !this.isLunchService();
  }

  formatAddress() {
    var address = this.getDeliveryAddress();
    return address ? address.get("description") : "";
  }
  formatExtraPrice(price: string | number) {
    return formatPrice(price);
  }
  formatStore() {
    var store = this.getStore();
    if (_.isUndefined(store)) {
      return "";
    }
    return store.getName();
  }
  hasCagnotte(): boolean {
    return !!parseInt(this.get("cagnotte"));
  }
  // TODO vérifier l'usage de cette fonction (peut-être utiliser amountReduce)
  getCagnotte() {
    var cagnotte = parseInt(this.get("cagnotte"));
    if (cagnotte > 0) {
      return formatPrice(cagnotte);
    }
    return formatPrice(0);
  }
  getAmountReduce() {
    // utilisation de la cagnotte
    return parseFloat(this.get("amountReduce") || 0);
  }
  formatAmountReduce() {
    // utilisation de la cagnotte
    return formatPrice(this.getAmountReduce());
  }
  formatTotalPaid() {
    const total_paid = this.getTotalPaid();
    if (total_paid > 0) {
      return formatPrice(total_paid);
    }
    return "-";
  }
  formatTotalPaidReal(withExtraPrice?: boolean) {
    const total_paid_real = this.getTotalPaid(true, withExtraPrice);
    let res = "";
    if (total_paid_real > 0) {
      res = formatPrice(total_paid_real);
    } else {
      res = this.formatTotalPaid();
    }
    return res;
  }
  getTotalPaid(real?: boolean, withExtraPrice?: boolean) {
    var totalPaid = parseFloat(this.get("total_paid" + (real ? "_real" : "")));
    if (!withExtraPrice) {
      totalPaid -= this.getExtraPriceValue();
    }
    return totalPaid;
  }
  getExtraPriceValue() {
    var extraPriceValue = 0,
      extraPrice = this.get("extraPrice");
    _(extraPrice).each(function (e) {
      if (!e.free) {
        extraPriceValue += parseFloat(e.price_ttc);
      }
    });
    return extraPriceValue;
  }
  getExtraPricesFormated(): Array<{ label: string; price: string }> {
    return (
      this.get("extraPrice")?.map((extraPrice) => {
        return {
          label: extraPrice.name,
          price:
            (extraPrice.free
              ? extraPrice.freeLabel
              : formatPrice(extraPrice.price_ttc)) || "",
        };
      }) || []
    );
  }
  formatLoyalty() {
    return "-";
  }
  formatShop() {
    const store = app?.getStore(this.get("id_store") + "");
    return store ? store.getName() : "";
  }
  formatReorderNp1Product() {
    const nbr_product = this.get("nbr_product") || 0;
    let res = "+ " + (nbr_product - 1) + " ";
    if (nbr_product > 2) {
      res += app.t("produits", "UI");
    } else if (nbr_product > 1) {
      res += app.t("produit", "UI");
    } else {
      res = " "; // &nbsp;
    }
    return res;
  }
  // pour CartProductModel
  hasStore() {
    return true;
  } // cart && order utilise les mêmes fonctions
  getMaxPriceProduct() {
    var product,
      maxPriceProduct = this.get("max_product_price");
    if (!maxPriceProduct) {
      return false;
    }
    // on essaie d'utiliser les données de la commande
    product = this.getProducts().findWhere({ id_product: maxPriceProduct });
    if (!product) {
      // à défaut, on essaie avec les données API publique
      product = app.getProduct(maxPriceProduct);
    }
    return product;
  }
  getProducts(): CartProductCollection {
    const order = this;
    let products = order.products;
    if (!products) {
      products = new CartProductCollection([], { cart: order });
      let detail = order.get("detail"),
        productIds = order.get("products") || [];
      if (!detail || detail.length === 0) {
        // permet de convertir le tableau d'id dans details en tableau d'objet
        // nécessaire ensuite
        // ceci est lié à l'inconsistance de l'API Order
        detail = productIds.map((id: string) => {
          return { id_product: id, quantity: 1 };
        });
        order.set("detail", detail);
      }
      products.attachCart(order, "detail");
      order.products = products;
    }
    return products;
  }
  hasInvoice() {
    return !!this.get("has_invoice") && !!this.get("hash");
  }
  getInvoiceUrl() {
    return this.get("invoice_url");
  }
  displayInvoice() {
    return GATSBY_CONF_ENABLE_INVOICE;
  }

  formatCagnottePercent(): string {
    const recup = GATSBY_CONF_COMEIN_RECUP_VALUE;
    return recup ? recup + "%" : "";
  }
  getCagnotteAmount() {
    const recup = GATSBY_CONF_COMEIN_RECUP_VALUE;
    const total_paid_real = +(this.get("total_paid_real") || 0);
    const value = (recup / 100) * total_paid_real;

    return Math.round(value * 100) / 100;
  }

  formatCagnotteAmount() {
    const amount = this.getCagnotteAmount();
    return amount > 0 ? formatPrice(amount) : "";
  }
  formatCagnottePrice() {
    const amount = this.getCagnotteAmount();
    return amount > 0 ? formatPrice(amount) : "";
  }

  getPaymentModes(): Array<IPaymentMode & { formatedAmount?: string }> {
    return (
      this.get("payment_modes")?.map((item) => {
        let amoutPrice = +(item.amount || 0);
        return {
          ...item,
          formatedAmount: amoutPrice > 0 ? formatPrice(amoutPrice) : "",
        };
      }) || []
    );
  }

  getOrderComeinLabel() {
    const store = this.getStore();
    const vae = parseInt(this.get("vae") + "");
    let notwin;
    let notwintxt = "";
    let notwintxtdate = "";
    let notwintxtexpl = "";
    const storeVAD = store?.get("vad_jackpot");
    const storeVAE = store?.get("vae_jackpot");

    if (store) {
      if (vae !== 1 && storeVAD) {
        notwin = 0;
        notwintxt = app.t("Titre si cagnotte et vad", "Checkout");
        notwintxtdate = app.t("Sous-titre si cagnotte et vad", "Checkout");
        notwintxtexpl = app.t("Description si cagnotte et vad", "Checkout");
      } else if (vae !== 1 && !storeVAD) {
        notwin = 1;
        notwintxt = app.t("Titre si pas de cagnotte et vad", "Checkout");
        notwintxtdate = app.t(
          "Sous-titre si pas de cagnotte et vad",
          "Checkout"
        );
        notwintxtexpl = app.t(
          "Description si pas de cagnotte et vad",
          "Checkout"
        );
      } else if (vae === 1 && storeVAE) {
        notwin = 0;
        notwintxt = app.t("Titre si cagnotte et vae", "Checkout");
        notwintxtdate = app.t("Sous-titre si cagnotte et vae", "Checkout");
        notwintxtexpl = app.t("Description si cagnotte et vae", "Checkout");
      } else if (vae === 1 && !storeVAE) {
        notwin = 1;
        notwintxt = app.t("Titre si pas de cagnotte et vae", "Checkout");
        notwintxtdate = app.t(
          "Sous-titre si pas de cagnotte et vae",
          "Checkout"
        );
        notwintxtexpl = app.t(
          "Description si pas de cagnotte et vae",
          "Checkout"
        );
      } else {
        return;
      }
      return {
        notwin,
        notwintxt,
        notwintxtdate,
        notwintxtexpl,
      };
    }
  }

  getFormatedDiscount(): Array<{ label: string; price: string }> {
    const total_discount = this.get("total_discount") || 0;
    return total_discount > 0
      ? this.get("discounts")
          ?.filter((discount) => {
            const { auto = false } = discount;
            return !auto;
          })
          .slice(0, 1)
          .map((discount) => {
            return {
              label: app.t("Réduction code", "Cart") + " " + discount.name,
              price: formatPrice(-total_discount || 0),
            };
          }) || []
      : [];
  }

  getProductOfferedId(): string[] {
    return (
      this.get("discounts")?.map((discount) => discount.id_cart_discount) || []
    );
  }
}

OrderModel.prototype.name = "OrderModel";
OrderModel.prototype.route = "apiOrderModel";
OrderModel.prototype.idAttribute = "id_order";
OrderModel.prototype.url = function () {
  return `/order/${this.id}`;
};
export default OrderModel;
