import { useIntlTranslation } from "gatsby-plugin-intl-sushishop";
import htmlParse from "html-react-parser";
import { vsprintf } from "sprintf-js";
export interface IPluralArgs {
  count: number;
}
export interface ITranslationOptions {
  vsprintfArgs?: any[];
  plural?: IPluralArgs;
}

/* plural example
  {t( "{count, plural, =0 {no items} one {# item} other {# items}}", "StoreLocSeo", { plural: { count: 1 } } )}
 */

export const useTranslation = (ns: string | string[]) => {
  const { formatMessage } = useIntlTranslation(ns);

  const self = {
    /**
     *
     * @param translationKey Translation key
     * @param context Translation context
     * @param options Extra options to the translation function (plural, vsprintfArgs...)
     * @returns The translated text as string
     */
    t: (
      translationKey: string,
      context: string,
      options?: ITranslationOptions
    ): string => {
      const { vsprintfArgs, plural } = options || {};

      // translation process
      let translation: string = formatMessage(
        {
          id: `${context}:${translationKey}`,
          defaultMessage: translationKey,
        },
        plural ? { count: plural.count } : undefined
      );

      // handle vsprintf args
      if (vsprintfArgs) {
        translation = vsprintf(translation, vsprintfArgs);
      }

      return translation;
    },
    /**
     *
     * @param translationKey Translation key
     * @param context Translation context
     * @param options Extra options to the translation function (plural, vsprintfArgs...)
     * @returns The translated text or JSX Element (string or JSX.Element or JSX.Element[])
     */
    tHtml: (
      translationKey: string,
      context: string,
      options?: ITranslationOptions
    ): string | JSX.Element | JSX.Element[] =>
      htmlParse(self.t(translationKey, context, options)),
  };
  return self;
};
