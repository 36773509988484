import { createContext } from "react";
import { ImageUrlParams } from "../interface/ImageData";

const ImageUrlContext = createContext<{
  generateImageUrl: (props: ImageUrlParams) => string;
}>({
  generateImageUrl: () => {
    throw new Error("Please provide a valid ImageUrlContext");
  },
});
export default ImageUrlContext;
