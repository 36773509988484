// TODO: implement LocalStorage

import Backbone from "@src/backbone/prototypes/Backbone";
import ObjectModel from "./ObjectModel";
import _ from "underscore";
import { site_vars } from "../provider/siteVars";

let ls: Storage;
try {
  ls = window.localStorage;
} catch (err) {
  ls = {
    clear: function () {},
    removeItem: function () {},
    setItem: function () {},
    getItem: function () {
      return null;
    },
    key: function (index: number) {
      return null;
    },
    length: 0,
  };
}

abstract class LocalStorageModel<
  T extends Backbone.ObjectHash = any,
  S = Backbone.ModelSetOptions,
  E extends { route: string } = any
> extends ObjectModel<T, S, E> {
  public static ls = ls;
  abstract name: string;
  isRemoteFetching: boolean = false;
  backbonePattern = "Model";
  remoteFetch!: (
    options?: Backbone.ModelFetchOptions | Backbone.CollectionFetchOptions
  ) => JQueryXHR;
  static getList: (key: string) => any;
  static addInList: (key: string, value: any) => void;
  static isInList: (key: string, value: any) => boolean;

  getId(): string {
    return this.id as string;
  }

  onRemoteFetch(ressource: any, response: any, promise: any) {
    var self = this;
    self.isRemoteFetching = false;
    console.log("onRemoteFetch");
    if (promise && promise.xhr) {
      promise.xhr.done(function (data: any, textStatus: any, jqXHR: any) {
        self.setLocalStorageItem(self.toJSON(), self.getExpiresFromXHR(jqXHR));
      });
    }
  }
  softFetch() {
    // effectue une mise à jour des données à partir du localStorage sans notion d'expiration
    // utilisé au démarrage pour ne pas perdre des données qui sont sensées être expirées,
    // mais qui n'existe pas server side
    // le fetch classique (remote ou local) ne supprimera pas ces données si elles sont absentes
    const key = this.getLocalStorageKey();
    try {
      const item = ls.getItem(key);
      if (item) {
        const dataSet = JSON.parse(item);
        this.set({ ...this.toJSON(), ...dataSet });
      }
    } catch (err) {
      console.warn(`softFetch error on ${key} : ${err}`);
      // cas où le localStorage n'est pas opérationnel
    }
  }
  resetLocalStorageItem() {
    try {
      ls.removeItem(this.getLocalStorageKey());
      ls.removeItem(this.getLocalStorageKeyExpires());
    } catch (err) {}
  }
  localFetch() {
    try {
      var self = this,
        expiresKey = self.getLocalStorageKeyExpires(),
        expires = ls.getItem(expiresKey),
        itemKey = self.getLocalStorageKey(),
        item = ls.getItem(itemKey),
        expirationDelay =
          expires === "0" || !expires
            ? -1
            : new Date(expires).getTime() - new Date().getTime();
      // TODO : expires managment, with XHR Header Expires
      if (expirationDelay < 0) {
        self.resetLocalStorageItem();
      } else if (!_.isUndefined(item)) {
        if (item) {
          let dataSet = JSON.parse(item);
          self.set(dataSet);
          self.trigger("sync", self, dataSet);
          return true;
        }
      }
    } catch (err) {
      // cas où le localStorage n'est pas opérationnel
      return false;
    }
    return false;
  }

  localStorageSetup() {
    this.isRemoteFetching = false;
    this.on("sync", this.onRemoteFetch, this);
  }
  getLocalStorageKey() {
    const self = this,
      __ = "_";
    const { name, country_based, translated, id } = self;
    let key = name;
    if (country_based) {
      key += __ + site_vars.country_code;
    }
    if (translated) {
      key += __ + this.getLangIsoCode();
    }
    if (id) {
      key += __ + id;
    }
    return key;
  }
  getLocalStorageKeyExpires() {
    return this.getLocalStorageKey() + "_expires";
  }
  getExpiresFromXHR(xhr: any) {
    var Expires = "0";
    try {
      Expires = xhr.getResponseHeader("Expires") || "0";
    } catch (err) {}
    return Expires;
  }

  setLocalStorageItem(json?: any, expires?: string) {
    if (!expires) {
      expires = new Date(
        new Date().setFullYear(new Date().getFullYear() + 1)
      ).toString();
    }
    try {
      ls.setItem(this.getLocalStorageKeyExpires(), expires);
    } catch (err) {
      this.trigger("QuotaExceededError", this.name);
    }
    this.updateLocalStorageItem(json);
  }

  updateLocalStorageItem(json?: any) {
    if (!json) {
      json = this.toJSON();
    }
    try {
      ls.setItem(this.getLocalStorageKey(), JSON.stringify(json));
    } catch (err) {
      this.trigger("QuotaExceededError", this.name);
    }
  }
}

LocalStorageModel.prototype.remoteFetch = function (
  options:
    | Backbone.ModelFetchOptions
    | Backbone.CollectionFetchOptions
    | undefined
) {
  this.isRemoteFetching = true;
  return this.fetch.call(this, options);
};

//
// list utils
//
function getList(key: string) {
  try {
    return JSON.parse(ls.getItem(key) || "[]") || [];
  } catch (error) {
    return [];
  }
}
LocalStorageModel.getList = getList;

LocalStorageModel.addInList = function (key: string, value: any) {
  const list = getList(key);
  if (list.indexOf(value) < 0) {
    list.push(value);
  }
  return ls.setItem(key, JSON.stringify(list));
};

LocalStorageModel.isInList = function (key: string, value: any) {
  const list = getList(key);
  return list.indexOf(value) >= 0;
};

LocalStorageModel.ls = ls;

export default LocalStorageModel;
