import Backbone from "backbone";
import backboneSync from "./BackboneSync";
import ChallengeJs from "./ChallengeJs";

// @ts-ignore
// const Backbone;
Backbone.ajax = ChallengeJs;
Backbone.sync = backboneSync;

export default Backbone;
