//
import ConfigurationModel from "./ConfigurationModel";
import { isUndefined } from "underscore";
import LocalStorageCollection from "../prototypes/LocalStorageCollection";
// Collection Configuration
//

class ConfigurationCollection extends LocalStorageCollection<ConfigurationModel> {
  name = "ConfigurationCollection";
  route = "apiConfigurationCollection";
  idAttribute = "name";

  initialize(a: any, o: any) {
    var model = this;
    // ObjectCollection constructor heritage
    super.initialize.apply(model, [a, o]);

    //
    // model.on("sync", model.onSync, model);
  }

  getValue<Type extends string | number | boolean | undefined = string>(
    key: string,
    defaultValue?: Type,
    type?: "string" | "number" | "boolean"
  ): Type {
    const find = this.findWhere({ name: key });
    const undefinedValue = isUndefined(find);
    const undefinedDefaultValue = isUndefined(defaultValue);
    // les valeurs sont exprimées en string
    const rawValue: string | Type = undefinedValue
      ? defaultValue
      : find.get("value") || "";

    // la valeur par défaut permet d'extraire le type
    switch ((!type && !undefinedDefaultValue && typeof defaultValue) || type) {
      case "number":
        return parseInt(`${rawValue}`) as Type;
      case "boolean":
        return typeof rawValue === "boolean"
          ? rawValue
          : ((rawValue === "true" || !!parseInt(`${rawValue}`)) as Type);
      case "string":
        switch (rawValue) {
          case null:
          case undefined:
            return "" as Type;
          case "":
          default:
            return ("" + rawValue) as Type;
        }
      default:
        return rawValue as Type;
    }
  }
  // onSync() {}
}
ConfigurationCollection.prototype.url = () => "/configuration";
ConfigurationCollection.prototype.model = ConfigurationModel;

export default ConfigurationCollection;
