import React, { createContext } from "react";
import { PageProps } from "gatsby";

export type PageContextIntl = {
  lang: string;
  language: string;
  pageIntlId: string;
  pageTemplate: string;
  meta_title?: string;
  sponsorship?: boolean;
  intl: {
    lang: string;
    language: string;
    routed: boolean;
    originalPath: string;
    redirect: boolean;
    redirectDefaultLanguageToRoot: boolean;
    defaultLanguage: string;
    fallbackLanguage: string;
    ignorePaths: string[];
    localeJsonSourceName: string;
    alternates: Array<{
      isDefault: boolean;
      lang: string;
      uri: string;
    }>;
  };
};
type PagePropsIntl = Partial<
  Pick<PageProps<any, PageContextIntl>, "pageContext">
>;

const PageContext = createContext<PagePropsIntl>({});

const PageContextProvider = PageContext.Provider;

const usePageContext = () => {
  return React.useContext<PagePropsIntl>(PageContext);
};

export { PageContextProvider, usePageContext };
export default PageContext;
