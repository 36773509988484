import styled, { css } from "styled-components";

export const _CustomBoxCanvas = styled.canvas<{ $backgroundImage?: string }>(
  ({ $backgroundImage }) => {
    return {
      display: "block",
      margin: "auto",
      aspectRatio: "1/1",
      position: "absolute",
      width: "100%",
      height: "100%",
      backgroundSize: "cover",
      backgroundImage: `url(${$backgroundImage})`,
    };
  }
);

export const _CustomBoxCanvasWrapper = styled.div<{}>(() => {
  return {
    position: "relative",
    background: "#F9F8F5",
    aspectRatio: "1/1",
    "@media (max-width: 768px)": {
      maxHeight: "calc(100vh - 450px)",
      margin: "0 auto 0",
    },
  };
});

export const _CustomBoxAreasLocationBound = styled.button<{
  //   $x: number;
  //   $y: number;
  $w: number;
  $h: number;
  $a: number;
  $sourceImage: string | undefined;
  $isDivided: boolean;
  $isSushi: boolean;
}>(({ $a, $sourceImage, $isDivided, $h, $w, $isSushi }) => {
  if ($sourceImage) {
    // const sW = Math.round((imgW * ($isDivided ? 1 : 2)) / 3),
    //   sDx = $isDivided ? 0 : Math.round(imgW / 3);
  }
  return {
    position: "absolute",
    background: "transparent",
    border: 0,
    outline: "none !important",
    padding: 0,
    margin: 0,
    // backgroundColor: "red",
    "&:after": {
      content: '" "',
      display: "inline-block",
      backgroundSize: "cover",
      width: $w,
      height: $h,
      transform: `rotate(${$a}deg) ${
        $isSushi
          ? "scale(1) translateY(0px)"
          : ($isDivided && "translateX(0px)") || ""
      }`,
      transformOrigin: "68% 38%",
      backgroundImage: `url(${$sourceImage})`,
      backgroundRepeat: "no-repeat",
      backgroundPositionX: !$isDivided ? ($isSushi ? "86%" : "91%") : "5%",
    },
  };
});

export const _CustomBoxAreasLocation = styled.div<{
  $id?: string;
  $debug?: boolean;
}>(({ $id, $debug }) => {
  if (!$debug) {
    return {};
  }
  return {
    "button:before": {
      content: `"${$id}"`,
      position: "absolute",
      display: "inline-block",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      zIndex: 12,
      fontSize: 50,
      color: "#000",
      background: "rgba(255,255,255,0.4)",
    },
  };
});

export const _CustomBoxAreas = styled.div<{}>(
  () => css`
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  `
);
