import React from "react";
import { IMask, IMaskMixin } from "react-imask";
import moment from "moment";
import Input, { InputProps } from "design-system/src/Form/Input/Input";

// const momentFormat = "DD/MM/YYYY";

const MaskedInputDate = IMaskMixin<
  IMask.AnyMaskedOptions,
  "typed",
  any,
  HTMLInputElement,
  InputProps & { momentFormat?: string }
>(({ inputRef, ...props }) => <Input {...props} ref={inputRef} />);

const InputDate = React.forwardRef<
  HTMLInputElement,
  InputProps & { momentFormat?: string }
>((props, ref) => {
  const { momentFormat = "DD/MM/YYYY", ...rest } = props;

  return (
    // @ts-ignore
    <MaskedInputDate
      {...rest}
      inputRef={ref}
      mask={Date}
      pattern={momentFormat}
      lazy={true}
      eager={true}
      // min={new Date(1900, 0, 1).getTime()}
      // max={new Date().getTime()}
      format={(date: Date) => {
        const formattedDate = moment(date).format(momentFormat);
        if (formattedDate.length === 2 || formattedDate.length === 4) {
          return formattedDate + "/";
        }
        return formattedDate;
      }}
      parse={(str: string) => {
        return moment(str, momentFormat).toDate();
      }}
      blocks={{
        DD: { mask: IMask.MaskedRange, from: 1, to: 31 },
        MM: { mask: IMask.MaskedRange, from: 1, to: 12 },
        YYYY: { mask: IMask.MaskedRange, from: 1900, to: moment().year() },
      }}
    />
  );
});

export default InputDate;
