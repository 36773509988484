export type AnchorStylesProps = {
  $hover?: boolean;
  color?: string;
};

export const _LinkAnchorStyles = (props: AnchorStylesProps) => {
  const { $hover = true } = { ...props };
  return {
    textDecoration: "none",
    // textDecorationStyle: "none",
    cursor: "pointer",
    // color: color,
    ...($hover
      ? {
          ["&:hover"]: {
            [".link-text, > *"]: {
              textDecoration: "underline",
            },
          },
        }
      : {}),
  };
};
