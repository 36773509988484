import { FC, ReactElement, ReactNode } from "react";
import useLoadAfterInteraction from "./useLoadAfterInteraction";

interface ISuspenseAfterInteractionProps {
  touch?: boolean;
  fallback?: ReactNode;
  children: ReactNode;
}

const SuspenseAfterInteraction: FC<ISuspenseAfterInteractionProps> = (
  props
) => {
  const { fallback, children, touch } = props;
  const hasInteraction = useLoadAfterInteraction({ touch });
  return (hasInteraction ? children : fallback || null) as ReactElement;
};

export default SuspenseAfterInteraction;
