import React, {
  CSSProperties,
  useRef,
  useEffect,
  useState,
  ReactNode,
  useContext,
} from "react";
import Siema from "siema";
import useBreakpoint from "../../../Components/Grid/hooks/useBreakpoint";
import ImageUrlContext from "../../../Components/Image/Context/ImageUrlContext";
import ImageResponsive from "../../../Components/Image/ImageResponsive";
import {
  ImageFit,
  ImageFormat,
} from "../../../Components/Image/interface/ImageData";
import ProductDetailsCustomBox, {
  BoxLocationImageId,
  IProductDetailsCustomBoxProps,
} from "./ProductDetailsCustomBox/ProductDetailsCustomBox";
import {
  _ProductDetailsImagesRoot,
  _ProductDetailsImageWrapper,
  __ProductDetailsImagesContainer,
} from "./ProductDetailsImages.styled";
import SliderDot from "./SliderDot/SliderDot";
interface ImageItem {
  id: string;
}

export interface IProductDetailsImagesProps {
  images: Array<{
    desktop: ImageItem;
    mobile: ImageItem;
    alt: string;
  }>;
  customBox?: {
    baseImage: ImageItem;
    imageByLocation?: {
      [position in BoxLocationImageId]?: {
        imageId: string;
      };
    };
  };
  children?: ReactNode;
}

const imageWrapperStyleDefault: CSSProperties = {
  //   maxWidth: "100%",
  //   maxHeight: 360,
  //   borderRadius: 4,
  //   overflow: "hidden",
};
const imageStyleDefault: CSSProperties = {
  width: "100%",
  height: "100%",
  aspectRatio: "1/1",
};

const ProductDetailsImages = (props: IProductDetailsImagesProps) => {
  const { images, children, customBox } = props;
  const slider = useRef<Siema | null>(null);
  const sliderElement = useRef<HTMLDivElement>(null);
  const [sliderInitialized, setSliderInitialized] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const { isMobile } = useBreakpoint();
  const { generateImageUrl } = useContext(ImageUrlContext);

  const slideCount = images.length;
  const useSlider = slideCount > 1;

  useEffect(() => {
    setTimeout(() => {
      if (isMobile) {
        if (useSlider && sliderElement.current && !slider.current && isMobile) {
          slider.current = new Siema({
            selector: sliderElement.current,
            multipleDrag: false,
            duration: 400,
            easing: "ease-out",
            startIndex: 0,
            perPage: 1,
            draggable: true,
            threshold: 20,
            loop: false,
            rtl: false,
            onInit: () => {
              setSliderInitialized(true);
              setActiveIndex(0);
            },
            onChange: () => {
              if (slider.current) {
                setActiveIndex(slider.current.currentSlide);
              }
            },
          });
        }
      } else {
        if (slider.current) {
          slider.current?.destroy(true);
          slider.current = null;
          setSliderInitialized(false);
        }
      }
    }, 300);
  }, [useSlider, isMobile, sliderElement.current]);

  return (
    <__ProductDetailsImagesContainer
      style={
        customBox
          ? { background: "#F9F8F5", padding: "40px 0 20px 0" }
          : undefined
      }
      className="product-details"
    >
      {customBox ? (
        <ProductDetailsCustomBox
          // style={{ transform: "scale(0.8)" }}
          baseImage={generateImageUrl({
            id: customBox.baseImage.id,
            width: 1080,
            height: 1080,
            format: ImageFormat.PNG,
          })}
          imageByLocation={
            customBox.imageByLocation
              ? Object.entries(customBox.imageByLocation).reduce<
                  IProductDetailsCustomBoxProps["imageByLocation"]
                >((p, [key, value]) => {
                  if (value) {
                    return {
                      ...p,
                      [key]: generateImageUrl({
                        id: value.imageId,
                        width: 400,
                        height: 400,
                        format: ImageFormat.PNG,
                      }),
                    };
                  }
                  return p;
                }, {})
              : undefined
          }
        />
      ) : (
        <>
          <_ProductDetailsImagesRoot
            ref={sliderElement}
            $sliderInitialized={sliderInitialized}
            className="product-details-root"
          >
            {images.map((image, index) => {
              const { desktop, mobile, ...imageProps } = image;
              return (
                <_ProductDetailsImageWrapper
                  key={`image-${index}`}
                  $first={index === 0}
                >
                  <ImageResponsive
                    loading="eager"
                    style={{
                      aspectRatio: "1/1",
                    }}
                    desktop={{
                      image: {
                        id: desktop.id,
                        aspectRatio: 1,
                        fit: ImageFit.COVER,
                        width: 2048,
                        height: 2048,
                        layout: "fullWidth",
                      },
                      imgStyle: {
                        ...imageStyleDefault,
                      },
                      wrapperStyle: {
                        ...imageWrapperStyleDefault,
                      },
                    }}
                    mobile={{
                      image: {
                        id: mobile.id,
                        aspectRatio: 1,
                        fit: ImageFit.COVER,
                        outputPixelDensities: [1, 2],
                        width: 780,
                        height: 780,
                        layout: "fullWidth",
                      },
                      imgStyle: {
                        ...imageStyleDefault,
                      },
                      wrapperStyle: {
                        ...imageWrapperStyleDefault,
                      },
                    }}
                    {...imageProps}
                  />
                </_ProductDetailsImageWrapper>
              );
            })}
          </_ProductDetailsImagesRoot>
          <SliderDot
            className="product-details__slider-dot"
            total={slideCount}
            current={activeIndex}
          />
        </>
      )}
      {children}
    </__ProductDetailsImagesContainer>
  );
};

export default React.memo(ProductDetailsImages);
