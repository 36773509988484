"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _extends3 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _reactIntl = require("react-intl");
var _react = require("react");
var _intlContext = require("./intl-context");
var _fetchNamespace = require("./fetchNamespace");
var useIntlTranslation = function useIntlTranslation(ns) {
  if (ns === void 0) {
    ns = "default";
  }
  var intl = (0, _react.useContext)(_intlContext.IntlContext);
  var _ref = intl || {},
    addMessages = _ref.addMessages,
    language = _ref.language,
    fetchNamespaces = _ref.fetchNamespaces,
    setFetchNamespaces = _ref.setFetchNamespaces;
  var namespaces = (0, _react.useMemo)(function () {
    return typeof ns === "string" ? [ns] : ns;
  }, [ns]);
  (0, _react.useEffect)(function () {
    if (fetchNamespaces && namespaces) {
      namespaces.forEach(function (n) {
        if (!fetchNamespaces[language + ":" + n]) {
          (0, _fetchNamespace.fetchNamespace)(intl, n).then(function (json) {
            var newMsg = Object.keys(json).reduce(function (result, key) {
              result[n + ":" + key] = json[key];
              return result;
            }, {});
            (0, _react.startTransition)(function () {
              addMessages(newMsg);
              setFetchNamespaces(function (currentFetched) {
                var _extends2;
                return (0, _extends3.default)({}, currentFetched, (_extends2 = {}, _extends2[language + ":" + n] = true, _extends2));
              });
            });
          });
        }
      });
    }
  }, [fetchNamespaces, namespaces, language]);
  return (0, _reactIntl.useIntl)();
};
exports.useIntlTranslation = useIntlTranslation;