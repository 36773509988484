import React, { CSSProperties } from "react";
import { ImageData } from "./interface/ImageData";
import Image, { IImageProps } from "./Image";
import clsx from "clsx";
import { _ImageResponsiveRoot } from "./Image.styled";
import { responsiveMap } from "../Grid/_utils/responsiveObserve";

// main component
export type ResponsivePartialProps = Omit<Partial<IImageProps>, "image"> & {
  image: ImageData;
};
export interface IImageResponsiveProps extends Omit<IImageProps, "image"> {
  desktop: ResponsivePartialProps;
  mobile: ResponsivePartialProps;
  style?: CSSProperties;
  href?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}
const ImageResponsive = (props: IImageResponsiveProps) => {
  const { desktop, mobile, className, style, onClick, ...rest } = props;

  let mainImage: ResponsivePartialProps = mobile;
  const additionalImage: IImageProps["additionalImage"] = [];

  if (mobile.image && mobile.image.id !== "" && mobile.image.id !== "-1") {
    mainImage = mobile;
  }
  if (desktop.image && desktop.image.id !== "" && desktop.image.id !== "-1") {
    if (!mainImage) {
      mainImage = desktop;
    } else {
      additionalImage.push({
        ...desktop,
        image: { ...desktop.image, media: responsiveMap.lg },
      });
      mainImage.image = { ...mainImage.image, media: responsiveMap.mobile };
    }
  }

  return (
    <_ImageResponsiveRoot
      style={style}
      className={"ro-image-responsive"}
      onClick={onClick}
    >
      <Image
        {...rest}
        {...mainImage}
        additionalImage={additionalImage}
        className={clsx(className)}
      />
      {/* {loaded &&
        !isMobile &&
        desktop.image &&
        desktop.image.id !== "" &&
        desktop.image.id !== "-1" && (
          <Image
            className={clsx(className, "ro-image-responsive__desktop")}
            {...rest}
            {...desktop}
          />
        )}
      {mobile.image && mobile.image.id !== "" && mobile.image.id !== "-1" && (
        <Image
          className={clsx(className, "ro-image-responsive__mobile")}
          {...rest}
          {...mobile}
        />
      )} */}
    </_ImageResponsiveRoot>
  );
};

export default ImageResponsive;
