import { GatsbyBrowser } from "gatsby";
import { get } from "lodash";

const shouldUpdateScroll: GatsbyBrowser["shouldUpdateScroll"] = ({
  routerProps: { location },
  prevRouterProps,
}) => {
  const { location: prevLocation } = prevRouterProps || {};
  const isModal = get(location, "state.modal");
  const keepScroll = !!get(location, "state.keepScroll");
  const preventUpdateScroll = get(location, "state.noScroll");
  const modalShouldUpdateScroll = !!get(
    location,
    "state.modalShouldUpdateScroll"
  );
  const locationChanged = location.pathname !== prevLocation?.pathname;
  const update: boolean =
    (modalShouldUpdateScroll || locationChanged) &&
    !preventUpdateScroll &&
    !keepScroll &&
    !isModal;
  return update;
};

export default shouldUpdateScroll;
