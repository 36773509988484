import styled from "styled-components";

export const _SliderDotRoot = styled.div<{}>(() => {
  return {
    display: "flex",
    width: "100%",
    gap: 24,
    justifyContent: "center",
  };
});
export const _Dot = styled.div<{ $active?: boolean }>(({ $active }) => {
  return {
    width: 6,
    height: 6,
    background: $active ? "#1B1A16" : "#D8D8D8",
    borderRadius: "50%",
  };
});
