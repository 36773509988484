import useBackboneContext from "@src/backbone/provider/useBackboneContext";
import { CustomerData } from "@src/interface/Customer";
import { useAppSelector } from "@src/store/hooks";
import { useEffect, useState } from "react";
import CustomerModel from "../model/CustomerModel";

const useCustomerModelChange = <T extends keyof CustomerData>(
  list: T[]
): [
  {
    [_ in T]?: CustomerData[T];
  },
  CustomerModel
] => {
  const [listenedData, setListenedData] = useState<{
    [_ in T]?: CustomerData[T];
  }>({});
  const setupInitialized = useAppSelector(
    (state) => state.app.setupInitialized
  );
  const { app } = useBackboneContext();

  useEffect(() => {
    let handlers: { [_ in T]?: () => void } = {};
    const customer = app?.getCustomer();

    if (setupInitialized && customer) {
      const init = () => {
        const _data: {
          [_ in T]?: CustomerData[T];
        } = {};

        list.forEach((key) => {
          _data[key] = customer.get(key);
        });

        if (JSON.stringify(_data) !== JSON.stringify(listenedData)) {
          setListenedData(_data);
        }
      };

      handlers = list.reduce<{ [_ in T]?: () => void }>((acc, key) => {
        acc[key] = () => {
          setListenedData((prev) => ({
            ...prev,
            [key]: customer.get(key),
          }));
        };
        return acc;
      }, {});

      // trigger init
      init();

      Object.entries(handlers).forEach(([key, handler]) => {
        if (handler) {
          customer.on(`change:${key}`, handler as () => void);
        }
      });
    }
    return () => {
      Object.entries(handlers).forEach(([key, handler]) => {
        if (handler) {
          customer.off(`change:${key}`, handler as () => void);
        }
      });
    };
  }, [setupInitialized, list, listenedData, app]);

  return [listenedData, app?.getCustomer() || new CustomerModel()];
};

export default useCustomerModelChange;
