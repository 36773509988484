import useBackboneContext from "@src/backbone/provider/useBackboneContext";
import useReCaptcha from "@src/hooks/useLogin/useReCaptcha";
import { useTranslation } from "@src/hooks/useTranslation";
import SignInButton from "design-system/src/Components/SignInButton/SignInButton";
import React, { ReactChildren, useEffect, useState } from "react";
import { ISignInButtonProps } from "design-system/src/Components/SignInButton/SignInButton";

declare var AppleID: any;

interface AppleSuccessData {
  authorization: {
    code: string;
    id_token: string;
    state: string;
  };
  user: {
    email: string;
    name?: {
      firstName?: string;
      lastName?: string;
    };
  };
}

const APPLE_SCRIPT =
  "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";

const SignInWithApple = (
  props: Omit<ISignInButtonProps, "children"> & {
    children?: ReactChildren;
  }
) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  const { t } = useTranslation("Account");
  const { customer } = useBackboneContext();
  const reCaptcha = useReCaptcha();
  const {
    onClick,
    children = t("Connexion avec Apple", "Account"),
    ...restProps
  } = props;

  const GATSBY_CONF_APPLE_CONNECT_CLIENT_ID =
    process.env.GATSBY_CONF_APPLE_CONNECT_CLIENT_ID ||
    "com.sushishop.fr.client";
  const GATSBY_CONF_APPLE_CONNECT_CLIENT_REDIRECT_URI =
    process.env.GATSBY_CONF_APPLE_CONNECT_CLIENT_REDIRECT_URI ||
    "https://www.sushishop.fr";
  const GATSBY_CONF_APPLE_CONNECT_ENABLED =
    process.env.GATSBY_CONF_APPLE_CONNECT_ENABLED || "0";

  const clientId = GATSBY_CONF_APPLE_CONNECT_CLIENT_ID;
  const redirectURI = GATSBY_CONF_APPLE_CONNECT_CLIENT_REDIRECT_URI;
  const enabled = GATSBY_CONF_APPLE_CONNECT_ENABLED === "1";

  const handleLogin = async () => {
    try {
      const data: AppleSuccessData = await AppleID.auth.signIn();
      const {
        authorization: { id_token },
      } = data;
      console.log("Sign in with Apple", data);
      await customer?.loginApple(id_token, reCaptcha);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!scriptLoaded) {
      const script = document.createElement("script");
      script.src = APPLE_SCRIPT;
      script.async = true;

      script.onload = () => {
        AppleID.auth.init({
          clientId,
          redirectURI,
          usePopup: true,
          scope: "name email",
        });
        setScriptLoaded(true);
      };

      document.head.append(script);
    }
  }, [scriptLoaded]);

  return enabled ? (
    <SignInButton
      {...restProps}
      icon="logo_apple_colorized"
      onClick={handleLogin}
    >
      {children}
    </SignInButton>
  ) : null;
};

export default SignInWithApple;
