import { EntityId } from "@reduxjs/toolkit";
import store, { RootState } from "@src/store";
import { productsAdapter } from ".";

export type ProductSelector = ReturnType<typeof useProductsSelector>;

export const useProductsSelector = () => {
  const { selectById, selectAll, selectTotal, selectIds, selectEntities } =
    productsAdapter.getSelectors<RootState>((state) => state.product);

  return {
    selectById: (id: EntityId) => selectById(store.getState(), id),
    selectAll: () => selectAll(store.getState()),
    selectTotal: () => selectTotal(store.getState()),
    selectIds: () => selectIds(store.getState()),
    selectEntities: () => selectEntities(store.getState()),
  };
};
