//
// Model Category
import { app } from "./AppModel";
import _ from "underscore";
import moment from "moment";
import CartModel from "./CartModel";
import ProductCollection from "./ProductCollection";
import { urlWithSyncVersion } from "@src/backbone/prototypes/ObjectPrototype";
import Backbone from "@src/backbone/prototypes/Backbone";
import { CategoryPictures, CategoryVisibility } from "../../../graphql-types";
import LocalStorageModel from "../prototypes/LocalStorageModel";
import { defaultCart } from "../provider/ModelProvider";
import { GATSBY_CONF_REORDER_CATEGORY_ID } from "@src/utils/constants";

export type CategoryAttributes = Backbone.ObjectHash & {
  uri?: string;
  position: number;
  active: boolean;
  active_menu: boolean;
  lang: string;
  collaboration?: boolean;
  title_color?: string;
  format?: string;
  description?: string;
  description_short?: string;
  id_category?: string;
  name?: string;
  link_rewrite?: string;
  products?: Array<string>;
  customer_group?: Array<string>;
  banners?: Array<{
    position?: number;
    id_banner?: number;
  }>;
  pictures?: Array<CategoryPictures>;
  enable?:
    | string
    | {
        from?: string;
        to?: string;
      };
  titre_seo?: string;
  pageIntlId?: string;
  id_category_lang?: string;
  is_navigation_by_desire?: boolean;
  short_name?: string;
  id_parent?: string;
  label_icon?: string;
  visibility?: CategoryVisibility;
  label_color?: string;
  label_lunch?: string;
  id: string;
};

//
class CategoryModel extends LocalStorageModel<CategoryAttributes> {
  name = "CategoryModel";
  idAttribute = "id_category";
  route = "apiCategoryModel";
  isSync = true;

  initialize(attributes: CategoryAttributes, options: any) {
    // ObjectModel constructor heritage
    super.initialize.apply(this, [attributes, options]);
    //
    if (!this.get(this.idAttribute)) {
      this.attributes[this.idAttribute] = this.get("id");
    }
  }
  getCustomerGroup() {
    var model = this;

    return model.get("customer_group");
  }
  getName() {
    return this.get("name");
  }
  getDescription() {
    return this.get("description");
  }
  getLinkRewrite() {
    return this.get("link_rewrite");
  }
  getProductsId(): string[] {
    // retourne un tableau d'id_product
    const model = this;
    const ids: string[] = model.get("products") || [];
    if (model.id === GATSBY_CONF_REORDER_CATEGORY_ID) {
      app
        .getCustomer()
        .getReorders()
        .forEach(function (order: any, i: number) {
          if (i > 2) return;
          ids.push(...(order.get("products") || []));
        });
    }
    return ids;
  }

  getProductsCount(
    inContext: boolean,
    withFilters?: boolean,
    cart?: CartModel
  ) {
    // retourne le nombre de produit de la catégorie
    if (inContext || withFilters) {
      return this.getProducts(inContext, false, withFilters, cart).size();
    } else {
      return this.getProductsId().length;
    }
  }
  // getBanners() {
  //   const banners = this.get("banners");
  //
  //   return _(banners);
  // }
  // getElements(cart: CartModel) {
  //   const model = this,
  //     products = !!cart ? model.getProductsCart() : model.getProducts();
  //   const elements = products,
  //     banners = model.getBanners();
  //
  //   banners.each(function (e) {
  //     const banner = app.getBanner(e.id_banner + "");
  //     if (banner && banner?.isVisibleInContext()) {
  //       elements.models.splice(e.position || 0, 0, banner);
  //       elements.length++;
  //     }
  //   });
  //
  //   return elements;
  // }
  getProducts(
    inContext?: boolean,
    inContextBuyable?: boolean,
    withFilters?: boolean,
    cart?: CartModel
  ): ProductCollection {
    // retourne une ProductCollection des produits de la catégorie
    const productsId = this.getProductsId();
    const products = app.getProducts();
    return products && products.getProductsByIds
      ? products.getProductsByIds(
          productsId,
          inContext,
          inContextBuyable,
          withFilters,
          cart
        )
      : new ProductCollection([]);
  }
  getProductsCart(cart?: CartModel) {
    // raccourci sans les premiers parametre
    return this.getProducts(false, false, false, cart);
  }

  isEmpty2(inContext: boolean, withFilters: boolean, cart: CartModel): boolean {
    //
    // Exception :
    // si on est devatics, et pas encore synchronisé, alors on concidère qu'il va y avoir des produits...
    if (!this.isSync && this.get("devaticsStrategyId")) {
      return false;
    }
    return this.getProductsCount(inContext, withFilters, cart) <= 0;
  }

  isEnableFromTo(cart: CartModel): boolean {
    const enable = this.get("enable");
    if (!enable) {
      return false;
    }
    switch (enable) {
      case "always":
        return true;
      default:
        if (typeof enable === "object") {
          let { from, to } = enable;
          from = from?.replace(/-/g, "/");
          to = to?.replace(/-/g, "/");
          cart = defaultCart(cart);
          const contextMoment = cart.validateDeliveryOptions()
              ? cart.getDatetimeMoment()
              : moment(new Date()),
            fromDate = new Date(from || ""),
            toDate = new Date(to || ""),
            isAfterFrom = contextMoment.isAfter(fromDate),
            isBeforeTo = contextMoment.isBefore(toDate),
            validBoth = !!from && !!to && isAfterFrom && isBeforeTo,
            validFrom = !!from && isAfterFrom,
            validTo = !!to && isBeforeTo,
            valid = !!to ? (!!from ? validBoth : validTo) : validFrom;
          //
          return valid;
        }
        return false;
    }
  }
  containsId(id_product: any) {
    return _(this.getProductsId()).contains(id_product);
  }
  isVisibleInContext(cart: CartModel) {
    // basé sur la visibilité jour de la semaine + horaire
    const category = this,
      v = category.get("visibility");
    let period;
    let cartDate;
    let cartDay;
    let from;
    let to;
    const isEnableFromTo = this.isEnableFromTo(cart);
    //
    if (!isEnableFromTo) return false;
    if (
      _(category.getCustomerGroup()).intersection(
        app.getCustomer().getCustomerGroup()
      ).length === 0
    ) {
      return false;
    }
    // si non défini, pas de restriction
    if (_.isUndefined(v)) return true;

    // sinon, on regarde le panier
    cartDate = defaultCart(cart).getDatetime();
    if (_.isUndefined(cartDate)) return true;

    if (_.isArray(v)) {
      const day = cartDate.getDay();
      period = v[day];
    } else if (_.isObject(v)) {
      period = v;
    }
    //
    if (!period || period === "always") return true;

    cartDay = moment(cartDate).format("YYYY/MM/DD ");
    from = new Date(cartDay + period.from);
    to = new Date(cartDay + period.to);

    // test si l'horaire du panier correspond à celui du créneau
    return moment(cartDate).isBetween(from, to);
  }
  isActiveInContext(withFilters: boolean, cart: CartModel) {
    // dans le contexte,
    // en incluant les non-achetables
    // withFilters en option
    const isEmpty = this.isEmpty2(true, withFilters, cart);
    const isVisibleInContext = this.isVisibleInContext(cart);
    return !isEmpty && isVisibleInContext;
  }
  //   afterInitialize: {};
  //
}

CategoryModel.prototype.name = "CategoryModel";
CategoryModel.prototype.idAttribute = "id_category";
CategoryModel.prototype.route = "apiCategoryModel";
CategoryModel.prototype.url = function () {
  return urlWithSyncVersion(`/category/${this.id}`);
};

export default CategoryModel;
