import LocalStorageModel from "../prototypes/LocalStorageModel";
import AddressModel from "@src/backbone/model/AddressModel";
import { app } from "@src/backbone/model/AppModel";
import ApiRequest from "@src/backbone/prototypes/ApiRequest";
import PostcodeModel, { IDistrict } from "@src/backbone/model/PostcodeModel";
import { ICartDeliveryOptionsVAD } from "@src/backbone/model/CartModel";
import {
  GATSBY_CONF_GOOGLE_ADDRESS_REG_EXP_DESCRIPTIONEND,
  GATSBY_CONF_GOOGLE_ADDRESS_REG_EXP_PRIMARY,
  GATSBY_CONF_GOOGLE_ADDRESS_REG_EXP_SECONDARY,
} from "@src/utils/constants";

const places: PlaceModel[] = [];
let placesService: google.maps.places.PlacesService;

export interface IPlaceModelAttributes {
  place_id?: string;
  description?: string;
  lat?: number;
  lng?: number;
  postal_code?: string;
  locality?: string;
  route?: string;
  street_number?: string;
  country?: string;
}

let localitySecondary: RegExp,
  localityDescriptionEnd: RegExp,
  localityPrimary: RegExp;
const initLocalityRegExp = () => {
  //
  // initialise les expressions régulières
  //
  if (!localitySecondary) {
    localitySecondary = new RegExp(GATSBY_CONF_GOOGLE_ADDRESS_REG_EXP_PRIMARY); // city is the first item of secondary_text
    localityPrimary = new RegExp(GATSBY_CONF_GOOGLE_ADDRESS_REG_EXP_SECONDARY);
    localityDescriptionEnd = new RegExp(
      GATSBY_CONF_GOOGLE_ADDRESS_REG_EXP_DESCRIPTIONEND
    );
  }
};

// TODO
class PlaceModel extends LocalStorageModel {
  translated: boolean = false;
  country_based: boolean = false;
  static getPlaceStrict(
    place_id: string,
    defaultModel?: AddressModel | PlaceModel
  ): PlaceModel {
    const place = PlaceModel.getPlace(place_id, defaultModel);
    if (!place) {
      throw new Error("Invalid place_id");
    }
    return place;
  }
  static getPlace(
    place_id?: string,
    defaultModel?: AddressModel | PlaceModel
  ): PlaceModel | undefined {
    //
    // originModel est une base pour afficher des données
    //
    if (!place_id) return;
    //
    let place = places.find((p: PlaceModel) => {
      return p.getPlaceId() === place_id;
    });
    //
    //
    if (!place || !place?.get("description")) {
      let options: object = { place_id: place_id };
      if (defaultModel) {
        options = defaultModel.pick(
          "place_id",
          "description",
          "id_postcode",
          "id_district",
          "latitude",
          "longitude",
          "locality",
          "city",
          "postcode",
          "postal_code",
          "types",
          "number",
          "street_number",
          "street",
          "route"
        );
      }
      if (!place) {
        place = new PlaceModel(options);
        places.push(place);
      } else {
        place.set(options);
        place.updateLocalStorageItem();
      }
    }
    return place;
  }

  name = "PlaceModel";

  defaults = () => ({
    trust: false,
    isSync: false,
    no_alternative: false,
    base64address: "",
    place_id: "",
    types: [],
  });

  url = (): string => {
    return `/place/${this.getId()}/${encodeURIComponent(
      this.getDescription()
    )}`;
  };

  storeDistances = {};

  getPlaceId(): string {
    return this.get("place_id");
  }

  initialize(attributes?: any, options?: any) {
    super.initialize(attributes, options);
    const place = this;
    place.storeDistances = {};
    place.softFetch();
    place.on("error", function () {
      console.warn("error", place.getDescription());
    });
    //
    place.updateBase64Address();
    place.on("sync", place.onPlaceSync);
    place.on("error", place.onError);
    // place.on('QuotaExceededError',onQuotaExceededError);
    if (/^fallback_place_/.test(place.getId())) {
      // si la place est issue du formulaire secondaire de saisie d'adresse
      // le "postcode" est converti en "postal_code"
      place.set({
        postal_code: place.get("postcode"),
      });
    }
  }

  updateBase64Address() {
    // met à jour la description encodé en base 64
    const place = this,
      description = place.getDescription();
    if (description) {
      place.set({ base64address: description }, { silent: true });
    } else if (!/^address_\d+/.test(place.getId())) {
      app.error("Invalid place description", {
        cart: app.getCart().toJSON(),
        place: place.toJSON(),
      });
    }
  }

  onPlaceSync() {
    this.updateBase64Address();
    this.set("isSync", true);
    this.setLocalStorageItem();
  }

  isSync(): boolean {
    return !!this.get("isSync");
  }

  // isGooglePlace() {
  //   // test si l'identifiant n'est celui pour signifier une adresse manuelle
  //   const id = this.getPlaceId();
  //   return (
  //     id && id.length > 0 && !/^id_district|unknown|^fallback_place_/.test(id)
  //   );
  // }

  /** @deprecated */
  // getDelays() {
  //   return this.pick(
  //     "delay_max_vad",
  //     "delay_min_vad",
  //     "delay_max_vae",
  //     "delay_min_vae"
  //   );
  // }

  // isLocalityMatching() {
  //   //
  //   // vérifie la localité
  //   // si la localité existe parmis les codes postaux
  //   //
  //   const model = this;
  //   let locality,
  //     isLocalityMatching = false;
  //   //
  //   if ((isLocalityMatching = model.get("isLocalityMatching")))
  //     return isLocalityMatching;
  //   //
  //   const placeLocalityMatching = GATSBY_CONF_PLACE_LOCALITY_MATCHING;
  //   if (placeLocalityMatching == "ignore") {
  //     // ignore le matching locality, attention, cela nuit gravement aux performances
  //     return true;
  //   }
  //   //
  //   locality = model.getLocality();
  //   if (locality) {
  //     isLocalityMatching = app.getPostcodes().isLocalityMatching(locality);
  //   } else {
  //     // sinon on essaie quand même d'avoir plus d'info via l’API Place
  //     console.warn(
  //       "difficulté à identifier la localité",
  //       model.getDescription(),
  //       model.get("structured_formatting")
  //     );
  //     isLocalityMatching = true;
  //   }
  //   model.set("isLocalityMatching", isLocalityMatching);
  //   return isLocalityMatching;
  // }

  onError(place: PlaceModel, response: JQuery.jqXHR) {
    if (response && response.responseJSON && response.responseJSON.token) {
      place.set("token", response.responseJSON.token);
      // _GOOGLE_PLACE_API_LOCKED_ is definitively set to true
      // if (app.c("_GOOGLE_PLACE_API_LOCKED_", true)) {
      //   place.alternativeFetch();
      // } else {
      //   place.noAlternative();
      // }
      place.noAlternative();
    }
  }

  noAlternative() {
    // trigger when fetch fail, with no alternative
    const place = this;
    place.set("no_alternative", true);
    app.error("PlaceModel noAlternative", place.toJSON());
    place.trigger("sync", place);
  }

  // @deprecated
  // alternativeFetch() {
  //   const place = this;
  //   try {
  //     if (!placesService) {
  //       placesService = new google.maps.places.PlacesService(
  //         document.createElement("div")
  //       );
  //     }
  //     app.error("PlaceModel alternativeFetch", place.toJSON());
  //     placesService.getDetails(
  //       { placeId: place.get("place_id") },
  //       (results, status) => place.onAlternativeSync(results, status)
  //     );
  //   } catch (e) {
  //     place.noAlternative();
  //   }
  // }

  async onAlternativeSync(
    results: google.maps.places.PlaceResult | null,
    status: google.maps.places.PlacesServiceStatus
  ) {
    const place = this,
      datas: IPlaceModelAttributes = {};
    if (status == "OK") {
      if (results && results.geometry) {
        const g = results.geometry;
        if (g.location) {
          const location = g.location;
          datas.lat = location.lat();
          datas.lng = location.lng();
          // results.geometry = {
          //     lat: datas.lat,
          //     lng: datas.lng
          // };
        }
      }
      if (results) {
        results.address_components?.forEach(function (v, k) {
          const componentKeys = [
            "postal_code",
            "locality",
            "route",
            "street_number",
            "country",
          ];
          componentKeys.forEach(function (componentKey) {
            if (v.types?.includes(componentKey)) {
              datas[
                componentKey as
                  | "postal_code"
                  | "locality"
                  | "route"
                  | "street_number"
                  | "country"
              ] = v.long_name;
            }
          });
        });
      }

      var options = {
        result: { r: JSON.stringify(results) },
      };
      try {
        const data = await ApiRequest("PlaceModel", options, place.attributes);
        console.info("place public feed success");
        place.set(data);
        place.trigger("sync", place);
      } catch (err) {
        console.warn(
          "place double check failed ",
          place.getDescription(),
          place.id
        );
        app.error("PlaceModelError", options);
      }
    } else {
      place.noAlternative();
    }
  }

  // fillLatLngFromGeometry() {
  //   //
  //   // utilisé dans le cas où la synchronisation du placeId a lieu via le SDK JS de Google
  //   //
  //   var place = this,
  //     geometry = place.get("geometry"),
  //     location;
  //   if (geometry && (location = geometry.location)) {
  //     place.set({
  //       lat: location.lat(),
  //       lng: location.lng(),
  //     });
  //   }
  // }

  getDescription(): string {
    let description = this.get("description");
    return description || this.get("formatted_address") || "";
  }

  getLocality(): string | undefined {
    const model = this;
    let locality: string = model.get("locality");
    if (locality) return locality;

    let secondary_text,
      main_text,
      localityExec,
      structured_formatting = model.get("structured_formatting");
    initLocalityRegExp();
    if (structured_formatting && structured_formatting.secondary_text) {
      secondary_text = structured_formatting.secondary_text;
      main_text = structured_formatting.main_text;
      localityExec = localitySecondary.exec(secondary_text);
    }
    if (localityExec) {
      locality = localityExec[1];
    } else if (main_text) {
      localityExec = localityPrimary.exec(main_text);
      if (localityExec) locality = localityExec[1];
    }
    if (!locality) {
      locality = model.get("city");
      const localityDescription = localityDescriptionEnd.exec(
        model.getDescription()
      );
      if (!locality && localityDescription) {
        // 20 Rue de Madrid, Sainte-Luce-sur-Loire, France
        // 20 Rue de Madrid, Saint-Acheul, Amiens, France
        locality = localityDescription[1];
      }
    }
    if (!!locality) {
      model.set("locality", locality);
    }
    return locality;
  }

  getPostcode(): PostcodeModel | undefined {
    const model = this;
    let id_postcode = model.get("id_postcode") || "",
      postcode_value = model.get("postal_code") || model.get("postcode"),
      postcode: PostcodeModel | undefined = app.getPostcode(id_postcode),
      lat: number,
      lng: number,
      locality,
      postcodes: PostcodeModel[];
    //
    if (!postcode) {
      locality = model.getLocality();
      lat = model.getLat();
      lng = model.getLng();

      const associationMode = app.c<string>(
        "_PLACE_ID_POSTCODE_ASSOCIATION_MODE_",
        "postal_code_first"
      );
      if (associationMode === "geocode_first") {
        // attention cette méthode retourne le premier résultat valide
        postcode = app.getPostcodes().getByLatLng(lat, lng);
      }
      if (!postcode && !!postcode_value) {
        // approximation via la donnée postal_code
        postcode = app.getPostcodeByValue(postcode_value);
      }

      if (!postcode) {
        // approximation par localité
        postcodes = !!locality
          ? app.getPostcodes().filterByLocalityName(locality)
          : [];
        switch (postcodes.length) {
          case 0:
            break; // cas où aucun code postal ne match
          case 1: // cas ou un seul code postal matche avec le nom
            postcode = postcodes[0];
            break;
          default: // cas ou plusieurs codes postaux match, on utilise le premier
            postcode = postcodes.find(
              (postcodeModel) => !!postcodeModel.getLocationDistrict(lat, lng)
            );
        }
      }
    }
    //
    return postcode;
  }
  //
  // getPostcodeValue(): string {
  //   const p = this.getPostcode();
  //   return p ? p.getPostcode() : "";
  // }

  getDistrict(): IDistrict | undefined {
    const model = this,
      postcode = model.getPostcode(),
      district = postcode
        ? postcode.getDistrict(model.get("id_district"))
        : false;
    if (district) {
      return district;
    }
    if (postcode) {
      return postcode.getLocationDistrict(model.getLat(), model.getLng());
    }
  }

  getDistrictId(): string | undefined {
    return this.getDistrict()?.id_district || this.get("id_district");
  }

  // tryDistrict() {
  //   // méthode qui essaie de trouver le district si le serveur n'y arrive pas…
  //   var place = this,
  //     district = place.getDistrict(),
  //     postcode = place.getPostcode();
  //   if (district) return district;
  //   if (postcode) {
  //     district = postcode.getLocationDistrict(place.getLat(), place.getLng());
  //   }
  //   if (district) {
  //     console.log("done !", district.id_district);
  //     place.set("id_district", district.id_district);
  //     return district;
  //   }
  // }

  // getLatLng() {
  //   const lat = this.getLat(),
  //     lng = this.getLng();
  //   if (lat && lng) {
  //     return {
  //       lat: lat,
  //       lng: lng,
  //     };
  //   } else {
  //     return false;
  //   }
  // }

  getLat() {
    const lat = this.get("lat") || this.get("latitude");
    return parseFloat(lat);
  }

  getLng() {
    const lng = this.get("lng") || this.get("longitude");
    return parseFloat(lng);
  }

  // getStores(datetime) {
  //     var model = this,
  //         stores = [],
  //         postcode = model.getPostcode(),
  //         id_district = model.getDistrictId();
  //
  //     if (postcode) {
  //         stores = app.getStores().findByPostcode({
  //             id_postcode: postcode.id,
  //             id_district: id_district
  //         }, datetime);
  //         if (!(stores.length > 0)) {
  //             stores = [];
  //         } else if (stores.length == 1) {
  //             //console.log('%cpostcode','color:#f70;font-weight:bold;',this.getDescription(),stores[0].getName());
  //         }
  //     } else {
  //         //console.log('NONNONONON postcode',this.getDescription());
  //     }
  //     //
  //     // recherche alternative pour certains place_id,
  //     // on utilise une approximation par distance
  //     //
  //     if (model.isStoreDistanceApproximationAvailable()) {
  //         //
  //         //
  //         var latLng = model.getLatLng(),
  //             distanceStores = [];
  //         console.log('isStoreDistanceApproximationAvailable', latLng);
  //         if (latLng) {
  //             app.getStores().each(function (store) {
  //                 var distance = DistanceKm(latLng.lat, latLng.lng, store.getLatitude(), store.getLongitude());
  //                 //
  //                 // TODO static 25 km radius
  //                 if (distance < 25) {
  //                     distanceStores.push(store);
  //                     store._distance = model.storeDistances[store.id] = distance;
  //                 }
  //                 //console.log(distance);
  //             });
  //             // on les tries par distance
  //             distanceStores = _(distanceStores).sortBy('_distance');
  //             // on ajoute les boutiques si elles ne préexistent pas (via postcode)
  //             stores = _(stores).union(distanceStores);
  //         } else if (model.isSync()) {
  //             // si on est sur une place qui n'a pas de données, on essaie de fetcher, pour avoir
  //             //console.log('place model fetch',this.getDescription());
  //             console.log('WARNING : PLACE NOT FETCHING');
  //             //model.fetch();
  //         }
  //     }
  //     //
  //     //console.log('%cPlace stores => ','color:#d63',model.getDescription(),stores);
  //     return _(stores);
  // }

  //
  /** @deprecated */
  // parseAddressComponents() {
  //   var model = this,
  //     typesMap = [
  //       "street_number",
  //       "route",
  //       "country",
  //       "locality",
  //       "postal_code",
  //     ],
  //     s: any = {};
  //   model.get("address_components").forEach((comp: any) => {
  //     const firstType = comp.types[0];
  //     if (typesMap.includes(firstType)) {
  //       s[firstType] = comp.long_name;
  //     }
  //   });
  //   console.log("parseAddressComponents", s);
  //   model.set(s);
  // }

  // Données Google
  getCountry(): string {
    return this.get("country");
  }

  getStreet(): string {
    return (
      this.get("street") ||
      this.get("route") ||
      this.get("street_address") ||
      ""
    );
  }

  getStreetNumber(): string {
    return this.get("street_number") || this.get("number") || "";
  }

  getTypes(): string[] {
    // données Google | street_address | route | locality | sublocality | ...
    // voir https://developers.google.com/places/supported_types?hl=fr#table2
    return this.get("types") || [];
  }

  // isType(
  //   type: "street_address" | "locality" | "route" | "sublocality"
  // ): boolean {
  //   const types = this.getTypes();
  //   return types.includes(type);
  // }

  // isStreetAddress() {
  //   return this.isType("street_address");
  // }
  //
  // isStoreDistanceApproximationAvailable() {
  //   // si on est sur une localité, on ajoute une approximation par distance
  //   //console.log('%c'+this.getDescription(),'color:blue',' utilise l’approximation par distance => ',this.isType('locality'),' => Postcode =>',this.getPostcode() ? this.getPostcode().getPostcode():'inconnu')
  //   //console.log('%c'+this.getTypes().join('/'),'color:red',' utilise l’approximation par distance => ',this.isType('locality'),' => Postcode =>',this.getPostcode() ? this.getPostcode().getPostcode():'inconnu')
  //   return this.isType("locality");
  // }

  // retourne un objet préformaté avec toutes les options pour créer une adresse
  getCartDeliveryOptions(): ICartDeliveryOptionsVAD {
    const place = this,
      postcode = place.getPostcode(),
      postal_code = place.get("postal_code") || place.get("postcode") || "",
      id_district = place.getDistrictId() || "",
      optionsMap: ICartDeliveryOptionsVAD = {
        vae: 0,
        alias: place.get("alias") || "", // address alias
        type: place.get("type") || 0, // address type (icon)
        description: place.getDescription(),
        street: place.getStreet(),
        latitude: place.getLat(),
        longitude: place.getLng(),
        city: place.getLocality(),
        country: place.getCountry(),
        number: place.getStreetNumber(),
        place_id: place.getPlaceId(),
        postcode: postal_code || postcode?.getPostcode() || "",
        id_postcode: postcode?.getId() || place.get("id_postcode"),
        id_district,
      };
    return optionsMap;
  }
}

PlaceModel.prototype.name = "PlaceModel";
PlaceModel.prototype.idAttribute = "place_id";
PlaceModel.prototype.translated = false;
PlaceModel.prototype.country_based = false;

export default PlaceModel;
