import SuspenseAfterInteraction from "@src/lib/AfterInteraction/SuspenseAfterInteraction";
import { useAppSelector } from "@src/store/hooks";
import { authSlice } from "@src/store/reducers/AuthSlice";
import useOpenAuthModal from "@src/store/reducers/AuthSlice/selectors/useOpenAuthModal";
import React, { Suspense } from "react";
import { useDispatch } from "react-redux";
import SuspenseAfterRequired from "@src/lib/AfterRequired/SuspenseAfterRequired";

const AuthenticationContentDrawer = React.lazy(
  () =>
    import(
      "@src/components/feedback/AuthenticationContent/AuthenticationContentDrawer"
    )
);

const AuthDrawerProvider = () => {
  const openAuthModal = useOpenAuthModal();
  const onSuccess = useAppSelector(({ auth }) => auth.openAuthModal?.onSuccess);
  const dispatch = useDispatch();

  const handleCloseDrawer = () => {
    dispatch(
      authSlice.actions.setOpenAuthModel({
        ...openAuthModal,
        modalOpen: false,
      })
    );
  };

  const { modalOpen = false, shouldPreload = false } = { ...openAuthModal };

  return (
    <SuspenseAfterRequired required={modalOpen || shouldPreload}>
      <Suspense>
        <AuthenticationContentDrawer
          open={openAuthModal?.modalOpen}
          options={openAuthModal}
          onSuccess={onSuccess}
          onClose={handleCloseDrawer}
        />
      </Suspense>
    </SuspenseAfterRequired>
  );
};

export default AuthDrawerProvider;
