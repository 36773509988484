import ChallengeJs from "./ChallengeJs";
import _ from "underscore";
import { app } from "../model/AppModel";
import { routesApi } from "../provider/routes";
import { AxiosRequestConfig } from "axios";

const ApiRequest = (
  type: string,
  data?: any,
  urlOptions: any = {},
  options?: any & {
    cancelToken?: AxiosRequestConfig<any>["cancelToken"];
  }
) => {
  urlOptions = {
    iso_lang: app.isoLang(),
    ...urlOptions,
  };
  //
  // TODO: implement url
  const route = routesApi[type];
  if (!route) throw new Error("Invalid route type: " + type);
  const url = `/${_.template(route || "")(urlOptions)}`;

  // ajax overrides options
  const o = {
    ...{
      url,
      type: "POST",
      dataType: "json",
      data,
    },
    ...options,
  };

  return ChallengeJs(o);
};

export default ApiRequest;
