//
// les traductions françaises "momentLocale/fr" sont inclues au build principal
// pour des raisons de performance.
// il est possible d'imaginer un build par langue, mais pour des raisons d'infra et de temps de compilation,
// je préfère pour l'instant faire comme ça.
//

import moment, { Moment, MomentInput } from "moment";
import { app } from "../model/AppModel";
import { CalendarSpec } from "moment/moment";

//
// formatage des dates calendaires
//

const momentFormats = {
  getFormats: function () {
    return {
      sameDay: function (now: MomentInput) {
        // changement de service à 16h
        return this.isBefore(moment(now).startOf("day").add(16, "h"))
          ? app.t("[ce midi à] HH:mm", "Datetime")
          : app.t("[ce soir à] HH:mm", "Datetime");
      },
      nextDay: function (now: MomentInput) {
        // changement de service demain à 16h (+40h)
        return this.isBefore(moment(now).startOf("d").add(40, "h"))
          ? app.t("[demain midi à] HH:mm", "Datetime")
          : app.t("[demain soir à] HH:mm", "Datetime");
      },
      sameElse: function (now: MomentInput) {
        return this.isAfter(moment(now).startOf("y"))
          ? app.t("[le] Do MMMM [à] HH:mm", "Datetime")
          : app.t("[le] Do MMMM YYYY [à] HH:mm", "Datetime");
      },
    } as CalendarSpec & Moment;
  },
};

export default momentFormats;
