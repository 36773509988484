import styled from "styled-components";
import { IProductDetailsSelectionProductProps } from "./ProductDetailsSelectionProduct";
import { $white } from "../../../../Utils/color";
import { useTheme } from "../../../../Themes/defaultTheme";

export const _ProductDetailsSelectionProductRoot = styled.div<{
  $selected?: boolean;
  $disabled?: boolean;
  $editable?: boolean;
  $vertical?: boolean;
  $size?: IProductDetailsSelectionProductProps["size"];
}>(({ $selected, $size, $disabled, $editable, $vertical }) => {
  const minHeightMap: Record<
    NonNullable<IProductDetailsSelectionProductProps["size"]>,
    number
  > = {
    small: 52,
    medium: 64,
    large: 88,
  };

  return {
    opacity: $disabled ? 0.5 : undefined,
    pointerEvents: $disabled || !$editable ? "none" : undefined,
    minHeight: $size ? minHeightMap[$size] : 64,
    background: $vertical ? "transparent" : !$selected ? "#F4F3F1" : "#EAE6E0",
    borderRadius: 4,
    position: "relative",
    display: "flex",
    flexDirection: $vertical ? "column" : "row",
    flexWrap: $vertical ? "wrap" : "nowrap",
    gap: $vertical ? 12 : $size === "small" ? 8 : 16,
    alignItems: "center",
    padding: $vertical ? 0 : $size === "large" ? "0px 16px" : "2px 16px",
    cursor: "pointer",
    width: $vertical ? "100%" : undefined,
    height: "100%",
    "-webkit-tap-highlight-color": "rgba(0, 0, 0, 0)",
    ".quantifier": {
      position: "absolute",
      right: 10,
      marginTop: -60,
    },
    ".ro-quantifier--mobile-alt": {
      display: "none",
    },
    ".ro-qty__mobile-root": {
      position: "absolute",
      right: 10,
      bottom: 10,
    },
  };
});

export const _ProductDetailsSelectionProductActionsWrapper = styled.div<{}>(
  ({}) => {
    return {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      gap: 16,
      marginLeft: "auto",
      alignItems: "center",
    };
  }
);

export const _ProductDetailsSelectionProduct_CheckIconWrapper = styled.div<{
  $top?: number;
}>(({ $top = 8 }) => {
  return {
    position: "absolute",
    zIndex: 9,
    top: $top,
    left: 8,
    width: 24,
    height: 24,
  };
});

export const _ProductDetailsMaxWarning = styled.div<{}>(() => {
  const { color } = useTheme();
  return {
    ["@keyframes _ProductDetailsMaxWarning"]: {
      "0%": {
        opacity: 1,
      },
      "10%": {
        opacity: 1,
      },
      "100%": {
        opacity: 0,
      },
    },
    display: "flex",
    gap: 8,
    fontSize: 12,
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    padding: 10,
    background: "#E6610033",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    animation: "_ProductDetailsMaxWarning 2s 1 both",
    pointerEvents: "none",
    [".ro-typography"]: {
      padding: "2px 6px 3px 8px",
      background: color.warning,
      borderRadius: 12,
    },
  };
});

export const _IconWhiteWrapper = styled.div<{}>(() => {
  return {
    position: "relative",
    display: "flex",
    width: 24,
    height: 24,
    ["&:before"]: {
      content: '" "',
      display: "inline-block",
      width: 20,
      height: 20,
      borderRadius: "50%",
      background: "white",
      position: "absolute",
      left: "50%",
      top: "50%",
      zIndex: 0,
      transform: "translate(-50%, -50%)",
    },
    ["& svg"]: {
      zIndex: 1,
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%,-50%)",
    },
  };
});

export const _AnimatedIconWrapper = styled.div<{
  $visible?: boolean;
  $transitionMode?: "inline" | "fade-in";
}>(({ $visible, $transitionMode = "inline" }) => {
  const transitionInline = $visible
    ? "width 0.5s ease, opacity 0.3s ease 0.13s, transform 0.5s ease"
    : "width 0.5s ease, opacity 0.3s ease, transform 0.5s ease";
  const transitionAbsolute = $visible
    ? "transform 0.05s ease"
    : "transform 0.05s ease";
  return {
    opacity: $visible ? 1 : 0,
    width: $visible ? 24 : 0,
    transform:
      $transitionMode === "inline"
        ? $visible
          ? "translateX(0)"
          : "translateX(-10px)"
        : $visible
        ? "scale(1)"
        : "scale(0)",
    transition: $transitionMode ? transitionInline : transitionAbsolute,
  };
});
