import { ProductApiData } from "@src/interface/Product";
import { RollingStartEndpointBuilder } from "..";
import { IParticipationForm } from "@src/components/widgets/components/LandingStandardWidget/Components/GameParticipationModal/ParticipationForm/ParticipationForm";

export const createGameParticipantEndpoints = (
  builder: RollingStartEndpointBuilder
) => ({
  addGameParticipant: builder.mutation<ProductApiData, IParticipationForm>({
    query: (body) => ({
      url: `/gameparticipant`,
      method: "POST",
      body: {
        ...body,
        picto_id: body.pictoID,
        pictoID: undefined,
      },
    }),
  }),
});
