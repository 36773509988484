import React from "react";
import { MessageRenderFC } from "@src/components/feedback/AuthenticationContent/Form/InputRender";
import { useTranslation } from "@src/hooks/useTranslation";
import FlexBox from "design-system/src/Components/FlexBox/FlexBox";
import Typography from "design-system/src/Foundation/Typography/Typography";
import Icon from "design-system/src/Foundation/Icon/Icon";

const PasswordValidationMessage: MessageRenderFC = ({
  fieldState,
  ...field
}) => {
  const { value } = field;
  const { t } = useTranslation(["Authentification", "Account"]);
  //
  const _PASSWORD_MIN_LENGHT_ = parseInt(
    process.env.GATSBY_CONF_PASSWORD_MIN_LENGHT || "6"
  );
  const _PASSWORD_FORCE_NUMBER_ = !!parseInt(
    process.env.GATSBY_CONF_PASSWORD_FORCE_NUMBER || "1"
  );
  const _PASSWORD_FORCE_LOWERCASE_ = !!parseInt(
    process.env.GATSBY_CONF_PASSWORD_FORCE_LOWERCASE || "1"
  );
  const _PASSWORD_FORCE_CAP_ = !!parseInt(
    process.env.GATSBY_CONF_PASSWORD_FORCE_CAP || "1"
  );
  const _PASSWORD_FORCE_SPECIAL_CHAR_ = !!parseInt(
    process.env.GATSBY_CONF_PASSWORD_FORCE_SPECIAL_CHAR || "0"
  );

  const rules: {
    regexp: RegExp;
    message: string;
  }[] = [
    ...(!!_PASSWORD_FORCE_LOWERCASE_
      ? [
          {
            regexp: /[a-z]/,
            message: t("une minuscule", "Authentification"),
          },
        ]
      : []),
    ...(!!_PASSWORD_FORCE_CAP_
      ? [
          {
            regexp: /[A-Z]/,
            message: t("une majuscule", "Authentification"),
          },
        ]
      : []),
    ...(!!_PASSWORD_FORCE_NUMBER_
      ? [
          {
            regexp: /[0-9]/,
            message: t("un chiffre", "Authentification"),
          },
        ]
      : []),
    ...(!!_PASSWORD_FORCE_SPECIAL_CHAR_
      ? [
          {
            regexp: /\W+/,
            message: t("un caractère spécial", "Authentification"),
          },
        ]
      : []),
    ...(!!_PASSWORD_MIN_LENGHT_
      ? [
          {
            regexp: new RegExp(`.{${_PASSWORD_MIN_LENGHT_},}`),
            message: t("%s caractères minimum", "Account", {
              vsprintfArgs: [_PASSWORD_MIN_LENGHT_],
            }),
          },
        ]
      : []),
  ];

  return (
    <FlexBox wrap={"wrap"} gap={6}>
      {rules.map(({ regexp, message }, n) => {
        const matchRule = regexp.test(value || "");
        const color = !value
          ? "greyTextDisabled"
          : matchRule
          ? "success"
          : "error";
        return (
          <Typography
            as={"span"}
            variant={"ui4"}
            key={n}
            color={color}
            style={{ display: "flex", alignItems: "center", gap: 4 }}
          >
            <Icon icon={"check_mark_16"} size={16} />
            {message}
          </Typography>
        );
      })}
    </FlexBox>
  );
};

export default PasswordValidationMessage;
