const MysqlDatetimeFormat = function (datetime: string) {
  var datetimeExp = /\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/g,
    test = datetimeExp.test(datetime);
  if (!test) {
    throw new Error("Invalid Datetime : " + datetime);
  }
  return new Date(datetime.replace(/-/g, "/"));
};

export default MysqlDatetimeFormat;
