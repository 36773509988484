import { CustomerData } from "@src/interface/Customer";
import { setCurrentCustomer } from "@src/store/reducers/CustomerSlice";
import { RollingStartEndpointBuilder } from "..";

export interface IPostRegisterArgs {
  token?: string;
  action?: string;
  id_customer_comin?: string;
  email: string;
  firstname: string;
  lastname: string;
  birthday: string;
  phone: string;
  signupPassword: string;
  password_v2: string;
  optin?: boolean;
  sms?: boolean;
  mail_alert?: boolean;
  checkyoung?: "on";
}

export const createCustomerEndpoints = (
  builder: RollingStartEndpointBuilder
) => ({
  getCurrentCustomer: builder.query<CustomerData, void>({
    query: () => ({
      url: "/customer/current",
      method: "GET",
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      try {
        const res = await queryFulfilled;
        dispatch(setCurrentCustomer(res.data));
      } catch (error) {}
    },
  }),
  postLikeProduct: builder.mutation<
    CustomerData,
    { body: { id_product: string | number } }
  >({
    query: ({ body }) => ({
      url: "/customer/current/likeproduct",
      method: "POST",
      body,
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      try {
        const res = await queryFulfilled;
        dispatch(setCurrentCustomer(res.data));
      } catch (error) {}
    },
  }),
  sendSponsorshipByEmail: builder.query<
    CustomerData,
    {
      body: {
        emailList: string;
      };
    }
  >({
    query: ({ body }) => ({
      url: "/customer/current/sponsorshipsendbyemail",
      method: "POST",
      body,
    }),
  }),
  postRegisterNewsLetter: builder.mutation<CustomerData, { email: string }>({
    query: ({ email }) => ({
      url: "/customer/current/registernewsletter",
      method: "POST",
      body: {
        email,
      },
    }),
  }),
  postNewRegisterNewsLetter: builder.query<
    CustomerData,
    { email: string; action?: string }
  >({
    query: ({ email, action = "register-newsletter" }) => ({
      url: "/customer/new/registernewsletter",
      method: "POST",
      body: {
        email,
        action,
      },
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      try {
        const res = await queryFulfilled;
        dispatch(setCurrentCustomer(res.data));
      } catch (error) {}
    },
  }),
  postRegister: builder.query<CustomerData, IPostRegisterArgs>({
    query: (data) => ({
      url: "/customer/new/add",
      method: "POST",
      body: {
        ...data,
        checkyoung: "on",
      },
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      try {
        const res = await queryFulfilled;
        dispatch(setCurrentCustomer(res.data));
      } catch (error) {}
    },
  }),
});
