import { site_vars } from "../provider/siteVars";
import _ from "underscore";
import S from "string";
import { app } from "../model/AppModel";
import { routes } from "../provider/routes";
import store from "@src/store";
import { GATSBY_SOURCE_SUSHISHOP_BASE_URL } from "@src/utils/constants";

// Object de portée globale pour fabriquer les URL
declare global {
  interface Window {
    UrlFactory: any;
  }
}
let domain_sharding_img = site_vars.domain_sharding_img;

// pour ne pas utiliser de domain sharding, laisser vide la configuration _DOMAIN_SHARDING_IMG_
if (domain_sharding_img && domain_sharding_img.length > 0) {
  domain_sharding_img =
    typeof domain_sharding_img === "string"
      ? domain_sharding_img.split(",")
      : domain_sharding_img;
} else {
  domain_sharding_img = undefined;
}

const getIsoLang = () => {
  const currentPage = store.getState().page.currentPageContextIntl;
  return currentPage && currentPage.lang ? currentPage.lang : "fr";
};

const getRouteUrl = (
  id_route: string | number,
  ignoreBaseUrl?: boolean,
  ignoreLang?: boolean
) => {
  const path = routes[id_route] || "";
  return _.template(
    (ignoreBaseUrl ? "" : GATSBY_SOURCE_SUSHISHOP_BASE_URL) +
      "/" +
      (ignoreLang ? "" : (getIsoLang() || "fr").toLowerCase() + "/") +
      (path || "")
  );
};

function getDomainShardingImgUrl(path: string) {
  let base_url = GATSBY_SOURCE_SUSHISHOP_BASE_URL;
  if (domain_sharding_img) {
    let h = 0,
      l = domain_sharding_img.length,
      index = 0;
    if (l > 1) {
      // si on utilise plusieurs sous-domaine
      // on utilise le chemin pour obtenir l'index du sous-domaine
      for (var i = 0; i < path.length; i++) {
        h += path.charCodeAt(i);
      }
      index = h % l;
    }
    // on obtient le sous-domaine
    base_url = site_vars.http_mode + "://" + domain_sharding_img[index] + "/";
  }
  return base_url + path;
}

const UrlFactory = {
  // retourne l’url complete
  getUrl: function (
    route: any,
    options?: Record<string, any>,
    ignoreBaseUrl: boolean = false,
    ignoreLang: boolean = false
  ) {
    if (!options) {
      options = {};
    }
    options.c = app.c;
    if (!options.rewrite) {
      options.rewrite = "";
    }
    return UrlFactory.getUrlTemplate(route, ignoreBaseUrl, ignoreLang)(options);
  },
  // retourne la route
  getRoute: function (
    route: any,
    options: any,
    ignoreBaseUrl: boolean,
    ignoreLang: boolean
  ) {
    if (_.isUndefined(ignoreBaseUrl)) ignoreBaseUrl = true;
    if (_.isUndefined(ignoreLang)) ignoreLang = true;
    return UrlFactory.getUrl(route, options, ignoreBaseUrl, ignoreLang);
  },
  getUrlTemplate: function (
    route: any,
    ignoreBaseUrl?: boolean,
    ignoreLang?: boolean
  ) {
    return getRouteUrl(route, ignoreBaseUrl, ignoreLang);
  },
  getUrlPicture: function (
    id_picture: string,
    type: string,
    format: string,
    rewrite: any,
    extension: string
  ) {
    const path =
      type +
      "-" +
      id_picture +
      "-" +
      format +
      "/" +
      S(rewrite).slugify().s +
      "." +
      extension.toLowerCase();
    return getDomainShardingImgUrl(path);
  },
  getDomainShardingImgUrl,
  getThemeUrl: function () {
    return site_vars.theme_url;
  },
};

if (typeof window !== "undefined") {
  // Global scope
  window.UrlFactory = UrlFactory;
}

export default UrlFactory;
