import React, { lazy, Suspense } from "react";
import { IFlagProps } from "./Flag";
import { _FlagImage, _FlagRoot } from "./Flag.styled";
const Flag = lazy(() => import("./Flag"));

const LazyFlag = (props: IFlagProps) => {
  return (
    <Suspense
      fallback={
        <_FlagRoot>
          <_FlagImage />
        </_FlagRoot>
      }
    >
      <Flag {...props} />
    </Suspense>
  );
};

export default LazyFlag;
