import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import { responsiveMap } from "../_utils/responsiveObserve";

interface _ColStyled {
  $prefixCls: string;
}
const gridColumns = 12;

const percentage = (value: number) => value * 100 + "%";

export const _ColStyled = styled("div")<_ColStyled>(({ $prefixCls }) => {
  return ``;
  /* const loopGridColumns = (
    columns: number,
    cls: string
  ): FlattenSimpleInterpolation => {
    const result: string[] = [];

    for (let index = columns; index >= 0; index--) {
      if (index > 0) {
        result.push(`
          &.${$prefixCls}${cls}-${index} {
            display: block;
            flex: 0 0 ${percentage(index / gridColumns)};
            max-width: ${percentage(index / gridColumns)};
          }
          &.${$prefixCls}${cls}-push-${index} {
            left: ${percentage(index / gridColumns)};
          }
          &.${$prefixCls}${cls}-pull-${index} {
            right: ${percentage(index / gridColumns)};
          }
          &.${$prefixCls}${cls}-offset-${index} {
            margin-left: ${percentage(index / gridColumns)};
          }
          &.${$prefixCls}${cls}-order-${index} {
            order: index;
          }
        `);
      } else if (index === 0) {
        result.push(`
          &.${$prefixCls}${cls}-${index} {
            display: none;
          }
          &.${$prefixCls}-push-${index} {
            left: auto;
          }
          &.${$prefixCls}-pull-${index} {
            right: auto;
          }
          &.${$prefixCls}${cls}-push-${index} {
            left: auto;
          }
          &.${$prefixCls}${cls}-pull-${index} {
            right: auto;
          }
          &.${$prefixCls}${cls}-offset-${index} {
            margin-left: 0;
          }
          &.${$prefixCls}${cls}-order-${index} {
            order: 0;
          }
        `);
      }
    }
    return result;
  };
  const makeGrid = (breakpoint: string): FlattenSimpleInterpolation => {
    return loopGridColumns(gridColumns, breakpoint);
  };

  return css`
    &.${$prefixCls} {
      position: relative;
      max-width: 100%;
      min-height: 1px;
    }

    ${makeGrid("")}
    ${makeGrid("-xs")}

    @media ${responsiveMap.sm} {
      ${makeGrid("-sm")}
    }
    @media ${responsiveMap.md} {
      ${makeGrid("-md")}
    }
    @media ${responsiveMap.lg} {
      ${makeGrid("-lg")}
    }
    @media ${responsiveMap.xl} {
      ${makeGrid("-xl")}
    }
    @media ${responsiveMap.xxl} {
      ${makeGrid("-xxl")}
    }
  `; */
});
