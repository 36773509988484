import {
  EqualityFn,
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
  useStore,
} from "react-redux";
import store, { RootState } from ".";
import { hybridRoutingAdapter } from "./reducers/HybridRoutingSlice";
import isEqual from "lodash/isEqual";

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = <TSelected>(
  selector: (state: RootState) => TSelected,
  equalityFn?: EqualityFn<TSelected>
): TSelected => useSelector<RootState, TSelected>(selector, isEqual);
export const useAppStore = () => useStore<RootState>();

// selectors

export const useFrontControllerSelector = () => {
  return hybridRoutingAdapter.getSelectors<RootState>(
    (state) => state.hybridRouting
  );
};

export const useVisibleStores = () => {
  const { stores, benefitsFilter } = useAppSelector(
    ({ storeLocator }) => storeLocator
  );

  return stores.filter((store) => {
    const hasFilter = benefitsFilter.length > 0;
    if (!hasFilter) {
      return true;
    }

    let benefitVisible = false;
    if (store.benefit && benefitsFilter.length > 0) {
      const storeBenefits = new Set([
        ...(store.benefit.primary || []),
        ...(store.benefit.secondary || []),
      ]);
      benefitVisible = true;
      for (let index = 0; index < benefitsFilter.length; index++) {
        const element = benefitsFilter[index];
        if (!storeBenefits.has(element)) {
          benefitVisible = false;
          break;
        }
      }
    }

    return benefitVisible;
  });
};
