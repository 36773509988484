import ObjectModel from "../prototypes/ObjectModel";
import _ from "underscore";
import { app } from "./AppModel";
import { vsprintf } from "sprintf-js";
// import SidedishCollection from "./SidedishCollection";
import ProductModel from "./ProductModel";
import SidedishCollection from "./SidedishCollection";

export interface SidedishAttributes extends Backbone.ObjectHash {
  auto?: "0" | "1";
  button_cancel?: string;
  button_ok: string;
  compo_groupe?: string | "B1" | "F1";
  description?: string;
  description_partner?: string;
  display_free?: boolean;
  display_plus?: true;
  display_size?: 0 | 1 | 2;
  error_description?: string;
  evening: boolean;
  id_category: string;
  id_cross_selling: string;
  id_parent: string;
  id_product_auto?: string;
  id_product_default: string[];
  id_product_immutable: string[];
  id_product_max: string[];
  is_attached?: boolean;
  is_free?: boolean;
  is_pack?: boolean;
  is_required?: boolean;
  linked_to_crosselling?: string;
  midday?: boolean;
  mode?: string | "1";
  multiple_by_product?: boolean;
  pos_custom_box?: number[];
  quantity_max?: number;
  quantity_min?: number;
  selection?: SidedishSelection[];
  subtitle?: string;
  template?: string;
  title?: string;
  title_partner: string;
  url?: string;
}

export interface SidedishSelection {
  id: string;
  p: ProductModel;
  quantity: number;
  default: number;
  immutable: number;
  positions: any[];
  invalid?: boolean;
}

type ICreateSelection = (
  id_product: any,
  qty: number,
  defaultQty: number,
  immutableQty: number,
  shiftPosition: () => any
) => SidedishSelection;

const CUSTOM_BOX_42_LOCATIONS = _([
  { id: "A", type: "rolls", o: "h", x: 248, y: 200, z: 6 },
  { id: "B", type: "rolls", o: "h", x: 552, y: 200, z: 7 },
  { id: "C", type: "rolls", o: "v", x: 400, y: 400, z: 1 },
  { id: "D", type: "rolls", o: "h", x: 248, y: 600, z: 8 },
  { id: "E", type: "rolls", o: "h", x: 552, y: 600, z: 9 },
  { id: "F", type: "rolls-div", o: "v", x: 178, x_alt: 622, y: 400, z: 5 },
  { id: "G", type: "sushis-div", o: "v", x: 400, y: 160, y_alt: 640, z: 4 },
  { id: "H", type: "sushis", o: "v", x: 268, y: 400, z: 2 },
  { id: "I", type: "sushis", o: "v", x: 532, y: 400, z: 3 },
]);

//
// Model Sidedish
//

const createSelectionItem: ICreateSelection = (
  id_product: any,
  qty: number,
  defaultQty: number,
  immutableQty: number,
  shiftPosition: { (): any; (): any }
) => {
  const positions = [],
    product = app.getProduct(id_product) || new ProductModel({ id_product });
  let invalid = false;
  if (product && !product.isBuyableInContext()) {
    // si le produit n'est pas disponible, on affecte pas de quantité
    if (immutableQty) invalid = true;
    qty = defaultQty = immutableQty = 0;
  }

  for (var i = 0; i < qty; i++) positions.push(shiftPosition());

  return {
    id: id_product,
    p: product,
    quantity: qty,
    default: defaultQty,
    immutable: immutableQty,
    positions: positions,
    invalid: invalid,
  };
};

class SidedishModel extends ObjectModel<SidedishAttributes> {
  template: any;

  name = "SidedishModel";
  idAttribute = "id_cross_selling";

  static createSelectionItem: ICreateSelection;

  id_parent: string | undefined;
  midday: boolean = false;
  evening: boolean = false;
  id_category: string = "";
  id_product_default: any;
  id_product_immutable: string[] = [];
  pos_custom_box: any[] = [];
  id_cross_selling: string = "";
  quantity: any;
  immutable: any;
  p!: ProductModel;
  default: number = 0;
  positions: any[] = [];
  selection: any[] | undefined;

  defaults() {
    return {
      selection: [],
    };
  }
  initialize(attributes?: any, options?: any): void {
    const model = this;
    // ObjectModel constructor heritage
    super.initialize.apply(model, [attributes, options]);
    //
    if (!model.get(model.idAttribute)) {
      model.attributes[model.idAttribute] = model.get("id");
    }
  }
  getId() {
    return this.get(this.idAttribute);
  }
  getDisplaySize() {
    return this.get("display_size") || 0;
  }
  getDisplayPlus() {
    return this.get("display_plus");
  }
  getDefaultValue() {
    return this.get("id_product_default");
  }
  getErrorDescription() {
    return this.get("error_description") || "";
  }
  getCountdownDescription() {
    const message = this.getErrorDescription() || "";
    const string = '<span class="countdown">' + this.getMinimum() + "</span>";
    return vsprintf(message, [string]);
  }
  getTemplateName() {
    return this.get("template");
  }
  getTemplate() {
    //   TODO: implement getTemplate
    /* var model = this,
      templateName = model.getTemplateName(),
      template = templateSidedishesDefault;

    switch (templateName) {
      case "grouped_list":
        template = templateSidedishesListGrouped;
        break;
      case "custom_box_42":
        template = templateSidedishCustomBox42;
        break;
      default:
        if (templateName) {
          template = window.templates[templateName];
          if (!template) template = templateSidedishesDefault;
        }
    }
    hooks.execHook("getSidedishTemplate", templateName, function (tpl) {
      template = tpl;
    });
    return template; */
    return "";
  }
  execTemplate(index: any, product: any, isCartProduct: any) {
    // TODO: implement execTemplate
    /* var sidedish = this,
      template = sidedish.getTemplate();
    return template({
      index: index,
      product: product,
      sidedish: sidedish,
      key: "sidedish" + sidedish.id,
      products: sidedish.getCategoryProducts(),
      title: sidedish.getTitle(),
      isCartProduct: isCartProduct,
    }); */
    return "";
  }
  isRoot() {
    return this.get("id_parent") === "0";
  }
  getChild(id: any) {
    return this.getChildren().findWhere({ id: id });
  }
  getChildren() {
    return (this.collection as unknown as SidedishCollection)?.filtredChild(
      this.id.toString()
    );
  }
  eachChildren(iteratee: { (sidedish: any): void; (sidedish: any): void }) {
    this.getChildren().each(iteratee);
  }
  // catégorie produit associée
  getCategory() {
    return app.getCategory(this.get("id_category"));
  }
  getCategoryName() {
    var category = this.getCategory();
    return category ? category.getName() : " - ";
  }
  getCategoryDescription() {
    var category = this.getCategory();
    return category ? category.getDescription() : " - ";
  }
  getTitle() {
    return this.get("title") || this.getCategoryName();
  }
  formatTitle() {
    var model = this,
      title = model.getTitle(),
      url = model.get("url");
    if (url) {
      title = '<a href="' + url + '" target="_blank">' + title + "</a>";
    }
    return title;
  }
  getDescription() {
    return this.get("description") || this.getTitle();
  }
  getButtonCancel() {
    return this.get("button_cancel") || false;
  }
  getButtonOk() {
    return this.get("button_ok") || false;
  }
  getCategoryProducts() {
    const category = this.getCategory();
    return category?.getProducts(true);
  }
  getMinimum() {
    return this.get("quantity_min") || 0;
  }
  getMaximum() {
    return this.get("quantity_max") || Infinity;
  }
  getMaximumProduct(id_product: string) {
    const max = (this.get("id_product_max") || []).reduce((memo, id) => {
      return memo + (id === id_product ? 1 : 0);
    }, 0);
    return max > 0 ? max : Infinity;
  }
  isFixedProduct(id_product: any) {
    // la quantité peut être fixé
    const model = this,
      immutableQty = model.getSelectionQuantity(id_product, true),
      maxQty = model.getMaximumProduct(id_product);
    return maxQty === immutableQty;
  }
  getProduct(id_product: any) {
    // alias
    return app.getProduct(id_product);
  }
  getRulesLabel() {
    var label = "",
      model = this,
      PERFERCT_LABEL = "C’est parfait !",
      isRoot = model.isRoot();
    if (isRoot) {
      var missing: string[] = [],
        missingProducts;
      model.eachChildren(function (sidedish) {
        var qty = sidedish.getSelectionQuantity(),
          max = sidedish.getMaximum(),
          qtyDiff = max - qty;
        if (qtyDiff > 0) missing.push(qtyDiff + " " + sidedish.getTitle());
      });
      if (missing.length) {
        var last = missing.pop();
        missingProducts = missing.join(", ");
        if (last) {
          missingProducts =
            (!!missingProducts
              ? missingProducts + " " + app.t("et", "UI") + " "
              : "") + last;
        }
        label = app.t("Encore %s à sélectionner", "Product", missingProducts);
      } else {
        label = model.getButtonOk() || app.t(PERFERCT_LABEL, "Product");
      }
    } else {
      var qty = model.getSelectionQuantity(),
        max = model.getMaximum(),
        min = model.getMinimum(),
        required = model.isRequired() || min === max,
        qtyDiff = max - qty,
        diffPlural = qtyDiff > 1;
      if (qty === max) {
        label = model.getButtonOk() || app.t(PERFERCT_LABEL, "Product");
      } else if (required || max > 0) {
        label = app.t(
          "Ajoutez encore %s produit" + (diffPlural ? "s" : ""),
          "Product",
          qtyDiff
        );
      }
    }
    return label;
  }
  getIsMultipleByProduct() {
    return !!this.get("multiple_by_product") && this.getMaximum() > 1;
  }
  selectionContains(product: any) {
    var selection = this.get("selection");
    if (selection) {
      return _(selection).contains(product.id);
    }
    return false;
  }
  selectionContainsId(id_product: any) {
    var model = this,
      selectionContainsId = _(model.get("selection")).contains(id_product);
    return selectionContainsId;
  }
  selectionChecked(id_product: any) {
    return this.getSelectionQuantity(id_product) > 0 ? "checked" : "";
  }
  selectionAttrValue(id_product: any) {
    // attribut html de la valeur selectionnée
    var attr,
      model = this,
      max = model.getMaximum(),
      key = "sidedish" + model.id;
    attr = 'id="sidedish-' + key + "-" + id_product + '" ';
    if (model.getIsMultipleByProduct()) {
      attr += ' value="' + model.getSelectionQuantity(id_product) + '"';
      attr += ' min="0" step="1" max="' + max + '"';
      attr += ' name="sidedish-' + key + "-" + id_product + '"';
    } else {
      attr += model.selectionChecked(id_product);
      attr += ' name="sidedish-' + key + '"';
    }
    return attr;
  }
  getCapability(id_product: any, cap: string) {
    const model = this,
      qty = model.getSelectionQuantity(id_product),
      single_by_product = !!id_product && !model.getIsMultipleByProduct(),
      qtyTotal = single_by_product ? 0 : model.getSelectionQuantity(),
      qtyImmutable = model.getSelectionQuantity(id_product, true),
      isStockout: boolean = !!(
        !!id_product && model.getProduct(id_product)?.isStockout()
      ),
      max = single_by_product ? 1 : model.getMaximum(),
      maxByProduct = !!id_product
        ? Math.min(max, model.getMaximumProduct(id_product))
        : max,
      canAddTotal = qtyTotal < max,
      canAdd = canAddTotal && (!id_product || qty < maxByProduct),
      canRemove = qty > qtyImmutable;
    let capTest = false;
    if (isStockout) {
      // noop
    } else if (cap === "add") {
      capTest = canAdd;
    } else if (cap === "remove") {
      capTest = canRemove;
    }
    return capTest ? "yes" : "no";
  }
  getSelection(): SidedishSelection[] {
    const selection = this.get("selection");
    return selection || [];
  }
  getSimplifiedSelection() {
    return _(
      this.getSelection().filter(function (item: any) {
        return item.quantity > 0;
      })
    ).map(function (item) {
      return {
        id: item.id,
        quantity: item.quantity,
      };
    });
  }
  getLocations() {
    return CUSTOM_BOX_42_LOCATIONS;
  }
  getSelectionQuantity(id_product?: any, immutable?: boolean) {
    // permet d'obtenir la quantité totale, ou immutable d'un produit de la selection,
    // ou de l'ensemble de la selection.
    var selection = this.getSelection(),
      qty = 0;
    _(selection).each(function (s) {
      if (!id_product || s.id === id_product) {
        qty += immutable ? s.immutable : s.quantity;
      }
    });
    return qty;
  }
  getSelectionPrice() {
    const selection = this.getSelection();
    let price = 0;
    if (!this.isFree()) {
      _(selection).each(function (s) {
        var product = s.p,
          qty = s.quantity;
        if (product && qty > 0) {
          price += qty * product.getPrice("ttc", true);
        }
      });
    }
    return price;
  }
  getSelectionCartProducts() {
    return app
      .getProducts()
      .getProductsByIds(this.getSelection().map((e) => e.id));
  }
  isStockout() {
    // retourne true si un des produits de la selection est en rupture de stock
    var isStockout = false;
    this.getSelectionCartProducts().each(function (product) {
      isStockout = isStockout || product.isStockout();
    });
    return isStockout;
  }
  validateSelection() {
    const model = this,
      required = model.isRequired(),
      children = model.getChildren(),
      max = model.getQuantityMax(),
      min = model.getQuantityMin();
    let quantity = 0;
    let validate = !required;
    if (children.size()) {
      // si il y a des enfants, on vérifie la selection des enfants
      validate = true;
      children.each(function (sidedishChild) {
        validate = validate && sidedishChild.validateSelection();
      });
    } else if (required || min > 0) {
      // sinon, si c'est requis
      quantity = model.getSelectionQuantity();
      validate = quantity >= (min || 1) && quantity <= max;
    }
    return !!validate;
  }
  getEmptiness() {
    var model = this,
      children = model.getChildren(),
      quantity = 0;
    if (children.size()) {
      children.each(function (sidedish: {
        getSelectionQuantity: () => number;
      }) {
        quantity += sidedish.getSelectionQuantity();
      });
    } else {
      quantity = model.getSelectionQuantity();
    }
    return !(quantity > 0);
  }
  shiftSelection() {
    var selection = this.getSelection(),
      shifted: SidedishSelection | undefined = undefined,
      unshifted = [];
    while (!shifted) {
      shifted = selection.shift();
      if (shifted && shifted.quantity > shifted.immutable) {
        shifted.quantity--;
        if (shifted.quantity > 0) {
          unshifted.push(shifted);
        }
      } else if (shifted && shifted.quantity > 0) {
        // cas selection mémorisée avec "quantité nulle"
        unshifted.push(shifted);
        shifted = undefined;
      }
    }
    _(unshifted).each(function (s) {
      selection.unshift(s);
    });
    return shifted;
  }
  changeSelection(add: boolean | number, id_product: string) {
    const model = this,
      currentQuantity = model.getSelectionQuantity(id_product),
      newQuantity =
        typeof add === "boolean" ? currentQuantity + (add ? 1 : -1) : add;
    return model.updateSelection(newQuantity, id_product);
  }
  updateSelection(quantity: number, id_product: string) {
    var model = this,
      selection = model.getSelection(),
      currentSelection: SidedishSelection,
      allPositions = model.get("pos_custom_box") || [],
      filledPositions = _.flatten(_(selection).pluck("positions")),
      availablePositions = _.difference(allPositions, filledPositions);

    const findEl = _(selection).findWhere({ id: id_product });
    let diffQty = quantity - (!!findEl ? findEl.quantity : 0);
    let add = diffQty > 0;

    if (!findEl) {
      currentSelection = {
        id: id_product,
        quantity: 0,
        default: 0,
        immutable: 0,
        p: app.getProduct(id_product) || new ProductModel({ id_product }),
        positions: [],
      };
      selection.push(currentSelection);
    } else {
      currentSelection = findEl;
    }

    var positions = currentSelection.positions;
    for (var i = 0; i < Math.abs(diffQty); i++) {
      if (!add) {
        positions.pop();
      } else {
        positions.push(availablePositions[i] || "*");
      }
    }
    currentSelection.quantity = quantity;
    return currentSelection;
  }
  fillRandom() {
    // remplir aléatoirement la selection.
    const model = this;
    model.eachChildren(function (sidedish) {
      var selection = sidedish.getSelection(),
        immutableQty = sidedish.getSelectionQuantity(false, true),
        qty = 0,
        multiple_by_product = sidedish.getIsMultipleByProduct(),
        max = sidedish.getMaximum(),
        pos_custom_box = _(sidedish.get("pos_custom_box") || []).clone();
      // reset les quantités et positions
      _(selection).each(function (sel) {
        sel.quantity = sel.immutable || 0;
        sel.positions = sel.positions.slice(0, sel.immutable);
        pos_custom_box = _.difference(pos_custom_box, sel.positions);
      });

      function shiftPosition() {
        const shift = pos_custom_box.shift() || "*";
        return shift;
      }

      const products = sidedish.getCategoryProducts();
      let maxLoop = 0;
      if (max !== Infinity && !!products?.length) {
        while (qty < max - immutableQty) {
          maxLoop++;
          if (maxLoop > 1000) break; // sécurité pour des cas improbables, mais réels
          // par exemple, si le cross sell est limité à 1 quantité par produit,
          // et qu'il y a moins de produit que le minimum requis
          // etc...
          const product = products.at(
            Math.floor(Math.random() * products.length)
          );
          if (product && product.isBuyableInContext()) {
            const id_product = product.id;
            let currentSelection = _(selection).findWhere({ id: id_product });
            if (!currentSelection) {
              currentSelection = createSelectionItem(
                id_product,
                1,
                1,
                0,
                shiftPosition
              );
              selection.push(currentSelection);
            } else {
              const currentQty = currentSelection.quantity;
              if (
                currentQty &&
                (!multiple_by_product ||
                  currentQty >= sidedish.getMaximumProduct(id_product))
              )
                continue;
              currentSelection.positions.push(shiftPosition());
              currentSelection.quantity += 1;
            }
            qty++;
          }
        }
      }
    });
  }
  // quantité maximale
  getQuantityMax() {
    return this.get("quantity_max") || Infinity;
  }
  getQuantityMin() {
    return this.get("quantity_min") || 0;
  }
  isRequired() {
    return (this.get("quantity_min") || 0) > 0;
  }
  isFreeDisplay() {
    return this.get("display_free") === true;
  }
  isPlusDisplay() {
    return this.get("display_plus") === true;
  }
  isFree() {
    return this.get("is_free") === true;
  }
  isAttached() {
    return this.get("is_attached") === true;
  }
  isPack() {
    return this.get("is_pack") === true;
  }
  validateGroupedList() {
    // TODO: implement validateGroupedList. Is still usefull?
    /* var min = this.getMinimum(),
      max = this.getMaximum();

    var selectedIds: any[] = [];

    // For classic input
    var selected = $(
      ".sidedish-group-" +
        this.id +
        " .sidedish-list-grouped__input input:checked"
    );
    if (selected.length >= min && selected.length <= max) {
      selected.each(function () {
        selectedIds.push($(this).val());
      });
    }

    // For multiple inputs
    var multiple = $(
      ".sidedish-group-" +
        this.id +
        " .sidedish-list-grouped__input--multiple input"
    );
    var multipleTotal = 0;
    multiple.each(function () {
      var value = isNaN(parseInt($(this).val())) ? 0 : parseInt($(this).val());
      multipleTotal += value;
      if (multipleTotal > 0 && multipleTotal <= max && value) {
        selectedIds.push({
          id: $(this).attr("name"),
          quantity: value,
        });
      }
    });
    if (multiple.length && (multipleTotal < min || multipleTotal > max)) {
      selectedIds = [];
    }
    return selectedIds; */
    return [];
  }
}

SidedishModel.prototype.name = "SidedishModel";
SidedishModel.prototype.idAttribute = "id_cross_selling";

SidedishModel.createSelectionItem = createSelectionItem;

export default SidedishModel;
