import LocalStorageModel from "../prototypes/LocalStorageModel";
import { deburr } from "lodash";

export interface ILatLng {
  lat: number;
  lng: number;
}
export interface IDistrict {
  id_district: string;
  name: string;
  polygons: IMultiPolygon;
}
export type IPolygon = ILatLng[];
export type IMultiPolygon = IPolygon[];

// TODO
class PostcodeModel extends LocalStorageModel {
  name = "PostcodeModel";
  idAttribute = "id_postcode"
  defaults = () => ({
    postcode: "",
    postcodeUpperCase: "",
    districts: [],
    polygons: [],
    is_arrondissements: false,
  });

  initialize(attributes?: any, options?: any) {
    super.initialize(attributes, options);
    const model = this;
    if (!model.get(model.idAttribute)) {
      model.attributes[model.idAttribute] = model.get("id");
    }
    // optimisation de la recherche par nom, insensible à la casse
    model.set("postcodeUpperCase", model.get("postcode").toUpperCase());
  }

  getCity() {
    return this.get("city");
  }

  getPostcode() {
    return this.get("postcode");
  }

  getLocalitySimplification(wrap: boolean): string[] {
    const model = this,
      google_localities = model.get("google_localities");
    let localities = [];
    if (google_localities) {
      localities = google_localities.split(", ").map((c: string) => {
        // TODO string Simplification
        return deburr(c).toLowerCase();
      });
    } else {
      // TODO string Simplification
      // localities = [Simplification(model.get('city'), wrap)];
      localities = [deburr(model.get("city")).toLowerCase()];
    }
    return localities;
  }

  testFormattedLocality(formattedLocality: string) {
    const model = this,
      regExpAttr = "testFormattedRegExp";
    let localitySimplificationRegExp: RegExp = model.get(regExpAttr);
    if (!localitySimplificationRegExp) {
      const s = model.getLocalitySimplification(true);
      localitySimplificationRegExp = new RegExp(s.join("|"));
      model.set(regExpAttr, localitySimplificationRegExp);
    }
    var test = localitySimplificationRegExp.test(formattedLocality);
    return test;
  }

  getPolygons(): IMultiPolygon {
    return this.get("polygons");
  }

  getLocationDistrict(lat: number, lng: number): IDistrict | undefined {
    const postcode = this,
      districts = postcode.getDistricts();
    //
    return districts?.find((district: any) => {
      const polygons = district.polygons;
      let inDistrict = false;
      if (polygons) {
        polygons.forEach((polygon: ILatLng[]) => {
          //
          // original implementation
          // see https://github.com/substack/point-in-polygon/
          let inside = false;
          for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
            const xi = polygon[i].lat,
              yi = polygon[i].lng;
            const xj = polygon[j].lat,
              yj = polygon[j].lng;

            const intersect =
              yi > lng !== yj > lng &&
              lat < ((xj - xi) * (lng - yi)) / (yj - yi) + xi;
            if (intersect) inside = !inside;
          }
          inDistrict ||= inside;
        });
      }
      return inDistrict;
    });
  }

  isLatLngMatching(lat: number, lng: number): boolean {
    return !!this.getLocationDistrict(lat, lng);
  }

  hasDistricts(): boolean {
    return this.getDistricts().length > 0;
  }

  getDistricts(): IDistrict[] {
    return this.get("districts") || [];
  }

  getDistrict(id_district: string): IDistrict | undefined {
    return this.getDistricts().find((d) => d.id_district === id_district);
  }
}

PostcodeModel.prototype.name = "PostcodeModel";
PostcodeModel.prototype.idAttribute = "id_postcode";

export default PostcodeModel;
