//
// Model Tag

import LocalStorageModel from "../prototypes/LocalStorageModel";

//

class TagModel extends LocalStorageModel {
  name = "TagModel";
  idAttribute = "id_tag";
  route = "apiTagModel";
  defaults() {
    return {
      filterChecked: false, // par défaut, le tag est selectionné en tant que filtre
    };
  }
  initialize(attributes?: any, options?: any): void {
    // ObjectModel constructor heritage
    super.initialize.apply(this, [attributes, options]);
    //
  }
  getName() {
    var name = this.get("name");
    return name;
  }
  isChecked() {
    return this.get("filterChecked");
  }
}

TagModel.prototype.name = "TagModel";
TagModel.prototype.idAttribute = "id_tag";
export default TagModel;
