//
// Collection Tag

import LocalStorageCollection from "../prototypes/LocalStorageCollection";
import TagModel from "./TagModel";
import _ from "underscore";

class TagCollection extends LocalStorageCollection<TagModel> {
  name = "TagModelCollection";
  route = "apiTagCollection";
  idAttribute = "id_tag";
  model = TagModel;
  initialize(models?: Record<string, any>[] | TagModel[], options?: any): void {
    // ObjectCollection constructor heritage
    super.initialize.apply(this, [models, options]);
  }

  whereIds(ids: any[]) {
    var collection = new TagCollection();
    collection.reset();
    this.each(function (tagModel) {
      if (_(ids).indexOf(tagModel.get("id_tag")) >= 0) {
        collection.add(tagModel);
      }
    });
    return collection;
  }

  isAllChecked() {
    var allChecked = true;
    this.each(function (model) {
      allChecked = allChecked && model.isChecked();
    });
    return allChecked;
  }

  isNoneChecked() {
    var noneChecked = true;
    this.each(function (model) {
      noneChecked = noneChecked && !model.isChecked();
    });
    return noneChecked;
  }

  isFilterChecked() {
    return !(this.isNoneChecked() || this.isAllChecked());
  }

  getChecked() {
    return new TagCollection(
      this.filter(function (model) {
        return model.isChecked();
      })
    );
  }

  getCheckedCount() {
    var collection = this;
    return collection.isFilterChecked() ? collection.getChecked().length : 0;
  }
}

TagCollection.prototype.model = TagModel;

export default TagCollection;
