import clsx from "clsx";
import styled from "styled-components";
import { $champagne7, $champagne, $white, $beigeBg } from "../../Utils/color";
import { IQuantifierProps } from "./Quantifier";
import { responsiveMobileBreakpoint } from "../../Components/Grid/_utils/responsiveObserve";
import { useTheme } from "../../Themes/defaultTheme";

interface IQuantifierRootProps extends IQuantifierProps {
  useMobileTemplate?: boolean;
  addOnly?: boolean;
  $loading?: boolean;
}
export const QuantifierRoot = styled.div.attrs<IQuantifierRootProps>(
  ({ useMobileTemplate, addOnly, $loading, className, ...props }) => {
    return {
      ...props,
      className: clsx(
        "ro-quantifier",
        useMobileTemplate && "ro-quantifier--mobile-alt",
        $loading && "ro-quantifier--loading",
        addOnly && "ro-quantifier--add-only",
        className
      ),
    };
  }
)<IQuantifierRootProps>((props) => {
  const color = $white;
  const background = $champagne7;
  const backgroundMobile = $beigeBg;
  return {
    position: "relative",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    height: "36px",
    padding: "0 6px",
    borderRadius: "48px",
    color,
    background,
    userSelect: "none",
    ["span"]: {
      display: "inline-block",
      textAlign: "center",
      width: "20px",
      padding: "0 2px",
      transform: "translateY(-1px)",
    },
    [`@media ${responsiveMobileBreakpoint}`]: {
      ["&.ro-quantifier--mobile-alt"]: {
        background: backgroundMobile,
      },
    },
    [".ro-typography"]: {
      transition: "opacity .25s, visibility .25s",
    },
    ["&.ro-quantifier--loading .ro-typography"]: {
      opacity: 0,
    },
  };
});
export const QuantifierMobileRoot = styled.div<{}>(() => {
  return {
    position: "relative",
    margin: "auto 0 auto auto",
    ["@media screen and (min-width: 768px)"]: {
      display: "none",
    },
  };
});

export const QuantifierMobileButton = styled.button<{
  $addOnly?: boolean;
  $busy?: boolean;
  $variant?: "light" | "dark";
  $size?: "small" | "medium";
}>(({ $addOnly, $busy, $variant, $size }) => {
  const { color: themeColors } = useTheme();
  const { champagne7, beigeBg, white } = themeColors;
  const isDark = $variant === "dark";
  const backgroundColor = isDark ? "#494949" : beigeBg;
  const isSmall = $size === "small";
  const pxSize = isSmall ? 28 : 36;
  const color = isDark ? white : champagne7;
  return {
    display: "flex",
    position: "relative",
    padding: "0",
    minWidth: pxSize,
    height: pxSize,
    margin: "auto 0 auto auto",
    border: "none",
    backgroundColor,
    borderRadius: pxSize,
    color,
    ["+ select"]: {
      opacity: 0,
      top: 0,
      width: "100%",
      height: "100%",
      position: "absolute",
    },
    [".ricon"]: {
      margin: "auto 8px auto -8px",
      position: "relative",
      transition: "opacity .5s, visibility .5s, margin-left .5s",
    },
    [".ricon-Plus"]: {
      ...(!$addOnly && {
        position: "absolute",
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        height: "1em",
        opacity: 0,
        visibility: "hidden",
        margin: "auto 0",
      }),
    },
    [".ro-typography"]: {
      position: "relative",
      transition: "opacity .25s, visibility .25s",
      margin: "auto",
      lineHeight: "1em",
      display: "inline-flex",
      padding: `0 8px ${isSmall ? 0.2 : 0.1}em 8px`,
      minWidth: pxSize,
      boxSizing: "border-box",
      textAlign: "center",
    },
    [".spinner"]: {
      position: "absolute",
      bottom: 0,
      top: 0,
      margin: "auto",
      left: 0,
      right: 0,
    },
    ["&[data-quantity='0']"]: {
      [".ro-typography"]: {
        opacity: 0,
        visibility: "hidden",
        margin: "auto -11px auto -11px",
        // ...($addOnly && { margin: "auto -11px auto -11px" }),
      },
      ...($addOnly
        ? {
            [".ricon-Plus"]: {
              margin: "auto -6px auto -8px",
            },
          }
        : {
            ["+ select"]: {
              display: "none",
            },
            [".ricon-Arrowhead_down"]: {
              marginLeft: -24,
              opacity: 0,
              visibility: "hidden",
            },
            [".ricon-Plus"]: {
              opacity: 1,
              visibility: "visible",
            },
          }),
    },
    ...(!!$busy && {
      [".ro-typography, .ricon, &[data-quantity='0'] .ricon-Plus"]: {
        opacity: 0,
        visibility: "hidden",
        margin: "auto 0",
      },
    }),
  };
});

export const QuantifierMobileDropdownRoot = styled.div<{
  open: boolean;
}>(({ open }) => {
  const { color: themeColors } = useTheme();
  const { champagne7, beigeBg, white } = themeColors;
  const backgroundColor = beigeBg;
  const color = champagne7;
  return {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    gap: 8,
    padding: "12px 16px",
    minWidth: 36,
    minHeight: 36,
    top: 80,
    right: -10,
    margin: "auto",
    border: "none",
    backgroundColor,
    borderRadius: 24,
    color,
    visibility: "hidden",
    opacity: 0,
    transition: "opacity .5s, visibility .5s",
    zIndex: 1,
    ...(open && {
      visibility: "visible",
      opacity: 1,
    }),
    ["button"]: {
      border: "none",
      padding: "3px 0",
      gap: 6,
      margin: 0,
      display: "flex",
      background: "none",
      height: 17,
    },
  };
});

export const QuantifierButton = styled.button<{}>(({ disabled }) => ({
  position: "relative",
  display: "flex",
  width: "24px",
  height: "24px",
  alignItems: "center",
  justifyContent: "center",
  background: "transparent",
  border: "none",
  boxShadow: "none",
  opacity: disabled ? 0.3 : 1,
  pointerEvents: disabled ? "none" : "inherit",
  padding: 0,
  cursor: "pointer",
  ["&:after"]: {
    zIndex: 0,
    position: "absolute",
    content: "''",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    borderRadius: "48px",
    background: "transparent",
  },
  ["i"]: {
    zIndex: 1,
  },
  ["&:hover"]: {
    ["&:after"]: {
      background: "#000",
    },
  },
}));

export const QuantifierAdd = styled.button<{ $loading?: boolean }>(
  ({ disabled, $loading }) => ({
    position: "relative",
    display: "flex",
    height: "24px",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
    border: "none",
    boxShadow: "none",
    opacity: disabled ? 0.3 : 1,
    pointerEvents: disabled ? "none" : "inherit",
    color: "#fff",
    padding: "0 17px",
    cursor: "pointer",
    ["span"]: {
      width: "auto",
    },
    ...($loading && {
      [".ro-typography"]: {
        opacity: 0,
      },
    }),
  })
);
