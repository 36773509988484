import React from "react";

export interface IButtonContextState {
  textColor?: string;
  backgroundColor?: string;
}

const ButtonContext = React.createContext<IButtonContextState>({});

export const useButtonContext = () => React.useContext(ButtonContext);

export default ButtonContext;
