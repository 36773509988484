import LocalStorageModel from "../prototypes/LocalStorageModel";
import _ from "underscore";
// import WidgetGroupCollection from "./WidgetGroupCollection";
import { app } from "./AppModel";
import UrlFactory from "../prototypes/UrlFactory";

class WidgetGroupModel extends LocalStorageModel {
  name = "WidgetGroupModel";
  route = "apiWidgetGroupModel";
  idAttribute = "id_widget_group";
  collections = undefined;

  initialize(attributes?: any, options?: any): void {
    // ObjectModel constructor heritage
    super.initialize.apply(this, [attributes, options]);
    //
    if (!this.get(this.idAttribute)) {
      this.attributes[this.idAttribute] = this.get("id");
    }
  }

  //
  getCollections() {
    // retourne le tableau de toutes les collections du groupe
    var group = this,
      collections = group.collections;
    //   TODO: implement WidgetCollectionCollection
    /* if (!collections) {
      // fabrique la collection une seule fois, lors de la première demande
      collections = new WidgetCollectionCollection();
      _(group.get("collection")).each(function (id_widget_collection) {
        collections.add(app.getWidgetCollection(id_widget_collection));
      });
      group.collections = collections;
    } */
    return collections;
  }
  // récupère la Widget Collection la plus pertitante du groupe
  getContextualCollection(): any {
    //   TODO: implement getContextualCollection
    /* //
    var group = this,
      oneMatch = 0,
      sorted = group.getCollections().sortBy(function (widgetCollection) {
        var matchTarget = widgetCollection.matchTarget(), // 0|1|2
          matchDate = widgetCollection.matchDatetime() ? 1 : 0, // 0|1
          matchScore = matchDate * matchTarget;
        oneMatch += matchScore;
        //
        return -matchScore;
      });
    //
    return oneMatch > 0
      ? sorted[0]
      : app.getWidgetCollection(group.get("default_collection")); // par défaut, on a toujours un id default_collection */

    return {};
  }

  getUrl() {
    return UrlFactory.getUrl("widgetGroup", {
      widget_group_rewrite: this.get("link_rewrite"),
    });
  }
  isPage() {
    return this.get("is_page") === "1";
  }
  useJs() {
    return this.get("use_js");
  }
}

WidgetGroupModel.prototype.name = "WidgetGroupModel";
WidgetGroupModel.prototype.route = "apiWidgetGroupModel";
WidgetGroupModel.prototype.idAttribute = "id_widget_group";
// WidgetGroupModel.prototype.collections = WidgetGroupCollection;

export default WidgetGroupModel;
