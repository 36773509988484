//
// Model AllergenGroup

import LocalStorageModel from "../prototypes/LocalStorageModel";

class AllergenGroupModel extends LocalStorageModel {
  backbonePattern = "Model";
  name = "AllergenGroupModel";
  idAttribute = "id_allergen_group";
  route = "apiAlergenGroupModel";
  defaults() {
    return {
      filterChecked: true, // par défaut, l'allergen est selectionné en tant que filtre
    };
  }
  initialize(attributes?: any, options?: any): void {
    // ObjectModel constructor heritage
    super.initialize.apply(this, [attributes, options]);
    //
  }
  getName() {
    var name = this.get("name");
    return name;
  }
  isChecked() {
    return this.get("filterChecked");
  }
}

AllergenGroupModel.prototype.name = "AllergenGroupModel";
AllergenGroupModel.prototype.url = function () {
  return "allergengroup/<%= id_allergen_group %>";
};

export default AllergenGroupModel;
