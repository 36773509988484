//
// Model Setup
//

import { site_vars } from "../provider/siteVars";
import { app } from "./AppModel";
import SetupPrivateModel, { privateObjects } from "./SetupPrivateModel";
import Backbone from "@src/backbone/prototypes/Backbone";
import moment from "moment";
import LocalStorageModel from "../prototypes/LocalStorageModel";
// import models from ".";
import ObjectCollection from "../prototypes/ObjectCollection";
import { urlWithSyncVersion } from "@src/backbone/prototypes/ObjectPrototype";
import CategoryCollection from "./CategoryCollection";
import reduxStore from "@src/store";
import ProductCollection from "./ProductCollection";
import backboneSlice from "@src/store/reducers/BackboneSlice";
import { AppSlice } from "@src/store/reducers/AppSlice";
import { startTransition } from "react";

async function loadClassDynamically(filename: string) {
  try {
    const module = await import(`@src/backbone/model/${filename}`);
    return module.default;
  } catch (error) {
    return undefined;
  }
}

// global app reference
// warning : this feature is for debug only, please use require.js
// window.app = app;
//

// const objects: any = {};
export let setupPrivate: SetupPrivateModel;
// let sync;

class SetupModel extends LocalStorageModel<any> {
  public name = "SetupModel";
  remoteCache = "no-cache";
  urlRoot: Backbone._Result<string> = "setup";
  route!: string;
  backbonePattern = "Model";
  private objects: any = {};

  defaults() {
    return { storeInt: [], storeService: [], allergengroup: [] };
  }

  initialize(attributes: any, options: any) {
    const { configuration, ...rest } = attributes;
    // ObjectModel constructor heritage
    super.initialize(rest, options);
    reduxStore.dispatch(AppSlice.actions.setSetupReady(false));

    const setup = this;
    // setup.on("sync", setup.onSetup, setup);
    // setup.on("error", setup.onSetupError, setup);
    setupPrivate = new SetupPrivateModel();
    //
    setupPrivate.listenTo(app, "ws__update_sync", setupPrivate.updateSync);
    setupPrivate.on("setupPrivate", setup.onSetupPrivate, setup);
    startTransition(() => {
      reduxStore.dispatch(backboneSlice.actions.setIsSetup(false));
    });
    // performance.mark("setupPrivate-fetch-start");

    // fetch setup and setupPrivate at the same time
    Promise.allSettled([
      setupPrivate.setup(),
      (async () => {
        await setup.fetch();
        await this.onSetup(attributes);
        return this.objects;
      })(),
    ]).then(([privateData, data]) => {
      const mergerObject = {
        ...(data.status === "fulfilled" ? data.value : {}),
        ...(privateData.status === "fulfilled" ? privateData.value : {}),
      };
      app.setupObjects(mergerObject);
      startTransition(() => {
        reduxStore.dispatch(backboneSlice.actions.setIsSetup(true));
      });
    });
  }

  onSetupPrivate() {
    // performance.mark("setupPrivate-fetch-end");
    // console.log(
    //   performance.measure(
    //     "setupPrivate-fetch",
    //     "setupPrivate-fetch-start",
    //     "setupPrivate-fetch-end"
    //   )
    // );
    // this.fetch().then((d) => console.log("---setup-done", d));
  }

  onSetupError(a: any, b: any, c: any) {
    const statusText = c.textStatus || b.statusText || "UnknownStatus";
    const statusCode = b.status || 500;
    app.error("JSSetupError", {
      statusText: statusText,
      statusCode: statusCode,
    });
    app.alert(
      {
        title: site_vars.default_warning_title || "Attention",
        subtitle:
          site_vars.default_warning_setup ||
          "Une erreur est survenue durant le chargement, veuillez recharger la page.",
        validation: "Recharger",
      },
      function () {
        //   TODO: reload
        // window.location.reload(true);
      }
    );
  }
  async onSetup({ configuration = [] }: any) {
    return new Promise<any>(async (resolve, reject) => {
      // instancie tous les objets issus du JSON en collection Backbone
      const tasks = Object.entries(this.attributes).map(
        async ([key, object]) => {
          // essaie de fabriquer le constructeur des collections
          let constructorName: string = key[0].toUpperCase() + key.slice(1);
          let o;
          switch (key) {
            // seul la traduction est un Model
            case "translation":
              constructorName = constructorName + "Model";
              break;
            case "allergengroup":
              constructorName = "AllergenGroupCollection";
              break;
            case "storeInt":
              constructorName = "StoreCollection";
              break;
            default:
              constructorName = constructorName + "Collection";
          }
          try {
            // if (models[constructorName]) {
            //   o = this.objects[key] = new models[constructorName](object);
            //   o.trigger("sync", key, o);
            // } else {
            let ClassName = await loadClassDynamically(constructorName + ".ts");
            if (ClassName) {
              // si le constructeur existe
              // on construit la collection
              o = this.objects[key] = new ClassName(object);
              o.trigger("sync", o);
            } else {
              console.warn(`Collection not found [${constructorName}]`);
            }
            // }
          } catch (err) {
            console.warn(`Collection not found [${constructorName}]`, err);
            switch (constructorName) {
              case "CategoryCollection":
                o = this.objects[key] = new CategoryCollection(object);
                o.trigger("sync", o);
                break;
              case "ProductCollection":
                o = this.objects[key] = new ProductCollection(object);
                o.trigger("sync", o);
                break;

              default:
                const constructor = class Collection extends ObjectCollection {
                  name = constructorName;
                };
                //return;
                if (typeof constructor == "function") {
                  // si le constructeur existe
                  // on construit la collection
                  o = this.objects[key] = new constructor(object);
                  o.trigger("sync", o);
                }
                break;
            }
          }
          switch (key) {
            case "configuration":
              o.set(configuration, { remove: false, merge: false });
              break;
          }
          return o;
        }
      );
      await Promise.allSettled(tasks);
      // performance.mark("setup-fetch-end");
      // console.log(
      //   performance.measure(
      //     "setup-fetch",
      //     "setupPrivate-fetch-end",
      //     "setup-fetch-end"
      //   )
      // );

      //
      // charge la variante linguistique (locale) de moment.js
      //
      const LoadMomentLocale = (callback: () => void) => {
        const isoLang = reduxStore.getState().app.isoLang;
        const momentJsLocale =
          this.objects.configuration?.getValue("_MOMENT_JS_LOCALE_") ||
          isoLang ||
          "";

        // objects.configuration.getValue("_MOMENT_JS_LOCALE_") || isoLang;
        function MomentCallback(localCode: string) {
          moment.locale(localCode);
          callback();
        }

        switch (momentJsLocale) {
          case "":
          case "en":
            MomentCallback("en");
            break;
          case "nl":
            require(["moment/locale/nl-be"], (r) => {
              MomentCallback("nl-be");
            });
            break;
          default:
            //   LogExecutionTime("loading moment.js locale");
            require(["moment/locale/" + momentJsLocale], () => {
              MomentCallback(momentJsLocale);
              // @ts-ignore
            }, () => callback());

            break;
        }
      };

      LoadMomentLocale(() => {
        // this.allSetupObjectsReady();
        resolve(this.objects);
      });
    });
  }

  allSetupObjectsReady() {
    // TODO: check resync
    //
    // surchage les objets privés aux publiques
    //
    /* if (!privateObjects.sync.onChangeAssetVersion()) {
      // stop le chargement ici, si les assets ont changés
      return;
    } else if (privateObjects.sync.test()) {
      //
      // cache local est purgé sans distinction, grosse chasse d'eau
      localStorage.clear();
      //
      // force un rechargement du setup
      // et redémarre le processus de chargement
      super.remoteFetch();
      return;
    } */
    const mergerObject = { ...this.objects, ...privateObjects };
    app.setupObjects(mergerObject);
    // console.log("allSetupObjectsReady", mergerObject);
    // TODO: LogExecutionTime
    // LogExecutionTime("setup private ready");
    startTransition(() => {
      reduxStore.dispatch(backboneSlice.actions.setIsSetup(true));
    });
  }
}

SetupModel.prototype.route = "/setupweb";
SetupModel.prototype.url = () => urlWithSyncVersion("/setupweb");

export default SetupModel;
