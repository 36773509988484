"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
exports.__esModule = true;
var _exportNames = {
  Link: true,
  withIntl: true,
  navigate: true,
  changeLocale: true,
  getAlternates: true,
  getLanguages: true,
  IntlContextProvider: true,
  IntlContextConsumer: true,
  useIntlTranslation: true,
  InitProvider: true,
  fetchNamespace: true
};
exports.withIntl = exports.useIntlTranslation = exports.navigate = exports.getLanguages = exports.getAlternates = exports.fetchNamespace = exports.changeLocale = exports.Link = exports.IntlContextProvider = exports.IntlContextConsumer = exports.InitProvider = void 0;
var _reactIntl = require("react-intl");
Object.keys(_reactIntl).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _reactIntl[key]) return;
  exports[key] = _reactIntl[key];
});
var _link = _interopRequireWildcard(require("./link"));
exports.Link = _link.default;
exports.navigate = _link.navigate;
exports.changeLocale = _link.changeLocale;
exports.getAlternates = _link.getAlternates;
exports.getLanguages = _link.getLanguages;
var _withIntl = _interopRequireDefault(require("./with-intl"));
exports.withIntl = _withIntl.default;
var _intlContext = require("./intl-context");
exports.IntlContextProvider = _intlContext.IntlContextProvider;
exports.IntlContextConsumer = _intlContext.IntlContextConsumer;
var _useIntlTranslation = require("./useIntlTranslation");
exports.useIntlTranslation = _useIntlTranslation.useIntlTranslation;
var _wrapPage = require("./wrap-page");
exports.InitProvider = _wrapPage.InitProvider;
var _fetchNamespace = require("./fetchNamespace");
exports.fetchNamespace = _fetchNamespace.fetchNamespace;
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }