import { GATSBY_ENV_COUNTRY_CODE } from "@src/utils/constants";

export const site_vars: {
  iso_lang?: string;
  store_preset?: string;
  sync_version?: string;
  default_warning_title?: string;
  default_warning_setup?: string;
  country_code?: string;
  theme_url?: string;
  asset_version?: string;
  domain_sharding_img?: string[] | string;
  base_url?: string;
  http_mode?: string;
  img_url?: string;
  checkout_url?: string;
  ignore_microtemplating?: string;
  reCaptchaEnable: string;
  reCaptchaOrderEnable: string;
  reCaptcha: string;
  reCaptchaKey: string;
  debug: boolean;
} = {
  iso_lang: "en",
  country_code: GATSBY_ENV_COUNTRY_CODE,
  reCaptchaEnable: "",
  reCaptchaOrderEnable: "",
  reCaptcha: "",
  reCaptchaKey: "",
  debug: false,
};
