import React, { FunctionComponent, ImgHTMLAttributes } from "react";

export interface IResponsiveImageProps {
  sizes?: string;
  srcSet: string;
}

export type SourceProps = IResponsiveImageProps &
  (
    | {
        media: string;
        type?: string;
      }
    | {
        media?: string;
        type: string;
      }
  );

export type ImageDataSources = {
  sources?: SourceProps[];
  fallback?: FallbackProps;
};

export type FallbackProps = { src: string } & Partial<IResponsiveImageProps>;

type ImageProps = ImgHTMLAttributes<HTMLImageElement> & {
  src?: string;
  alt: string;
  shouldLoad: boolean;
  aspectRadio?: string;
  outputPixelDensities?: number[];
};

export type PictureProps = ImgHTMLAttributes<HTMLImageElement> & {
  fallback?: FallbackProps;
  sources?: Array<SourceProps>;
  alt: string;
  shouldLoad?: boolean;
  aspectRadio?: string;
  outputPixelDensities?: number[];
  width?: number;
  height?: number;
};

const Image: FunctionComponent<ImageProps> = function Image({
  src,
  srcSet,
  loading,
  alt = ``,
  shouldLoad,
  aspectRadio = "auto",
  style,
  ...props
}) {
  const { width, height } = props;
  const w = Number(width),
    h = Number(height);
  const autoAspectRatio = w > 0 && h > 0 ? w / h : aspectRadio || "auto";
  return (
    <img
      {...props}
      decoding="async"
      loading={loading}
      src={shouldLoad ? src : undefined}
      data-src={!shouldLoad ? src : undefined}
      srcSet={shouldLoad ? srcSet : undefined}
      data-srcset={!shouldLoad ? srcSet : undefined}
      alt={alt}
      style={{ ...style, aspectRatio: autoAspectRatio }}
      draggable={false}
    />
  );
};

export const Picture: React.FC<PictureProps> = function Picture({
  fallback,
  sources = [],
  shouldLoad = true,
  ...props
}) {
  const sizes = props.sizes || fallback?.sizes;
  const fallbackImage = (
    <Image {...props} {...fallback} sizes={sizes} shouldLoad={shouldLoad} />
  );

  if (!sources.length) {
    return fallbackImage;
  }

  return (
    <picture>
      {sources.map(({ media, srcSet, type, sizes: sSizes }) => {
        return (
          <source
            key={`${media}-${type}-${srcSet}`}
            type={type}
            media={media ? media : undefined}
            srcSet={shouldLoad ? srcSet : undefined}
            data-srcset={!shouldLoad ? srcSet : undefined}
            sizes={sSizes || sizes}
          />
        );
      })}
      {fallbackImage}
    </picture>
  );
};
