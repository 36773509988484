const decodeParamForKey = (
  paramString: string,
  key: string | number | boolean
) => {
  return decodeURIComponent(
    paramString.replace(
      new RegExp(
        "^(?:.*[&\\?]" +
          encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") +
          "(?:\\=([^&]*))?)?.*$",
        "i"
      ),
      "$1"
    )
  );
};

export default decodeParamForKey;
