import React, { HTMLAttributes, ReactNode, CSSProperties } from "react";
import { ReactElement } from "react";
import { _DropdownContentWrapper } from "./Dropdown.styled";
import Trigger, { TriggerProps } from "rc-trigger";
import "rc-trigger/assets/index.css";

type DropdownProps = TriggerProps;
interface ChildEvent
  extends Pick<
    HTMLAttributes<HTMLElement>,
    | "onClick"
    | "onMouseDown"
    | "onTouchStart"
    | "onMouseEnter"
    | "onMouseLeave"
    | "onFocus"
    | "onBlur"
  > {}
interface IChildProps extends ChildEvent {}

export interface IDropdownProps {
  content?: ReactNode;
  contentStyle?: CSSProperties;
  children: ReactElement<IChildProps & Record<string, any>>;
  trigger?: "click" | "hover";
  onVisibleChange?: DropdownProps["onPopupVisibleChange"];
  popupVisible?: DropdownProps["popupVisible"];
  placement?: keyof typeof builtinPlacements;
  disabled?: boolean;
}
const builtinPlacements = {
  topLeft: {
    points: ["bl", "tl"],
  },
  bottomLeft: {
    points: ["tl", "bl"],
  },
  bottomRight: {
    points: ["tr", "br"],
  },
};

const Dropdown = (props: IDropdownProps) => {
  const {
    content,
    trigger = "click",
    contentStyle,
    children,
    onVisibleChange,
    popupVisible = undefined,
    placement = "bottomLeft",
  } = props;

  return (
    <>
      {/* @ts-ignore */}
      <Trigger
        action={trigger}
        onPopupClick={(e) => e.stopPropagation()}
        {...(popupVisible !== undefined ? { popupVisible } : {})}
        // popupAlign={{
        //   points: ["tl", "bl"],
        //   offset: [0, 3],
        // }}
        popupPlacement={placement}
        getPopupContainer={(trigger) => {
          return (trigger.parentNode as HTMLElement) || document.body;
        }}
        builtinPlacements={builtinPlacements}
        popup={
          <_DropdownContentWrapper
            className="dropdown-content-wrapper"
            $placement={placement}
            style={contentStyle}
          >
            {content}
          </_DropdownContentWrapper>
        }
        onPopupVisibleChange={onVisibleChange}
      >
        {children}
      </Trigger>
    </>
  );
};

export default Dropdown;
