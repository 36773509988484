export const $gold1 = "#b4a895";
export const $gold2 = "#aa9981";
export const $gold3 = "#93826f";
export const $gold4 = "#726151";
export const $gold5 = "#4f4535";
export const $gold6 = "#282219";
export const $gold7 = "#1b1a16";
export const $black = "#000000";
export const $white = "#FFFFFF";

export const $beigeBg = "#F4F3F1";

export const $champagne = "#b4a895";
export const $champagne3 = "#93826f";
export const $champagne4 = "#726151";
export const $champagne7 = "#1b1a16";
export const $disabled = "#ebe1d1";

export const $greyText = "#777777";
