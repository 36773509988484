import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RunContext } from "@src/AppProvider";
import { NewsletterModalProps } from "@src/components/feedback/NewsletterModal/NewsletterModal";

export interface ISmsModeData {
  data: {
    code_size?: number;
    charset?: string;
    error?: string;
  };
  callback: (smsCode: string, requireNewCode?: boolean) => void;
}

interface AppState {
  runContext?: RunContext;
  isoLang?: string;
  syncVersion: string;
  setupInitialized: boolean;
  setupReady: boolean;
  deliveryOptionsVisible: boolean;

  deliveryOptionsDown: boolean;
  deliveryOptionsDownLocked: boolean;
  env?: string;
  smsMode?: ISmsModeData;
  newsletterModal?: NewsletterModalProps;
}

const initialState: AppState = {
  setupInitialized: false,
  setupReady: false,
  deliveryOptionsVisible: true,
  deliveryOptionsDown: true,
  deliveryOptionsDownLocked: false,
  syncVersion: "initial",
  env: "",
};

export const AppSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setRunContext(state, action: PayloadAction<RunContext>) {
      state.runContext = action.payload;
      return state;
    },
    setSetupInitialized(state, action: PayloadAction<boolean>) {
      state.setupInitialized = action.payload;
      return state;
    },
    setSetupReady(state, action: PayloadAction<boolean>) {
      state.setupReady = action.payload;
      return state;
    },
    setDeliveryOptionsVisible(state, action: PayloadAction<boolean>) {
      state.deliveryOptionsVisible = action.payload;
      return state;
    },
    setDeliveryOptionsDown(state, action: PayloadAction<boolean>) {
      if (!state.deliveryOptionsDownLocked) {
        state.deliveryOptionsDown = action.payload;
      }
      return state;
    },
    setDeliveryOptionsDownForce(state, action: PayloadAction<boolean>) {
      state.deliveryOptionsDown = action.payload;
      return state;
    },
    lockDeliveryOptionsDown(state, action: PayloadAction<boolean>) {
      state.deliveryOptionsDownLocked = action.payload;
      return state;
    },
    setIsoLang(state, action: PayloadAction<string>) {
      state.isoLang = action.payload;
      return state;
    },
    setSyncVersion(state, action: PayloadAction<string>) {
      state.syncVersion = action.payload;
      return state;
    },
    openSmsMode(state, action: PayloadAction<ISmsModeData>) {
      state.smsMode = action.payload;
      return state;
    },
    closeSmsMode(state, action: PayloadAction<void>) {
      state.smsMode = undefined;
      return state;
    },
    openNewsletterModal(state, action: PayloadAction<NewsletterModalProps>) {
      state.newsletterModal = action.payload;
      return state;
    },
    closeNewsletterModal(state, action: PayloadAction<void>) {
      state.newsletterModal = undefined;
      return state;
    },
  },
});

const reducer = AppSlice.reducer;
export default reducer;
