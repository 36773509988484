import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAddressData } from "@src/interface/Address";

interface State {
  addresses: IAddressData[];
}

const initialState: State = { addresses: [] };

export const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {
    setAddresses(state, action: PayloadAction<IAddressData[]>) {
      state.addresses = action.payload;
      return state;
    },
    addAddress(state, action: PayloadAction<IAddressData>) {
      state.addresses.push(action.payload);
      return state;
    },
  },
});

const reducer = addressSlice.reducer;

export const { setAddresses, addAddress } = addressSlice.actions;
export default reducer;
