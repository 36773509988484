import styled, { CSSProperties } from "styled-components";
import { responsiveMap } from "../../../Components/Grid/_utils/responsiveObserve";
import { produtDetailConfig } from "../ProductDetails.config";
import FlexBox from "../../../Components/FlexBox/FlexBox";

export const _ProductDetailsInfoRoot = styled.div<{}>(() => {
  return {
    borderBottom: `1px solid ${produtDetailConfig.color.border}`,
    paddingBottom: 34,
    [`@media ${responsiveMap.lg}`]: {
      paddingTop: 0,
    },
  };
});
export const _ProductDetailsInfo_MainList = styled.div<{}>(() => {
  return {
    display: "flex",
    marginTop: 12,
  };
});

export const _ProductDetailsInfo_MainList_Separator = styled.div<{
  $color?: string;
}>(({ $color = "#000000" }) => {
  return {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    width: 20,
    ["&:before"]: {
      content: '" "',
      display: "inline-block",
      width: 4,
      height: 4,
      backgroundColor: $color,
      borderRadius: "50%",
    },
  };
});

export const _ProductBoostBannerRoot = styled(FlexBox)<{
  $bgPosition: CSSProperties["backgroundPosition"];
}>(({ $bgPosition = "center" }) => {
  return {
    padding: 24,
    marginTop: 24,
    borderRadius: 4,
    overflow: "hidden",
    userSelect: "none",
    position: "relative",
    cursor: "pointer",
    [".ro-typography"]: {
      position: "relative",
    },
    ["p"]: {
      maxWidth: 400,
    },
    [".ro-image"]: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      ["img"]: {
        height: "100%",
        width: "100%",
        backgroundPosition: $bgPosition,
      },
    },
    [".ro-typography--body"]: {
      margin: "4px auto 16px 0",
    },
    [".ro-button"]: {
      margin: "auto auto auto 0",
    },
  };
});
