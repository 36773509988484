import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FilterState {
  selectedFilter: string[];
  selectedAllergenGroups: string[];
}

const initialState: FilterState = {
  selectedFilter: [],
  selectedAllergenGroups: [],
};

export const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setfilter(state, action: PayloadAction<string[]>) {
      state.selectedFilter = action.payload;
      return state;
    },
    setAllergenGroups(state, action: PayloadAction<string[]>) {
      state.selectedAllergenGroups = action.payload;
      return state;
    },
  },
});

const reducer = filterSlice.reducer;

export default reducer;
