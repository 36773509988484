import SignInButton from "design-system/src/Components/SignInButton/SignInButton";
import SignInWithApple from "@src/components/feedback/SignInWithApple/SignInWithApple";
import Button from "design-system/src/Foundation/Button/Button";
import FlexBox from "design-system/src/Components/FlexBox/FlexBox";
import React, { createRef, useEffect } from "react";
import { IUseAuthenticationLogic } from "@src/components/feedback/AuthenticationContent/UseAuthenticationLogic";
import { useTranslation } from "@src/hooks/useTranslation";
import useFacebookConnectSdk from "@src/lib/FacebookConnect/useFacebookConnectSdk";
import { AlternateSmallButton } from "@src/components/feedback/AuthenticationContent/Form/AlternateSmallButton";

interface ISocialConnectStepProps {
  authentificationLogic: IUseAuthenticationLogic;
}
const SocialConnectStep = (props: ISocialConnectStepProps) => {
  const { t } = useTranslation(["Account"]);
  const googleButtonRef = createRef<HTMLButtonElement>();
  const { authentificationLogic } = props;
  const {
    onClickFacebook,
    onClickGoogle,
    onClickSignInEmail,
    onClickSignUpEmail,
    attachGoogleSignIn,
    isSigningFacebook,
    isSigningGoogle,
  } = authentificationLogic;

  const facebookConnect = useFacebookConnectSdk({});

  useEffect(() => {
    if (googleButtonRef.current) {
      attachGoogleSignIn(googleButtonRef.current);
    }
  }, [attachGoogleSignIn, googleButtonRef]);

  return (
    <FlexBox direction="column" gap={12} flex={1}>
      <SignInButton
        disabled={
          facebookConnect.isProcessing ||
          !facebookConnect.isSdkLoaded ||
          !facebookConnect.isMounted
        }
        icon="logo_facebook_colorized"
        onClick={onClickFacebook}
        loading={isSigningFacebook}
      >
        {t("Connexion avec Facebook", "Account")}
      </SignInButton>
      <SignInWithApple />
      <SignInButton
        ref={googleButtonRef}
        icon="logo_google_colorized"
        onClick={onClickGoogle}
        loading={isSigningGoogle}
      >
        {t("Connexion avec Google", "Account")}
      </SignInButton>
      <AlternateSmallButton onClick={onClickSignInEmail}>
        {t("Connexion par e-mail", "Account")}
      </AlternateSmallButton>
      <AlternateSmallButton
        onClick={onClickSignUpEmail}
        style={{ margin: "auto auto 0" }}
      >
        {t("Créer un compte", "Account")}
      </AlternateSmallButton>
    </FlexBox>
  );
};

export default SocialConnectStep;
