import styled from "styled-components";
import { FontIconProps } from "./FontIcon";

export const FontIconRoot = styled("i")<{
  $size?: FontIconProps["size"];
  $color?: FontIconProps["color"];
  className?: FontIconProps["className"];
}>(({ $size = 24, $color = "inherit" }) => ({
  fontSize: `${$size}px`,
  color: $color || "inherit",
  lineHeight: `${$size}px`,
  display: "inline-block",
}));
