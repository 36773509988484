export const cleanMySQLDateToISO = (date: string, withTime: boolean = true) => {
  const result =
    /^(?<year>\d{4})-(?<month>\d{2})-(?<day>\d{2}) ?(?<time>.*)$/g.exec(date);
  if (result && !!result.groups) {
    const { year, month, day, time } = result.groups;
    return `${year}-${month}-${day}${
      withTime ? "T" + (time || "00:00:00") : ""
    }`;
  } else {
    throw new Error(
      `Unable to clean date, invalid date format provided ${date}`
    );
  }
};
