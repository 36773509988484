import React, { useCallback, lazy } from "react";
import { useDispatch } from "react-redux";
import { cartSlice } from "@src/store/reducers/CartSlice";
import { useAppSelector } from "@src/store/hooks";
import { SuspenseHelper } from "@src/components/general/SuspenseHelper/SuspenseHelper";
import SuspenseAfterInteraction from "@src/lib/AfterInteraction/SuspenseAfterInteraction";

const DeliveryOptions = lazy(
  () => import("@src/components/molecule/DeliveryOptions/DeliveryOptions")
);

const DeliveryOptionsProvider = () => {
  const dispatch = useDispatch();

  const openDeliveryOptionsDrawer = useAppSelector(
    (state) => state.cart.openDeliveryOptions
  );
  const deliveryOptionsPreset = useAppSelector(
    (state) => state.cart.deliveryOptionsPreset
  );
  const showAddAddress = useAppSelector((state) => state.cart.showAddAddress);
  const preorderOptions = useAppSelector((state) => state.cart.preorder);

  const handleCloseDeliveryOptions = useCallback(() => {
    dispatch(cartSlice.actions.closeDeliveryOptions());
    dispatch(cartSlice.actions.closePreorder());
    dispatch(cartSlice.actions.setShowAddAddress(false));
  }, [dispatch]);

  const drawerOpen =
    openDeliveryOptionsDrawer || !!preorderOptions || showAddAddress;

  return (
    <SuspenseAfterInteraction>
      <SuspenseHelper fallback={null}>
        <DeliveryOptions
          drawerOnly={true}
          drawerOpen={drawerOpen}
          preorder={preorderOptions}
          preset={deliveryOptionsPreset}
          onCloseDrawer={handleCloseDeliveryOptions}
          isAddAddress={!!showAddAddress}
        />
      </SuspenseHelper>
    </SuspenseAfterInteraction>
  );
};

export default DeliveryOptionsProvider;
