import React, { forwardRef } from "react";
import { _ContainerRoot } from "./Container.styled";
import clsx from "clsx";

export interface ContainerProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  bg?: string;
  mt?: React.CSSProperties["marginTop"];
  mb?: React.CSSProperties["marginBottom"];
  as?: React.ComponentType<any> | string;
  [p: string]: any;
}

const Container = forwardRef((props: ContainerProps, ref) => {
  const { children, bg, style, className, mt, mb, as } = props;

  return (
    <_ContainerRoot
      {...{
        ref,
        style,
        as,
        className: clsx("ro-container", className),
        $bg: bg,
        $mt: mt,
        $mb: mb,
      }}
    >
      {children}
    </_ContainerRoot>
  );
});

export default Container;
