import { ICartProductSidedish } from "@src/backbone/model/CartModel";
import useBackboneContext from "@src/backbone/provider/useBackboneContext";
import { useTranslation } from "@src/hooks/useTranslation";
import { ProductApiData } from "@src/interface/Product";
import { useAppDispatch } from "@src/store/hooks";
import { useIsConnected } from "@src/store/reducers/CustomerSlice/hooks/useIsConnected";
import { useLikedProduct } from "@src/store/reducers/CustomerSlice/selectors";
import { addProduct } from "@src/store/reducers/ProductSlice";
import { useProductsSelector } from "@src/store/reducers/ProductSlice/selectors";
import React, { useEffect, useRef, useState } from "react";
import { useMemo, useCallback } from "react";
import { IProductCardProps } from "./ProductCard";
import ProductModel from "@src/backbone/model/ProductModel";
import { ProductItem } from "@src/hooks/query/useAllProduct";
import formatPrice from "@src/backbone/prototypes/formatPrice";
import { cartSlice } from "@src/store/reducers/CartSlice";

interface IProductState {
  isBuyableInContext?: boolean;
  allowPreorder?: boolean;
  hasSideDish?: boolean;
}

export const useProductCardLogic = (props: {
  product: ProductApiData | ProductItem;
  closeTo?: IProductCardProps["closeTo"];
}) => {
  const {
    product: { id_product },
    product,
  } = props;
  const { selectById } = useProductsSelector();
  const isConnected = useIsConnected();
  const { likes } = useLikedProduct();
  const [quantity, setQuantity] = useState(0);
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(0);
  const [priceInContext, setPriceInContext] = useState(0);
  const [priceFormatted, setPriceFormatted] = useState(
    formatPrice(product.price_ttc)
  );
  const [isStockoutNow, setIsStockoutNow] = useState(false);
  const [isMenu, setIsMenu] = useState<boolean>(!!product.is_menu);
  const [productState, setProductState] = useState<IProductState>({});
  const productStateRef = useRef(productState);
  const [hasSidedish, setHasSidedish] = useState(false);

  const dispatch = useAppDispatch();
  const { t } = useTranslation(["Product", "discount", "UI"]);
  const { app } = useBackboneContext();

  const productModelRef = useRef<ProductModel | null>(null);

  const getProductModel = useCallback(() => {
    return productModelRef.current
      ? productModelRef.current
      : (productModelRef.current = app.getProduct(id_product || "") || null);
  }, [id_product, app]);

  // const isMenu = getProductModel()?.isMenu() || product.is_menu;
  const productUrl = useMemo(() => product.uri, [product.uri]);

  const removeProductLabel = t("Supprimer", "UI");

  // select product from store
  const productData = useMemo(() => {
    if (id_product) {
      const selectedProduct = selectById(id_product);
      if (!selectedProduct) {
        dispatch(addProduct(product as ProductApiData));
        return product;
      }
      return selectedProduct;
    }
    return product;
  }, [dispatch, id_product, product, selectById]);

  const { pieceText } = useMemo(() => {
    return {
      pieceText:
        productData.quantity && productData.quantity > 1
          ? t("%s pièces", "Product", {
              vsprintfArgs: [productData.quantity],
            })
          : undefined,
    };
  }, [productData.quantity, t]);

  const isFavoris: boolean = useMemo(
    () =>
      !!(
        productData.id_product &&
        likes &&
        likes.reduce(
          (a, c) => (a ? true : `${c}` === `${productData.id_product}`),
          false
        )
      ),
    [likes, productData.id_product]
  );

  const ingredientList: string[] = useMemo(() => {
    const _list: string[] = [];
    [
      ...(productData.block_ingredient_1 || []),
      ...(productData.block_ingredient_2 || []),
    ].forEach((ingredient) => {
      if (ingredient && ingredient.name) {
        _list.push(ingredient.name);
      }
    });
    return _list;
  }, [productData]);

  const tagList: string[] = useMemo(() => {
    const _list: string[] = [];
    productData.tag?.forEach((tag) => {
      if (tag && tag.name) {
        _list.push(tag.name);
      }
    });
    return _list;
  }, [productData]);

  useEffect(() => {
    const cartModel = app?.getCart();

    const handler = (event?: string, changeProps?: boolean) => {
      const productModel = id_product
        ? app.getProduct(id_product) || null
        : null;
      productModelRef.current = productModel;
      // changeProps && console.log("handler", id_product);

      if (productModel && changeProps) {
        setPrice(productModel.getPrice());
        const cart = app.getCart();
        setPriceInContext(productModel.getPrice("ttc", true, cart));
        setPriceFormatted(productModel.formatPrice("ttc", true, cart) || "");
        setIsMenu(productModel.isMenu());

        // Product state
        const isBuyableInContext = productModel.isBuyableInContext();
        const allowPreorder = productModel.allowPreorder();
        setIsStockoutNow(!isBuyableInContext && !allowPreorder);
        setProductState({
          isBuyableInContext,
          allowPreorder,
        });
      }

      const newQuantity = id_product
        ? cartModel?.getProductQuantityById(id_product)
        : 0;

      if (quantity !== newQuantity) {
        setQuantity(newQuantity);
      }

      if (id_product) {
        const productCartModel = cartModel?.getProductsById(id_product).pop();
        const sidedishInCart = productCartModel?.getSidedishes();
        const hasSideDish: boolean =
          (sidedishInCart && sidedishInCart.size() > 0) || false;
        setHasSidedish(hasSideDish);
      }
    };

    const setupReadyHandler = () => {
      handler("init", true);
    };
    const changeHandler = (e: any) => {
      handler("change");
    };
    const addHandler = () => {
      handler("add");
    };
    const removeHandler = () => {
      handler("remove");
    };
    const deliveryOptionChange = () => {
      handler("delivery-options", true);
    };

    handler("init", true);

    app.on("setupReady", setupReadyHandler);
    if (quantity > 0) {
      cartModel.on("productsChange", changeHandler);
      cartModel.on("removeProduct", removeHandler);
    } else {
      cartModel.on("addProduct", addHandler);
    }
    cartModel.on("changeContext", deliveryOptionChange);
    return () => {
      app.off("setupReady", setupReadyHandler);
      cartModel.off("changeContext", deliveryOptionChange);

      if (quantity > 0) {
        cartModel.off("productsChange", changeHandler);
        cartModel.off("removeProduct", removeHandler);
      } else {
        cartModel.off("addProduct", addHandler);
      }
    };
  }, [app, id_product, price, quantity]);

  const getSubmitLabel = (
    isCartProduct?: boolean,
    mainLabel?: string
  ): string => {
    let label = mainLabel || t("Ajouter au panier", "Product");
    const productModel = getProductModel();
    if (productModel) {
      const isBuyableInContext = productModel.isBuyableInContext(),
        isMenu = productModel.isMenu(),
        allowPreorder = productModel.allowPreorder();
      if (isBuyableInContext) {
        if (isMenu && isCartProduct) {
          label = t("Modifier mon menu", "Product");
        }
      } else if (allowPreorder) {
        label = productModel.getPreorderButtonLabel();
      } else {
        label = productModel.getUnavailableMessage();
      }
      const newStockoutValue = !isBuyableInContext && !allowPreorder;
      if (isStockoutNow !== newStockoutValue) {
        setIsStockoutNow(newStockoutValue);
      }
    }
    return label;
  };

  // ------------------- handler ----------------

  const handleClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e?.stopPropagation?.();
    if (product.id_product && product.uri) {
      dispatch(
        cartSlice.actions.openProductPage({
          id_product: product.id_product,
          uri: product.uri,
        })
      );
    }
    // navigate(product.uri, { state: { modal: true } });

    // app.getCart()?.openProduct(product.id_product + "");
  };

  const handleHover: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      // !!productUrl && prefetchPathname(productUrl);
    },
    []
  );

  useEffect(() => {
    productStateRef.current = productState;
  }, [productState]);
  const handleQuantityChange = async (
    newQuantity: number,
    options?: {
      // usefull in other component
      sidedish?: ICartProductSidedish;
    }
  ): Promise<any | void> => {
    const { sidedish } = options || {};
    performance.mark("handleQuantityChange-start");
    const productModel = getProductModel();
    const cart = app.getCart();
    setLoading(true);
    try {
      const productStateRefCurrent = productStateRef.current;
      const { allowPreorder } = productStateRefCurrent;
      let { isBuyableInContext } = productStateRefCurrent;
      if (productModel) {
        if (isBuyableInContext) {
          // ADD TO CART
          // post data
          if (id_product) {
            const productQuantity = quantity || 0;
            const diffQuantity = newQuantity - productQuantity;
            const activeCrossSelling = productModel.get("active_cross_selling");

            if ((isMenu && activeCrossSelling) || hasSidedish) {
              if (diffQuantity > 0) {
                if (sidedish) {
                  await cart.addProduct(id_product, 1, { sidedish });
                } else {
                  diffQuantity > 0 && cart.openProduct(id_product);
                }
              } else {
                // remove first product from cart
                const cartProduct = cart.getProductsById(id_product);
                const id_cartProduct = cartProduct?.[0]?.getId();
                id_cartProduct && (await cart.deleteProduct(id_cartProduct));
              }
            } else if (activeCrossSelling && diffQuantity <= 0) {
              // remove first product from cart
              const cartProduct = cart.getProductsById(id_product);
              // get first product in cart
              const firstProduct = cartProduct?.[0];
              if (firstProduct) {
                const itemQuantity = firstProduct.getQuantity();
                // ensure item quantity is equal to 1 before removing product row
                if (itemQuantity === 1) {
                  // remove product row
                  const id_cartProduct = firstProduct?.getId();
                  id_cartProduct && (await cart.deleteProduct(id_cartProduct));
                } else {
                  // reduce item quantity
                  await cart.addProduct(id_product, diffQuantity);
                }
              }
            } else {
              await cart.addProduct(id_product, diffQuantity);
            }
          }
        } else if (allowPreorder) {
          // PREORDER
          await new Promise<void>((resolve, reject) => {
            if (id_product) {
              cart.preorder(id_product, false, (success) => {
                if (success) {
                  isBuyableInContext =
                    productStateRef.current.isBuyableInContext = app
                      .getProduct(id_product)
                      ?.isBuyableInContext();
                  if (isBuyableInContext) {
                    handleQuantityChange(1).then(resolve).catch(reject);
                  } else {
                    reject();
                  }
                } else {
                  reject();
                }
              });
            }
          });
        }
      } else {
        await new Promise<void>((resolve, reject) => {
          reject("Invalid product");
        });
      }
    } catch (e) {
      console.warn(e);
    } finally {
      performance.mark("handleQuantityChange-end");
      const performanceMeasure = performance.measure(
        "handleQuantityChange",
        "handleQuantityChange-start",
        "handleQuantityChange-end"
      );
      setLoading(false);
      console.log("handleQuantityChange", performanceMeasure.duration);
    }
  };

  const handleFavorisChange = useCallback(async () => {
    if (productData.id_product) {
      await app.getCustomer().likeProduct(productData.id_product);

      app.bottomNotice(t("Produit retiré des favoris !", "Product"));
    }
  }, [app, productData.id_product, t]);

  const getRollingStartPictures = (): NonNullable<
    ProductApiData["pictures"]
  > => {
    // TODO: get picture from model
    const staticPicture = productData?.pictures?.filter(
      (p) => p && p.types && p.types.includes("rollingstart")
    );
    const modelPicture = getProductModel()?.getPictureByType("rollingstart");
    return staticPicture && staticPicture.length > 0
      ? staticPicture || []
      : modelPicture
      ? [modelPicture]
      : [];
  };

  const getImageSource = (
    pictureIndex: number,
    rollingStart: boolean = true
  ) => {
    const getImage = (index: number) => {
      const pictures = rollingStart
        ? getRollingStartPictures()
        : productData.pictures;
      const { id_object_picture = "" } =
        pictures?.find((p, i) => i === index)! || {};
      return id_object_picture;
    };

    const defaultImage = getImage(0);

    return (
      (!getImage(pictureIndex) ? defaultImage : getImage(pictureIndex)) || "-1"
    );
  };

  return {
    getRollingStartPictures,
    handleQuantityChange,
    handleFavorisChange,
    isFavoris,
    isMenu,
    getImageSource,
    handleClick,
    handleHover,
    isConnected,
    t,
    productData,
    price,
    priceInContext,
    quantity,
    loading,
    pieceText,
    ingredientList,
    tagList,
    getProductModel,
    formatPrice: priceFormatted,
    productUrl,
    isStockoutNow,
    getSubmitLabel,
    app,
    removeProductLabel,
  };
};
