import React, { useMemo, useCallback } from "react";
import FlexBox from "../../../Components/FlexBox/FlexBox";
import Tag from "../../../Components/Tag/Tag";
import Typography from "../../../Foundation/Typography/Typography";
import ProductDetailsAccordian from "../ProductDetailsAccordian/ProductDetailsAccordian";
import { _ProductIngredientAccordianListWrapper } from "./ProductIngredientAccordian.styled";

interface IProductIngredientAccordianProps {
  title: string;
  ingredients: string[];
  tags?: string[];
}

const ProductIngredientAccordian = (
  props: IProductIngredientAccordianProps
) => {
  const { title, ingredients, tags } = props;
  const data = useMemo(() => {
    const ingredientLeft: string[] = [];
    const ingredientRight: string[] = [];
    ingredients.forEach((item, index) => {
      if (index % 2 === 0) {
        ingredientLeft.push(item);
      } else {
        ingredientRight.push(item);
      }
    });
    return {
      ingredientLeft,
      ingredientRight,
    };
  }, [ingredients]);

  const mapper = useCallback((ingredient: string, index: number) => {
    return (
      <Typography
        key={ingredient + "" + index}
        color="greyText2"
        variant="body2"
        style={{ lineHeight: "21px" }}
      >
        {ingredient}
      </Typography>
    );
  }, []);
  return (
    <ProductDetailsAccordian header={title} innerPadding={"0px 0px 30px"}>
      <_ProductIngredientAccordianListWrapper>
        <div>{data.ingredientLeft.map(mapper)}</div>
        <div>{data.ingredientRight.map(mapper)}</div>
      </_ProductIngredientAccordianListWrapper>
      {tags && tags.length ? (
        <FlexBox mt={20} gap={8} wrap="wrap">
          {tags.map((tag, index) => {
            return (
              <Tag key={tag + "" + index} variant="product-secondary">
                {tag}
              </Tag>
            );
          })}
        </FlexBox>
      ) : null}
    </ProductDetailsAccordian>
  );
};

export default ProductIngredientAccordian;
