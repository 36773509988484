import Backbone from "@src/backbone/prototypes/Backbone";
import LocalStorageModel from "../prototypes/LocalStorageModel";
import { app } from "./AppModel";

class IngredientModel extends LocalStorageModel {
  backbonePattern = "Model";
  name = "IngredientModel";
  idAttribute = "id_ingredient";
  route = "apiIngredientModel";
  initialize(attributes?: any, options?: any): void {
    // ObjectModel constructor heritage
    super.initialize.apply(this, [attributes, options]);
    //
  }
  getRelatedProducts(limit: any) {
    var model = this;
    return new Backbone.Collection(
      app.getProducts().filter(function (product) {
        var contains = false;
        product
          .getAllIngredient()
          .each(function (ingredient: { id: string | number }) {
            if (contains) return;
            contains = +ingredient.id === +model.id;
          });
        return contains;
      })
    ).slice(0, limit || Infinity);
  }
}

IngredientModel.prototype.name = "IngredientModel";
IngredientModel.prototype.url = function () {
  return `/ingredient/${this.get("id_ingredient")}`;
};

export default IngredientModel;
