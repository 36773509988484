import axios from "axios";
import { GATSBY_SOURCE_SUSHISHOP_BASE_URL } from "@src/utils/constants";

export const getAxiosInstance = (baseUrl: string = "") => {
  const baseURL = baseUrl || `${GATSBY_SOURCE_SUSHISHOP_BASE_URL || ""}/api/`;
  return axios.create({
    baseURL,
    // timeout: 10000,
    withCredentials: true,
  });
};

export const BaseAxiosApi = getAxiosInstance();

export const useAxiosInstance = () => {
  return BaseAxiosApi;
};
