import React, { useState, useCallback, useMemo } from "react";
import FlexBox from "../../../Components/FlexBox/FlexBox";
import Select, { ISelectProps } from "../../../Form/Select/Select";
import Typography from "../../../Foundation/Typography/Typography";
import Button, { IButtonProps } from "../../../Foundation/Button/Button";
import {
  _ProductDetailsAddToCardRoot,
  _ProductDetailsAddToCard_QuantifierWrapper,
} from "./ProductDetailsAddToCard.styled";

export interface IProductDetailsAddToCardProps {
  label: string;
  unitPrice?: number;
  renderPrice?: (quantity: number, unitPrice: number) => string;
  quantity?: number;
  loading?: boolean;
  outOfStock?: boolean;
  outOfStockLabel?: string;
  onSubmitQuantity?: (value: number) => void;
  withQuantity?: boolean;
  disabled?: boolean;
  override?: {
    label: string;
    onClick: () => void;
  };
  variant?: IButtonProps["variant"];
  customize?: IButtonProps["customize"];
}

const ProductDetailsAddToCard = (props: IProductDetailsAddToCardProps) => {
  const {
    label,
    unitPrice,
    quantity: defaultQuantity = 1,
    outOfStock,
    outOfStockLabel = "Produit indisponible",
    onSubmitQuantity,
    renderPrice,
    withQuantity,
    disabled,
    override,
    loading,
    variant = "champagne",
    customize,
  } = props;
  const hasOverride = !!override;
  const [quantity, setQuantity] = useState(defaultQuantity);
  const countOptions: ISelectProps<number>["options"] = [
    // { label: "Supprimer", value: 0 },
    ...Array.from({ length: 10 }, (_, i) => ({
      label: i + 1,
      value: i + 1,
    })),
  ];

  const _labels: string[] = useMemo(() => {
    const _l: string[] = [outOfStock ? outOfStockLabel : label];
    if (unitPrice) {
      const price = unitPrice * quantity;
      _l.push(renderPrice ? renderPrice(quantity, unitPrice) : price + "");
    }
    return _l;
  }, [unitPrice, quantity, outOfStockLabel, label, outOfStock]);

  const handleQuantityChange = useCallback((value: number) => {
    setQuantity(value);
  }, []);

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.preventDefault();
      if (hasOverride) {
        override.onClick();
      } else {
        onSubmitQuantity && onSubmitQuantity(withQuantity ? quantity : 1);
      }
    },
    [quantity, withQuantity, override?.onClick]
  );

  const quantifier = withQuantity && !outOfStock && (
    <Select
      value={quantity}
      size={"small"}
      options={countOptions}
      onChange={handleQuantityChange}
      placement="topLeft"
    />
  );

  return (
    <_ProductDetailsAddToCardRoot>
      <Button
        type="submit"
        style={{
          overflow: "visible",
          padding: "10px 16px",
        }}
        disabled={outOfStock || disabled}
        variant={variant}
        customize={customize}
        fullWidth={true}
        onClick={handleSubmit}
        loading={loading}
      >
        <FlexBox gap={10}>
          {hasOverride ? (
            <Typography
              variant="button"
              color={customize ? () => customize.color : "white"}
            >
              {override.label}
            </Typography>
          ) : (
            _labels.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {index > 0 && (
                    <Typography variant="button" color="white">
                      •
                    </Typography>
                  )}
                  <Typography variant="button" color="white">
                    {item}
                  </Typography>
                </React.Fragment>
              );
            })
          )}
        </FlexBox>
      </Button>
      <_ProductDetailsAddToCard_QuantifierWrapper>
        {quantifier}
      </_ProductDetailsAddToCard_QuantifierWrapper>
    </_ProductDetailsAddToCardRoot>
  );
};

export default React.memo(ProductDetailsAddToCard);
