import { createGlobalStyle, css } from "styled-components";
export const drawerPrefix = "ro-drawer";

export const DrawerGlobalCss = createGlobalStyle(() => {
  return css`
    &.${drawerPrefix} {
      position: fixed;
      z-index: 1050;
      pointer-events: none;
      inset: 0;
    }
    .${drawerPrefix}-inline {
      position: absolute;
    }
    .${drawerPrefix}-mask {
      position: absolute;
      z-index: 1050;
      background: rgba(0, 0, 0, 0.5);
      pointer-events: auto;
      inset: 0;
    }
    .${drawerPrefix}-content-wrapper {
      position: absolute;
      z-index: 1050;
      overflow: hidden;
      transition: transform 0.3s;
      background: #fff;
    }
    .${drawerPrefix}-content-wrapper-hidden {
      display: none;
    }
    .${drawerPrefix}-left .${drawerPrefix}-content-wrapper {
      top: 0;
      bottom: 0;
      left: 0;
    }
    .${drawerPrefix}-right .${drawerPrefix}-content-wrapper {
      top: 0;
      right: 0;
      bottom: 0;
    }
    .${drawerPrefix}-content {
      width: 100%;
      height: 100%;
      overflow: auto;
      /* background: #fff; */
      pointer-events: auto;
      color: #000;
      display: flex;
      flex-direction: column;
    }

    /* motion */
    $duration: 0.1s;

    .mask-motion {
      &-enter,
      &-appear,
      &-leave {
        &-active {
          transition: all $duration;
        }
      }

      &-enter,
      &-appear {
        opacity: 0;

        &-active {
          opacity: 1;
        }
      }

      &-leave {
        opacity: 1;

        &-active {
          opacity: 0;
          display: none;
        }
      }
    }

    .panel-motion {
      &-left {
        &-enter,
        &-appear,
        &-leave {
          &-start {
            transition: none !important;
          }

          &-active {
            transition: all $duration;
          }
        }

        &-enter,
        &-appear {
          transform: translateX(-100%);

          &-active {
            transform: translateX(0);
          }
        }

        &-leave {
          transform: translateX(0);

          &-active {
            transform: translateX(-100%) !important;
          }
        }
      }

      &-right {
        &-enter,
        &-appear,
        &-leave {
          &-start {
            transition: none !important;
          }

          &-active {
            transition: all $duration;
          }
        }

        &-enter,
        &-appear {
          transform: translateX(100%);

          &-active {
            transform: translateX(0);
          }
        }

        &-leave {
          transform: translateX(0);

          &-active {
            transform: translateX(100%) !important;
          }
        }
      }
    }
  `;
});
