import React, { startTransition, useEffect } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import "./Modal.styles.scss";
import clsx from "clsx";
import Button from "design-system/src/Foundation/Button/Button";
import { useTranslation } from "@src/hooks/useTranslation";

export interface IModalProps {
  onClose?: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement>;
  closable?: boolean;
  show: any;
  title?:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null;
  children?:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null;
  contentStyle?: React.CSSProperties;
  bodyStyle?: React.CSSProperties;
  headerStyle?: React.CSSProperties;
  footerStyle?: React.CSSProperties;
  isPage?: boolean;
}
const Modal = (props: IModalProps) => {
  const {
    onClose,
    children,
    show,
    contentStyle,
    bodyStyle,
    footerStyle,
    isPage,
    // closable = true,
  } = props;
  const { t } = useTranslation(["UI"]);

  const modalContent = (
    <div
      className={clsx({ modal: true, show, "modal-page": isPage })}
      onClick={onClose}
    >
      <div
        className="modal-content"
        style={contentStyle}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal-body" style={bodyStyle}>
          {children}
        </div>
        {!isPage && (
          <div className="modal-footer" style={footerStyle}>
            <Button variant="champagne" onClick={onClose} className="button">
              {t("Fermer", "UI")}
            </Button>
          </div>
        )}
      </div>
    </div>
  );

  const [isBrowser, setIsBrowser] = React.useState(false);

  React.useEffect(() => {
    setIsBrowser(
      typeof window !== "undefined" && typeof document !== "undefined"
    );
  }, []);

  return isBrowser
    ? ReactDOM.createPortal(
        <CSSTransition in={show} timeout={{ enter: 0, exit: 100 }}>
          {modalContent}
        </CSSTransition>,
        document.getElementById("___gatsby")!
      )
    : modalContent;
};

export default Modal;
