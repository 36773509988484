import { NotificationAPI } from "design-system/src/Core/type";
import React, { ReactNode } from "react";

export interface IDisplayModalComponentProps {
  onClose?(): void;
  onComplete?(): void;
}
export type IDisplayModalContentType = <
  P extends IDisplayModalComponentProps = IDisplayModalComponentProps
>(
  props: P
) => ReactNode;

export type IDisplayAlert = {
  type: "alert" | "confirm";
  message: React.ReactNode;
  template?: "sorry" | string;
  title?: ReactNode;
  label?: ReactNode;
  onOkText?: string;
  onCancelText?: string;
  onOk?: () => Promise<boolean> | boolean;
  onCancel?: () => void;
};

export type IDisplayOption =
  | IDisplayAlert
  | {
      type: "modal";
      content: IDisplayModalContentType;
      onComplete?: () => void;
      onClose?: () => void;
    };

export interface IAlertNoticeContextState {
  displayMsg: (option: IDisplayOption) => void;
  notice: NotificationAPI;
  bottomNotice: NotificationAPI;
  removeNotice?: (id: string) => void;
  createNotice?: (data: IDisplayOption) => void;
  notices: Array<IDisplayOption & { id: string }>;
}
const AlertNoticeContext = React.createContext<IAlertNoticeContextState>({
  displayMsg: (message) => {},
  notice: { open: () => {} } as unknown as NotificationAPI,
  bottomNotice: { open: () => {} } as unknown as NotificationAPI,
  notices: [],
});
export default AlertNoticeContext;
