import CategoryModel from "@src/backbone/model/CategoryModel";
import useBackboneContext from "@src/backbone/provider/useBackboneContext";
import store from "@src/store";
import { useCallback } from "react";
import useTagCo from "@src/hooks/useTagCo";
import { GATSBY_CURRENCY_SIGN } from "@src/backbone/prototypes/formatPrice";
import { GATSBY_CONF_ENV, GATSBY_ENV_COUNTRY_CODE } from "@src/utils/constants";
import { getEnvChannel } from "@src/components/general/WrapPageElement/TagCoLoader";

const CURRENCY_CODE = GATSBY_CURRENCY_SIGN;
const COUNTRY_CODE = GATSBY_ENV_COUNTRY_CODE;

type PageViewOptions<T = Record<string, any>> = Record<string, any> & {
  path?: string;
} & T;

export default function usePageLoad() {
  const { app: site_vars } = store.getState();

  const { app } = useBackboneContext();

  const { getEnvTemplate } = useTagCo();

  const getListProduct = useCallback(
    (type: string) => {
      type = type ? type : "default";
      const cart = app.getCart();
      let list_product = [],
        cartProductCollection = cart.getProducts();
      if (type === "consumables") {
        cartProductCollection = cart.getConsumables();
      }
      if (type === "order") {
        return (window as any).tc_vars.order_products || [];
      }
      list_product = cartProductCollection.map(function (
        cartProduct: Record<string, any>,
        index: number
      ) {
        try {
          var product = cartProduct.getProduct(),
            quantity = cartProduct.getQuantity(),
            category = product.getCategoryDefault(),
            product_vars,
            product_id = product.id,
            product_name = product.getName(),
            product_cat = category ? category.getName() : "",
            product_cat_id = category ? category.id : "",
            product_unitprice_ati = product.getPrice("ttc", true, cart),
            product_unitprice_tf = product.getPrice("ht", true, cart),
            product_isbundle = product.isMenu() ? "Yes" : "No",
            product_ref = product.get("reference") || "";
          if (type === "consumables") {
            product_vars = {
              order_annexe_id: product_id,
              order_annexe_ref: product_ref,
              order_annexe_cat: product_cat,
              order_annexe_name: product_name,
              order_annexe_quantity: quantity,
            };
          } else {
            product_vars = {
              order_product_cat: product_cat,
              order_product_cat_id: product_cat_id,
              order_product_currency: CURRENCY_CODE,
              order_product_discount_ati: 0,
              order_product_discount_tf: 0,
              order_product_id: product_id,
              order_product_ref: product_ref,
              order_product_isbundle: product_isbundle,
              order_product_name: product_name,
              order_product_quantity: quantity,
              order_product_unitprice_ati: product_unitprice_ati,
              order_product_unitprice_tf: product_unitprice_tf,
              order_product_index: index,
            };
          }
          return product_vars;
        } catch (err) {
          return {
            order_product_id: cartProduct.id,
          };
        }
      });
      return list_product;
    },
    [app]
  );

  const getFilter = useCallback(() => {
    let filter = "",
      tags = app.getTags(),
      allTags = tags.isAllChecked(),
      allergens = app.getAllergenGroups(),
      allAllergens = allergens.isAllChecked();
    if (allTags && allAllergens) {
      filter = "No";
    } else {
      if (!allAllergens) {
        filter = allergens.getChecked().pluck("name").join("/");
      }
      if (!allTags) {
        filter +=
          (allAllergens ? "" : "//") +
          tags.getChecked().pluck("name").join("/");
      }
    }
    return filter;
  }, [app]);
  const getTagCommanderOrderVars = useCallback(
    (pagename: string, options: PageViewOptions) => {
      let customer = app.getCustomer(),
        cart = app.getCart(),
        store = cart.getStore(), //|| (order ? order.getStore() : undefined),
        order_id = "",
        id_cart = cart.get("id"),
        delai = "",
        order_amount_ati_without_sf: number | string = "",
        order_amount_ati_with_sf: number | string = "",
        order_discount_ati: number | string = "",
        order_ship_ati: number | string = "",
        order_amount_tf_without_sf: number | string = "",
        order_amount_tf_with_sf: number | string = "",
        order_discount_tf: number | string = "",
        order_ship_tf: number | string = "",
        order_tax: number | string = "",
        order_comin_amount = "",
        order_payment_methods = "",
        order_shipping_method = "",
        order_shipping_date: string = "",
        order_shipping_time = "",
        order_status = "",
        order_promo_code = "",
        order_currency = "",
        order_newcustomer = "",
        order_piece_number: number | string = "",
        order_products_number = 0,
        order_product_list_name = pagename,
        order_products = [], // array default defined in tc_vars.js.php
        order_products_annexe = [],
        total_burn = 0,
        //-----------------------------------------------------------------
        // store
        //-----------------------------------------------------------------
        store_id = "",
        store_name = "",
        store_postal_code = "",
        store_city = "",
        store_country = "",
        //
        user_recency = "",
        filter = "";

      if (pagename === "Store" && options.subpage === "single") {
        // si on est sur une fiche boutique, on utilise les données de la boutique
        var stores = app.getStores();
        if (options.link_rewrite) {
          store = stores.findWhere({ link_rewrite: options.link_rewrite });
        } else {
          store = stores.get(options.id_store);
        }
      }

      if (store) {
        store_id = store.id as string;
        store_name = store.getName();
        store_postal_code = store.formatPostcode();
        store_city = store.getCity();
        store_country = COUNTRY_CODE;
      }

      if (pagename === "CheckoutConfirmation") {
        const wTcVars = { ...(window as any).tc_vars };
        // Checkout confirmation
        order_id = wTcVars.order_id;
        order_amount_ati_without_sf = wTcVars.order_amount_ati_without_sf;
        order_amount_ati_with_sf = wTcVars.order_amount_ati_with_sf;
        order_discount_ati = "";
        order_ship_ati = wTcVars.order_ship_ati;
        order_amount_tf_without_sf = 0;
        order_amount_tf_with_sf = "";
        order_discount_tf = "";
        order_ship_tf = wTcVars.order_ship_tf;
        order_tax = wTcVars.order_tax;
        order_comin_amount = wTcVars.order_comin_amount;
        order_payment_methods = "";
        // TODO order_payment_methods
        // order.getPaymentModes()
        // ?.pluck("name")
        // .join(",");
        order_shipping_method = "";
        order_shipping_date = "";
        order_shipping_time = "";
        order_status = wTcVars.order_status;
        order_promo_code = "";
        order_currency = CURRENCY_CODE;
        order_newcustomer = "";
        order_piece_number = "";
        order_products = getListProduct("order");
        total_burn = wTcVars.total_burn;
        order_products_annexe = wTcVars.order_products_annexe; //
        store_id = wTcVars.store_id;
        store_name = wTcVars.store_name;
        store_postal_code = wTcVars.store_postal_code;
        store_city = wTcVars.store_city;
        store_country = wTcVars.store_country;
        user_recency = wTcVars.user_recency;
      } else {
        // Cart & default
        // TODO
        let cartPriceTTC = cart.getPriceTTC(),
          cartPriceHT = cart.getValueOf("total_price_ht"),
          cartPriceTTCWithoutExtraPrice = cart.getPriceTTC({
            ignoreExtraPrices: true,
          }),
          totalDiscountTTC = cart.getValueOf("total_discount"),
          extraPriceHT = cart.getExtraPriceValue("price_ht");
        //paymentMethod = cart.getPaymentMethod()
        order_amount_ati_without_sf = cartPriceTTCWithoutExtraPrice;
        order_amount_ati_with_sf = cartPriceTTC;
        order_discount_ati = totalDiscountTTC;
        order_ship_ati = cart.getExtraPriceValue();
        order_amount_tf_without_sf = cartPriceHT - extraPriceHT;
        order_amount_tf_with_sf = cartPriceHT;
        order_discount_tf = cart.getValueOf("total_discount");
        order_ship_tf = extraPriceHT;
        order_tax = cart.getValueOf("total_tva");
        order_payment_methods = "";
        order_shipping_method =
          (cart.isVAE() ? "Click & Collect" : "Livraison") +
          (cart.isNow() ? "" : " - différé");
        order_shipping_date = cart.get("order_date") as string;
        order_shipping_time = cart.get("order_time") as string;
        order_status = "Panier";
        order_promo_code = cart.getVouchers().pluck("description").join(";");
        order_currency = CURRENCY_CODE;
        order_newcustomer = app.getCustomer().isProspect()
          ? "Prospect"
          : "Client";
        order_piece_number = cart.getPieces();
        order_products = getListProduct("default");
        order_products_annexe = getListProduct("consumables");

        if (pagename === "category") {
          const category = app
            .getCategories()
            .findWhere({ link_rewrite: options.link_rewrite });
          if (category) order_product_list_name = category.getName() || "";
        }

        filter = getFilter();
      }
      order_products_number = order_products.length || 0;

      const extraPriceHT = cart.getExtraPriceValue("price_ht");

      const tc_vars_order = {
        //
        //list_products : list_products ,
        //
        order_id: order_id,
        //
        user_recency: user_recency,
        // Order ID
        basket_id: id_cart,
        // Basket ID created when a product is added to the basket
        order_amount_ati_without_sf: order_amount_ati_without_sf,
        // Order amount ATI (all tax included) without shipping fee
        order_amount_ati_with_sf: order_amount_ati_with_sf,
        // Order amount ATI (all tax included) with shipping fee
        order_discount_ati: order_discount_ati,
        // Order discount ATI (all tax included) - let empty if no discount
        order_ship_ati: order_ship_ati,
        // Order shipping fee ATI (all tax included)
        order_amount_tf_without_sf: order_amount_tf_without_sf,
        // Order amount TF (tax free) without shipping fee
        order_amount_tf_with_sf: order_amount_tf_with_sf,
        // Order amount TF (tax free) with shipping fee
        order_discount_tf: order_discount_tf,
        // extra price HT = frais de gestion
        fees: extraPriceHT,
        // Order discount TF (tax free) - let empty if no discount
        order_ship_tf: order_ship_tf,
        // Order shipping fee TF (tax free)
        order_tax: order_tax,
        // Order cagnotte usage
        order_comin_amount: order_comin_amount,
        // TODO : pourquoi dupliquer la variable d'usage de la cagnotte ?
        total_burn: total_burn,
        // Taxes
        order_payment_methods: order_payment_methods,
        // Payment methods (VISA, CB,MASTERCARD,AMEX,â€¦) - JavaScript array if several payment methods for the same order
        order_shipping_method: order_shipping_method,
        // Shipping method ("livraison" ou "emporter")
        order_shipping_date: order_shipping_date,
        // Shipping date
        order_shipping_time: order_shipping_time,
        // Shipping time
        order_status: order_status,
        // Order status ("validated","rejected")
        order_promo_code: order_promo_code,
        // List of promotionnal codes/coupons in the order - JavaScript array if several coupons for the same order
        order_currency: order_currency,
        // Currency code (ISO code 4217)
        order_newcustomer: order_newcustomer,
        // First order or already client
        order_piece_numbre: order_piece_number,
        // piece commande
        order_products_number: order_products_number,
        //
        order_product_list_name: order_product_list_name,
        // Number of products into the order
        order_products: order_products,
        order_products_annexe: order_products_annexe,
        //-----------------------------------------------------------------
        // store
        //-----------------------------------------------------------------
        store_id: store_id,
        // Store ID
        store_name: store_name,
        // Store name
        store_postal_code: store_postal_code,
        // Store postal code
        store_city: store_city,
        // Store postal code
        store_country: store_country,
        // Store postal code

        delai: delai,
        filter: filter, // filtres ingrédients et allergen
      };
      return tc_vars_order;
    },
    [app, getFilter, getListProduct]
  );

  const getPageTitle = useCallback(
    (pagename: string, options: PageViewOptions) => {
      let page_title = options.meta_title || "";
      if (!page_title) {
        if (pagename === "product") {
          const product = app.getProduct(options.id_product);
          if (product) {
            page_title = product.getName() || "";
          }
        }
        if (pagename === "checkoutopc" || pagename === "cart") {
          switch (options.order_step) {
            default:
            case 1:
              page_title = "Panier";
              break;
            case 2:
              page_title = "Panier Accompagnements";
              break;
            case 3:
              page_title = "Paiement";
              break;
          }
        }
      }
      return page_title || document.title.toString() || "";
    },
    [app]
  );

  const getPageCat1 = useCallback(
    (pagename: string, options: PageViewOptions) => {
      var page_cat_1 = pagename;
      switch (pagename) {
        case "cross_selling":
          page_cat_1 = options.label || "";
          break;
        case "checkoutopc":
          page_cat_1 = "Checkout";
          break;
        case "checkoutconfirmation":
          page_cat_1 = "Checkout confirmation";
          break;
        case "category":
          const { id_category = "" } = options,
            category = app.getCategories().get(id_category);
          if (category) {
            page_cat_1 = category.getName() || "";
          } else {
            page_cat_1 = `category_${id_category}`;
          }
          break;
        case "product":
          const product = app.getProduct(options.id_product);
          let _category: CategoryModel;
          if (product && (_category = product.getCategoryDefault())) {
            page_cat_1 = _category.getName() || "";
          }
          break;
        case "store":
          let store;
          if (
            options.subpage === "single" &&
            (store = app.getStore(options.id_store))
          ) {
            page_cat_1 = store.getCity() || "";
          } else {
            page_cat_1 = options.search || "";
          }
          break;
        case "widget":
          page_cat_1 = options.groupName || "";
          break;
      }
      return page_cat_1;
    },
    [app]
  );

  const getPageCat2 = useCallback(
    (pagename: string, options: PageViewOptions) => {
      let page_cat_2 = "";
      switch (pagename) {
        case "checkoutopc":
          switch (options.order_step) {
            case 1:
              page_cat_2 = "Cart";
              break;
            case 2:
              page_cat_2 = "CrossSelling";
              break;
            case 3:
              page_cat_2 = "Payment";
              break;
            default:
              page_cat_2 = "Step " + options.order_step;
              break;
          }
          break;
        case "product":
          // TODO product name
          let product = app.getProduct(options.id_product);
          if (product) {
            page_cat_2 = product.getName() || "";
          }
          break;
        case "store":
          let store;
          if (
            options.subpage === "single" &&
            (store = app.getStore(options.id_store))
          ) {
            page_cat_2 = store.getName() || "";
          }
          break;
        case "account":
          page_cat_2 = options.account_page;
          break;
        case "news":
          page_cat_2 = options.flag_link_rewrite || "";
          break;
        case "supportcenter":
          const supportCenter = options.supportCenter;
          let category;
          if (
            supportCenter &&
            (category = supportCenter.getCurrentCategory())
          ) {
            page_cat_2 = category.get("name");
          }
          break;
      }
      return page_cat_2;
    },
    [app]
  );

  function getPageCat3(pagename: string, options: PageViewOptions) {
    let page_cat_3 = "";
    switch (pagename) {
      case "supportcenter":
        const supportCenter = options.supportCenter;
        let article;
        if (supportCenter && (article = supportCenter.getCurrentArticle())) {
          page_cat_3 = article.get("name");
        }
        break;
      case "news":
        page_cat_3 = options.news_link_rewrite || "";
        break;
    }
    return page_cat_3;
  }

  function getPageCatId1(pagename: string, options: PageViewOptions) {
    let page_cat_id_1 = "";
    switch (pagename) {
      case "category":
        page_cat_id_1 = options.id_category || "";
        break;
    }
    return page_cat_id_1;
  }

  function getOrderStep(pagename: string, options: PageViewOptions) {
    switch (pagename) {
      case "cart":
        return "1";
      case "cross_selling":
      case "checkoutopc":
        return options.order_step || "";
      default:
        return "";
    }
  }

  function getPageName(pagename: string, options: PageViewOptions) {
    let name = pagename;
    switch (pagename) {
      case "CheckoutOpc":
        name = "Checkout";
        break;
      case "Widget":
        name = "Landing Page";
        break;
      case "Store":
        if (options.subpage !== "list") {
          name = "Store page";
        } else {
          name = "Store locator";
        }
        break;
      case "News":
        if (!options.news_link_rewrite) {
          name = "All news";
        }
        break;
      case "CheckoutConfirmation":
        name = "Checkout confirmation";
        break;
      default:
    }
    // capitalized
    name = name.charAt(0).toUpperCase() + name.slice(1);
    return name;
  }

  const getTagCommanderTemplateVars = useCallback(
    (pagename: string, options: PageViewOptions) => {
      let pagenameToLowerCase = pagename.toLowerCase(),
        env_work = GATSBY_CONF_ENV,
        env_channel = getEnvChannel(), //
        env_language = site_vars.isoLang,
        env_template = getEnvTemplate(pagenameToLowerCase),
        page_title = getPageTitle(pagenameToLowerCase, options),
        page_cat1 = getPageCat1(pagenameToLowerCase, options),
        page_cat2 = getPageCat2(pagenameToLowerCase, options),
        page_cat3 = getPageCat3(pagenameToLowerCase, options),
        page_cat_id_1 = getPageCatId1(pagenameToLowerCase, options),
        order_step = getOrderStep(pagenameToLowerCase, options),
        page_name_tc = pagename || "default",
        page_name = getPageName(page_name_tc, options),
        page_error = ""; // TODO gérer les 404 / erreurs

      switch (pagename) {
        case "NotFound":
          page_error = pagename;
          break;
      }

      return {
        env_work: env_work,
        env_channel: env_channel,
        env_language: env_language,
        // Website language
        env_country: COUNTRY_CODE,
        // Country
        env_template: env_template,
        page_title: page_title,
        page_cat1: page_cat1,
        page_cat2: page_cat2,
        page_cat3: page_cat3,
        page_cat_id_1: page_cat_id_1,
        page_name_tc: page_name_tc,
        page_name: page_name,
        page_error: page_error,
        //
        order_step: order_step,
        //
        // TODO: get site_vars
        /// site_version : site_vars.deploy_tag_version+'('+site_vars.asset_version+')'
        site_version: "rs_deploy_tag_version",
      };
    },
    [getPageCat1, getPageCat2, site_vars.env, site_vars.isoLang]
  );

  const getTagCommanderProductVars = useCallback(
    (pagename: string, options: PageViewOptions) => {
      const id_product = options.id_product || "",
        product = app.getProduct(id_product);
      let product_ref = "",
        product_name = "",
        product_cat = "",
        product_cat_id: number | string = "",
        product_price_ttc: number | string = "",
        product_price_ht: number | string = "",
        product_currency = "",
        product_url_page = "",
        product_isbundle = "",
        list_products_cat: number[] = [];
      if (pagename === "product" && product) {
        const categoryDefault = product.getCategoryDefault();
        product_ref = product.get("reference") || "";
        product_name = product.get("name") || "";
        product_price_ttc = product.getPrice("ttc", true);
        product_price_ht = product.getPrice("ht", true);
        product_currency = CURRENCY_CODE;
        product_cat = categoryDefault?.getName() || "";
        product_cat_id = categoryDefault ? categoryDefault.id : "";
        product_url_page = product.getUrl();
        product_isbundle = product.isMenu() ? "Yes" : "No";
      }

      if (pagename === "category" && options.list_products_cat) {
        list_products_cat = (
          options.list_products_cat as Array<Record<string, any>>
        ).map(({ id }: any) => {
          return parseInt(id);
        });
      }

      const tc_vars_product = {
        //-----------------------------------------------------------------
        // product
        //-----------------------------------------------------------------
        product_id: id_product,
        // Product ID
        product_ref: product_ref,
        // Product REF
        product_name: product_name,
        // Product name
        product_cat: product_cat,
        // Product name
        product_cat_id: product_cat_id,
        // Product category
        product_unitprice_ati: product_price_ttc,
        // Product unit price ATI (all tax included)
        product_discount_ati: 0,
        // Product discount price ATI (all tax included)
        product_unitprice_tf: product_price_ht,
        // Product unit price TF (tax free)
        product_discount_tf: 0,
        // Product discount price TF (tax free)
        product_currency: product_currency,
        // Currency code (ISO code 4217)
        product_url_page: product_url_page,
        // Product URL (URL of the product page)
        //  product_url_picture : product_url_picture,
        // Main picture absolute path from the first slash (URL of the product main image)
        product_isbundle: product_isbundle,
        // Product bundle or not
        list_products_cat: list_products_cat,
      };
      return tc_vars_product;
    },
    [app]
  );

  const getClientId = useCallback(() => {
    return "";
  }, []);
  // const resetClientId = useCallback(() => {
  //   getClientId();
  // }, []);

  const getTagCommanderVars = useCallback(
    (pagename: string, options: PageViewOptions) => {
      //
      //
      //
      var // customer
        app_route = options.app_route || window.location.pathname,
        customer = app.getCustomer(),
        isLogged = customer.isLogged(),
        orders = customer.getOrders(),
        // cart = app.getCart(),
        id_customer = customer.get("id_customer") || "",
        id_customer_comein = customer.getIdComein() || "",
        id_customer_neolane = customer.getIdNeolane() || "",
        birthday = customer.get("birthday") || "",
        user_email = customer.get("email") || "",
        is_newsletter = customer.isNewsletter(),
        isSms = customer.isSms(),
        user_email_optin = isLogged ? (is_newsletter ? 1 : 0) : "",
        user_frequency = orders.size(), // nombre de commande déjà passée
        user_status =
          user_frequency > 0
            ? user_frequency > 1
              ? "Client"
              : "Nouveau client"
            : "Prospect", // > 1 = visiteur identifié comme ayant déjà passé commande
        user_subscription_date = customer.get("date_add") || "", //
        // customer address
        address = customer.getPrimaryAddress(),
        postcode = address ? address.getPostcode() : "",
        user_city = address ? address.get("city") || "" : "",
        // new variables
        user_optin = isLogged
          ? is_newsletter && isSms
            ? "SMS + Mail"
            : is_newsletter
            ? "Mail"
            : isSms
            ? "SMS"
            : "None"
          : "N/A",
        user_payment = !!customer.getCards().size() ? "Yes" : "No",
        user_category = customer.getSegmentName(),
        preset_adress = !!address ? "Yes" : "No",
        env_meteo = "N/A",
        tc_vars = {
          //-----------------------------------------------------------------
          // customer
          //-----------------------------------------------------------------
          user_id_customer_comin: id_customer_comein,
          user_id_customer_neolane: id_customer_neolane,

          // TODO: get client id
          client_id: getClientId(),
          user_id: id_customer,
          // Visitor ID
          user_gender: "",
          // Visitor Gender
          user_birthdate: birthday,
          // Visitor Birthdate
          user_postalcode: postcode,
          // Visitor postal code
          user_category: user_category,
          // Visitor category (internal CRM segmentation)
          user_frequency: user_frequency,
          // F - Visitor frequency (number of purchases)
          user_subscription_date: user_subscription_date,
          // Visitor subscription date
          user_last_connexion_date: "",
          // Visitor last connexion date
          user_email: user_email,
          // Email of the customer
          user_email_optin: user_email_optin,
          // Visitor optin newsletter or not
          user_city: user_city,
          // Visitor city
          user_zipcode: postcode,
          // Visitor zipcode
          user_country: !!id_customer ? COUNTRY_CODE.toLowerCase() : "",
          // Visitor country
          user_status: user_status,
          // Visitor status : customer or prospect

          // new variables
          user_optin: user_optin,
          user_payment: user_payment,

          preset_adress: preset_adress,
          env_meteo: env_meteo,
        };

      return {
        app_route,
        ...tc_vars,
        ...getTagCommanderTemplateVars(pagename, options),
        ...getTagCommanderOrderVars(pagename, options),
        ...getTagCommanderProductVars(pagename, options),
      };
    },
    [
      app,
      getClientId,
      getTagCommanderOrderVars,
      getTagCommanderProductVars,
      getTagCommanderTemplateVars,
    ]
  );

  const TrackPageView = useCallback(
    (
      pagename: string,
      options: PageViewOptions,
      initialPageLoad: boolean = false
    ) => {
      const _window = window as any;
      _window._prevTcVars = _window.tc_vars;
      try {
        const wipTCVars = getTagCommanderVars(pagename, options),
          tC = _window.tC;
        //console.log("tc_vars ==>", (window as any).tc_vars);
        _window.tc_vars = wipTCVars;
        //console.log("wipTCVars ==>", wipTCVars);
        const shouldContentChange = !!tC && !initialPageLoad;
        const { page_title = "" } = wipTCVars;
        console.log(
          "%ctracking page view ==> %s : %s",
          `color:${shouldContentChange ? "green" : "orange"}`,
          pagename,
          page_title,
          options
        );

        if (shouldContentChange) {
          tC.container.reload(); // mise à jour du dataLayer
          tC.event.contentChange(); // tracking page view
        }
      } catch (err: any) {
        app?.error("TagCommanderError", { message: err.message });
      }
    },
    [app, getTagCommanderVars]
  );

  const TrackBackPageView = (vars?: object) => {
    const _window = window as any;
    try {
      const tc_vars = {
        ..._window.tc_vars,
        ..._window._prevTcVars,
        ...vars,
      };
      _window.tc_vars = tc_vars;
      const { page_name = "", page_title = "" } = tc_vars;
      console.log(
        "%ctracking back page view ==> %s : %s",
        `color:purple`,
        page_name,
        page_title,
        _window.location.pathname
      );
      _window.tC.event.contentChange();
    } catch (err) {
      console.warn(err);
    }
  };

  return {
    TrackPageView,
    TrackBackPageView,
  };
}
