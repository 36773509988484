import useBackboneContext from "@src/backbone/provider/useBackboneContext";
import { useAppSelector } from "@src/store/hooks";

export const useCustomerData = () => {
  return useAppSelector((state) => state.customer.current);
};

export const useLikedProduct = (): { likes: Array<string | number> } => {
  const customer = useAppSelector((state) => state.customer.current);
  const { app } = useBackboneContext();
  const customerModel = app.getCustomer();
  const likes = customerModel?.get("likes") || [];

  return {
    likes: likes || customer.likes || [],
  };
};
