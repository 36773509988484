import {
  createEntityAdapter,
  createSlice,
  // PayloadAction,
} from "@reduxjs/toolkit";
import { ICategoryData } from "@src/interface/Category";

// adapter
export const categoryAdapter = createEntityAdapter<ICategoryData>({
  selectId: (category) => category.id_category || "",
});

// slice
export const categorySlice = createSlice({
  name: "category",
  initialState: categoryAdapter.getInitialState(),
  reducers: {
    setAll: categoryAdapter.setAll,
    addOne: categoryAdapter.addOne,
    addMany: categoryAdapter.addMany,
    updateOne: categoryAdapter.updateOne,
  },
});

const reducer = categorySlice.reducer;

export default reducer;
