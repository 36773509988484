import React, { PropsWithChildren } from "react";
import {
  ThemeProvider as StyledComponentThemeProvider,
  ThemeProviderProps,
} from "styled-components";
import "../../lib/svgIcon/font/rolling-start.css";
import { defaultTheme, IDefaultTheme } from "../../Themes/defaultTheme";

interface IThemeProviderProps
  extends PropsWithChildren<
    Omit<ThemeProviderProps<IDefaultTheme, any>, "theme">
  > {
  theme?: Partial<ThemeProviderProps<IDefaultTheme, any>["theme"]>;
}

const ThemeProvider = (props: IThemeProviderProps) => {
  const { theme = defaultTheme, children } = props;
  const partialTheme = { ...defaultTheme, ...theme };
  return (
    <StyledComponentThemeProvider
      theme={partialTheme || {}}
      {...{ children }}
    />
  );
};

export default ThemeProvider;
