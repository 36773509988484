import React, { ReactNode } from "react";
import "./ProductPageContentWrapper.styles.scss";

const ProductPageContentWrapper = (props: {
  children?: ReactNode;
  onClose?(): void;
}) => {
  const { children, onClose } = props;
  return (
    <div className="product-page__root">
      <div className="product-page__overlay" onClick={onClose}></div>
      <div className="product-page__wrapper">{children}</div>
    </div>
  );
};

export default ProductPageContentWrapper;
