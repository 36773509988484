import { IUseAuthenticationLogic } from "@src/components/feedback/AuthenticationContent/UseAuthenticationLogic";
import { Controller } from "react-hook-form";
import Input, {
  EInputState,
  EInputType,
  EInputVariant,
} from "design-system/src/Form/Input/Input";
import FlexBox from "design-system/src/Components/FlexBox/FlexBox";
import React from "react";
import { useTranslation } from "@src/hooks/useTranslation";
import { AlternateSmallButton } from "@src/components/feedback/AuthenticationContent/Form/AlternateSmallButton";
import Button from "design-system/src/Foundation/Button/Button";
import InputRender from "@src/components/feedback/AuthenticationContent/Form/InputRender";
import SubmitButton from "@src/components/feedback/AuthenticationContent/Form/SubmitButton";
import CustomerFields from "@src/components/feedback/AuthenticationContent/Form/CustomerFields";

interface ISignInStepStepProps {
  authentificationLogic: IUseAuthenticationLogic;
}
const SignInEmailStep = (props: ISignInStepStepProps) => {
  const { t } = useTranslation(["Account"]);
  const { authentificationLogic } = props;
  const {
    control,
    onClickForgetPassword,
    onSubmit,
    isLoading,
    customerFields,
  } = authentificationLogic;
  return (
    <FlexBox direction="column" gap={12} flex={1}>
      {<CustomerFields customerFields={customerFields} control={control} />}
      <SubmitButton type="submit" loading={isLoading} onClick={onSubmit}>
        {t("Connexion", "Account")}
      </SubmitButton>
      <AlternateSmallButton
        onClick={onClickForgetPassword}
        style={{ margin: "auto auto 0" }}
      >
        {t("Mot de passe oublié ?", "Account")}
      </AlternateSmallButton>
    </FlexBox>
  );
};
export default SignInEmailStep;
