import { ProductApiData } from "@src/interface/Product";
import { cartSlice } from "@src/store/reducers/CartSlice";
import { rehydrateProduct } from "@src/store/reducers/ProductSlice";
import { RollingStartEndpointBuilder } from "..";

export const createProductEndpoints = (
  builder: RollingStartEndpointBuilder
) => ({
  getProductById: builder.query<ProductApiData, { id_product: string }>({
    query: ({ id_product }) => ({
      url: `/product/${id_product}`,
      method: "GET",
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      try {
        const res = await queryFulfilled;
        if (res.data && res.data.id_product) {
          dispatch(
            rehydrateProduct({ id: res.data.id_product, changes: res.data })
          );
        }
      } catch (error) {}
    },
  }),
  getStockoutProduct: builder.query<
    { id_store: string; products: string[] },
    { id_store: string }
  >({
    query: ({ id_store }) => ({
      url: `/stockoutproduct/${id_store}`,
      method: "GET",
    }),
    /* async onQueryStarted(_, { dispatch, queryFulfilled }) {
      try {
        const res = await queryFulfilled;
        if (res.data && res.data.id_store) {
          dispatch(
            cartSlice.actions.setStockoutProduct({
              id_store: res.data.id_store,
              products: res.data.products || [],
            })
          );
        }
      } catch (error) {}
    }, */
  }),
});
