import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageContextIntl } from "@src/components/general/WrapPageElement/PageContext";
import { ILayoutProviderProps } from "@src/LayoutProvider";

interface PageState {
  currentPageContextIntl?: PageContextIntl;
  layoutHistory: ILayoutProviderProps[];
  isModal: boolean;
}

const initialState: PageState = {
  layoutHistory: [],
  isModal: false,
};

export const PageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    setCurrentPageContextIntl(state, action: PayloadAction<PageContextIntl>) {
      state.currentPageContextIntl = action.payload;
      return state;
    },
    setIsModal(state, action: PayloadAction<boolean>) {
      state.isModal = action.payload;
      return state;
    },
    addLayoutHistory(state, action: PayloadAction<ILayoutProviderProps>) {
      const value = action.payload;
      const last = state.layoutHistory[state.layoutHistory.length - 1];
      if (last) {
        if (last.props.uri !== value.props.uri) {
          state.layoutHistory = [
            // @ts-ignore
            ...state.layoutHistory,
            // @ts-ignore
            action.payload,
          ];
        }
      } else {
        state.layoutHistory = [
          // @ts-ignore
          ...state.layoutHistory,
          // @ts-ignore
          action.payload,
        ];
      }
      return state;
    },
  },
});

const reducer = PageSlice.reducer;
export default reducer;
