import FlexBox from "design-system/src/Components/FlexBox/FlexBox";
import React, { useEffect } from "react";
import { IUseAuthenticationLogic } from "@src/components/feedback/AuthenticationContent/UseAuthenticationLogic";
import { useTranslation } from "@src/hooks/useTranslation";
import { AlternateSmallButton } from "@src/components/feedback/AuthenticationContent/Form/AlternateSmallButton";
import SubmitButton from "@src/components/feedback/AuthenticationContent/Form/SubmitButton";
import { useConfiguration } from "@src/hooks/query/useConfiguration";
import Typography from "design-system/src/Foundation/Typography/Typography";
import Icon from "design-system/src/Foundation/Icon/Icon";
import { LoyaltyOptInStepWrapper } from "@src/components/feedback/AuthenticationContent/Steps/LoyaltyOptInStep.styled";
import { IconName } from "design-system/src/lib/svgIcon/components";
import { IDefaultTheme } from "design-system/src/Themes/defaultTheme";
import LogoMySushiShop from "@src/components/branding/LogoMySushiShop/LogoMySushiShop";
import { useRollingStartNavigation } from "@src/lib/rolling-start-navigation";
import { useIsConnected } from "@src/store/reducers/CustomerSlice/hooks/useIsConnected";
import { EAuthentificationStep } from "../AuthenticationContent";

interface ILoyaltyOptInStepProps {
  authentificationLogic: IUseAuthenticationLogic;
}

const LoyaltyOptInStep = (props: ILoyaltyOptInStepProps) => {
  const { t } = useTranslation(["Account", "UI"]);
  const { getConfiguration } = useConfiguration();

  const { authentificationLogic } = props;
  const { isLoading, isValid, onSubmit, closeSuccessModal, setStep } =
    authentificationLogic;
  const _OPTIN_MY_SUSHI_SHOP_MENTIONS_TEXT_ = getConfiguration<string>(
      "_OPTIN_MY_SUSHI_SHOP_MENTIONS_TEXT_",
      ""
    ),
    _OPTIN_MY_SUSHI_SHOP_TITLE_TEXT_ = getConfiguration<string>(
      "_OPTIN_MY_SUSHI_SHOP_TITLE_TEXT_",
      ""
    ),
    _OPTIN_MY_SUSHI_SHOP_LIST_TEXT_ = getConfiguration<string>(
      "_OPTIN_MY_SUSHI_SHOP_LIST_TEXT_",
      ""
    )
      .split("\n")
      .slice(0, 3),
    _OPTIN_MY_SUSHI_SHOP_LANDING_URL_ = getConfiguration<string>(
      "_OPTIN_MY_SUSHI_SHOP_LANDING_URL_",
      ""
    );
  const listIcon: IconName[] = ["gift_bold", "wallet_bold", "confettis_bold"];
  const listColor: (keyof IDefaultTheme["color"])[] = [
    "blueExplorer",
    "orangeGourmet",
    "redEpicurian",
  ];
  const { navigate } = useRollingStartNavigation();
  const isLogged = useIsConnected();

  useEffect(() => {
    if (!isLogged) {
      setStep(EAuthentificationStep.DEFAULT);
    }
  }, [isLogged]);

  return (
    <LoyaltyOptInStepWrapper direction="column" gap={12} flex={1}>
      <FlexBox flex={5} direction={"column"} gap={12}>
        <LogoMySushiShop />
        <Typography
          variant={"h4"}
          className="loyalty-opt-in-title"
          color={"champagne7"}
          align={"center"}
        >
          {_OPTIN_MY_SUSHI_SHOP_TITLE_TEXT_}
        </Typography>
        <FlexBox
          as={"ul"}
          direction={"column"}
          className={"loyalty-opt-in-list"}
        >
          {_OPTIN_MY_SUSHI_SHOP_LIST_TEXT_.map((label, index) => {
            return (
              <Typography
                key={index}
                as={"li"}
                variant={"h5"}
                color={listColor[index]}
                className={"loyalty-opt-in-list__item"}
              >
                <Icon type={"font"} icon={listIcon[index]} />
                <span>{label}</span>
              </Typography>
            );
          })}
        </FlexBox>
      </FlexBox>
      <FlexBox flex={2} direction={"column"} gap={12}>
        <SubmitButton
          type="submit"
          disabled={!isValid}
          loading={isLoading}
          onClick={onSubmit}
          style={{ margin: "0", minHeight: 48 }}
        >
          {t("J’en profite", "Account")}
        </SubmitButton>
        <AlternateSmallButton
          style={{ margin: "0 auto 0" }}
          onClick={() => {
            _OPTIN_MY_SUSHI_SHOP_LANDING_URL_ &&
              navigate(_OPTIN_MY_SUSHI_SHOP_LANDING_URL_);
            closeSuccessModal();
          }}
        >
          {t("En savoir plus", "UI")}
        </AlternateSmallButton>
        <Typography
          variant={"ui3"}
          color={"greyText"}
          align={"center"}
          dangerouslySetInnerHTML={{
            __html: _OPTIN_MY_SUSHI_SHOP_MENTIONS_TEXT_,
          }}
        />
      </FlexBox>
    </LoyaltyOptInStepWrapper>
  );
};

export default LoyaltyOptInStep;
