import Backbone from "@src/backbone/prototypes/Backbone";
import store from "@src/store";

function onLoadXHR(obj: any, xhr: any) {
  /* appHttpHeaders.each(function (headerName) {
    var appHeaderNextState = xhr.getResponseHeader(headerName);
    if (app && appHeaderNextState) {
      appHttpHeadersStates[headerName] = appHeaderNextState;
      app.trigger(headerName, appHeaderNextState);
    }
  }); */
}

export function alwaysOnXhr(this: any, data: any, textStatus: any, jqXHR: any) {
  var obj = this;
  // success -> data, textStatus, jqXHR
  // fail -> jqXHR, textStatus, errorThrown
  switch (textStatus) {
    case "success":
      onLoadXHR(obj, jqXHR);
      break;
    case "error":
      // data = jqXHR en cas d'erreur
      onLoadXHR(obj, data);
      break;
    // case 'parseerror':
    default:
      break;
  }
}

export function ExecuteEachFunction(func: any, funcName: any, self: any) {
  switch (typeof func) {
    case "string":
      ExecuteEachFunction(self[func], funcName, self);
      break;
    case "function":
      func.call(self);
      break;
    case "object":
      break;
    default:
    case "undefined":
  }
}

export function urlWithSyncVersion(route: string) {
  const {
    app: { syncVersion },
  } = store.getState();
  route += route.indexOf("?") === -1 ? "?" : "&";
  if (syncVersion) {
    route += "sync=" + syncVersion;
  }
  return route;
}

abstract class ObjectPrototype<
  T extends Backbone.ObjectHash = any,
  S = Backbone.ModelSetOptions,
  E = any
> extends Backbone.Model<T, S, E> {
  country_based: boolean = true; // défini un objet qui va varier d’une instance pays à une autre
  translated: boolean = true; // défini un objet qui va varier d'une langue à une autre
  remoteCache: string = "no-cache"; // défini un niveau de cache distant
  privateApi: boolean = false; // défini si une API est privée ou publique (utilisée pour vérifier l'authentification)
  beforeInitialize = {};
  afterInitialize = {};
  backbonePattern: string = "model";

  executeEachFunction(func: any, funcName: any, self: any) {
    ExecuteEachFunction(func, funcName, self);
  }
  getLangIsoCode() {
    return store.getState().app.isoLang || "";
  }

  initApp() {
    //   TODO: initapp
    // if (!app) app = this;
  }
  initAppHttpHeadersChange() {
    var self = this;
    if (self.privateApi) {
      self.on("request", function (o, xhr, options) {
        try {
          xhr.always(alwaysOnXhr.bind(self));
        } catch (err) {}
      });
    }
  }
  pipeAppHttpHeaders(xhr: any) {
    // var self = this;
    // xhr.always(alwaysOnXhr.bind(self));
    return xhr;
  }
}

export default ObjectPrototype;
