import styled from "styled-components";

export const _ProductComposeModalRoot = styled.div<{}>(() => {
  return {
    background: "#FFFFFF",
    borderRadius: "16px 16px 0px 0px",
    position: "relative",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  };
});
export const _ProductComposeModalFooter = styled.div<{}>(() => {
  return {
    background: "#FFFFFF",
    borderRadius: "16px 16px 0px 0px",
    position: "relative",
  };
});
