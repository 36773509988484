const TC_PAGE_HOME = "homepage";
const TC_PAGE_CATEGORY_MAIN = "category_main";
const TC_PAGE_PRODUCT = "product";
const TC_PAGE_CHECKOUT = "funnel_step3";
const TC_PAGE_CHECKOUT_CONFIRMATION = "funnel_confirmation";
const TC_PAGE_AUTHENTIFICATION = "myaccount_form";
const TC_PAGE_ACCOUNT = "myaccount_other";
const TC_PAGE_404 = "other_errors";
const useTagCo = () => {
  function getEnvTemplate(pagename: string = "") {
    const env_template_cp: Record<string, any> = {
      home: TC_PAGE_HOME,
      category: TC_PAGE_CATEGORY_MAIN,
      allcategories: TC_PAGE_CATEGORY_MAIN,
      product: TC_PAGE_PRODUCT,
      cart: TC_PAGE_CHECKOUT,
      //cart_set_infos:"funnel_step2" , // mobile
      //cart_delivery_informations:"funnel_step2" ,
      checkoutopc: TC_PAGE_CHECKOUT,
      //checkout_payment:'funnel_step3' ,
      checkoutconfirmation: TC_PAGE_CHECKOUT_CONFIRMATION,
      authentification: TC_PAGE_AUTHENTIFICATION,
      account: TC_PAGE_ACCOUNT,
      //"404":"other_errors" ,
      notfound: TC_PAGE_404,
    };

    const env_template =
      env_template_cp[pagename.toLowerCase()] || "other_generic";
    return env_template;
  }
  return {
    getEnvTemplate,
  };
};

export default useTagCo;
