import _ from "underscore";
import ObjectModel from "../prototypes/ObjectModel";
import AdvantageModel, { AdvantageFilters } from "./AdvantageModel";
import AdvantageCollection from "./AdvantageCollection";

//
// AdvantageGroupModel
//

class AdvantageGroupModel extends ObjectModel {
  advantagesCollection: AdvantageCollection = new AdvantageCollection([]);

  initialize() {
    const model = this,
      filters: any[] = [];
    let advantagesCollection;

    //
    // setup Advantage Collection
    //
    advantagesCollection = new AdvantageCollection(model.get("advantages"), {
      group: model,
    });
    model.advantagesCollection = advantagesCollection;
    //
    // setup filters
    //
    advantagesCollection.each(function (advantage) {
      AdvantageFilters.forEach((filterId) => {
        if (advantage.get(filterId) && !filters.includes(filterId)) {
          filters.push(filterId);
        }
      });
    });
    model.set("filters", filters);
  }
  getFilters() {
    const filters: any[] = [];
    //
    // setup filters
    //
    this.getAdvantages().each(function (advantage) {
      AdvantageFilters.forEach((filterId) => {
        if (advantage.get(filterId) && !filters.includes(filterId)) {
          filters.push(filterId);
        }
      });
    });
    return filters;
  }
  getFiltersClassList() {
    return _(this.getFilters())
      .map(function (f) {
        return "filter-" + f;
      })
      .join(" ");
  }
  getAdvantages() {
    const model = this;

    //
    // setup Advantage Collection
    //
    const advantagesCollection = new AdvantageCollection(
      model.get("advantages"),
      {
        group: model,
      }
    );
    model.advantagesCollection = advantagesCollection;
    return advantagesCollection;
  }
  getUsableAdvantages(onCart: boolean = false) {
    return this.getAdvantages().getUsableAdvantages(onCart);
  }
}

export default AdvantageGroupModel;
