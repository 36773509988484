//
import ObjectCollection from "../prototypes/ObjectCollection";
import AdvantageGroupModel from "./AdvantageGroupModel";
// AdvantageGroupCollection
//

class AdvantageGroupCollection extends ObjectCollection<AdvantageGroupModel> {
  name = "AdvantageGroupCollection";
}

AdvantageGroupCollection.prototype.name = "AdvantageGroupCollection";
AdvantageGroupCollection.prototype.model = AdvantageGroupModel;

export default AdvantageGroupCollection;
