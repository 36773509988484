//
// Collection Sidedish

import ObjectCollection from "../prototypes/ObjectCollection";
import SidedishModel, { SidedishAttributes } from "./SidedishModel";
import _ from "underscore";
import AppModel from "./AppModel";
import { ICartProductSidedish } from "./CartModel";
// import { ISideDish } from "@src/interface/Product";

var createSelectionItem = SidedishModel.createSelectionItem;

type Iterate = _.CollectionIterator<SidedishModel, void, SidedishModel[]>;

class SidedishCollection extends ObjectCollection<SidedishModel> {
  name = "SidedishCollection";
  idAttribute = "id_cross_selling";
  model = SidedishModel;
  static prepareSelection: (
    sidedishes: any,
    app: any,
    accompagnements: any,
    presetSelection: any
  ) => SidedishCollection;
  presetIndex: number | undefined;

  initialize(
    models?: Record<string, any>[] | SidedishModel[],
    options?: any
  ): void {
    // ObjectCollection constructor heritage
    super.initialize.apply(this, [models, options]);
  }
  findById(id: string) {
    return this.findWhere({ id_cross_selling: id });
  }
  filtredChild(id_parent: string) {
    return _(this.filter((c) => c.get("id_parent") === id_parent));
  }
  atRoot(index: number) {
    return this.atChild(index, "0");
  }
  atChild(index: number, id_parent: string) {
    return this.filtredChild(id_parent).toArray()[index];
  }
  sizeRoot() {
    return this.filtredChild("0").size();
  }
  eachChild(id_parent: string, iteratee: Iterate) {
    return this.filtredChild(id_parent).each(iteratee);
  }
  eachRoot(iteratee: Iterate) {
    return this.eachChild("0", iteratee);
  }
  getSerializedSelection() {
    return JSON.stringify(this.getSimplifiedSelection());
  }
  getSimplifiedSelection(): ICartProductSidedish {
    const _selectionData: ICartProductSidedish = {};
    this.eachRoot((sidedish, index) => {
      let children = sidedish.getChildren();
      if (children.size() > 0) {
        children.each(function (sidedishChild) {
          _selectionData[sidedishChild.id] =
            sidedishChild.getSimplifiedSelection();
        });
      } else {
        _selectionData[sidedish.id] = sidedish.getSimplifiedSelection();
      }
    });

    return _selectionData;
  }
}

SidedishCollection.prototype.name = "SidedishCollection";
SidedishCollection.prototype.model = SidedishModel;

// cette fonction prépare une selection pour l'afficher sur la fiche produit.
SidedishCollection.prepareSelection = function (
  sidedishes: SidedishAttributes[],
  app: AppModel,
  accompagnements,
  presetSelection: any[]
) {
  const cart = app.getCart();
  let presetIndex: number | undefined = undefined;
  const filtered = sidedishes.filter((s, si: number) => {
    let isVisible =
        (!!cart.isNowLunch() && s.midday) || (!cart.isNowLunch() && s.evening),
      cat = app.getCategory(s.id_category),
      isNotEmpty = cat ? cat.getProducts(true).size() > 0 : false,
      isGroupedList =
        !!s.template && ["grouped_list", "custom_box_42"].includes(s.template),
      id_product_default = s.id_product_default,
      id_product_immutable = s.id_product_immutable,
      initialSelection: any[] = [],
      isChildrenVisibles = false,
      filtered,
      pos_custom_box: number[] = [...(s.pos_custom_box || [])]; // position of selection in custom box

    // les templates groupé (BYOP) et box à composer (custom_box)
    // héritent de principes fonctionnels proches.
    if (isGroupedList) {
      // on cherche dans les sidedish / cross_selling enfant si tous ne sont pas indisponibles
      isChildrenVisibles =
        sidedishes.filter(function (sc) {
          var isChild = sc.id_parent === s.id_cross_selling,
            isVisible = isChild;
          if (isChild) {
            var childCat = app.getCategory(sc.id_category);
            isVisible = childCat
              ? childCat.getProducts(true).size() > 0
              : false;
          }
          return isVisible;
        }).length > 0;
    }
    filtered = isVisible && (isNotEmpty || isChildrenVisibles);

    if (filtered) {
      function shiftPosition() {
        return pos_custom_box.shift() || "*";
      }

      if (accompagnements && accompagnements.models) {
        // édition d'une selection existante
        _(
          accompagnements.filter({
            id_cross_selling: parseInt(s.id_cross_selling || ""),
          })
        ).each((accompagnement) => {
          const id_product = accompagnement.get("id_product"),
            qty = accompagnement.getQuantity(),
            qtyImmutable =
              _(s.id_product_immutable || []).countBy((id) => {
                return id === id_product ? 1 : 0;
              }).true || 0;
          initialSelection.push(
            createSelectionItem(id_product, qty, 0, qtyImmutable, shiftPosition)
          );
        });
      } else {
        // nouvelle selection
        _(_.countBy(id_product_immutable || "")).each(function (
          qty,
          id_product
        ) {
          // ajoute les produits immuables à la selection
          initialSelection.push(
            createSelectionItem(id_product, qty, 0, qty, shiftPosition)
          );
        });
        function setDefault(qty: number, id_product: any) {
          // ajoute les produits par défaut à la selection
          var defaultSelection = _(initialSelection).findWhere({
            id: id_product,
          });
          if (!defaultSelection) {
            defaultSelection = createSelectionItem(
              id_product,
              qty,
              qty,
              0,
              shiftPosition
            );
            initialSelection.push(defaultSelection);
          } else {
            for (let i = 0; i < qty; i++)
              defaultSelection.positions.push(shiftPosition());
            defaultSelection.quantity += qty;
            defaultSelection.default = qty;
          }
        }
        let preset;
        if (presetSelection && (preset = presetSelection[si])) {
          // préselection (product boost)
          if (preset.length > 0) {
            // si il y a une quantité selectionnée
            presetIndex = si; // en cas de boost produit
          } else if (!presetIndex) {
            presetIndex = 0; // on est à minima sur la première étape.
          }
          _(_.countBy(preset, "id")).each(setDefault);
        } else {
          // selection par défaut
          _(_.countBy(id_product_default || "")).each(setDefault);
        }
      }
      // attribute;
      s.selection = initialSelection;
    }
    return filtered;
  });

  const collection = new SidedishCollection(filtered);
  collection.presetIndex = presetIndex;
  return collection;
};

export default SidedishCollection;
