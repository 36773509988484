import styled, { css } from "styled-components";
import { responsiveMap } from "design-system/src/Components/Grid/_utils/responsiveObserve";
import { Col, Row } from "../../Components/Grid";
import clsx from "clsx";

export const _ProductDetailsLoader = styled("div")<{}>(() => {
  return {
    "&.box": {
      display: "unset",
      [`@media ${responsiveMap.lg}`]: {
        display: "none",
      },
    },
  };
});
export const _ProductDetailsRoot = styled(Row)<{}>(
  () => {
    return {
      position: "relative",
      [`@media ${responsiveMap.lg}`]: {
        overflow: "hidden",
        height: "100%",
      },
      ".main-content.box": {
        display: "none",
        [`@media ${responsiveMap.lg}`]: {
          display: "unset",
        },
      },
      ".product-loader.box": {
        display: "unset",
        [`@media ${responsiveMap.lg}`]: {
          display: "none",
        },
      },
    };
  },
  css`
    #sidedish-container-scroll::-webkit-scrollbar-track {
      background-color: #fff;
    }

    #sidedish-container-scroll::-webkit-scrollbar {
      background-color: #fff;
      width: 11px;
    }

    #sidedish-container-scroll::-webkit-scrollbar-thumb {
      background-color: #bfbfbf;
      border-radius: 8px;
      border: 2px solid #fff;
    }
    #sidedish-container-scroll {
      &:hover {
        &::-webkit-scrollbar-thumb {
          background-color: #7f7f7f;
        }
      }
    }
  `
);
export const _ProductDetailsCol = styled(Col).attrs(
  ({ className, ...props }) => {
    return { ...props, className: clsx("ds-col", className) };
  }
)<{
  $left?: boolean;
  $showScrollBar?: boolean;
}>(({ $left, $showScrollBar }) => {
  const lgWidth = $left ? 53.4 : 46.6;
  return {
    maxWidth: "100%",
    flex: "0 0 100%",

    [`@media ${responsiveMap.lg}`]: {
      maxWidth: `${lgWidth}% !important`,
      flex: `0 0 ${lgWidth}%`,
      height: "100%",
      overflowY: "scroll",
      overflowX: "hidden",
      "-ms-overflow-style": $showScrollBar ? undefined : "none",
      "scrollbar-width": $showScrollBar ? undefined : "none",
      "&::-webkit-scrollbar": {
        display: $showScrollBar ? undefined : "none",
      },
    },
  };
});

export const _ProductDetailsMainTagContainer = styled.div<{}>(() => {
  return {
    position: "absolute",
    left: 0,
    top: 0,
    paddingLeft: 28,
    paddingTop: 30,
    [`@media ${responsiveMap.lg}`]: {
      paddingLeft: 50,
      paddingTop: 45,
    },
  };
});
export const _ProductDetailsInfoContainer = styled.div<{}>(() => {
  return {
    padding: "29px 16px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    paddingBottom: 0,
    [`@media ${responsiveMap.lg}`]: {
      padding: "52px 50px 0px 54px",
    },
  };
});
export const _ProductDetailsClose = styled.div<{}>(() => {
  return {
    position: "absolute",
    right: 26,
    top: 26,
    cursor: "pointer",
    zIndex: 3,
    [`@media ${responsiveMap.lg}`]: {
      right: 25,
      top: 26,
    },
  };
});
export const _ProductDetailsAddToCardContainer = styled.div<{}>(({ theme }) => {
  return {
    marginTop: "auto",
    paddingTop: 16,
    paddingBottom: 16,
    position: "sticky",
    bottom: 0,
    background: theme.color.white,
    zIndex: 9,
    [`@media ${responsiveMap.lg}`]: {
      paddingTop: 32,
      paddingBottom: 36,
    },
  };
});

export const _ProductDetailsSidedishContainer = styled.div<{}>(() => {
  return {
    display: "flex",
    flexDirection: "column",
  };
});
