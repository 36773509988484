import { CSSProperties } from "react";

export interface IBound {
  right: number;
  left: number;
  top: number;
  bottom: number;
  width: number;
  height: number;
  style: CSSProperties;
}

export type LocationId = "A" | "B" | "C" | "D" | "E" | "F" | "G" | "H" | "I";

export interface IBoxLocation {
  id: LocationId;
  type: "rolls" | "sushis" | "rolls-div" | "sushis-div";
  o: "v" | "h";
  x: number;
  y: number;
  z: number;
  x_alt?: number;
  y_alt?: number;
  bounds?: IBound[];
}

let CUSTOM_BOX_42_LOCATIONS: IBoxLocation[] = [
  { id: "A", type: "rolls", o: "h", x: 248, y: 200, z: 6 },
  { id: "B", type: "rolls", o: "h", x: 552, y: 200, z: 7 },
  { id: "C", type: "rolls", o: "v", x: 400, y: 400, z: 1 },
  { id: "D", type: "rolls", o: "h", x: 248, y: 600, z: 8 },
  { id: "E", type: "rolls", o: "h", x: 552, y: 600, z: 9 },
  { id: "F", type: "rolls-div", o: "v", x: 178, x_alt: 622, y: 400, z: 2 },
  { id: "G", type: "sushis-div", o: "v", x: 400, y: 160, y_alt: 640, z: 5 },
  { id: "H", type: "sushis", o: "v", x: 268, y: 400, z: 4 },
  { id: "I", type: "sushis", o: "v", x: 532, y: 400, z: 3 },

  //   { id: "H", type: "sushis", o: "v", x: 308, y: 416, z: 4 },
  //   { id: "I", type: "sushis", o: "v", x: 532, y: 416, z: 3 },
];

export const CUSTOM_BOX_LOCATION_BASE_W = 154;
export const CUSTOM_BOX_LOCATION_BASE_H = 260;
export const SUSHI_W = 112;
export const SUSHI_H = 236;

export const getBoxLocations = (): IBoxLocation[] => {
  return CUSTOM_BOX_42_LOCATIONS.map((location) => {
    const isSushi = location.type === "sushis";
    const baseH = isSushi ? SUSHI_H : CUSTOM_BOX_LOCATION_BASE_H;
    const baseW = isSushi ? SUSHI_W : CUSTOM_BOX_LOCATION_BASE_W;
    const type = location.type,
      baseType = type.replace(/\-div$/, ""),
      pScale = 100 / 800,
      orientation = location.o,
      horizontal = orientation === "h",
      a = horizontal ? 90 : 0,
      x = location.x || 0,
      y = location.y || 0,
      x_alt = location.x_alt || x,
      y_alt = location.y_alt || y,
      isDivided = /\-div$/.test(type);
    let W: number,
      H: number,
      bounds: IBound[] = [];

    W = horizontal ? baseH : baseW;
    H = horizontal ? baseW : baseH;
    if (isDivided) {
      W /= 2;
    }
    const pushBounds = (useAlt: boolean) => {
      var boundX = useAlt ? x_alt : x,
        boundY = useAlt ? y_alt : y,
        left = boundX - W / 2,
        top = boundY - H / 2,
        style = {
          top: `${top * pScale}%`,
          left: `${left * pScale}%`,
          width: `${W * pScale}%`,
          height: `${H * pScale}%`,
        };

      /* 
        top = 278 = y - 276 / 2 
        = 216 = y - 184 / 2
            top: 34.75%; 
            left: 27%; 
            width: 14%;
            height: 29.5%;

            14% => 125.52px
            23% => 206.21px => 184

            34.5% =>  276
            29.5%
         */

      bounds.push({
        right: boundX + W / 2,
        left: left,
        top: top,
        bottom: boundY + H / 2,
        width: W,
        height: H,
        style: style,
      });
    };
    pushBounds(false);
    if (isDivided) pushBounds(true);
    // location.bounds = bounds;
    // console.log("CUSTOM_BOX_42_LOCATIONS", location, bounds);
    return {
      ...location,
      bounds,
    };
  });
};
