import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StoreLocatorItem } from "@src/page-templates/StoreLocatorPage";

interface State {
  stores: StoreLocatorItem[];
  searchResult: StoreLocatorItem[];
  benefitsFilter: string[];
}

const initialState: State = {
  stores: [],
  searchResult: [],
  benefitsFilter: [],
};

export const storeLocatorSlice = createSlice({
  name: "storeLocator",
  initialState,
  reducers: {
    initStoreLocator(state, action: PayloadAction<StoreLocatorItem[]>) {
      state.stores = action.payload;
      state.searchResult = action.payload;
      return state;
    },
    setBefefitFilter(state, action: PayloadAction<string[]>) {
      state.benefitsFilter = [...action.payload];
      return state;
    },
  },
});

const reducer = storeLocatorSlice.reducer;

export const { initStoreLocator, setBefefitFilter } = storeLocatorSlice.actions;

export default reducer;
