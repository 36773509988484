import { useEffect } from "react";

const useAutoResizeTextArea = (
  textAreaRef: HTMLTextAreaElement | null,
  value: string
) => {
  useEffect(() => {
    if (textAreaRef) {
      // Reset the height to calculate the new scrollHeight
      textAreaRef.style.height = "auto";
      textAreaRef.style.height = `${textAreaRef.scrollHeight}px`;
    }
  }, [textAreaRef, value]);
};

export default useAutoResizeTextArea;
