import Backbone, { _StringKey } from "backbone";
import moment, { Moment, MomentInput } from "moment";
import CartProductCollection, {
  CartProductType,
} from "./CartProductCollection";
import { Discount, ICartData } from "@src/interface/Cart";
import LocalStorageModel from "../prototypes/LocalStorageModel";
import { app } from "./AppModel";
import ApiRequest from "../prototypes/ApiRequest";
import _, { uniq } from "underscore";
import StoreModel from "./StoreModel";
import OfferedProductCollection from "./OfferedProductCollection";
import PlaceModel from "./PlaceModel";
import CategoryModel from "./CategoryModel";
import CartProductModel from "./CartProductModel";
import formatPrice from "../prototypes/formatPrice";
import ProductModel, { ProductPaymentRestriction } from "./ProductModel";
import momentFormats from "../prototypes/momentFormats";
import AddressModel from "./AddressModel";
import OrderModel from "./OrderModel";
import { IconName } from "design-system/src/lib/svgIcon/components";
import isUndefined from "lodash/isUndefined";
import isNumber from "lodash/isNumber";
import isNull from "lodash/isNull";
import isNaN from "lodash/isNaN";
import { AxiosError } from "axios";
import OfferedProductModel from "./OfferedProductModel";
import reduxStore from "@src/store";
import { cartSlice } from "@src/store/reducers/CartSlice";
import { setOpenAuthModel } from "@src/store/reducers/AuthSlice";
import { IConfirmOptions } from "../interface/Alert";
import PaymentModeModel from "@src/backbone/model/PaymentModeModel";
import RollingStartError, {
  RollingStartErrorCode,
} from "@src/utils/RollingStartError";
import { cleanMySQLDateToISO } from "@src/utils/cleanDate";
import { startTransition } from "react";
import {
  CATEGORY_SLUGS,
  ECategorySlug,
} from "@src/backbone/model/CategoryCollection";
import {
  GATSBY_CONF_CAGNOTTE_ENABLED,
  GATSBY_CONF_COMEIN_RECUP_VALUE,
  GATSBY_CONF_SMART_REPLACE_POSTAL_CODE,
} from "@src/utils/constants";
import { EAuthentificationStep } from "@src/components/feedback/AuthenticationContent/AuthenticationContent";
//
// Model Cart
//
//
// WARNING > "offored" (et non offered) est le maintien d’une coquille,
// présente dans le schéma du modèle de données.
// la coquille a été corrigée dans tous le FRONT sauf ici — où elle est maintenue volontairement.
const FAVORITE_PRODUCT_OFFERED_PREFIX = "favorite_product_offored_";
const STOCKOUT_ID_PRODUCTS_HASH = "stockout_id_products_hash",
  STOCKOUT_AVAILABLE_MSG = "STOCKOUT";
//

const ZERO_DATE = "0000-00-00",
  CART_DATE_FORMAT = "YYYY-MM-DD",
  CART_TIME_FORMAT = "HH:mm:ss",
  //
  // EXPRESS_CHECKOUT_READY = 1,
  // EXPRESS_CHECKOUT_NOT_ACTIVE = 2,
  // EXPRESS_CHECKOUT_OUT_OF_DATE = 3,
  // EXPRESS_CHECKOUT_TOO_LONG = 4,
  CART_DATETIME_FORMAT = CART_DATE_FORMAT + " " + CART_TIME_FORMAT;
//
const SECOND_IN_MS = 1000,
  MINUTE_IN_MS = SECOND_IN_MS * 60,
  HOUR_IN_MS = MINUTE_IN_MS * 60,
  UI_WARNING_TEXT = "Attention",
  UI_CONTEXT = "UI",
  CART_TRANSLATION_CONTEXT = "Cart";

let cartRefreshTimeout: any,
  paymentMethod: any,
  paymentMode: PaymentModeModel | undefined,
  offeredProductCollection: any;

export enum ECartNow {
  NOW = 1,
  TODAY = 0,
  NOT_TODAY = -1,
}

export type ToolingDoPaymentCall = {
  reCaptcha: (action: string) => Promise<string>;
  OpenCVCModal: (
    callback: (cvc?: string) => void,
    options: { cvcLength?: number; cvc?: string }
  ) => void;
};

export type CartDeliveryOptions =
  | ICartDeliveryOptionsVAE
  | ICartDeliveryOptionsVAD;

export enum EDeliveryOptionsMode {
  VAD = 0,
  VAE = 1,
}

export interface ICartDeliveryOptions {
  vae: EDeliveryOptionsMode;
  now?: ECartNow;
  order_date?: string;
  order_time?: string;
  autocomplete_literal?: string;
  id_address?: string;
  id_store?: string;
  id_postcode?: string;
  id_district?: string;
  place_id?: string;
  redirect?: string;
}

export interface ICartProductSidedish {
  [_sidedishId: string]: Array<{
    id: string;
    quantity: number;
  }>;
}

export interface IAccompagnement {
  id_product: string;
  visible_error: boolean;
}

interface IInvalidSidedish {
  id_cart_product: string;
  id_cross_selling: string | false;
}

export interface ICartDeliveryOptionsVAE extends ICartDeliveryOptions {
  vae: 1;
  id_store?: string;
}

export interface ICartDeliveryOptionsVAD extends ICartDeliveryOptions {
  vae: 0;
  ignore_geo_validation?: 0 | 1;
  id_address_delivery?: string;
  attach_to_cart?: boolean;
  customer_primary?: boolean;
  data?: string;
  source?: 0 | 1 | 2 | 3 | 4;
  alias?: string; // address alias
  type?: 0 | 1 | 2 | 3 | 4; // address type (icon)
  description?: string;
  street?: string;
  latitude?: number;
  longitude?: number;
  city?: string;
  country?: string;
  number?: string;
  place_id?: string;
  postcode?: string;
  id_postcode?: string;
  id_district?: string;
  id_place?: string;
}

interface ICartDeliveryOptionsErrorResponse {
  data?: {
    error: string;
    type: string;
    post_alert_action?: ECartDeliveryOptionsPostAlertAction;
    next_opening?: {
      now: ECartNow;
      order_date: string;
      order_time: string;
    };
    id_store?: string;
  };
}

export enum ECartDeliveryOptionsPostAlertAction {
  DO_NOTHING = 0,
  DIFFER_TO_NEXT_OPENING = 1,
  SWITCH_TO_VAD = 2,
  SWITCH_TO_VAE = 3,
}

export enum EFormatDeliveryDelay {
  NAV = "nav",
  DEFAULT = "default",
}

export let cart: CartModel | undefined = undefined;

class CartModel extends LocalStorageModel<ICartData> {
  name = "CartModel";
  route = "apiCartModel";
  idAttribute = "id_cart";
  translated = false;
  privateApi = true;
  token?: string;
  amountReduce: any;
  cartProductCollection: CartProductCollection;
  cartUnavailableProductCollection: CartProductCollection;
  cartConsumablesCollection: CartProductCollection;
  cartExtraPriceCollection: CartProductCollection;
  productCollections: CartProductCollection[] = [];
  invalidSidedishes: any[] = [];
  q?: number; // cached products quantity value
  CHECK_PRODUCT_LAST_MESSAGE?: string = "";
  datetime_moment?: Moment; // cache moment js object
  datetime_moment_id?: string; // cache moment js object

  constructor(attributes?: ICartData, options?: { newInstance: boolean }) {
    super(attributes, options);
    const cart = this;

    const onConstruct = () => {
      const cart = this;
      const InitCollection = (type: CartProductType): CartProductCollection => {
        const collection = new CartProductCollection([], {
          cart,
          type,
        });
        collection.attachCart(cart, type);
        return collection;
      };

      const cartProductCollection = InitCollection("products"),
        cartConsumablesCollection = InitCollection("consumables"),
        cartUnavailableProductCollection = InitCollection(
          "unavailable_products"
        ),
        cartExtraPriceCollection = InitCollection("extraPrice");

      cart.cartProductCollection = cartProductCollection;
      cart.cartConsumablesCollection = cartConsumablesCollection;
      cart.cartUnavailableProductCollection = cartUnavailableProductCollection;
      cart.cartExtraPriceCollection = cartExtraPriceCollection;

      cart.productCollections = [];
      cart.productCollections.push(
        cartProductCollection,
        cartConsumablesCollection,
        cartUnavailableProductCollection,
        cartExtraPriceCollection
      );

      return {
        cartProductCollection,
        cartConsumablesCollection,
        cartUnavailableProductCollection,
        cartExtraPriceCollection,
      };
    };

    const {
      cartProductCollection,
      cartConsumablesCollection,
      cartUnavailableProductCollection,
      cartExtraPriceCollection,
    } = onConstruct();

    this.cartProductCollection = cartProductCollection;
    this.cartConsumablesCollection = cartConsumablesCollection;
    this.cartUnavailableProductCollection = cartUnavailableProductCollection;
    this.cartExtraPriceCollection = cartExtraPriceCollection;

    cart.listenToOnce(cart, "sync", (d) => {
      onConstruct();
    });
  }

  defaults() {
    return {
      total_cart_quantity: 0,
      id_cart: "current",
      virtual: false,
      products: [],
      consumables: [],
      consumables_qty: {},
      unavailable_products: [],
      extraPrice: [],
      express_checkout_confirm: false, // TODO à désactiver pour utiliser le localStorage
      express_checkout_unavailable_products: [],
      payment_restrictions: {},
      store_closed: null,
    };
  }
  initialize(attributes?: ICartData, options?: { newInstance: boolean }): void {
    // ObjectModel constructor heritage
    super.initialize.apply(this, [attributes, options]);
    //
    //
    let localCart = this;
    localCart.softFetch();

    // cart.softFetch();
    //
    // annule les vérifications utilisateur
    localCart.setAndSave(
      {
        offeredProductsCheckSum: "",
        deliveryAddressDataCheckSum: "",
      },
      { silent: true }
    );
    //
    // si on a pas d'options de livraison
    if (!localCart.getDeliveryOptions()) {
      localCart.setAndSave("deliveryOptions", null);
    }
    localCart.on("productsChange", localCart.onProductsChange, localCart);

    // Fonction d'instanciation des CartProductCollection,
    // à partir de l'identifiant du tableau de produit

    // instancie les Cart Product Collection du panier

    //
    // localCart.on("datetimePassed", localCart.onDatetimePassed);
    localCart.on("change:store_closed", localCart.onChangeStoreClosed);
    //

    // virtual
    localCart.setAndSave("virtual", localCart.id === "virtual");
    if (!localCart.isVirtual()) {
      // si on est sur le panier principal
      localCart.refresh(true);
    }
  }

  setAndSave(
    attributeName: any,
    value?: any,
    options?: Backbone.ModelSetOptions
  ) {
    this.set(attributeName, value, options);
    this.setLocalStorageItem();
  }

  ApiRequest(
    type: string,
    data: any,
    urlOptions: any,
    options?: any
  ): Promise<ICartData> {
    return ApiRequest(type, data, urlOptions, options);
  }

  isNowLunch() {
    return app.isNowLunch(this.getDatetime());
  }

  setProductLikeOffered(
    id_cart_product: string,
    sidedishes?: any
  ): Promise<void> {
    let cart = this;
    const options = {
      id_cart_product: id_cart_product,
      sidedishes,
    };

    return new Promise<void>((resolve, reject) => {
      cart
        .ApiRequest("CartSetProductLikeOffered", options, { id_cart: cart.id })
        .then(function (data: any) {
          cart.setAndSave(data);
          app.trigger("setProductLikeOffered");
          resolve();
        })
        .catch(function (err) {
          app.error();
          reject();
        });
    });
  }

  onChangeStore() {
    const cart = this;
    const store = cart.getStore();
    if (store) {
      store.once("sync", function () {
        cart.trigger("storeSync", store);
      });
      store.fetch();
    }
  }

  productCanBeOffered() {
    return this.get("favorite_product_offored");
  }

  setup(callback: () => void) {
    try {
      const cart: CartModel = this;
      const { cartProductCollection, cartUnavailableProductCollection } = cart;
      cartProductCollection.on("add", cart.onAddProduct, cart);
      cartProductCollection.on("remove", cart.onRemoveProduct, cart);
      cartUnavailableProductCollection.on(
        "add",
        cart.triggerEmptyOrUnavailable,
        cart
      );
      cartUnavailableProductCollection.on(
        "remove",
        cart.triggerEmptyOrUnavailable,
        cart
      );
      cart.on("deliveryUpdate", () => {
        app.getMessages().fetchAndReset();
        app.getProducts()?.clearSidedishes();
      });
      cart.on("change", cart.onChange, cart);
      cartProductCollection.on("change", cart.onChange, cart);
      cart.on("change:id_store", cart.onChangeStore, cart);
      //
      cart.listenTo(
        cartProductCollection,
        "change add remove delete",
        cart.updateProductPaymentRestrictions
      );
      cart.on(
        "change:id_store change:vae storeSync",
        cart.updateProductPaymentRestrictions
      );
      cart.updateProductPaymentRestrictions();
      //
      function AfterSetup(store?: StoreModel) {
        if (store) {
          store.off("sync", AfterSetup);
        }

        const contextAttr = [
          "order_date",
          "order_time",
          "now",
          "vae",
          "id_store",
        ];
        contextAttr.forEach((key) => {
          cart.on("change:" + key, cart.onChangeContext);
        });

        callback();
      }

      const store = cart.getStore();
      if (store) {
        store.fetchStockoutProduct(function () {
          // on synchronise
          store.on("sync", AfterSetup);
          store.fetch();
        });
      } else {
        AfterSetup();
      }
      startTransition(() => {
        reduxStore.dispatch(
          cartSlice.actions.updateCurrentCart(this.attributes)
        );
      });
      //
      // // met à jour le panier s'il est différent du store
      // let cartState = reduxStore.getState().cart.currentCart;
      // reduxStore.subscribe(() => {
      //   let newState = reduxStore.getState().cart.currentCart;
      //   if (JSON.stringify(newState) != JSON.stringify(cartState)) {
      //     // update state from redux
      //     cartState = newState;
      //     // cart.set(cartState);
      //   }
      // });
    } catch (err) {
      console.error(err);
    }
    //
  }

  onChangeContext(a: any, b: any, c: any) {
    let cart = this;
    //if(!cart.validateDatetime()){
    //    cart.trigger('changeContext',cart);
    //}
    if (!cart.validateDeliveryOptions()) {
      cart.trigger("deliveryUpdate", cart);
    }
  }

  getExtraPrice() {
    const cart = this;
    const InitCollection = (type: CartProductType): CartProductCollection => {
      const collection = new CartProductCollection([], {
        cart,
        type,
      });
      collection.attachCart(cart, type);
      cart.cartExtraPriceCollection = collection;
      return collection;
    };

    // DO NOT CHANGE: sometime cart.cartExtraPriceCollection is undefined
    return cart.cartExtraPriceCollection || InitCollection("extraPrice");
  }
  isVirtual() {
    let cart = this;
    return cart.get("virtual");
  }
  onChange() {
    reduxStore.dispatch(cartSlice.actions.updateCurrentCart(this.attributes));

    const cart = this;
    // change delay shortcut event
    let delayHasChanged = false;
    ["max", "min"].forEach((m) => {
      ["vae", "vad"].forEach((t) => {
        const key = "delay_" + m + "_" + t;
        delayHasChanged =
          delayHasChanged || cart.hasChanged(key as keyof ICartData);
      });
    });
    if (delayHasChanged) {
      cart.trigger("delayHasChanged");
    }
    cart.getQuantity(true); // refresh quantity one single time
    cart.updateCagnotte();
    //
    // TODO : attention bug constaté, récursion des validations, incohérence client/server
    // cart.validateDatetime();
    //
    // cart.updateLocalStorageItem(); // met à jour localStorage
    cart.refresh(true); // report refresh
  }

  onAddProduct(model: any, collection: any, update: any) {
    let cart = this;
    cart.trigger("addProduct");
    if (this.cartProductCollection.size() === 1) {
      cart.trigger("start", cart);
    }
  }

  onRemoveProduct(collection: any, model: any, update: any) {
    let cart = this;
    cart.trigger("removeProduct");
    cart.triggerEmptyOrUnavailable();
  }

  triggerEmptyOrUnavailable() {
    const cart = this;
    const productsCount = cart.cartProductCollection.size(),
      productsUnavailableCount = cart.cartUnavailableProductCollection.size();
    if (productsCount === 0 && productsUnavailableCount === 0) {
      cart.trigger("empty", cart);
    } else if (productsCount === 0) {
      cart.trigger("unavailable", cart);
    }
    cart.setAndSave({
      productsCount,
      productsUnavailableCount,
    });
  }

  // onDatetimePassed() {
  //   const cart = this;
  //   const store = cart.getStore(),
  //     isVAE = cart.isVAE();
  //   if (store) {
  //     // si la date est passée, on essaie de la mettre à jour avec la date disponible la plus à jour
  //     const availableDatetime = store.findAvailableDatetime(
  //       isVAE,
  //       cart.getDatetime()
  //     );
  //     if (
  //       availableDatetime &&
  //       availableDatetime.moment &&
  //       !availableDatetime.closed
  //     )
  //       cart.setDeliveryDatetime(
  //         availableDatetime.moment.format(CART_DATE_FORMAT),
  //         availableDatetime.moment.format(CART_TIME_FORMAT),
  //         availableDatetime.now
  //       );
  //   }
  // }

  onChangeStoreClosed(cart: CartModel, isClosed: boolean) {
    cart.trigger("deliveryUpdate", cart);
    if (isClosed && cart.getStore()) {
      app.confirm(
        {
          title: app.t("Attention", "UI"),
          subtitle: app.t(
            "Le créneau prévu pour votre commande est indisponible. Veuillez selectionner un autre créneau.",
            "Cart"
          ),
          validation: app.t("Choisir un autre créneau", "Cart"),
        },
        function (confirm: any) {
          if (confirm) {
            // TODO: check displayModal
            // app.displayModal(new DateTimeSelectorView());
          } else {
            // TODO: redirect to home
            app.go("home");
          }
        }
      );
    }
  }

  // TODO: fix isEmpty, conflict with parent class
  // @ts-ignore
  isEmpty(withUnavailable: boolean = false, withConsumable: boolean = false) {
    const cart = this,
      { cartUnavailableProductCollection, cartProductCollection } = cart,
      rejectConsumable = (p: CartProductModel) => {
        return (
          p.get("id_category_default") ===
          CATEGORY_SLUGS[ECategorySlug.CONSUMABLE]
        );
      };
    let cartProductSize: number = withConsumable
      ? cartProductCollection.size()
      : cartProductCollection.reject(rejectConsumable).length || 0;
    if (withUnavailable) {
      cartProductSize += withConsumable
        ? cartUnavailableProductCollection.size()
        : cartUnavailableProductCollection.reject(rejectConsumable).length || 0;
    }
    return cartProductSize <= 0;
  } // vente à emporter

  isVAE() {
    return this.get("vae") === true;
  }
  isVAD() {
    return !this.isVAE();
  }

  // maintenant [1], aujourd'hui [0], un autre jour [-1]
  isNow() {
    return this.get("now") === 1;
  }

  getMinimumShipping() {
    return Math.abs(parseFloat(this.get("minimum_shipping") || "0")) || 0;
  }

  blockMinimumShipping() {
    return this.getMinimumShipping() > this.getPriceTTCWithoutDiscount();
  }

  alertMinimumShipping() {
    const cart = this;
    app.alert({
      title: app.t("Attention", "UI"),
      subtitle: app.t("Le montant minimum en livraison est de %s", "Cart", [
        formatPrice(cart.getMinimumShipping()),
      ]),
      validation: app.t("Ok", "UI"),
    });
  }

  // test si le panier a bien toutes les options de livraison nécessaire
  validateDeliveryOptions(): boolean {
    let cart = this;
    if (isUndefined(cart.getDatetime())) {
      // est-ce qu'il y a une date de livraison
      return false;
    }
    const now = new Date();
    if (cart.getDatetimeMoment().diff(now) < 0) {
      // est-ce que la date n'est pas passée
      return false;
    }
    if (!cart.hasStore()) {
      // est-ce qu'une boutique est attachée
      return false;
    }
    if (cart.get("store_closed") === true) {
      // est-ce qu'une boutique est attachée et explicitement fermée
      return false;
    }
    if (cart.isVAE()) {
      return cart.getDatetimeMoment().diff(now) > 0;
    } else {
      return (
        !isNull(cart.getDeliveryOptions()) ||
        !isUndefined(cart.getAddressDelivery())
      );
    }
  }

  alertRequiredSidedishes() {
    const cart = this;
    app.trigger("alertRequiredSidedishes");
    const cartProduct = cart.getProduct(this.invalidSidedishes[0]);
    app.confirm({
      validation: app.t("Choisir", UI_CONTEXT),
      title: app.t(UI_WARNING_TEXT, UI_CONTEXT),
      subtitle: app.t(
        "Veuillez selectionnez vos accompagnements obligatoires sur le produit %s",
        CART_TRANSLATION_CONTEXT,
        [cartProduct?.getName() || ""]
      ),
    });
  }

  // retourne la date sur forme d'objet Date JS
  getDatetime() {
    const cart = this,
      od = cart.get("order_date"),
      m = cart.getDatetimeMoment();
    if (od === ZERO_DATE || !m.isValid()) return new Date();
    return m.toDate();
  } // retourne la date wrappé dans la librairie de formatage moment.js

  getDatetimeMoment() {
    const cart = this,
      od = cart.get("order_date"),
      ot = cart.get("order_time"),
      useNow = !od || od === ZERO_DATE || cart.isNow(),
      id =
        "moment_datetime_" +
        (useNow ? `now_${Math.floor(new Date().getTime() / 60000)}` : od + ot);
    let { datetime_moment, datetime_moment_id } = cart;
    // cache moment datetime
    if (id !== datetime_moment_id || !datetime_moment) {
      datetime_moment = useNow
        ? moment(new Date()).add(5, "m")
        : moment(od + " " + ot, CART_DATETIME_FORMAT);
      cart.datetime_moment = datetime_moment;
      cart.datetime_moment_id = id;
    }
    return datetime_moment;
  } //

  formatDatetime(format: string) {
    let cart = this;
    return cart.getDatetimeMoment().format(format);
  }

  hasStore() {
    let cart = this;
    return !isUndefined(cart.getStore());
  }

  // retour les produits offerts, sauces, baguettes, gimgembre, wasabi
  getOfferedProducts(reset?: boolean): OfferedProductCollection {
    let cart = this;
    if (reset) {
      offeredProductCollection = undefined;
    }
    if (!offeredProductCollection) {
      offeredProductCollection = new OfferedProductCollection([], {
        cart,
      });
    }

    if (offeredProductCollection.size() === 0) {
      const other_consumables = app.getCategory(
        CATEGORY_SLUGS[ECategorySlug.CONSUMABLE]
      );
      if (other_consumables) {
        other_consumables.getProductsCart(cart).each(function (product) {
          const id_product = product.id;
          offeredProductCollection.add({
            id_product: id_product,
          });
        });
        offeredProductCollection.attachCart(cart);
      } else {
        //console.warn("other_consumables not found");
      }
    }

    return offeredProductCollection;
  }

  preorder(
    id_product: string,
    display_product?: boolean,
    callback?: (succes: boolean) => void
  ) {
    const product = app.getProduct(id_product);

    if (product) {
      const preorder_type = product.get("preorder_type") || 0;
      enum DeliveryOptionRestriction {
        ALL = 0,
        STORE = 1,
        ADDRESS = 2,
      }
      const preorder_address_type = ([
        DeliveryOptionRestriction.ALL,
        DeliveryOptionRestriction.ALL,
        DeliveryOptionRestriction.STORE,
        DeliveryOptionRestriction.ADDRESS,
      ][preorder_type] ||
        DeliveryOptionRestriction.ALL) as DeliveryOptionRestriction;
      const preorder_add_product = !product.isMenu() && !display_product;
      const cart = app.getCart();
      const preorderCart = cart; //new CartConstructor(cartOptions)
      const store = preorderCart.getStore();
      const has_store = !!store;
      const isVAE = preorderCart.isVAE();
      let id_store = has_store ? (store?.id as string) : undefined;
      let preset: CartDeliveryOptions;
      const has_wrong_delivery_type =
        preorder_address_type !== DeliveryOptionRestriction.ALL &&
        preorder_address_type ===
          (isVAE
            ? DeliveryOptionRestriction.ADDRESS
            : DeliveryOptionRestriction.STORE);
      let delivery_mode_restrictions: EDeliveryOptionsMode[];
      if (preorder_address_type === DeliveryOptionRestriction.ADDRESS) {
        delivery_mode_restrictions = [EDeliveryOptionsMode.VAD];
      } else if (preorder_address_type === DeliveryOptionRestriction.STORE) {
        delivery_mode_restrictions = [EDeliveryOptionsMode.VAE];
      }
      if (has_store) {
        if (has_wrong_delivery_type) {
          if (preorder_address_type === DeliveryOptionRestriction.ADDRESS) {
            preset = {
              vae: EDeliveryOptionsMode.VAD,
              autocomplete_literal: "",
            } as ICartDeliveryOptionsVAD;
          } else if (
            preorder_address_type === DeliveryOptionRestriction.STORE
          ) {
            preset = {
              vae: EDeliveryOptionsMode.VAE,
              autocomplete_literal: store?.getName() || "",
              id_store,
            };
          }
        } else {
          const currentPreset = preorderCart.getDeliveryOptions();
          if (currentPreset) {
            preset = currentPreset;
          }
        }
      } else {
        if (preorder_address_type === DeliveryOptionRestriction.ADDRESS) {
          preset = {
            vae: EDeliveryOptionsMode.VAD,
            autocomplete_literal: "",
          } as ICartDeliveryOptionsVAD;
        } else if (preorder_address_type === DeliveryOptionRestriction.STORE) {
          preset = {
            vae: EDeliveryOptionsMode.VAE,
            autocomplete_literal: "",
          };
        }
      }

      const onComplete = (success: boolean = false) => {
        callback?.(success);
      };

      const requireDeliveryOptions = (confirm: boolean) => {
        if (confirm) {
          reduxStore.dispatch(
            cartSlice.actions.openPreorder({
              id_product,
              add_product: preorder_add_product,
              delivery_mode_restrictions,
              preset,
              onClose: onComplete,
              onComplete: onComplete,
            })
          );
        }
      };

      if (!!has_store && has_wrong_delivery_type) {
        const message =
            "Ce produit est disponible uniquement %s. Pour le commander, vous devez basculer sur ce mode de livraison.",
          modeName = app
            .t(
              preorder_address_type === DeliveryOptionRestriction.STORE
                ? "À emporter"
                : "En livraison",
              CART_TRANSLATION_CONTEXT
            )
            .toLowerCase();
        app.confirm(
          {
            title: app.t(UI_WARNING_TEXT, UI_CONTEXT),
            subtitle: app.t(message, CART_TRANSLATION_CONTEXT, modeName),
          },
          requireDeliveryOptions
        );
      } else {
        requireDeliveryOptions(true);
      }
    }
  }

  getAutoCompleteInputText(): string {
    return this.getDeliveryOptions()?.autocomplete_literal || "";
  }

  getDeliveryOptions(): CartDeliveryOptions | undefined {
    const deliveryOptions = this.get("deliveryOptions");
    return (
      (deliveryOptions && (deliveryOptions as CartDeliveryOptions)) || undefined
    );
  }

  getDeliveryDelay() {
    const cart = this,
      v = cart.isVAE() ? "vae" : "vad",
      d = cart.formatDatetime("YYYY/MM/DD "),
      delay_min = cart.get(("delay_min_" + v) as keyof ICartData),
      delay_max = cart.get(("delay_max_" + v) as keyof ICartData),
      date_min = new Date(d + cart.get("order_time_min")),
      date_max = new Date(d + cart.get("order_time_max"));
    return {
      date_min,
      date_max,
      moment_min: moment(date_min),
      moment_max: moment(date_max),
      delay_min,
      delay_max,
      diff: delay_max - delay_min,
    };
  }

  formatDeliveryDelay(format?: EFormatDeliveryDelay) {
    let cart = this;
    const d = cart.getDeliveryDelay();
    const parameters = [d.delay_min, d.delay_max];
    switch (format) {
      //
      case EFormatDeliveryDelay.NAV:
        return app.t("%s à %s min", "Cart", parameters);
      case EFormatDeliveryDelay.DEFAULT:
      default:
        return app.t("dans %s à %s min", "Cart", parameters);
    }
  }

  getPlace() {
    const deliveryOptions = this.getDeliveryOptions();
    const place_id = this.get("place_id") || deliveryOptions?.place_id || "";
    return place_id
      ? PlaceModel.getPlace(
          place_id,
          new PlaceModel({
            place_id,
            ...deliveryOptions,
          })
        )
      : undefined;
  }

  getQuantity(fresh_cache?: boolean) {
    const cart = this;
    let { q } = cart;
    if (fresh_cache || isUndefined(q)) {
      q = cart
        .getProductsNonConsumable()
        .reduce((memo, cartProduct) => memo + cartProduct.getQuantity(), 0);
      cart.q = q;
      // cache quantity as "q" property
    }
    return q;
    // return parseInt(cart.get('total_cart_quantity'));
  }
  getConsumables() {
    return this.cartConsumablesCollection;
  }

  getProducts() {
    return this.cartProductCollection;
  }
  getProductsIndispo() {
    return this.cartUnavailableProductCollection;
  }
  getProduct(id_cart_product: string): CartProductModel | undefined {
    let cartProduct = this.cartProductCollection.get(id_cart_product);
    if (!cartProduct) {
      // si le produit est indisponible.
      cartProduct = this.cartUnavailableProductCollection.get(id_cart_product);
    }
    return cartProduct;
  }

  getProductQuantityById(id_product: string): number {
    let _quantity = 0;
    this.cartProductCollection.forEach((item) => {
      if (item && item.get("id_product") === id_product) {
        _quantity += item.getQuantity();
      }
    });

    return _quantity;
  }

  getProductsById(id_product: string) {
    const products = this.cartProductCollection.filter((cartProduct) => {
      let product = cartProduct.getProduct();
      return !!(product && product.id === id_product);
    });
    return products;
  }

  getProductsByCategory(category: CategoryModel, invert?: boolean) {
    const products = this.cartProductCollection.filter((cartProduct) => {
      let product = cartProduct.getProduct();
      return product ? category?.containsId(product.id) !== invert : false;
    });
    return products;
  }
  getProductsNonConsumable() {
    return this.getProductsByCategory(
      app.getCategory(CATEGORY_SLUGS[ECategorySlug.CONSUMABLE]),
      true
    );
  }

  // getPostcode() {
  //   let cart = this;
  //   let postcodeModel;
  //   if (cart.isVAE()) {
  //     const store = cart.getStore();
  //     if (store) {
  //       postcodeModel = store.getPostcode();
  //     }
  //   } else {
  //     postcodeModel = app.getPostcode(cart.getPostcodeId());
  //     if (!postcodeModel) {
  //       const deliveryAddress = cart.getAddressDelivery();
  //       if (deliveryAddress) {
  //         return deliveryAddress.getPostcode();
  //       }
  //       const deliveryOptions = cart.getDeliveryOptions();
  //       if (deliveryOptions && !deliveryOptions?.vae) {
  //         //var postal_code = deliveryOptions.postal_code,
  //         const id_postcode = deliveryOptions.id_postcode;
  //         // if (postal_code) return postal_code;
  //         if (id_postcode) {
  //           postcodeModel = app.getPostcode(id_postcode);
  //         }
  //       }
  //     }
  //   }
  //   if (postcodeModel) {
  //     return postcodeModel.getPostcode();
  //   }
  // }

  getPieces() {
    const cart = this;
    let p = 0;
    cart.getProducts().each(function (cartProduct) {
      const product = cartProduct.getProduct();
      p += product ? product.getPieces() * cartProduct.getQuantity() : 0;
    });
    return p >= 0 ? p : 0;
  }

  getAddressDelivery() {
    const cart = this;
    const id_address_delivery =
      cart.get("id_address_delivery") || cart.getDeliveryOption("id_address");
    let address = app.getAddress(id_address_delivery);
    // console.info("getAddressDelivery", id_address_delivery, address);
    if (id_address_delivery && /^fallback_place/.test(id_address_delivery)) {
      // récupère les données en localstorage
      const place = PlaceModel.getPlace(id_address_delivery);
      if (place) {
        address = app.getAddresses()?.createAddressFromPlace(place, true);
      }
    }
    if (!address) {
      const place = cart.getPlace();
      if (place) {
        address = app.getAddresses()?.createAddressFromPlace(place, true);
      }
    }
    return address;
  }

  getCartProduct(id_cart_product: string): CartProductModel | undefined {
    // cherche le model CartProduct, à partir de l'id
    let product: CartProductModel | undefined = undefined;
    this.productCollections.forEach(function (collection) {
      const p = collection.get(id_cart_product);
      if (p) product = p;
    });
    return product;
  }
  getCartProductCollection(id_cart_product: string): CartProductCollection {
    const cart = this,
      product = cart.getCartProduct(id_cart_product);
    // par défault, retourne la collection principale
    return product
      ? (product.collection as CartProductCollection)
      : cart.cartProductCollection;
  }

  addProduct(
    id_cart_product: string,
    quantity?: number,
    options?: {
      sidedish?: ICartProductSidedish;
    }
  ) {
    return this.getCartProductCollection(id_cart_product).addProduct(
      id_cart_product,
      quantity,
      options
    );
  }
  deleteProduct(id_cart_product: string, quantity?: number) {
    let cart = this;
    return cart
      .getCartProductCollection(id_cart_product)
      .deleteProduct(id_cart_product, quantity);
  }
  async updateProductSidedish(
    id_cart_product: string,
    sidedish: ICartProductSidedish
  ) {
    // sidedish est un tableau
    let updateCartProductCollection = this.cartProductCollection;
    if (!updateCartProductCollection.get(id_cart_product)) {
      updateCartProductCollection = this.cartUnavailableProductCollection;
    }
    return updateCartProductCollection.updateProductSidedish(
      id_cart_product,
      sidedish
    );
  }
  getPriceTTC(
    options: { ignoreExtraPrices: boolean } = {
      ignoreExtraPrices: false,
    }
  ) {
    const cart = this;
    let price = 0;
    try {
      cart.getProducts().each(function (cartProduct) {
        //
        const product = cartProduct.getProduct();
        if (product && product.isBuyableInContext(cart)) {
          const cartProductPrice = cartProduct.getPrice();
          // computedPrice = product.getPrice("ttc", true, cart) * cartProduct.getQuantity();
          price += cartProductPrice;
          //
          product.getExtraDisplayPrice().each(function (extraPrice) {
            price +=
              parseFloat(extraPrice.price_ttc) * cartProduct.getQuantity();
          });
        }
      });

      //
      if (!options.ignoreExtraPrices) {
        cart.getExtraPrice().each(function (extraPrice) {
          if (!extraPrice.get("free")) {
            price += extraPrice.getPrice("price_ttc");
          }
        });
      }

      const discount = cart.get("total_discount") || "0";

      price -= +discount;

      return Math.round(price * 100) / 100;
    } catch (err) {
      // si il y a une erreur dans le calcul du prix
      // on retourne Not a Number
      console.warn(err);
      return NaN;
    }
  }
  getValueOf(key: keyof ICartData) {
    return parseFloat(this.get(key)) || 0;
  }
  getExtraPriceValue(type: string = "price_ttc") {
    let cart = this;
    let price = 0;
    cart.getExtraPrice().each(function (extraPrice) {
      if (!extraPrice.get("free")) {
        price += extraPrice.getPrice(type);
      }
    });
    return price;
  }
  getPriceTTCWithoutDiscount() {
    const price = this.get("total_price_ttc_without_discounts") || "0";

    return Math.round(+price * 100) / 100;
  }
  hasTransfert() {
    return !!this.get("id_store_transfered");
  }
  getStoreTransfert() {
    return this.get("id_store_transfered");
  }
  // getMinimumShippingPrice() {
  //   let minValue = 0;
  //   // si on est en livraison seulement
  //   if (!this.isVAE()) {
  //     const postcode = this.getPostcodeId(),
  //       district = this.getDistrictId();
  //     let store = this.getStore();
  //
  //     if (this.hasTransfert()) {
  //       store = app.getStore(this.getStoreTransfert() || "");
  //     }
  //     if (store && postcode) {
  //       minValue = store.getMinimumShippingPrice(
  //         this.getDatetime(),
  //         postcode,
  //         district
  //       );
  //     }
  //   }
  //   return minValue;
  // }
  onProductsChange() {
    let cart = this;
    let stockout_id_products: any[] = [];
    cart.getProducts().each(function (cartProduct) {
      const accompagnements = cartProduct.get("accompagnements");
      accompagnements?.forEach(
        (a: { visible_error: string; id_product: string }) => {
          if (a.visible_error === STOCKOUT_AVAILABLE_MSG) {
            let id_product = a.id_product;
            if (stockout_id_products.indexOf(id_product) === -1) {
              stockout_id_products.push(id_product);
            }
          }
        }
      );
    });
    cart.getProductsIndispo().each(function (cartProduct) {
      if (cartProduct.get("available_msg") === STOCKOUT_AVAILABLE_MSG) {
        const id_product = cartProduct.get("id_product");
        if (stockout_id_products.indexOf(id_product) === -1) {
          stockout_id_products.push(id_product);
        }
      }
    });
    const stockout_id_products_hash = stockout_id_products.join(",");
    cart.set(
      STOCKOUT_ID_PRODUCTS_HASH as keyof ICartData,
      stockout_id_products_hash
    );
  }

  hasRequiredSidedishes(cross_selling_products_errors?: any) {
    const cart = this;
    //
    // valide que tous les accompagnements obligatoires sont bien setté
    //
    let validated = true;
    cart.invalidSidedishes = [];
    cart.getProducts().each((cartProduct) => {
      const product = cartProduct.getProduct(),
        id_cart_product = cartProduct?.id;
      if (
        !!id_cart_product &&
        (cross_selling_products_errors || []).includes(id_cart_product)
      ) {
        validated = false;
        cart.invalidSidedishes.push(id_cart_product);
      } else if (product && product.isMenu(true)) {
        cartProduct.getSidedishes().each((sidedish) => {
          if (
            sidedish.isRequired() &&
            (!sidedish.validateSelection() || sidedish.isStockout())
          ) {
            validated = false;
            cart.invalidSidedishes.push(id_cart_product);
          }
        });
      }
    });
    return validated;
  }
  requireSmsConfirmationCode(
    params: any,
    callback: (sms_code: any, requireNewCode: any) => void
  ) {
    app.trigger("openConfirmationCodePromptDialogView", params, callback);
  }
  getVouchers() {
    let cart = this;
    const vouchers = cart.get("discounts");
    return _(vouchers);
  }
  getDiscount(id_cart_discount: string) {
    return (this.get("discounts") || []).find(
      ({ id_cart_discount: id }) => id === id_cart_discount
    );
  }
  // TODO - fonction peut-être inutile / dépréciée
  // getSponsorship() {
  //   let cart = this;
  //   // static id_cart_discount "sponsorship"
  //   return cart.getVoucher().findWhere({ id_cart_discount: "sponsorship" });
  // }

  getDiscountLabel() {
    const voucher = this.getVouchers();
    return (
      app.t("Réduction code", "Cart") +
      " " +
      (voucher
        .filter(({ auto = false }) => !auto)
        .map(({ name }) => {
          return name || "";
        })
        .join(", ") || "")
    );
  }
  formatDiscount(displayZero: boolean = false, displayMinus: boolean = true) {
    let reduction = parseFloat(this.get("total_discount") || "0") || 0;
    if (isUndefined(reduction)) reduction = 0;
    return reduction === 0 && !displayZero
      ? ""
      : formatPrice((displayMinus ? -1 : 1) * reduction);
  }
  updateCagnotte() {
    let cart = this;
    // met à jour la cagnotte pour ne pas dépasser le panier
    cart.setCagnotte(cart.getCagnotte());
  }
  setCagnotte(value: number) {
    let cart = this;
    if (!GATSBY_CONF_CAGNOTTE_ENABLED) {
      value = 0;
    } else {
      value = Math.max(0, cart.getCagnotteMax(value));
    }
    cart.setAndSave("cagnotte", value);
  }
  getCagnotteConversionRate() {
    return app.getCustomer().getCagnotteConversionRate();
  }
  getCagnotteMax(value: number = Infinity) {
    return Math.min(
      app.getCustomer().getCagnotteAmount() || 0,
      this.getPriceTTC() / this.getCagnotteConversionRate(),
      value
    );
  }
  getCagnotte(currencyConversion?: any) {
    let cart = this;
    if (!GATSBY_CONF_CAGNOTTE_ENABLED) {
      return 0;
    }
    let cagnotte = Math.max(cart.get("cagnotte") || 0, 0);
    if (currencyConversion) {
      cagnotte *= app.getCustomer().getCagnotteConversionRate();
    }
    return cagnotte;
  }
  formatCagnotteCustomer() {
    return app.getCustomer().displayCagnotteAmount();
  }
  formatCagnotteUsed() {
    const cart = this;
    // if used, display negative used, else positive available
    const amountUsed = cart.getCagnotte();
    let formatted;
    if (amountUsed > 0) {
      formatted = formatPrice(-amountUsed); // default formatting
    } else {
      formatted = cart.formatCagnotteCustomer();
    }
    return formatted;
  }
  formatEarnCagnotte() {
    return formatPrice(
      ((this.getPriceTTC() - this.getCagnotte()) *
        GATSBY_CONF_COMEIN_RECUP_VALUE) /
        100
    );
  }
  formatCagnotteSubtitle() {
    const amount = this.formatCagnotteUsed();
    return app.t(
      !app.getCustomer().isLogged()
        ? "Veuillez vous connecter" // non connecté
        : amount.includes("-")
        ? "Montant utilisé %s" // cagnotte utilisée
        : "Saisissez le montant à déduire", // cagnotte disponible
      "Cart",
      amount
    );
  }
  formatPriceTotal() {
    let cart = this;
    const cagnotte = cart.getCagnotte(true);
    return cart.formatPrice(-cagnotte);
  }
  isZeroTTC() {
    let cart = this;
    const cagnotte = cart.getCagnotte(true);
    return cart.getPrice(-cagnotte) === 0;
  }
  getPrice(addition?: number) {
    let cart = this;
    if (isUndefined(addition)) addition = 0;
    let price = cart.getPriceTTC();
    if (!isNumber(price)) price = 0;
    let priceAddition = price + addition;
    priceAddition = priceAddition < 0 ? 0 : priceAddition;
    if (priceAddition < 0) {
      priceAddition = 0;
    }

    return parseFloat(priceAddition.toString());
  }
  formatPrice(addition?: number) {
    let cart = this;
    return formatPrice(cart.getPrice(addition));
  }
  // pour les fermetures exceptionnelles, on va reporter la date à la réouverture
  // reportToNextOpening(
  //   json: { closing_date_end?: MomentInput; error?: any },
  //   options: any,
  //   store: StoreModel
  // ): boolean | Moment {
  //   let cart = this;
  //   let report: boolean | Moment = false;
  //   if (json && json.closing_date_end) {
  //     // fermeture exceptionnelles
  //     const closing_date_end = moment(json.closing_date_end);
  //     let report_recursion = options.report_recursion | 0;
  //     report_recursion++;
  //     if (!closing_date_end.isValid()) {
  //       // au cas où la date de closing_date_end soit invalide...
  //       app.alert();
  //       report = true;
  //     } else if (report_recursion < 8) {
  //       // protège contre les récursions, liées à des erreurs
  //       options.report_recursion = report_recursion;
  //       //
  //       if (report_recursion === 2) {
  //         cart.displayUnavailableDatetime(json.error);
  //       }
  //       const availableDatetime = store.findAvailableDatetime(
  //         options.vae === 1,
  //         closing_date_end.toDate()
  //       );
  //       if (availableDatetime.moment) {
  //         options.now = availableDatetime.now;
  //         report = availableDatetime.moment;
  //       }
  //     } else {
  //       // si on dépasse les 8 tentatives
  //       report = true;
  //     }
  //   }
  //   //
  //   // return false | true | moment
  //   //
  //   return report;
  // }

  prepareDeliveryOptions<optionsType = CartDeliveryOptions>(
    options: optionsType
  ) {
    const cart = this;
    const preparedOptions: optionsType = {
      ...options,
    };
    let { now, order_date, order_time, vae, autocomplete_literal } =
      preparedOptions as CartDeliveryOptions;
    let targetMoment;
    if (isUndefined(now)) {
      now = cart.getNow();
      targetMoment = moment(cart.getDatetime());
    } else {
      const targetDate =
        now === 1
          ? new Date()
          : (order_date &&
              order_time &&
              new Date(cleanMySQLDateToISO(`${order_date} ${order_time}`))) ||
            new Date();
      targetMoment = moment(targetDate);
    }
    if (vae) {
      const id_store =
        (preparedOptions as ICartDeliveryOptionsVAE).id_store || "";
      (preparedOptions as ICartDeliveryOptionsVAE).autocomplete_literal =
        autocomplete_literal || app.getStore(id_store)?.getName() || "";
    } else {
      (preparedOptions as ICartDeliveryOptionsVAD).autocomplete_literal =
        autocomplete_literal ||
        (options as ICartDeliveryOptionsVAD).alias ||
        (options as ICartDeliveryOptionsVAD).description ||
        "";
    }
    (preparedOptions as CartDeliveryOptions).now = now;
    (preparedOptions as CartDeliveryOptions).order_date =
      targetMoment.format(CART_DATE_FORMAT);
    (preparedOptions as CartDeliveryOptions).order_time =
      targetMoment.format(CART_TIME_FORMAT);
    console.log("prepareDeliveryOptions", preparedOptions);
    return {
      options: preparedOptions,
      moment: targetMoment,
    };
  }

  // vérification des options de livraison
  checkDeliveryOptions<
    optionsType extends ICartDeliveryOptionsVAE | ICartDeliveryOptionsVAD
  >(
    propOptions: optionsType
  ): Promise<{
    options: optionsType;
    store?: StoreModel;
    moment: Moment;
    error?: string;
    type?: string;
    next_opening?: object;
  }> {
    const cart = this;
    const { moment, options } =
      cart.prepareDeliveryOptions<optionsType>(propOptions);
    return new Promise((resolve, reject) => {
      cart
        .ApiRequest("CartCheckDeliveryOptions", options, { id_cart: cart.id })
        .catch((reason: AxiosError) => {
          const { response } = reason;
          if (response && response.data) {
            const { data } = { ...response };
            const { error, id_store = "" } = data;
            const store = app.getStore(id_store);
            if (store) {
              return resolve({
                ...data,
                options,
                store,
                moment,
              });
            } else if (!!error) {
              return reject(new Error(error, { cause: data }));
            }
          }
          reject(new Error("checkDeliveryOptions error"));
        })
        .then((data) => {
          if (data && data.id_store) {
            const store = app.getStore(data.id_store);
            if (store) {
              // throw new Error("checkDeliveryOptions invalid store");
            }
            return resolve({
              ...data,
              options,
              store,
              moment,
            });
          }
          reject(
            new Error("checkDeliveryOptions invalid store", {
              cause: { type: "invalid_store" },
            })
          );
        });
    });
  }

  // options de livraison
  setDeliveryOptions(
    optionsProps: ICartDeliveryOptionsVAE | ICartDeliveryOptionsVAD
  ): Promise<ICartData> {
    const cart = this;
    const { options } = cart.prepareDeliveryOptions<
      ICartDeliveryOptionsVAE | ICartDeliveryOptionsVAD
    >(optionsProps);

    return new Promise<ICartData>((resolve, reject) => {
      cart
        .ApiRequest("CartDeliveryOptions", options, { id_cart: cart.id })
        .then((data: ICartData) => {
          if (!data) {
            reject("setDeliveryOptions failed, empty response");
            // throw new Error("setDeliveryOptions failed, empty response");
          }
          // applique les détails des addresses de livraison
          data.deliveryOptions = {
            ...options,
          };
          const address = app.getAddress(data.id_address_delivery);
          address && (data.deliveryAddressData = address.toJSON());
          // data.id_address_delivery = data.id_address_delivery;
          !options.vae &&
            options.id_district &&
            (data.id_district = options.id_district);
          //
          // si la donnée a disparu adresse de livraison a disparu, il faut la supprimer
          if (!data.id_address_delivery) {
            data.id_address_delivery = undefined;
          }

          //
          cart.setAndSave(data);
          cart.trigger("deliveryUpdate", cart);
          const store = cart.getStore();
          if (!store) {
            reject("setDeliveryOptions failed, store is not found");
            // throw new Error("setDeliveryOptions failed, store is not found");
          }
          // if (!store) {
          //   return setDeliveryOptionsFail();
          // }

          //
          // TODO ajouter _SMART_REPLACE_POSTAL_CODE_ à update.php
          //
          if (
            GATSBY_CONF_SMART_REPLACE_POSTAL_CODE &&
            data.id_address_delivery
          ) {
            const address = cart.getAddressDelivery();
            if (address) {
              address.smartReplacePostalCode();
            }
          }

          store?.once("sync", function storeFetch() {
            store.fetchStockoutProduct(
              () => {
                cart.trigger("changeContext", cart);
              },
              () => {
                app.alert();
              }
            );
          });
          store?.fetch();
          resolve(data);
        })
        .catch((error: { response?: ICartDeliveryOptionsErrorResponse }) => {
          const { response } = error;
          new Promise<ICartData>((EResolve, EReject) => {
            const alertOptions: IConfirmOptions = {
              title: app.t("Toutes nos excuses !", UI_CONTEXT),
              subtitle: "",
              template: "sorry",
            };
            const RejectFactory = (
              type: string = "cancel",
              silent: boolean = true,
              options: object = {}
            ) => {
              EReject(
                new Error(`Reject setDeliveryOption ${type}`, {
                  cause: { type, silent, ...options },
                })
              );
            };
            if (response?.data) {
              const { data } = response;
              const { type, error, next_opening, post_alert_action, id_store } =
                data;
              const { order_date, order_time } = { ...next_opening };
              const nextDateString = !!(order_date && order_time)
                  ? cleanMySQLDateToISO(order_date + " " + order_time)
                  : "",
                nextMoment = nextDateString && moment(nextDateString);
              const nextOptions = { ...options, ...next_opening };
              alertOptions.subtitle = error;
              if (type === "store_closed") {
                app.alert(alertOptions, () => {
                  cart
                    .setDeliveryOptions(nextOptions)
                    .then(EResolve)
                    .catch(EReject);
                });
              } else if (
                type === "no_store_zone" ||
                type === "invalid_store_zone" ||
                type === "store_not_selling_online"
              ) {
                RejectFactory(type, false, { error });
              } else if (type === "require_next_opening" && nextMoment) {
                alertOptions.title = app.t(UI_WARNING_TEXT, UI_CONTEXT);
                const subtitle =
                  alertOptions.subtitle +
                  ", " +
                  app.t(
                    "votre commande sera prise pour la prochaine date d’ouverture disponible, %s",
                    "AddressAutocomplete",
                    nextMoment.calendar()
                  );
                const title = app.t(UI_WARNING_TEXT, UI_CONTEXT);
                app.confirm({ ...alertOptions, title, subtitle }, (confirm) => {
                  if (confirm) {
                    cart
                      .setDeliveryOptions(nextOptions)
                      .then(EResolve)
                      .catch(EReject);
                  } else {
                    RejectFactory();
                  }
                });
              } else if (type === "store_exceptional_closed") {
                const post_alert_action_i =
                    post_alert_action ||
                    ECartDeliveryOptionsPostAlertAction.DO_NOTHING,
                  post_alert_action_label =
                    post_alert_action_i > 0
                      ? app.t(
                          [
                            "Ne rien faire", // ancien label
                            "Différer",
                            "Passer en livraison",
                            "Passer en Click & Collect",
                          ][post_alert_action_i],
                          "Checkout"
                        )
                      : app.t("J'ai compris", "UI");
                // if > 0 ? confirm : alert
                const func = !!post_alert_action ? app.confirm : app.alert;
                func(
                  { ...alertOptions, validation: post_alert_action_label },
                  (confirm) => {
                    if (confirm) {
                      switch (post_alert_action) {
                        case ECartDeliveryOptionsPostAlertAction.DIFFER_TO_NEXT_OPENING:
                          cart
                            .setDeliveryOptions(nextOptions)
                            .then(EResolve)
                            .catch(EReject);
                          break;
                        case ECartDeliveryOptionsPostAlertAction.SWITCH_TO_VAD:
                          // passer en VAD
                          // TODO gérer le switch vers la saisie d'adresse
                          cart.trigger(
                            "toggleAddressAutocompleteType",
                            EDeliveryOptionsMode.VAD
                          );
                          RejectFactory();
                          break;
                        case ECartDeliveryOptionsPostAlertAction.SWITCH_TO_VAE:
                          // passer en VAE
                          const { order_date, order_time, now } = options;
                          cart
                            .setDeliveryOptions({
                              id_store,
                              now,
                              order_date,
                              order_time,
                              vae: 1,
                            })
                            .then(EResolve)
                            .catch(EReject);
                          break;
                        default:
                        case ECartDeliveryOptionsPostAlertAction.DO_NOTHING:
                          // ne rien faire
                          RejectFactory("do nothing");
                      }
                    } else {
                      RejectFactory("store closed");
                    }
                  }
                );
              } else {
                app.alert(alertOptions, (result) => {
                  RejectFactory("error", true, { error });
                });
              }
            } else if (error) {
              RejectFactory("error", false, { error });
            } else {
              RejectFactory("invalid", false);
            }
          })
            .then(resolve)
            .catch(reject);
        })
        .finally(() => cart.checkProducts());
    });
  } //

  subscribePostcode(deliveryOptions: ICartDeliveryOptionsVAD, email?: string) {
    const { postcode } = deliveryOptions;
    const datas = {
      ...deliveryOptions,
      email,
    };
    return ApiRequest(
      "PostcodeSubscribe",
      datas,
      { postcode },
      { method: "POST" }
    );
  }
  getNow(): ECartNow {
    const now = this.get("now");
    return typeof now === "number" ? now : ECartNow.NOW;
  } //
  displayNoDeliveryOptions() {
    return app.t(
      "Merci de renseigner votre adresse de livraison ou votre shop de retrait afin d’afficher la carte de votre Shop",
      CART_TRANSLATION_CONTEXT
    );
  }
  // localAndRemoteFetch() {
  //   // override LocalStorage.localAndRemoteFetch
  //   this.localFetch();
  //   this.refresh();
  // } //
  refresh(reportRefresh?: boolean) {
    const cart = this;
    //
    // use remoteFetch function, including refreshTimeout
    //
    let refresh = !reportRefresh;

    clearTimeout(cartRefreshTimeout);
    if (refresh) {
      // force l'actualisation
      cart.remoteFetch();
    }
    // var cartRefreshDelay = cart.getRefreshDelay();
    // TODO: cart refreshTimeout
    // if (cartRefreshDelay > 0) {
    //   cartRefreshTimeout = setTimeout(
    //     $.proxy(cart.refresh, cart),
    //     cart.getRefreshDelay()
    //   );
    // }
  }
  // getRefreshDelay(max?: number) {
  //   const cart = this;
  //   //
  //   // retourne le délai pour le prochain rafraichissement
  //   //
  //   const now = cart.get("now"),
  //     min = HOUR_IN_MS * 2; // maximum 2 heures
  //   max = max ? max : MINUTE_IN_MS; // minimum 1 minute
  //   let refreshDelay = 0;
  //   switch (now) {
  //     // différé
  //     default:
  //     case -1:
  //     case 0:
  //       refreshDelay = cart
  //         .getDatetimeMoment()
  //         .subtract(15, "minutes")
  //         .diff(new Date());
  //       // à l'échéance, moins 15 minutes
  //       break;
  //
  //     // maintenant
  //     case 1:
  //       refreshDelay = 15 * MINUTE_IN_MS;
  //     // toutes les 15 minutes, réinitialisé en cas de mise à jour
  //   }
  //   if (isNaN(refreshDelay)) {
  //     refreshDelay = max;
  //   }
  //   if (refreshDelay < 0) {
  //     cart.trigger("datetimePassed");
  //   }
  //   // http://stackoverflow.com/questions/3468607/why-does-settimeout-break-for-large-millisecond-delay-values
  //   return Math.min(min, Math.max(max, refreshDelay));
  // } //

  //
  // demande un nouveau panier à partir d'un id_order
  //
  reorder(order: OrderModel) {
    const cart = this;
    const id_parent_cart = order.get("id_cart");

    const checkMissingProducts = () => {
      const initial_products_ids = order.get("products") || [],
        cart_products = _(cart.get("products")).pluck("id_product"),
        diff = _.difference(initial_products_ids || [], cart_products || []);
      return diff.length > 0;
    };

    const validationLabel = app.t("Ok", "UI");
    const title = app.t("Votre commande est prête !", "Reorder");

    const checkRequiredSidedishes = () => {
      const hasRequiredSidedishes = cart.hasRequiredSidedishes();
      if (!hasRequiredSidedishes) {
        // formules avec accompagnements obligatoires
        // on alerte
        let subtitle = app.t(
          "Cependant, certaines formules sont à compléter",
          "Reorder"
        );
        if (checkMissingProducts()) {
          subtitle = app.t(
            "Cependant, certaines formules sont à compléter et des produits ne sont actuellement plus disponibles.",
            "Reorder"
          );
        }
        app.alert(
          {
            title: title,
            subtitle: subtitle,
            validation: validationLabel,
          },
          () => {
            // puis on va sur le panier
            app.go("cart");
            const cartProduct = cart.getProduct(this.invalidSidedishes[0]);
            // TODO: display modal
            // et on affiche la formule avec accompagnement indisponible
            if (cartProduct) {
              const product = cartProduct.getProduct();
              if (product)
                reduxStore.dispatch(
                  cartSlice.actions.openProductPage({
                    id_product: product.id,
                    uri: product.getUri(),
                    cartProduct,
                  })
                );
            }
          }
        );
      }

      return hasRequiredSidedishes;
    };

    return cart
      .ApiRequest(
        "CartReorder",
        {
          validate_partial: 0,
          validate_empty: 0,
        },
        {
          id_parent_cart: id_parent_cart,
          id_cart: cart.id,
        }
      )
      .then((data: any) => {
        delete data.id_cart;
        cart.set(data);
        cart.unset("reorderFail");
        if (!cart.isVAE()) {
          const customerAddress = cart.getAddressDelivery();
          if (customerAddress) {
            try {
              const options = customerAddress.getCartDeliveryOptions();
              cart.set("deliveryOptions", options);
            } catch (err) {
              cart.unset("deliveryOptions");
              cart.set("reorderFail", "unavailableAddress");
            }
          } else {
            cart.unset("deliveryOptions");
          }
        }
        cart.updateLocalStorageItem();

        if (checkRequiredSidedishes()) {
          // si pas de formule impactée
          if (checkMissingProducts()) {
            // mais qu'il manque des produits, on alerte
            app.alert(
              {
                title: title,
                subtitle: app.t(
                  "Cependant, des produits ne sont actuellement plus disponibles.",
                  "Reorder"
                ),
                validation: validationLabel,
              },
              function () {
                app.go("cart");
              }
            );
          } else {
            // sinon, quand tout va bien, on redirige vers le panier, sans prévenir
            app.go("cart");
          }
        }

        cart.trigger("reorder");
      })
      .catch((res: any) => {
        console.error(res);
        const responseJSON = res ? res.responseJSON : false,
          errMessage = responseJSON
            ? responseJSON.error || responseJSON.message
            : false;
        if (errMessage) {
          app.alert(errMessage);
          //cart.set('reorderFail','unkwown');
        } else {
          app.alert();
        }
        cart.trigger("reorder");
      });
  }
  addToCartReorder(id_parent_cart: any) {
    let cart = this;
    cart
      .ApiRequest("CartAddToCart", null, {
        id_parent_cart: id_parent_cart,
        id_cart: "virtual",
      })
      .then(function (data: any) {
        //
        //
        //
        cart.setAndSave(data);
        if (app) {
          app
            .getCart()
            .remoteFetch()
            .done(function () {
              cart.trigger("addToCartReorder");
            });
        }
      })
      .catch(function () {
        app.alert();
      });
  }
  //
  // transforme un panier en panier principal
  //
  setCurrent() {
    let cart = this;
    return cart
      .ApiRequest("CartSetCurrent", null, { id_cart: cart.id })
      .then(function (data: { id_cart?: string }) {
        //
        const currentCart = app.getCart();
        data.id_cart = "current";
        function Sync() {
          const deliveryOptions = cart.getDeliveryOptions();
          currentCart.set("deliveryOptions", deliveryOptions);
          cart.trigger("setCurrent");
        }
        currentCart.once("sync", Sync);
        currentCart.remoteFetch();
        //
      })
      .catch(function () {
        app.alert();
      });
  }

  reset(data: any) {
    let cart = this;
    cart.clear();
    cart.set(data);
    cart.setLocalStorageItem();
  }

  resetPreset() {
    let cart = this;
    cart
      .ApiRequest("CartConsumableReset", null, { id_cart: cart.id })
      .then(function (data: any) {
        cart.setAndSave(data);

        cart.trigger("resetpreset", cart);
      })
      .catch(function () {
        app.alert();
      });
  }

  // setDeliveryDatetime(date: any, time: any, now: any) {
  //   let cart = this;
  //   var options: any = { order_date: date, order_time: time, now: now },
  //     store = cart.getStore();
  //   if (store && store.hasDistricts()) {
  //     options.id_district = cart.get("id_district");
  //   }
  //
  //   function SetDeliveryDatetime(options: {
  //     order_date: any;
  //     order_time: any;
  //     now?: any;
  //     vae?: any;
  //   }) {
  //     return cart
  //       .ApiRequest("CartDatetime", options, { id_cart: cart.id })
  //       .then(function (data: any) {
  //         cart.setAndSave(data);
  //         cart.trigger("deliveryUpdate", cart);
  //         cart.trigger("changeContext", cart);
  //       })
  //       .catch(function (
  //         xhr: any
  //         // status: any,
  //         // errorType: any
  //       ) {
  //         let errorType;
  //         options.vae = cart.get("vae");
  //         var json = xhr.responseJSON,
  //           err: any,
  //           reportDateTime: any = store
  //             ? cart.reportToNextOpening(json, options, store)
  //             : undefined;
  //
  //         if (reportDateTime) {
  //           if (reportDateTime !== true) {
  //             // non  dépassement des récursions
  //             options.order_date = reportDateTime.format(CART_DATE_FORMAT);
  //             options.order_time = reportDateTime.format(CART_TIME_FORMAT);
  //             cart.displayUnavailableDatetime(json.error, function () {
  //               SetDeliveryDatetime(options);
  //             });
  //           }
  //           return;
  //         }
  //
  //         cart.trigger("setDeliveryOptionsFail", cart);
  //         if (json && json.error) {
  //           err = {
  //             title: errorType,
  //             subtitle: xhr.responseJSON.error,
  //             template: "sorry",
  //           };
  //         }
  //         app.alert(err);
  //       });
  //   }
  //   return SetDeliveryDatetime(options);
  // }

  displayUnavailableDatetime(
    details: string,
    callback?: { (): void; (): void }
  ) {
    const subtitle =
      details +
      "<br/><small>" +
      app.t(
        "Votre commande va être basculée sur le prochain créneau disponible.",
        CART_TRANSLATION_CONTEXT
      ) +
      "</small>";
    app.alert(
      {
        title: app.t("Fermeture exceptionnelle", CART_TRANSLATION_CONTEXT),
        subtitle: subtitle,
        template: "sorry",
      },
      callback
    );
  }

  getProductOffered() {
    let cart = this;
    let product_offered_id: string = "",
      true_id = cart.get(
        (FAVORITE_PRODUCT_OFFERED_PREFIX + "true_id") as keyof ICartData
      ),
      id = cart.get(
        (FAVORITE_PRODUCT_OFFERED_PREFIX + "id") as keyof ICartData
      );
    if (true_id && id !== "0-0" && id !== "0") {
      product_offered_id = true_id;
    }
    if (product_offered_id === "0" || product_offered_id === "0-0") {
      product_offered_id = "";
    }
    return product_offered_id;
  }

  // getProductOfferedId() {
  //   let cart = this;
  //   const pattern = /^(\d+)-?.*/,
  //     free_product_id_true_or_not_true =
  //       cart.get((FAVORITE_PRODUCT_OFFERED_PREFIX + "id") as keyof ICartData) ||
  //       cart.get(
  //         (FAVORITE_PRODUCT_OFFERED_PREFIX + "true_id") as keyof ICartData
  //       );
  //   if (
  //     free_product_id_true_or_not_true &&
  //     pattern.test(free_product_id_true_or_not_true)
  //   ) {
  //     const result = pattern.exec(free_product_id_true_or_not_true);
  //     return result ? result[1] : false;
  //   } else {
  //     return false;
  //   }
  // }

  hasProductOfferedSelected() {
    const getProductOffered = this.getProductOffered();
    return !(
      !getProductOffered ||
      getProductOffered === "0-0" ||
      getProductOffered === "0"
    );
  }
  getStore(): StoreModel | undefined {
    return app?.getStore(this.get("id_store") || "0");
  }
  // getCustomer() {
  //   return app?.getCustomer();
  // }
  getDeliveryOption(key: string) {
    let cart = this;
    const deliveryOptions = cart.get("deliveryOptions");
    if (deliveryOptions == null || isUndefined(deliveryOptions[key])) {
      return "";
    } else {
      return deliveryOptions[key];
    }
  }
  getDistrictId() {
    const cart = this;
    const d = cart.get("id_district");
    if (d && d !== "0") return d;
  }
  getPostcodeId() {
    let cart = this;
    var p = cart.get("id_postcode");
    if (p && p !== "0") return p;
  }
  // requireAddressAlias() {
  //   let cart = this;
  //   var addressDelivery = cart.getTemporaryAddress(),
  //     requireAddressAlias = cart.isVAD();
  //   if (requireAddressAlias && addressDelivery) {
  //     var alias = addressDelivery.getAlias(),
  //       type = addressDelivery.get("type");
  //     requireAddressAlias = !(type > 0 || !!alias);
  //   }
  //   return requireAddressAlias;
  // }
  // getAddressIcon(): IconName {
  //   const cart = this;
  //   return cart.getAddressDelivery()?.getIcon() || "pin_outline";
  // }
  formatLocation(): string {
    const cart = this;
    const store = cart.getStore();
    if (store) {
      if (cart.isVAE()) {
        return cart.getStore()?.getName() || "";
      }
      let description =
        cart.getAddressDelivery()?.formatAliasLabel(false, true) ||
        cart.getDeliveryOption("description");
      //
      return (
        description || app.t("Votre adresse n’a pu être déterminée.", "Cart")
      );
    } else {
      return app.t(
        "Saisissez votre adresse de livraison ou votre shop",
        "Cart"
      );
    }
  } // retourne une phrase indiquant le moment de livraison

  formatDeliveryTimeLabel(format?: EFormatDeliveryDelay) {
    let cart = this;
    // TODO peux mieux faire
    return cart.isNow()
      ? cart.formatDeliveryDelay(format)
      : cart
          .getDatetimeMoment()
          .calendar(new Date(), momentFormats.getFormats());
  }
  // retourne une phrase indiquant le type de livraison
  formatDeliveryTypeLabel(format?: EFormatDeliveryDelay) {
    const isVAE = this.isVAE() && this.validateDeliveryOptions();
    switch (format) {
      case EFormatDeliveryDelay.NAV:
        return isVAE
          ? app.t("À emporter", "Cart")
          : app.t("En livraison", "Cart");
      default:
        return isVAE
          ? app.t("À venir chercher", "Cart")
          : app.t("Livraison prévue", "Cart");
    }
  }
  getTemporaryAddress() {
    const cart = this;
    // instancie une adresse temporaire
    // hors du carnet d'adresse
    // afin d'avoir des fonctions de formatage dans la partie checkout address
    const place = cart.getPlace();
    let address = cart.getAddressDelivery();
    if (!address && place) {
      address = AddressModel.createAddressFromPlace(
        place,
        true,
        "",
        false,
        cart.getDeliveryOption("source")
      );
    }
    if (!!address) {
      address.set("attach_to_cart", 1);
    }
    return address;
  }

  // alias de la fonction statique "requireDeliveryOptions", sur une instance panier
  requireDeliveryOptions(payload?: Partial<CartDeliveryOptions>) {
    reduxStore.dispatch(cartSlice.actions.openDeliveryOptions(payload));
  }

  removeVoucher(id_cart_discount: any) {
    let cart = this;
    return cart
      .ApiRequest(
        "CartVoucherRemove",
        { id_cart_discount: id_cart_discount },
        { id_cart: cart.id }
      )
      .then(function (data: { discounts?: any }) {
        if (!data.discounts) {
          cart.unset("discounts");
          cart.unset("total_discount");
        }
        cart.setAndSave(data);
        app.trigger("cancelVoucher");
      })
      .catch(function () {
        app.alert();
      });
  }

  addVoucher(voucherCode: string, onAlert?: (actionStatus: boolean) => void) {
    const cart = this;
    return new Promise<void>((resolve, reject) => {
      cart
        .ApiRequest(
          "CartVoucherAdd",
          { discount_name: voucherCode },
          { id_cart: cart.id }
        )
        .then((data: any) => {
          cart.setAndSave(data);
          const discounts = cart.get("discounts") || [];
          let displayModal = false;
          discounts.forEach((dd) => {
            if (
              voucherCode.toUpperCase() === dd.name?.toUpperCase() &&
              dd.force_cart_apear === "1"
            ) {
              cart.unset(
                (FAVORITE_PRODUCT_OFFERED_PREFIX + "id") as keyof ICartData
              );

              cart.unset(
                (FAVORITE_PRODUCT_OFFERED_PREFIX + "true_id") as keyof ICartData
              );
              cart.reopenFreeProductChoice();
              // TODO: display modal
              // affiche la fenetre de selection de produit offert
              /* displayModal = true;
            var modalView = new FreeProductChoiceVoucherView({
              voucher: dd,
            }); 
            app.displayModal(modalView); */
            }
          });
          if (!displayModal) {
            // le nombre de bon est limité à 1 par panier
            _(discounts).each(function (dd) {
              if (voucherCode.toUpperCase() === dd.name?.toUpperCase()) {
                app.alert({
                  title: app.t(
                    "Le code offre %s est appliqué",
                    CART_TRANSLATION_CONTEXT,
                    voucherCode
                  ),
                  subtitle: dd.description || "",
                });
              }
            });

            // app.banNotice(app.t('Votre coupon a été ajouté !', CART_TRANSLATION_CONTEXT));
            app.trigger("addVoucher");
          }
          resolve();
        })
        .catch((xhr: any) => {
          console.log("catch addVoucher", [xhr]);
          const error = xhr?.response?.data?.error;
          const errorId = xhr?.response?.data?.error_id;
          if (error) {
            if (errorId === 43) {
              app.alert({
                title: app.t("Désolé", UI_CONTEXT),
                subtitle: error,
              });
            } else if (errorId === 0) {
              app.alert(
                {
                  title: app.t("Désolé", UI_CONTEXT),
                  subtitle: error,
                },
                (res) => {
                  if (res && !cart.validateDeliveryOptions()) {
                    cart.requireDeliveryOptions();
                  }
                }
              );
            } else {
              app.alert(
                {
                  title: app.t("Désolé", UI_CONTEXT),
                  subtitle: error,
                },
                onAlert
              );
            }
          } else {
            app.alert();
          }
          app.trigger("cancelVoucher");
          reject();
        });
    });
  }

  reopenFreeProductChoice() {
    const cart = this;
    let modalView = null;
    const favorite_product_offored_id_discount = cart.get(
      (FAVORITE_PRODUCT_OFFERED_PREFIX + "id_discount") as keyof ICartData
    );
    if (favorite_product_offored_id_discount) {
      const discounts = cart.get("discounts");
      console.log("reopenFreeProductChoice", discounts);
      discounts?.forEach(function (discount) {
        if (
          favorite_product_offored_id_discount + "" ===
            discount.id_cart_discount + "" &&
          discount.force_cart_apear === "1"
        ) {
          cart.trigger("openFreeProductChoice", discount);
        }
      });
    }
    return modalView;
  }
  //
  getUsableAdvantages() {
    return app.getCustomer().getUsableAdvantages(false, true);
  } //
  //
  usePaymentMode(pm?: PaymentModeModel) {
    paymentMode = pm;
  }
  requireAuthentification(
    callback?: (_isLogged: boolean) => void,
    confirmBefore?: string
  ) {
    const isLogged = () => {
      return app.getCustomer().isLogged();
    };
    const logged = isLogged();
    const complete = () => {
      callback && callback(isLogged());
    };
    if (!logged) {
      const displayModal = () => {
        reduxStore.dispatch(
          setOpenAuthModel({
            modalOpen: true,
            step: EAuthentificationStep.DEFAULT,
            onSuccess() {
              console.log("onSuccess");
              complete();
            },
          })
        );
      };
      if (confirmBefore) {
        console.log("confirmBefore");
        app.confirm(
          {
            title: app.t(UI_WARNING_TEXT, UI_CONTEXT),
            subtitle: confirmBefore,
            validation: app.t("Je me connecte", UI_CONTEXT),
          },
          function (confirm: any) {
            if (confirm) {
              displayModal();
            }
          }
        );
      } else {
        displayModal();
      }
    } else if (callback) {
      complete();
    }
    return !logged;
  }
  // hasCheckOfferedProducts() {
  //   let cart = this;
  //   //
  //   // vérifie
  //   // si les produits offerts ont changés,
  //   // ou n'ont jamais été vu
  //   //
  //   const currentCheckSum = cart.get("offeredProductsCheckSum");
  //   let newCheckSum = "";
  //   cart.getOfferedProducts().each((offeredProduct: OfferedProductModel) => {
  //     newCheckSum +=
  //       offeredProduct.id + "_" + offeredProduct.getQuantity() + "+";
  //   });
  //   cart.setAndSave({ offeredProductsCheckSum: newCheckSum }, { silent: true });
  //   return currentCheckSum === newCheckSum;
  // }

  // hasAllSidedishes() {
  //   const cart = this,
  //     hasDessert = cart.hasProductOfCategorySlug(ECategorySlug.DESSERT),
  //     hasDrink = cart.hasProductOfCategorySlug(ECategorySlug.DRINK),
  //     hasSidedish = cart.hasProductOfCategorySlug(ECategorySlug.SIDEDISH);
  //   return hasDrink && hasDessert && hasSidedish;
  // }
  checkProducts(
    forceMessage: boolean = false,
    errorCallback?: Function,
    successCallback?: Function
  ) {
    const cart = this;

    let firstUnavailableCrossSellingProduct: CartProductModel,
      firstUnavailableCrossSelling: IAccompagnement;
    console.groupCollapsed("checkProducts");

    if (forceMessage) cart.CHECK_PRODUCT_LAST_MESSAGE = "";
    // vérifie la dispo produit
    const goCart = () => {
      app.go("cart");
    };
    const applyErrorCallback = () => {
      console.warn("applyErrorCallback");
      if (cancelConsumablesIfNoStrictlyEmptyCart(applyErrorCallback)) {
        // noop
      } else if (errorCallback) {
        errorCallback();
      } else {
        // comportement par défaut en cas d'erreur
        goCart();
      }
    };
    const applySuccessCallback = () => {
      !!successCallback && successCallback();
    };

    const cancelConsumablesIfNoStrictlyEmptyCart = (callback: Function) => {
      const notStrictlyEmpty = cart.isEmpty(true) && !cart.isEmpty(true, true);
      console.log(
        "test 0 : on vérifie qu'on n'ait pas uniquement des produits complémentaires (sauces, etc)"
      );
      if (notStrictlyEmpty) cart.removeProducts(callback);
      return notStrictlyEmpty;
    };
    //
    //
    const notify = (
      notificationFunction: (
        options: IConfirmOptions,
        callback?: (...args: any) => void
      ) => void,
      m: string,
      confirmCallback: (arg?: any) => void,
      validationButton: string = "",
      cancelButton: string = ""
    ) => {
      if (m === cart.CHECK_PRODUCT_LAST_MESSAGE) return;
      cart.CHECK_PRODUCT_LAST_MESSAGE = m;
      notificationFunction(
        {
          title: app.t(UI_WARNING_TEXT, UI_CONTEXT),
          subtitle: m,
          validation: validationButton,
          cancel: cancelButton,
        },
        confirmCallback
      );
    };
    const confirmNotice = (
      m: string,
      confirmCallback: (...args: any) => void,
      validationButton: string,
      cancelButton: string
    ) => {
      console.log("confirmNotice");
      notify(app.confirm, m, confirmCallback, validationButton, cancelButton);
    };
    const alertNotice = (
      m: string,
      confirmCallback: (...args: any) => void,
      validationButton: string
    ) => {
      notify(app.alert, m, confirmCallback, validationButton);
    };
    //
    const afterConfirmProductCrossSellingMissing = (
      confirm: boolean,
      hideConfirm?: Function
    ) => {
      !!hideConfirm && hideConfirm();
      firstUnavailableCrossSellingProduct?.openProductPageView({
        handleOnClose: () => {
          cart.CHECK_PRODUCT_LAST_MESSAGE = "";
          // if (closeStateAddToCart) {
          doCheck();
          // } else {
          //     applyErrorCallback();
        },
      });
    };
    //
    const afterConfirmProductOfferedMissing = (
      confirm: boolean,
      hideConfirm?: Function
    ) => {
      console.log("confirmProductOfferedMissing", confirm);
      if (confirm) {
        // on affiche le sélecteur
        !!hideConfirm && hideConfirm();
        console.warn("TODO modal reopenFreeProductChoice");
        cart.reopenFreeProductChoice();
        // cart.listenToOnce(modalView, "close", function () {
        //   // on reste en stand by
        //   applyErrorCallback();
        //   // et on ouvre le panier
        //   goCart();
        // });
      } else {
        // on supprime le bon et on ouvre le panier
        cart
          .removeVoucher(
            cart.get(
              (FAVORITE_PRODUCT_OFFERED_PREFIX +
                "id_discount") as _StringKey<ICartData>
            )
          )
          .then(function () {
            !!hideConfirm && hideConfirm();
            // on reste en stand by
            applyErrorCallback();
            // et on ouvre le panier
            goCart();
          });
      }
    };
    //
    const afterConfirmProductUnavailable = (
      confirm: boolean,
      hideConfirm?: Function
    ) => {
      cart.getStore()?.fetchStockoutProduct(() => {
        if (confirm) {
          // vérifie et supprime les produits indisponibles
          cart.removeUnavailableProducts(function () {
            hideConfirm?.();
            doCheck();
          });
        } else {
          hideConfirm?.();
          applyErrorCallback(); //app.go('cart');
        }
      }, doCheck);
    };

    const doCheck = () => {
      let message;

      // test 1 : on vérifie que les cross-selling ne sont pas indisponibles
      console.log(
        "test 1 : on vérifie que les cross-selling ne sont pas indisponibles"
      );
      firstUnavailableCrossSellingProduct = cart
        .getProducts()
        .find((cartProduct) => {
          const accompagnements = cartProduct.get("accompagnements");
          firstUnavailableCrossSelling = accompagnements?.find(
            (a: IAccompagnement) => !!a.visible_error
          );
          return !!firstUnavailableCrossSelling;
        });
      if (firstUnavailableCrossSellingProduct && firstUnavailableCrossSelling) {
        console.warn(
          "test 1 : fail",
          firstUnavailableCrossSellingProduct.id,
          firstUnavailableCrossSelling.id_product
        );
        console.groupEnd();
        const unavailableProduct = app.getProduct(
          firstUnavailableCrossSelling.id_product
        );
        message = app.t(
          "Le produit %s dans la formule %s est actuellement indisponible.",
          "Cart",
          [
            unavailableProduct?.getName(),
            firstUnavailableCrossSellingProduct.getName(),
          ]
        );
        alertNotice(
          message,
          afterConfirmProductCrossSellingMissing,
          app.t("Modifier", CART_TRANSLATION_CONTEXT)
        );
        return false;
      }

      // test 2 : on vérifie que les cross-selling sont ok
      console.log(
        "test 2 : on vérifie que les cross-selling ne sont pas manquant"
      );
      let invalidSidedishes = cart.getInvalidSidedishes();
      if (invalidSidedishes.length > 0) {
        console.warn("test 2 : fail", invalidSidedishes);
        console.groupEnd();
        firstUnavailableCrossSellingProduct = cart.getProduct(
          invalidSidedishes[0].id_cart_product
        ) as CartProductModel;
        message = app.t(
          "Veuillez selectionnez vos accompagnements obligatoires sur le produit %s",
          CART_TRANSLATION_CONTEXT,
          [firstUnavailableCrossSellingProduct.getName()]
        );
        alertNotice(
          message,
          afterConfirmProductCrossSellingMissing,
          app.t("Choisir", CART_TRANSLATION_CONTEXT)
        );
        // TODO
        return false;
      }

      // test 3 : on vérifie le produit préféré offert
      console.log("test 3 : on vérifie le produit préféré offert");
      const discount = cart.getFavoriteProductDiscount();
      if (discount) {
        const hasProductOffered = cart.hasProductOfferedSelected(),
          cartProductOffered = cart.getProductOfferedCartProduct();
        if (
          !hasProductOffered ||
          !cartProductOffered ||
          !cartProductOffered.isAvailable()
        ) {
          const { name = "" } = discount;
          if (cartProductOffered) {
            message = app.t(
              "Le produit %s inclus dans le coupon %s est actuellement indisponible. Veuillez en sélectionner un autre ou retirer le coupon.",
              CART_TRANSLATION_CONTEXT,
              [cartProductOffered.getName(), name]
            );
          } else {
            message = app.t(
              "Le produit inclus dans le coupon %s n'a pas été sélectionné. Veuillez en sélectionner un ou retirer le coupon.",
              CART_TRANSLATION_CONTEXT,
              [name]
            );
          }
          confirmNotice(
            message,
            afterConfirmProductOfferedMissing,
            app.t("Sélectionner", CART_TRANSLATION_CONTEXT),
            app.t("Retirer", CART_TRANSLATION_CONTEXT)
          );
          console.warn("test 3 : fail", hasProductOffered, cartProductOffered);
          console.groupEnd();
          return false;
        }
      }
      // test 4 : on vérifie les produits indisponibles
      console.log("test 4 : on vérifie les produits indisponibles");
      const unavailable_id_products: string[] = cart
        .getProductsIndispo()
        .map((cartProduct) => cartProduct.get("id_product"));
      const unavailable_id_products_unique = uniq(unavailable_id_products);
      const uniqueLength = unavailable_id_products_unique.length;
      if (uniqueLength > 0) {
        console.warn("test 3 : fail", unavailable_id_products_unique);
        const productsNames = unavailable_id_products_unique
            .map((id_product) => {
              return app.getProduct(id_product)?.getName() || "";
            })
            .join(", "),
          store = cart.getStore();
        message = app.t(
          uniqueLength > 1
            ? "Les produits %s sont indisponibles sur la boutique %s et vont être retirés du panier."
            : "Le produit %s est indisponible sur la boutique %s et va être retiré du panier.",
          CART_TRANSLATION_CONTEXT,
          [productsNames, store ? store.getName() : ""]
        );
        alertNotice(
          message,
          afterConfirmProductUnavailable,
          app.t("Voir le panier", CART_TRANSLATION_CONTEXT)
        );
        console.warn("test 4 : fail", message);
        console.groupEnd();
        return false;
      }
      console.info("test ok !");
      console.groupEnd();
      applySuccessCallback();
      return true;
    };

    // test 5 : on vérifie qu'on n'ait pas uniquement des produits complémentaires (sauces, etc)
    if (cancelConsumablesIfNoStrictlyEmptyCart(doCheck)) {
      return false;
    }

    return doCheck();
  }

  removeProducts(callback: Function, collection?: CartProductCollection) {
    const cart = this;
    // supprime tous les produits indisponibles
    if (!collection)
      collection = cart.cartProductCollection as CartProductCollection;
    const deleteProducts = () => {
      const productToDelete = collection?.at(0);
      if (productToDelete) {
        cart.deleteProduct(productToDelete.id as string).then(deleteProducts);
      } else {
        callback();
      }
    };
    deleteProducts();
  }

  removeUnavailableProducts(callback: Function) {
    const cart = this;
    cart.removeProducts(callback, cart.getProductsIndispo());
  }

  getFavoriteProductDiscount(): Discount | undefined {
    const cart = this;
    const favorite_product_offored_id_discount = cart.get(
      (FAVORITE_PRODUCT_OFFERED_PREFIX + "id_discount") as _StringKey<ICartData>
    );
    if (favorite_product_offored_id_discount) {
      const discounts = cart.get("discounts");
      return discounts?.find(function (discount) {
        return (
          favorite_product_offored_id_discount == discount.id_cart_discount
        );
      });
    }
  }
  getProductOfferedCartProduct() {
    const cart = this;
    const id_cart_product = cart.getProductOffered();
    if (id_cart_product) return cart.getProduct(id_cart_product);
  }

  getInvalidSidedishes(
    cross_selling_products_errors: string[] = []
  ): IInvalidSidedish[] {
    //
    // valide que tous les accompagnements obligatoires sont bien setté
    //
    const cart = this,
      invalidSidedishes: IInvalidSidedish[] = [],
      invalidSidedishesProducts = [];
    const pushInvalid = (
      id_cart_product: string,
      id_cross_selling: string | false = false
    ) => {
      invalidSidedishesProducts.push(id_cart_product);
      invalidSidedishes.push({
        id_cart_product: id_cart_product,
        id_cross_selling: id_cross_selling,
      });
    };
    cart.getProducts().each((cartProduct) => {
      const product = cartProduct.getProduct(),
        id_cart_product = cartProduct.id as string;
      if ((cross_selling_products_errors || []).includes(id_cart_product)) {
        pushInvalid(id_cart_product);
      } else if (product && product.isMenu(true)) {
        cartProduct.getSidedishes().each(function (sidedish) {
          if (
            sidedish.isRequired() &&
            (!sidedish.validateSelection() || sidedish.isStockout())
          ) {
            pushInvalid(id_cart_product, sidedish.id as string);
          }
        });
      }
    });
    return invalidSidedishes;
  }
  confirmFreeProductVoucher(callback: Backbone.EventHandler) {
    let cart = this;
    app.confirm(
      {
        title: app.t(UI_WARNING_TEXT, UI_CONTEXT),
        subtitle: app.t(
          "Merci de choisir votre produit préféré",
          CART_TRANSLATION_CONTEXT
        ),
        cancel: app.t("Ignorer", UI_CONTEXT),
      },
      function (success: any) {
        if (success) {
          // on affiche le selecteur
          var modalView = cart.reopenFreeProductChoice();
          cart.listenToOnce(modalView, "close", callback);
        } else {
          // on supprime le bon et on ouvre le panier
          cart
            .removeVoucher(
              cart.get(
                (FAVORITE_PRODUCT_OFFERED_PREFIX +
                  "id_discount") as keyof ICartData
              )
            )
            .then(callback);
        }
      }
    );
  }

  removeStockoutProducts(id_products: any[], callback: { (): void; (): void }) {
    const cart = this;
    const unavailableProducts = cart.getProductsIndispo(),
      cartProducts = cart.getProducts();
    let cartProductToDelete: Array<any> = [];
    const listProducts = () => {
      let id_product = id_products.pop(),
        products;
      if (!!id_product) {
        id_product = id_product.toString();
        products = unavailableProducts.getProductsByUniqueId(id_product);
        if (!products.length) {
          products = cartProducts.getProductsByUniqueId(id_product);
        }
        cartProductToDelete = _.union(cartProductToDelete, products);
        listProducts();
      } else {
        deleteProducts();
      }
    };
    const deleteProducts = () => {
      const productToDelete = cartProductToDelete.pop();
      if (productToDelete) {
        cart.deleteProduct(productToDelete.id).then(function () {
          deleteProducts();
        });
      } else {
        callback();
      }
    };
    listProducts();
  }

  updateProductPaymentRestrictions() {
    let cart = this;
    // met à jour les restrictions produits
    let payment_restrictions: { [id_payment_mode: string]: string[] } = {},
      // la sum permet de détecter rapidement les changements réels de restriction
      // on a un évenement change:payment_restrictions_sum
      // dans CheckoutPaymentMethodView afin d’optimiser les cas généraux
      payment_restrictions_sum = "",
      cartPaymentModes = cart.getPaymentModes(),
      cartPaymentModesIds = cartPaymentModes?.pluck("id_payment_mode") || [],
      availablePaymentModes = 0;

    function testProductPaymentRestrictions(
      product: ProductModel,
      ignore_if_empty?: boolean
    ) {
      if (product && product.hasPaymentRestriction()) {
        const productPaymentRestrictions = product.getPaymentRestrictions();
        productPaymentRestrictions &&
          productPaymentRestrictions.forEach(
            ({
              id_payment_mode,
              force_if_empty,
            }: ProductPaymentRestriction) => {
              const restriction = product.id as string;
              let restrictions = payment_restrictions[id_payment_mode];

              // gestion du cas exceptionnel "force_if_empty"
              if (ignore_if_empty && force_if_empty === "1") return;

              if (restrictions) {
                // si la méthode a déjà des restrictions, on ajoute le produit
                restrictions.push(restriction);
              } else {
                // si la méthode n'a pas encore de restriction, on l'ajoute
                restrictions = payment_restrictions[id_payment_mode] = [
                  restriction,
                ];
              }
              const paymentMode = cart.getPaymentMode(id_payment_mode);
              if (paymentMode) {
                paymentMode.set("restrictions", restrictions);
                payment_restrictions_sum +=
                  "/" + id_payment_mode + ":" + product.id;
              }
            }
          );
      }
    }

    function testCartProductsPaymentRestrictions(
      ignore_if_empty: boolean = false
    ) {
      payment_restrictions = {};
      payment_restrictions_sum = "";

      // pour tous les produits du paiment
      cart.getProducts().each((cartProduct) => {
        let product = cartProduct.getProduct();
        // on vérifie les restrictions du produit
        if (product) {
          testProductPaymentRestrictions(product, ignore_if_empty);
        }
        // et de ses accompagnements
        cartProduct.getAccompagnement().each((accompagnement) => {
          let itemProduct = accompagnement.getProduct();
          if (itemProduct)
            testProductPaymentRestrictions(itemProduct, ignore_if_empty);
        });
      });

      const restrictionModesIds = _(payment_restrictions).keys();
      availablePaymentModes = _.difference(
        cartPaymentModesIds,
        restrictionModesIds
      ).length;
    }

    // test initial
    testCartProductsPaymentRestrictions(false);
    //

    if (availablePaymentModes === 0) {
      // pour le cas exceptionnel ou les restrictions produits bloque tous les modes de paiment
      // on rejoue le test en "ignore_if_empty"
      testCartProductsPaymentRestrictions(true);
    }

    cart.setAndSave({
      payment_restrictions: payment_restrictions,
      payment_restrictions_sum: payment_restrictions_sum,
    });
  }

  getPaymentGroups(inContext?: any) {
    // TODO in Context
    const store = this.getStore();
    return store?.getPaymentGroups() || app.getPaymentGroups();
  }

  getPaymentModes() {
    const store = this.getStore(),
      paymentModes = store ? store.getPaymentModes() : false;
    return paymentModes ? paymentModes : app.getPaymentModes();
  }
  getPaymentMode(id_payment_mode: string) {
    return this.getPaymentGroups()?.getPaymentMode(id_payment_mode);
  }
  getUsedPaymentMode() {
    return paymentMode;
  }
  flush() {
    this.resetLocalStorageItem();
  }
  requireCVC() {
    // Ogone CVC display rules :
    // Total price > 100€ OR
    // Address never used OR never Store used
    const isVAE = this.isVAE();
    let requireCVC = true;
    if (this.getPriceTTC() < app.c<number>("_CVC_CART_MIN_DISPLAY_", 100)) {
      const customer = app.getCustomer(),
        store = this.getStore();
      if (store) {
        if (isVAE) {
          // si l'utilisateur a déjà commandé dans cette boutique
          requireCVC = !customer
            .get("orders_stores")
            ?.includes(store.id as string);
        } else {
          // si l'utilisateur a déjà commandé à cette adresse
          const deliveryAddress = this.getAddressDelivery();
          if (deliveryAddress)
            requireCVC = !customer
              .get("orders_addresses")
              ?.includes(deliveryAddress.id as string);
        }
        // si la boutique est en 3DS obligatoire
        if (store.get("force_card_security")) {
          requireCVC = true;
        }
      }
    }
    return requireCVC;
  }
  doPaymentCall(
    orderOptions: any,
    callback: (res?: RollingStartError) => void,
    tooling: ToolingDoPaymentCall
  ) {
    const cart = this;
    let defaultErrorMessage = app.t(
      "Une erreur est survenue sur votre paiement",
      "Payment"
    ); // erreur par défaut
    const errorMessage: any = {
      title: app.t("Sécurité", UI_CONTEXT),
      subtitle: defaultErrorMessage, // erreur par défaut
    };

    const triggerError = (message?: string, datas?: any) => {
      callback(
        new RollingStartError(
          message || errorMessage.subtitle,
          datas,
          RollingStartErrorCode.PAYMENT_ERROR
        )
      );
    };

    const RecaptchaRetryOrder = () => {
      tooling
        .reCaptcha("order")
        .then((token: string) => {
          //worf
          orderOptions.token = token;
          cart.doPaymentCall(orderOptions, callback, tooling);
        })
        .catch((err: any) => {
          triggerError(
            app.t(
              "Une erreur est survenue, liée à ReCaptcha, vérifiez peut-être votre logiciel antipub.",
              "Account"
            )
          );
          app.error("ReCaptchaError", err);
        });
    };

    const ResetCVC = () => {
      const usedPaymentMode = cart.getUsedPaymentMode();
      if (!!usedPaymentMode?.getOption("cvc", "", true)) {
        usedPaymentMode.setOption("cvc", "", true);
      }
    };

    cart
      .ApiRequest("ToolsPaymentMethod", orderOptions, {
        method: "doPayment",
        id_cart: cart.id,
      })
      .then((data: any) => {
        const status = data.status;
        if (data.error) {
          errorMessage.subtitle = defaultErrorMessage = data.error;
        }
        if (status === 1) {
          cart.flush();
          window.location = data.redirect;
          return;
        } else if (status === 0) {
          // noop
          ResetCVC();
        } else if (status <= -1) {
          if (data.type) {
            switch (data.type) {
              case "NeedRecaptcha":
                RecaptchaRetryOrder();
                return;
              case "AcceptSmsConfirmationCode":
                errorMessage.cancel = app.t("Modifier", UI_CONTEXT);
                app.confirm(errorMessage, function (confirm: any) {
                  if (confirm) {
                    orderOptions.sms_accept = 1;
                    RecaptchaRetryOrder();
                  } else {
                    triggerError();
                  }
                });
                return;
              case "InvalidSmsConfirmationCode":
              case "NeedSmsConfirmationCode":
                const RequireSmsConfirmation = () => {
                  cart.requireSmsConfirmationCode(
                    { ...data, ...errorMessage },
                    (sms_code, requireNewCode) => {
                      if (requireNewCode) {
                        delete orderOptions.sms_accept;
                        delete orderOptions.sms_code;
                        RecaptchaRetryOrder();
                      } else if (!sms_code) {
                        callback();
                      } else {
                        orderOptions.sms_code = sms_code;
                        RecaptchaRetryOrder();
                      }
                    }
                  );
                };
                if (data.new_code_available) {
                  // en cas d'échec multiple, on relance un nouveau code
                  app.confirm(errorMessage, function (confirm: any) {
                    if (confirm) {
                      delete orderOptions.sms_code;
                      errorMessage.subtitle = data.error;
                      RequireSmsConfirmation();
                    } else {
                      triggerError();
                    }
                  });
                } else {
                  RequireSmsConfirmation();
                }

                return;
              case "SubmitForm":
                const { action, method, vars } = data.form;
                const formElement = document.createElement("form");
                formElement.setAttribute("action", action);
                formElement.setAttribute("method", method);
                Object.keys(vars).forEach((key) => {
                  const input = document.createElement("input");
                  input.setAttribute("type", "hidden");
                  input.setAttribute("name", key);
                  input.setAttribute("value", vars[key]);
                  formElement.appendChild(input);
                });
                formElement.style.display = "none";
                document.body.appendChild(formElement);
                formElement.submit();
                return;
              case "Redirect":
                return (window.location = data.redirect);
              case "Print":
                // TODO: print
                try {
                  const printValue = atob(data.print.content);
                  callback();
                  cart.trigger("doPaymentPrint", printValue);
                } catch (e) {
                  triggerError();
                }
                // return app.$body.append(atob(data.print.content));
                return "";
              case "CrossSellingError":
                // dans tous les cas, on mets à jour les ruptures de stock
                cart.getStore()?.fetchStockoutProduct();

                if (
                  !cart.hasRequiredSidedishes(
                    data.cross_selling_products_errors
                  )
                ) {
                  // formules avec accompagnements obligatoires
                  cart.alertRequiredSidedishes();
                  cart.trigger("doPaymentError");
                } else {
                  this.once("sync", () => {
                    if (!cart.hasRequiredSidedishes()) {
                      cart.alertRequiredSidedishes();
                      cart.trigger("doPaymentError");
                    } else {
                      triggerError(data.error);
                    }
                  });
                  cart.remoteFetch();
                }
                return;
              case "StockoutProductError":
                const ignoreAlertUnavailable = () => {
                  cart.setAndSave("alertUnavailable", false);
                };
                const onSyncStockout = (c: any) => {
                  if (!cart.get("alertUnavailable")) {
                    let message = data.error,
                      baseMessage =
                        data.stockout_products.length > 1
                          ? "Souhaitez-vous les retirer du panier ou les vérifier ?"
                          : "Souhaitez-vous retirer le produit du panier ?";
                    message +=
                      "<br/>" + app.t(baseMessage, CART_TRANSLATION_CONTEXT);
                    app.confirm(
                      {
                        title: app.t("Attention", "UI"),
                        subtitle: message,
                        validation: app.t("Retirer", CART_TRANSLATION_CONTEXT),
                        cancel: app.t("Vérifier", CART_TRANSLATION_CONTEXT),
                      },
                      (confirm: any) => {
                        if (confirm) {
                          cart.removeStockoutProducts(
                            data.stockout_products,
                            () => {
                              triggerError();
                            }
                          );
                        } else {
                          app.go("cart");
                          triggerError();
                        }
                      }
                    );
                  } else {
                    triggerError();
                  }
                  ignoreAlertUnavailable();
                };
                ignoreAlertUnavailable();
                cart.once("sync error", onSyncStockout);
                cart.remoteFetch();
                return;
              default:
                ResetCVC();
              case "CartError":
                cart.remoteFetch();
                break;
              case "CardError":
                errorMessage.subtitle = app.t(
                  "Veuillez selectionner une carte bancaire",
                  "Payment"
                );
                break;
              case "CustomerMissingField":
                errorMessage.subtitle = data.error;
                // reduxStore.dispatch(setOpenCustomerMissingFields(true));
                break;
              case "CVCError":
                const cvcLength = paymentMode?.getCvcLength();
                return tooling.OpenCVCModal(
                  (cvc) => {
                    if (cvc) {
                      orderOptions.params = {
                        ...orderOptions.params,
                        ...{ cvc },
                      };
                      cart.getUsedPaymentMode()?.setOption("cvc", cvc, true);
                      RecaptchaRetryOrder();
                    } else {
                      callback();
                    }
                  },
                  { cvcLength, cvc: "" }
                );
              case "InvalidReCaptcha":
                triggerError();
                return app.alertReCaptcha(data.error);
              case "FavoriteProductMissingError":
                return this.confirmFreeProductVoucher(callback);
              case "PaymentError":
                ResetCVC();
              // noop
            }
          }
        } else {
          app.error("doPaymentException", data);
        }
        triggerError(defaultErrorMessage);
      })
      .catch((jqXHR: any) => {
        let status = "";
        const o = JSON.parse(JSON.stringify(orderOptions));
        if (o && o.params && o.params.cvc) {
          // CVC obfuscation in error log.
          o.params.cvc = "XXX";
        }
        app.error("doPaymentError", {
          status: status,
          error: JSON.parse(JSON.stringify(jqXHR)),
          options: o,
          cart: cart.toJSON(),
        });
        triggerError(defaultErrorMessage);
      });
  }
  doPayment(
    orderOptions: {
      phone: string;
      amountReduce: number;
      token: string;
      informations?: string;
      useasphonecustomer?: boolean;
    },
    callback: (res?: RollingStartError) => void,
    tooling: ToolingDoPaymentCall
  ) {
    const cart = this;
    const pm = paymentMode || paymentMethod;

    if (!pm) {
      app.trigger("paymentModeRequirement", null);
      return callback();
    }

    orderOptions = { ...orderOptions, ...{ ...pm.getOptions() } };

    function pmCallback(success: any) {
      if (success) {
        cart.doPayment(orderOptions, callback, tooling);
      } else {
        callback();
      }
    }

    if (!pm.validateBeforeSubmit(pmCallback)) {
      return;
    }

    if (app.getCustomer().requireMissingFields()) {
      callback();
      reduxStore.dispatch(
        setOpenAuthModel({
          modalOpen: true,
          step: EAuthentificationStep.MISSING_FIELDS,
        })
      );
      return;
    }
    if (isUndefined(orderOptions.phone) || !orderOptions.phone) {
      callback();
      app.alert({
        title: app.t("Champs manquant", CART_TRANSLATION_CONTEXT),
        subtitle: app.t(
          "Veuillez saisir votre téléphone",
          CART_TRANSLATION_CONTEXT
        ),
      });
      return;
    }

    if (!isUndefined(cart.amountReduce)) {
      orderOptions.amountReduce = cart.amountReduce;
    }
    if (!!cart?.token) {
      orderOptions.token = cart.token;
    }
    cart.token = "";
    if (cart.productCanBeOffered() && !cart.hasProductOfferedSelected()) {
      //
      // si le processus a été interompu, on lui redemande
      // afin d'éviter au maximum le burn de coupon non-utilisé
      //
      cart.confirmFreeProductVoucher(function () {
        cart.doPayment(orderOptions, callback, tooling);
      });
      return;
    }
    if (cart.isVAD()) {
      const address = cart.getAddressDelivery();
      if (address?.isFallback()) {
        address.set("attach_to_cart", true);
        address.once("sync", () => {
          cart.doPayment(orderOptions, callback, tooling);
        });
        address.save();
        console.warn("address fallback", address.toJSON());
        return;
      }
    }

    cart.doPaymentCall(orderOptions, callback, tooling);
  }

  openProduct(
    id_product: string,
    options?: {
      isOfferedProduct?: boolean;
      onSubmit?: (options: {
        id_product: string;
        sidedish: ICartProductSidedish;
      }) => void;
    }
  ) {
    // app.displayModal(({ onClose }) => (
    //   <ProductPageView id_product={id_product} onClose={onClose} />
    // ));
    reduxStore.dispatch(
      cartSlice.actions.openProductPage({
        id_product,
        ...options,
      })
    );
  }
}

CartModel.prototype.idAttribute = "id_cart";
CartModel.prototype.name = "CartModel";
CartModel.prototype.country_based = true;
CartModel.prototype.translated = false;
CartModel.prototype.url = function () {
  return `/cart/${this.get("id_cart") || "current"}`;
};
CartModel.prototype.route = "apiCartModel";

export default CartModel;
