import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { IFrontController } from "@src/interface/HybridRouting";

export const hybridRoutingAdapter = createEntityAdapter<IFrontController>({
  selectId: (controller) => controller.id_front_controller || "",
});

export const slice = createSlice({
  name: "hybridRouting",
  initialState: hybridRoutingAdapter.getInitialState(),
  reducers: {
    setFrontController: hybridRoutingAdapter.setAll,
  },
});

const reducer = slice.reducer;

export const { setFrontController } = slice.actions;
export default reducer;
