import clsx from "clsx";
import styled, { css, keyframes } from "styled-components";
import { TypographyScale } from "../Typography/Typography.styles";
import { IButtonProps } from "./Button";
import { defaultTheme, IThemeMode } from "../../Themes/defaultTheme";
import { hexToRGB } from "../../Utils/font";
import { IButtonContextState } from "./Button.context";

type IButtonRootProps = Omit<IButtonProps, "loading" | "theme"> & {
  $mode?: IThemeMode;
  $loading?: boolean;
} & IButtonContextState;

export type ButtonTheme = {
  ctaBtn: CtaButtonTheme;
  submitBtn: SubmitButtonTheme;
};

export type CtaButtonTheme = {
  main: IButtonStyleConfig;
  champagne: IButtonStyleConfig;
  champagne_light: IButtonStyleConfig;
  mono_light: IButtonStyleConfig;
  mono_dark: IButtonStyleConfig;
};
export type SubmitButtonTheme = {
  primary: IButtonStyleConfig;
  secondary: IButtonStyleConfig;
};

export interface IButtonStyleConfig {
  color: string;
  background: string;
  border: string;
  hoverColor?: string;
  hoverBg?: string;
  hoverBorder?: string;
  activeBg?: string;
  activeBorder?: string;
  disabledColor?: string;
  disabledBg?: string;
  disabledBorder?: string;
}

const $transparent = "transparent";
const {
  color: {
    black: $black,
    champagne: $champagne,
    champagne3: $champagne3,
    champagne4: $champagne4,
    champagne7: $champagne7,
    disabled: $disabled,
    greyTextDisabled: $greyTextDisabled,
    greyText: $greyText,
    greyText2: $greyText2,
    white: $white,
    placeholder: $placeholder,
  },
} = defaultTheme;

const afterSpin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const ButtonRoot = styled.button.attrs<
  IButtonRootProps,
  IButtonRootProps
>(({ $loading, ...props }) => {
  return {
    ...props,
    className: clsx("ro-button", {
      active: props.active,
      loading: $loading,
    }),
    $loading: $loading,
  };
})(
  (props: IButtonRootProps) => {
    const {
      color = "primary",
      variant = "main",
      rounded = true,
      textTransform,
      fullWidth,
      size = "default",
      type,
      theme,
      $mode,
      align = "center",
      customize = {},
      overrideButtonTheme,
    } = props;
    const defaultButtonTheme =
      theme.mode === "dark" || $mode === "dark"
        ? buttonDarkTheme
        : buttonDefaultTheme;

    const buttonTheme: ButtonTheme = defaultButtonTheme;
    const mapSubmitButtons = buttonTheme.submitBtn || SUBMIT_DEFAULT_THEME;
    const mapCTAButtons = buttonTheme.ctaBtn || CTA_DEFAULT_THEME;

    const buttonTypeIsSubmit: boolean = type === "submit" || type === "reset";

    const CTAButtonVariant = (
      variant === "mono" ? `${variant}_${$mode}` : variant
    ) as keyof CtaButtonTheme;
    const $buttonStyle: IButtonStyleConfig = buttonTypeIsSubmit
      ? // @ts-ignore
        mapSubmitButtons[color] || mapSubmitButtons.primary
      : mapCTAButtons[CTAButtonVariant] || mapCTAButtons.main;

    /** Default style */
    const $background = customize.background || $buttonStyle.background;
    const $borderColor = customize.border || $buttonStyle.border;

    /** Active style */
    const $activeBg = customize.activeBg || $buttonStyle.activeBg || "#93826f";
    const $activeBorder =
      customize.activeBorder || $buttonStyle.activeBorder || "";

    /** Hover style */
    const $hoverBg = customize.hoverBg || $buttonStyle.hoverBg || "#93826f";
    const $hoverColor =
      customize.hoverColor || $buttonStyle.hoverColor || undefined;
    const $hoverBorder =
      customize.hoverBorder || $buttonStyle.hoverBorder || $borderColor;

    /** Disabled style */
    const $disabledBg = customize.disabledBg || $buttonStyle.disabledBg || "";
    const $disabledColor =
      customize.disabledColor || $buttonStyle.disabledColor || "";
    const $disabledBorder =
      customize.disabledBorder || $buttonStyle.disabledBorder || "";
    const $color = customize.color || $buttonStyle.color;

    const paddingPerSize: Record<
      NonNullable<IButtonRootProps["size"]>,
      string | number
    > = {
      "extra-large": "10px 32px",
      default: "10px 24px",
      small: "10px 24px",
    };

    return {
      position: "relative",
      display: "inline-flex",
      alignItems: "center",
      gap: 16,
      justifyContent: align,
      height: size === "small" ? 36 : buttonTypeIsSubmit ? "48px" : "40px",
      width: fullWidth ? "100%" : undefined,
      padding: paddingPerSize[size],
      border: `1px solid ${$borderColor}`,
      borderRadius: rounded ? "40px" : "0px",
      textTransform: textTransform || undefined,
      color: $color,
      transition:
        "background-color 0.2s ease, color 0.2s ease, border-color 0.2s ease",
      textDecoration: "none",
      background: customize.bg || $background,
      cursor: "pointer",
      userSelect: "none",
      overflow: "hidden",
      ...TypographyScale["button"],
      ["&:hover, &.hover"]: {
        background: $hoverBg,
        color: $hoverColor,
        borderColor: $hoverBorder,
      },
      ["&.active"]: {
        background: $activeBg,
        borderColor: $activeBorder,
      },
      ["&.loading"]: {
        pointerEvents: "none",
        ["> span"]: {
          transform: "translateY(-200%)",
        },
        ["> svg"]: {
          transform: "translateY(-32px)",
        },
        [".button-spinner"]: {
          opacity: 1,
          transform: "translate3d(-50%, -50%, 0) scale(1)",
        },
        ["&::after"]: {
          opacity: 1,
          transform: "scale(1)",
        },
      },
      ["&[disabled]:not(.loading)"]: {
        background: customize.disabledBg || $disabledBg,
        color: $disabledColor,
        borderColor: customize.disabledBg || $disabledBorder,
        cursor: "default",
      },
      ["&::after"]: {
        position: "absolute",
        content: "''",
        display: "block",
        top: 0, //"50%",
        left: 0, //"50%",
        bottom: 0, //"50%",
        right: 0, //"50%",
        width: "16px",
        height: "16px",
        margin: "auto",
        border: "2px solid hsla(0, 0%, 100%, 0.1)",
        borderTopColor: $color,
        borderRadius: "50%",
        transform: "scale(0)",
        transition: "all .3s cubic-bezier(.645,.045,.355,1)",
        opacity: 0,
      },
      ["> span"]: {
        display: "inline-block",
        position: "relative",
        marginTop: "-2px",
        transition: "0.2s ease",
      },
    };
  },
  css`
    &.loading {
      &::after {
        animation: ${afterSpin} 1s linear infinite;
      }
    }
  `
);

const SUBMIT_DEFAULT_THEME: SubmitButtonTheme = {
  primary: {
    color: $white,
    background: $champagne,
    border: $champagne,
    hoverBg: $champagne4,
    hoverBorder: $champagne4,
    activeBg: $champagne4,
    activeBorder: $champagne4,
    disabledColor: $champagne,
    disabledBg: $disabled,
    disabledBorder: $disabled,
  },
  secondary: {
    color: $champagne7,
    background: $transparent,
    border: "#d8d8d8",
    hoverBg: $transparent,
    hoverBorder: $champagne7,
    activeBg: $transparent,
    activeBorder: $champagne7,
    disabledColor: $greyText,
    disabledBorder: "#d8d8d8",
  },
};

export const CTA_DEFAULT_THEME: CtaButtonTheme = {
  main: {
    color: $white,
    background: $champagne7,
    border: $champagne7,
    hoverBg: "#4b4b4b",
    hoverBorder: "#4b4b4b",
    activeBg: "#313131",
    disabledBg: "#cccccc",
    disabledBorder: "#cccccc",
    disabledColor: $greyTextDisabled,
  },
  champagne: {
    color: $white,
    background: $champagne3,
    border: $champagne3,
    hoverBg: $champagne4,
    activeBg: $champagne3,
    disabledBg: "#D7CDBE",
    disabledBorder: "#D7CDBE",
  },
  champagne_light: {
    color: $white,
    background: $champagne3,
    border: $champagne3,
    hoverBg: $champagne4,
    activeBg: $champagne3,
    disabledBg: "#D7CDBE",
    disabledBorder: "#D7CDBE",
  },
  mono_dark: {
    color: $white,
    background: $transparent,
    border: $white,
    hoverColor: $champagne7,
    hoverBg: $white,
    hoverBorder: $white,
    activeBg: $champagne7,
    activeBorder: $white,
    disabledBg: $transparent,
    disabledBorder: hexToRGB($white, 0.5),
  },
  mono_light: {
    color: $champagne7,
    background: $transparent,
    border: $champagne7,
    hoverColor: $white,
    hoverBg: $champagne7,
    hoverBorder: $champagne7,
    activeBg: $white,
    activeBorder: $white,
    disabledBg: $transparent,
    disabledBorder: hexToRGB($champagne7, 0.5),
  },
};

export const buttonDefaultTheme: ButtonTheme = {
  submitBtn: SUBMIT_DEFAULT_THEME,
  ctaBtn: CTA_DEFAULT_THEME,
};
export const buttonDarkTheme: ButtonTheme = {
  submitBtn: SUBMIT_DEFAULT_THEME,
  ctaBtn: {
    ...CTA_DEFAULT_THEME,
    // mono_dark: {
    //   color: $white,
    //   background: $transparent,
    //   border: $white,
    //   hoverColor: $white,
    //   hoverBg: $black,
    //   hoverBorder: $black,
    //   activeBg: $champagne7,
    //   activeBorder: $champagne7,
    //   disabledBg: $transparent,
    //   disabledBorder: $white,
    // },
  },
};

export const signInButtonTheme: ButtonTheme = {
  submitBtn: SUBMIT_DEFAULT_THEME,
  ctaBtn: {
    ...CTA_DEFAULT_THEME,
  },
};
