import CartModel from "./CartModel";
import { ICartData } from "@src/interface/Cart";
import { mainCartInstance } from "../provider/ModelProvider";

//
// Model Cart
//

// var SLUG_CONSUMABLES = ['baguettes','sauce_sucree','sauce_salee','gingembre','wasabi'];
//
// WARNING > "offored" (et non offered) est le maintien d’une coquille,
// présente dans le schéma du modèle de données.
// la coquille a été corrigée dans tous le FRONT sauf ici — où elle est maintenue volontairement.
// let mainCart: CartModel;

const CartModelModule = {
  /* getConstructor(attributes?: ICartData) {
    return new CartModel(attributes);
  }, */
  getMainInstant(attributes?: ICartData) {
    return mainCartInstance.setData(attributes || {});
  },
  getInstance(attributes?: ICartData) {
    return new CartModel(attributes, { newInstance: true });
  },
  requireDeliveryOptions(
    type?: string,
    cart?: CartModel,
    force?: boolean,
    callback?: () => void,
    options?: any
  ) {
    // TODO: display modal
    /* if (!cart) {
      cart = app.getCart();
    }
    var modalView = new AddressAutocompleteView(
      _.defaults(options || {}, {
        theme: "modal",
        type: type,
        autoSubmit: true,
        cart: cart,
      })
    );
    modalView.on("close", function () {
      modalView.off();
      var complete = modalView.isComplete();
      if (!complete && force) {
        cart.requireDeliveryOptions(type, cart, force, callback);
      } else if (_.isFunction(callback)) {
        // en cas de fin de l'appel et si il y a un callback,
        // on l'execute
        callback(complete);
      }
    });
    // évite d'afficher une croix si c'est pour réouvrir la même modal
    if (force) {
      modalView.$(".btn-close").remove();
    }
    app.displayModal(modalView); */
  },
};

export default CartModelModule;
