import React, { ButtonHTMLAttributes, ReactNode } from "react";
import { useRollingStartNavigation } from "@src/lib/rolling-start-navigation";
import { Link, navigate } from "gatsby";
import { appConfig } from "@src/config/appConfig";
import { TRollingStartLink } from "design-system/src/Core/Link/Link";
import clsx from "clsx";

const LinkWrapper: TRollingStartLink = (props) => {
  const { sushishopBaseUrl } = appConfig;
  const { isRollingStart } = useRollingStartNavigation();
  const {
    children,
    ref,
    to,
    href = "",
    style,
    onClick,
    type,
    className,
    ...anchorRest
  } = props;
  const re = new RegExp(`^${sushishopBaseUrl}`, "i");
  const route = (to || href).replace(re, "");
  const javascript_uri = /^javascript:(.*)/.exec(route);
  const isInternal = /^\/(?!\/)/.test(route);
  // const useRollingStartRouting = isRollingStart(route);
  // console.log("useRollingStartRouting-", sushishopBaseUrl, route, useRollingStartRouting);
  // if (isInternal && useRollingStartRouting) {
  //   return (
  //     <Link
  //       {...{
  //         to: route,
  //         style,
  //       }}
  //       {...anchorRest}
  //       className={clsx(className)}
  //     >
  //       {children}
  //     </Link>
  //   );
  // }
  // const javascript_uri = /^javascript:(.*)/.exec(route);
  // let evalJs: Function;
  // if (!route || javascript_uri) {
  //   evalJs = () => {
  //     eval(javascript_uri ? javascript_uri[1] : "()=>{}");
  //   };
  //   const { target, ...buttonRest } = {
  //     ...anchorRest,
  //   };
  //   return (
  //     <button
  //       {...buttonRest}
  //       style={{
  //         background: "transparent",
  //         border: "none",
  //         textAlign: "inherit",
  //         padding: 0,
  //         ...style,
  //       }}
  //       className={className}
  //       type="button"
  //       onClick={(event) => (onClick || evalJs)(event)}
  //     >
  //       {children}
  //     </button>
  //   );
  // }

  const absoluteUrl = javascript_uri
    ? "#"
    : (isInternal ? sushishopBaseUrl : "") + route;

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    const useRollingStartRouting = isRollingStart(route);
    if (isInternal && useRollingStartRouting) {
      navigate(route);
    } else {
      if (!route || javascript_uri) {
        if (javascript_uri) {
          const evalJs: Function = () => {
            eval(javascript_uri ? javascript_uri[1] : "()=>{}");
          };
          evalJs();
        } else {
          onClick?.(e);
        }
      } else {
        window.location.href = absoluteUrl;
      }
    }
  };
  return (
    <a
      {...{
        href: absoluteUrl,
        style,
        ...anchorRest,
        onClick: handleClick,
        className: clsx(className),
      }}
    >
      {children}
    </a>
  );
};

export default LinkWrapper;
