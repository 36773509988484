import React, { CSSProperties, ReactNode } from "react";
import Icon from "../../../Foundation/Icon/Icon";
import {
  _ProductDetailsClose,
  _ProductDetailsInfoContainer,
} from "../ProductDetails.styled";
import {
  _ProductComposeModalFooter,
  _ProductComposeModalRoot,
} from "./ProductComposeModal.styled";

interface IProductComposeModalProps {
  onClose?: () => void;
  children?: ReactNode;
  footer?: ReactNode;
  style?: CSSProperties;
}

const ProductComposeModal = (props: IProductComposeModalProps) => {
  const { onClose, children, footer, style } = props;
  return (
    <_ProductComposeModalRoot>
      <_ProductDetailsClose onClick={onClose}>
        <Icon icon="cross_close" size={24} color={"#1B1A16"} />
      </_ProductDetailsClose>
      <_ProductDetailsInfoContainer
        style={{
          flex: 1,
          overflow: "scroll",
          overflowX: "hidden",
          ...style,
        }}
      >
        {children}
      </_ProductDetailsInfoContainer>
      <_ProductComposeModalFooter>{footer}</_ProductComposeModalFooter>
    </_ProductComposeModalRoot>
  );
};

export default ProductComposeModal;
