import React, { lazy, ReactNode, Suspense } from "react";
import "./dev.scss";
import "./DefaultLayout.styles.scss";
import GridGlobalCss from "design-system/src/Components/Grid/GridGlobalCss";
import { IDefaultHeaderProps } from "./DefaultHeader/DefaultHeader";
// import { SuspenseHelper } from "@src/components/general/SuspenseHelper/SuspenseHelper";
import { DrawerGlobalCss } from "design-system/src/Core/Drawer/Drawer.styled";
// import SuspenseAfterSetup from "@src/lib/AfterSetup/SuspenseAfterSetup";
import DeliveryOptionsProvider from "@src/layout/DefaultLayout/DefaultHeader/DeliveryOptionsProvider/DeliveryOptionsProvider";
import AuthDrawerProvider from "@src/backbone/provider/AuthDrawerProvider/AuthDrawerProvider";
import useDefaultLayoutDatas from "@src/layout/DefaultLayout/useDefaultLayoutDatas";
import SuspenseAfterInteraction from "@src/lib/AfterInteraction/SuspenseAfterInteraction";
import { InnerLayoutProps } from "@src/LayoutProvider";

const AlertNotice = lazy(() => import("@src/lib/alert-notice/AlertNotice"));
const OrderTracking = lazy(
  () =>
    import(
      "@src/layout/DefaultLayout/DefaultHeader/OrderTracking/OrderTracking"
    )
);
const NewsletterModal = lazy(
  () => import("@src/components/feedback/NewsletterModal/NewsletterModal")
);

const ProductModal = lazy(
  () => import("@src/components/page-content/ProductModal/ProductModal")
);
const SMSModeProvider = lazy(
  () => import("@src/backbone/provider/SMSModeProvider")
);

const CartDrawer = lazy(
  () => import("@src/components/page-content/Cart/CartDrawer")
);
const DefaultFooter = lazy(() => import("./DefaultFooter/DefaultFooter"));
const DefaultHeader = lazy(() => import("./DefaultHeader/DefaultHeader"));

const GATSBY_ENV_LEGACY = !!process.env.GATSBY_ENV_LEGACY;

export interface IDefaultLayoutProps extends InnerLayoutProps {
  children: ReactNode;
  wrapper?: IDefaultHeaderProps["wrapper"];
}

const displayNav = !GATSBY_ENV_LEGACY;

const DefaultLayout: React.FC<IDefaultLayoutProps> = (props) => {
  const { children, wrapper, isModal } = props;
  const defaultLayoutDatas = useDefaultLayoutDatas();

  return (
    <>
      <div>
        <GridGlobalCss />
        <DrawerGlobalCss />
        {displayNav && (
          <Suspense fallback={null}>
            <DefaultHeader
              wrapper={wrapper}
              datas={defaultLayoutDatas}
              isModal={isModal}
            />
          </Suspense>
        )}

        <DeliveryOptionsProvider />

        <AuthDrawerProvider />

        <SuspenseAfterInteraction>
          <Suspense fallback={<div></div>}>
            <CartDrawer />
          </Suspense>
          <Suspense fallback={<></>}>
            <AlertNotice />
          </Suspense>
          <Suspense fallback={<></>}>
            <OrderTracking />
          </Suspense>
          <Suspense fallback={<></>}>
            <ProductModal />
          </Suspense>
          <Suspense fallback={<></>}>
            <SMSModeProvider />
          </Suspense>
          <Suspense fallback={<></>}>
            <NewsletterModal />
          </Suspense>
        </SuspenseAfterInteraction>

        <main className={"layout-content"}>
          <div className="layout-main">{children}</div>
        </main>
        {displayNav && (
          <footer className="layout-footer">
            <SuspenseAfterInteraction>
              <Suspense fallback={<div></div>}>
                <DefaultFooter datas={defaultLayoutDatas} />
              </Suspense>
            </SuspenseAfterInteraction>
          </footer>
        )}
      </div>
    </>
  );
};
DefaultLayout.displayName = "DefaultLayout";
export default React.memo(DefaultLayout);
