//
import LocalStorageCollection from "../prototypes/LocalStorageCollection";
import IngredientModel from "./IngredientModel";
// Collection Currency
//

class IngredientCollection extends LocalStorageCollection<IngredientModel> {
  backbonePattern = "Collection";
  name = "IngredientCollection";
  route = "apiIngredientCollection";
  idAttribute = "id_ingredient";
  model = IngredientModel;
  initialize(
    models?: Record<string, any>[] | IngredientModel[],
    options?: any
  ): void {
    // ObjectCollection constructor heritage
    super.initialize.apply(this, [models, options]);
  }
}

IngredientCollection.prototype.name = "IngredientCollection";
IngredientCollection.prototype.model = IngredientModel;

export default IngredientCollection;
