export enum RollingStartErrorCode {
  DEFAULT_ERROR = 0,
  PAYMENT_ERROR = 1,
}
export default class RollingStartError<D = any> extends Error {
  datas?: any;
  code: RollingStartErrorCode;
  constructor(
    message: string,
    datas?: D,
    code: RollingStartErrorCode = RollingStartErrorCode.DEFAULT_ERROR
  ) {
    super(message);
    this.name = "RollingStartError";
    this.datas = datas;
    this.code = code;
  }
}
