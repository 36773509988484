//
// Model Customer
//

import LocalStorageModel from "../prototypes/LocalStorageModel";
import _ from "underscore";
import { app } from "./AppModel";
import ApiRequest from "../prototypes/ApiRequest";
import OrderCollection from "./OrderCollection";
import formatPrice from "../prototypes/formatPrice";
import moment from "moment";
import AdvantageGroupCollection from "./AdvantageGroupCollection";
import AdvantageCollection from "./AdvantageCollection";
import AdvantageGroupModel from "./AdvantageGroupModel";
import { IconName } from "design-system/src/lib/svgIcon/components";
import {
  CustomerCard,
  CustomerData,
  CustomerNeolaneParams,
  ECustomerGroup,
} from "@src/interface/Customer";
import AddressCollection from "@src/backbone/model/AddressCollection";
import { ReCaptchaAction } from "@src/hooks/useLogin/useReCaptcha";
import reduxStore from "@src/store";
import { customerSlice } from "@src/store/reducers/CustomerSlice";
import {
  GATSBY_CONF_CUSTOMER_ENABLE_TAX_IDENTIFICATION_NUMBER,
  GATSBY_CONF_MY_SUSHI_SHOP_ORDER_BONUS_COUNT,
  GATSBY_ENV_COUNTRY_CODE,
} from "@src/utils/constants";
import { AccountLevel } from "design-system/src/Components/DrawerContent/MyAccountContent/AccountDetails/AccountDetails.type";
import { IPostRegisterArgs } from "@src/services/rollingStartApi/endpoints/customer.endpoints";

const hobbiesSlugs = [
    "art",
    "musique",
    "shopping",
    "cinema",
    "series",
    "sport",
  ],
  hobbiesNames = ["Art", "Musique", "Shopping", "Cinéma", "Séries", "Sport"];
//hobbiesPolygons =  ['1','2','3','1','2','3'];

const statusList = ["Ichi", "Ni", "San"];
const statusIconList: IconName[] = [
  "logo_comein_ichi",
  "logo_comein_ni",
  "logo_comein_san",
];

// let orderCollection: OrderCollection | undefined = undefined;

function UndefinedValueTranslation() {
  return app.t("Non renseigné", "Account");
}

class CustomerModel extends LocalStorageModel<CustomerData> {
  name = "CustomerModel";
  static STATUS_LIST: string[];

  route = "apiCustomerModel";
  idAttribute = "id_customer";
  translated = false;
  privateApi = true;
  ordersFull: OrderCollection = new OrderCollection([]);
  initialize(attributes?: any, options?: any): void {
    const customer = this;
    // ObjectModel constructor heritage
    super.initialize.apply(customer, [attributes, options]);

    customer.on("change", customer.onChange);
    customer.on("change:orders", customer.onChangeOrder);
    customer.on("change:id_customer", customer.customerChange);
    customer.on("change:advantageGroups", customer.onChangeAdvantageGroups);

    customer.on("sync", (cust) => {
      if (cust.attributes) {
        reduxStore.dispatch(
          customerSlice.actions.setCurrentCustomer(cust.attributes)
        );
      }
    });
    // //
    // let customerState = reduxStore.getState().customer.current;
    // reduxStore.subscribe(() => {
    //   let newState = reduxStore.getState().customer.current;
    //   if (JSON.stringify(newState) != JSON.stringify(customerState)) {
    //     // update state from redux
    //     customerState = newState;
    //     customer.set(customerState);
    //   }
    // });
    customer.listenTo(app, "applyLogout", customer.logout);
  }

  ApiRequest(
    type: string,
    data?:
      | { emailList?: any; id_product?: any; id_card?: any }
      | any
      | undefined,
    urlOptions?: any,
    options?: any
  ) {
    return this.pipeAppHttpHeaders(ApiRequest(type, data, urlOptions, options));
  }

  getCaptainWalletUrl() {
    return this.get("captainWalletUrl");
  }

  getDiscount() {
    return this.get("discounts");
  }

  async loadAllOrders() {
    const model = this,
      ordersFull = new OrderCollection();
    function onSync() {
      model.ordersFull = ordersFull;
      app.trigger("allOrderLoad");
    }
    ordersFull.once("sync", onSync);
    const response = await (ordersFull.remoteFetch({
      data: {
        details: "1",
      },
    }) as unknown as Promise<any>);
    return new OrderCollection(response);
  }
  getAllOrders() {
    var model = this;
    return model.ordersFull;
  }
  getLastReorderOrders() {
    const reorders = this.getReorders(),
      orders = [];
    let selectedOrders = 0;

    if (reorders.length <= 0) {
      return _([]);
    }
    let i = 0;
    while (selectedOrders < 3 && i < reorders.length) {
      const order = reorders[i];
      if (order && !order.isDesk()) {
        orders.push(order);
        selectedOrders++;
      }
      i++;
    }
    return _(orders);
  }
  getCustomerGroup() {
    const customer = this;
    if (customer.isLogged()) {
      const currentGroup = [...(customer.get("groups") || [])];
      // add connected group
      !currentGroup.includes(ECustomerGroup.CONNECTED) &&
        currentGroup.push(ECustomerGroup.CONNECTED);

      // add all user group
      !currentGroup.includes(ECustomerGroup.ALL) &&
        currentGroup.push(ECustomerGroup.ALL);
      return currentGroup;
    } else {
      return [ECustomerGroup.NOT_CONNECTED, ECustomerGroup.ALL]; // GUEST GROUP
    }
  }
  displayCagnotteAmount() {
    const amount = this.getCagnotteAmount();
    let formated = formatPrice(amount); // default formatting
    //   TODO: hooks
    /* hooks.execHook("formatCagnotte", amount, function (f) {
      formated = f;
    }); */
    return formated;
  }
  getCagnotteConversionRate() {
    //   TODO: hooks
    /* hooks.execHook("getCagnotteConversionRate", this, function (r) {
      rate = r;
    }); */
    return 1;
  }
  getCagnotteAmount() {
    let amount: number =
      Math.floor(parseFloat(this.get("customer_loyalty_cagnotte") + "") * 100) /
      100;
    if (!(amount > 0)) {
      amount = 0;
    }
    return amount;
  }
  getComeinExpirationDate() {
    const date_exp = this.getNeolaneParam("amountExpirationDate");
    let date_exp_obj = new Date();
    try {
      const theDate = new Date(date_exp || "");
      date_exp_obj = theDate;
    } catch (e) {
      date_exp_obj = new Date();
    }
    return (
      date_exp_obj?.getDate() +
      "-" +
      date_exp_obj?.getMonth() +
      "-" +
      date_exp_obj?.getFullYear()
    );
  }
  //
  // Advantages
  //
  getAdvantageGroups(reset: boolean = false) {
    const model = this,
      // advantageGroups
      advantageGroupsAttributeName = "advantageGroups";
    function getAdvantageGroupsDatas() {
      return model.get(advantageGroupsAttributeName);
    }

    const advantageGroupCollection = new AdvantageGroupCollection(
      getAdvantageGroupsDatas()
    );

    return advantageGroupCollection;
  }
  getUsableAdvantages(reset: boolean, onCart: boolean = false) {
    // return usable Advantage Collection
    const model = this;
    const groups = model.getAdvantageGroups(reset);
    const advantages = new AdvantageCollection();
    // }
    groups.each((group: AdvantageGroupModel) => {
      group.getUsableAdvantages(onCart).each(function (advantage) {
        advantages.add(advantage);
      });
    });

    return advantages;
  }
  onChangeAdvantageGroups() {
    // force reset of both usableAdvantagesCollection and advantageGroupsCollection
    // with updated values
    this.getUsableAdvantages(true, true);
  }
  //
  getTaxIdentficationNumber() {
    if (GATSBY_CONF_CUSTOMER_ENABLE_TAX_IDENTIFICATION_NUMBER) {
      const tax_identification_number = this.get("tax_indentification_number");
      return tax_identification_number;
    }
    return 0;
  }
  displayTaxIdentficationNumber() {
    const TIN = this.getTaxIdentficationNumber();
    switch (TIN) {
      case 0:
        // case "":
        return UndefinedValueTranslation();
      default:
        return TIN;
    }
  }
  formatBirthday(format: string = "LL") {
    const birthday = this.get("birthday") || "";
    const m = moment(birthday, "YYYY-MM-DD");
    return m.isValid() ? m.format(format) : "";
  }
  getAddresses(): AddressCollection {
    return app.getAddresses();
  }
  getPrimaryAddress() {
    return app.getAddress(this.get("id_address_primary") || "");
  }
  displayPrimaryAddressData() {
    var address = this.getPrimaryAddress();
    return address ? address.getData() : "";
  }
  displayPrimaryAddress() {
    var address = this.getPrimaryAddress();
    return address ? address.formatAliasLabel() : UndefinedValueTranslation();
  }
  getPhone(): string | undefined {
    return this.get("phone");
  }
  getPhoneOrder(): string {
    const customer = this;
    const phoneOrder = customer?.get("phoneOrder");
    const phone = customer?.get("phone");
    if (!!phone) {
      return phone;
    }
    return phoneOrder?.find((phone) => !!phone) || "";
  }
  getOrders(): OrderCollection {
    const customer = this,
      orders = customer.get("orders");
    // if (!orderCollection) {
    //   orderCollection = new OrderCollection(orders);
    // }

    return new OrderCollection(orders);
  }
  getOrder(id_order: string | number) {
    var orders = this.getOrders();
    return orders.findWhere({ id_order: id_order.toString() });
  }
  getReorders() {
    var orders = this.getOrders();
    return orders.filterReorder();
  }
  getLastOrder() {
    var model = this,
      lastOrder = model.getOrders().getLastOrder(),
      lastOrdering = model.getOrdering().getLastOrder();
    if (lastOrdering) {
      if (lastOrder) {
        lastOrder = lastOrder.id < lastOrdering.id ? lastOrdering : lastOrder;
      } else {
        lastOrder = lastOrdering;
      }
    }
    return lastOrder;
  }
  getOrdering(withoutCompleted: boolean = false) {
    let orders = (this.get("ordering") || []).filter((order) =>
      withoutCompleted ? !order.completed : true
    );
    return new OrderCollection(orders);
  }

  async resetPassword(
    options?:
      | {
          email?: string;
          emailList?: any;
          id_product?: any;
          id_card?: any;
        }
      | {
          link: string;
          password: string;
        }
  ) {
    return new Promise<void>((resolve, reject) => {
      return this.ApiRequest("CustomersetResetPassword", options)
        .then((result: any) => {
          resolve(result);
        })
        .catch((error: { response: { data: any } }) => {
          const json = error?.response?.data;
          if (json?.error) {
            app.banNotice(json?.error);
          } else {
            app.alert();
          }
          reject(json);
        });
    });
  }
  setSmsAlert() {
    return new Promise<void>((resolve, reject) => {
      this.ApiRequest("CustomersetSmsAlert")
        .then((result: any) => {
          this.set("sms", result["sms"]);
          resolve(result);
        })
        .catch(() => {
          app.alert();
          reject();
        });
    });
  }
  sponsorshipSendByEmail(emailList: any) {
    return this.ApiRequest("CustomerSponsorshipSendByEmail", {
      emailList: emailList,
    });
  }
  getSponsorship() {
    return this.get("sponsorship") || undefined;
  }
  setNewsletter() {
    return new Promise<void>((resolve, reject) => {
      this.ApiRequest("CustomersetNewsletter")
        .then((result: any) => {
          this.set("mail_alert", result["mail_alert"]);
          resolve(result);
        })
        .catch(() => {
          app.alert();
          reject();
        });
    });
  }
  getChildren() {
    return parseInt(this.get("children") + "");
  }
  displayChildren() {
    var children = this.getChildren();
    return children >= 0 ? children : UndefinedValueTranslation();
  }
  getMaritalStatus() {
    var maritalStatus = this.get("marital_status");
    return maritalStatus;
  }
  isProspect() {
    // est-ce que l'utilisateur est un nouveau client
    return this.getOrders().size() < 2;
  }
  getCards(filterBrandIds?: string[]) {
    let cards = this.get("card");
    if (!cards) cards = [];

    if (filterBrandIds) {
      cards = _(cards).filter(function (card) {
        return _(filterBrandIds).contains(card.brand);
      });
    }

    cards.sort(function (a, b) {
      return +a.id_card < +b.id_card ? 1 : -1;
    });

    cards.sort(function (a: any, b: any) {
      return a.brand < b.brand ? -1 : 1;
    });

    return _(cards);
  }
  getCard(id_card: string) {
    return this.getCards().findWhere({ id_card: id_card });
  }
  addPaymentCard(card: CustomerCard) {
    if (!!card) {
      const customer = this,
        currentCards = customer.get("card") || [],
        cards = [...currentCards, card];
      customer.set("card", cards);
    }
  }
  // current customer key is not based on id_customer
  getLocalStorageKey() {
    return this.name + "_" + GATSBY_ENV_COUNTRY_CODE;
  }
  onChange() {
    //
    this.updateLocalStorageItem(); // met à jour localStorage

    // reduxStore.dispatch(
    //   customerSlice.actions.setCurrentCustomer(this.attributes)
    // );
  }
  onChangeOrder() {
    // orderCollection = new OrderCollection([]);
  }
  customerChange(customer: CustomerModel, b: any, c: any) {
    this.onChangeOrder();
    this.trigger(customer.isLogged() ? "login" : "logout", customer);
  }
  likeProduct(id_product: string) {
    return new Promise<void>((resolve, reject) => {
      this.ApiRequest("CustomerLikeProduct", {
        id_product: +id_product,
      })
        .then((result: any) => {
          this.set(_(result).pick(["likes"]));
          app.trigger("FavoritesChange");
          resolve();
        })
        .catch(() => {
          reject();
          app.alert();
        });
    });
  }
  displayMaritalStatus() {
    var label = "";
    switch (this.getMaritalStatus() + "") {
      case "1":
        label = app.t("Célibataire", "Account");
        break;
      case "2":
        label = app.t("En couple", "Account");
        break;
      case "3":
        label = app.t("Marié(e)", "Account");
        break;
      case "4":
        label = app.t("Autre", "UI");
        break;
      default:
        label = UndefinedValueTranslation();
        break;
    }
    return label;
  }

  // retourne si un produit est liké
  isProductLiked(id_product: string) {
    return _(this.get("likes")).contains(id_product);
  }
  isLogged() {
    return !!this.get("id_customer");
  }
  requireRgpdType() {
    // les utilisateur non connecté sont valides
    const model = this;
    let requireOptin = 1,
      requireFields = 0;
    const logged = model.isLogged() ? 1 : 0;
    if (logged) {
      requireOptin = app.isRGPD() && !model.get("optin") ? 1 : 0;

      ["lastname", "firstname", "phone", "birthday"].forEach(function (key) {
        const value = model.get(key as any);
        if (
          !!requireFields ||
          !value ||
          (key === "birthday" && value === "0000-00-00")
        ) {
          requireFields = 1;
        }
      });
    }
    // 0 : non connecté ou tout est OK
    // 1 : optin requis
    // 2 : champs requis
    // 3 : optin et champs requis
    return logged * (requireOptin + 2 * requireFields);
  }
  requireOptin() {
    return this.requireRgpdType() % 2 === 1;
  }
  requireFields() {
    return this.requireRgpdType() > 1;
  }
  requireRgpdOptin() {
    return this.requireRgpdType() > 0;
  }

  requireMissingFields() {
    const customer = this;
    const { phone, birthday, firstname, lastname } = customer.pick([
      "phone",
      "birthday",
      "firstname",
      "lastname",
    ]);
    const validate =
      !!phone &&
      !!birthday &&
      birthday !== "0000-00-00" &&
      !!firstname &&
      !!lastname &&
      !customer.requireOptin();
    return !validate;
  }
  getStore() {
    return app.getStore(this.get("shopLike") + "");
  }
  getSegment() {
    return this.get("segmentation");
  }
  getSegmentIndex(offset: number = -1): number {
    const segment = this.getSegment();
    return (!segment ? 0 : parseInt(segment)) + offset;
  }
  getSegmentName(index?: number) {
    const _index =
      typeof index === "number" && index >= 0 ? index : this.getSegmentIndex();
    return statusList[_index] || "";
  }
  getNextAccountLevel(): AccountLevel | "" {
    return (
      (statusList[this.getSegmentIndex(0)]?.toLowerCase() as AccountLevel) || ""
    );
  }
  getAccountLevel() {
    return this.getSegmentName().toLowerCase() as AccountLevel;
  }
  getAccountLevelLabel() {
    const label: Record<AccountLevel, string> = {
      ichi: "Explorateur",
      ni: "Gourmet",
      san: "Expert",
    };
    return label[this.getAccountLevel()] || "";
  }
  getSegmentIcon(): IconName {
    return statusIconList[this.getSegmentIndex()] || "user";
  }
  isComeIn() {
    return this.get("comein") === true;
  }
  isComeInSegmentation(segmentation: string | any[]) {
    // TODO
    let customer_segmentation = this.getSegment() + "";
    if (customer_segmentation) {
      customer_segmentation = customer_segmentation.toString();
    }
    return (
      this.isComeIn() && segmentation.indexOf(customer_segmentation + "") !== -1
    );
  }
  getIdComein() {
    return this.get("id_customer_comin");
  }
  getIdNeolane() {
    return this.getNeolaneParam("id_customer_neolane", "");
  }
  removeCard(id_card: string) {
    return new Promise((resolve, reject) => {
      this.ApiRequest("CustomerRemoveCard", {
        id_card: id_card,
      })
        .then((data: any) => {
          this.set(_(data).pick(["card"]));
        })
        .catch((data: any) => {
          if (data.responseJSON && data.responseJSON.error) {
            app.banNotice(data.responseJSON.error);
          } else {
            app.alert();
          }
        });
    });
  }
  // factorisation des requetes de connexion (basic, facebook, google)
  requestLogin(
    apiRoute: string,
    options: {
      emailList?: any;
      id_product?: any;
      id_card?: any;
      is_webmobile?: number;
      email?: string;
      password?: string;
      password_v2?: string;
      password_v2_confirmation?: string;
      token?: string; // recaptcha token
      validateByCustomerRGP?: boolean;
      id_token?: string; // Google connect or Sign in with Apple
      facebook_token?: string;
      user?: string;
    } = {},
    getReCaptchaToken: ReCaptchaAction
  ) {
    const customer = this;
    let recaptchaAction = "signin";
    if (apiRoute === "LoginFacebook" || apiRoute === "LoginGoogle") {
      options.is_webmobile = app.isMobile() ? 1 : 0;
      recaptchaAction = "social_connect";
    }
    return getReCaptchaToken(recaptchaAction).then((recaptcha_token) => {
      options.token = recaptcha_token; // recaptcha_token
      return customer
        .ApiRequest(apiRoute, options)
        .then(async (data: any) => {
          const cart = app.getCart();
          await customer.remoteFetch();
          // DO NOT wait cart fetch
          cart.remoteFetch();
          customer.trigger("change");
          return {
            status: "success",
            data: data,
          };
        })
        .catch((error: any) => {
          // TODO check error
          const { response } = error || {};
          const { data } = response || {};
          let message = data?.message ?? "",
            status = data?.error ?? "error";
          // if (responseJSON && responseJSON.error) {
          //   if (responseJSON.message) {
          //     message = responseJSON.message;
          //   } else {
          //     message = responseJSON.error;
          //   }
          //   error = responseJSON.error;
          // }
          console.log(status, message, error);
          return {
            status,
            message,
            data,
          };
        });
    });
  }
  // basic login
  login(datas: any, getReCaptchaToken: ReCaptchaAction) {
    return this.requestLogin(
      "Login",
      _.pick(
        datas,
        "email",
        "password",
        "password_v2",
        "password_v2_confirmation"
      ),
      getReCaptchaToken
    );
  }
  // facebook login
  loginFacebook(accessToken: string, getReCaptchaToken: ReCaptchaAction) {
    return this.requestLogin(
      "LoginFacebook",
      { facebook_token: accessToken },
      getReCaptchaToken
    );
  }
  // google login
  loginGoogle(id_token: string, getReCaptchaToken: ReCaptchaAction) {
    return this.requestLogin(
      "LoginGoogle",
      { id_token: id_token },
      getReCaptchaToken
    );
  }

  // apple login
  loginApple(code: string, getReCaptchaToken: ReCaptchaAction) {
    return this.requestLogin(
      "LoginApple",
      { id_token: code, user: JSON.stringify({ firstname: "", lastname: "" }) },
      getReCaptchaToken
    );
  }

  registerNewsletter(registerParameters?: { email: string }) {
    return this.ApiRequest(
      "RegisterNewsLetter",
      registerParameters,
      {},
      { method: "POST" }
    );
    // .then((data: { message: any }) => {
    //   app.banNotice(data.message);
    // })
    // .catch(function (data: { responseJSON: { error: any } }, status: any) {
    //   if (data.responseJSON && data.responseJSON.error) {
    //     app.banNotice(data.responseJSON.error);
    //   } else {
    //     app.alert();
    //   }
    // });
  }

  async register(
    registerParameters: IPostRegisterArgs,
    getReCaptchaToken: ReCaptchaAction
  ) {
    const customer = this;

    const recaptcha_token = await getReCaptchaToken("signup");
    return customer
      .ApiRequest("Register", {
        ...registerParameters,
        is_webmobile: app.isMobile() ? 1 : 0,
        token: recaptcha_token,
        checkyoung: "on",
      })
      .then(async (data: any, status: any) => {
        console.log("register success", data);
        customer.set(data);
        customer.trigger("registerSuccess", customer);
        await customer.remoteFetch();
        return {
          status: "success",
        };
      })
      .catch((error: any) => {
        // TODO check error
        const { response } = error || {};
        const { data } = response || {};
        let message = data?.error ?? "error";
        customer.trigger("registerFail", customer, data);
        return {
          status: "error",
          message,
          ...data,
        };
      });
  }
  async logout() {
    const customer = this;
    return new Promise<void>((resolve, reject) => {
      customer
        .ApiRequest("Logout")
        .then(async () => {
          customer.clear();
          // customer.getUsableAdvantages(true, true);
          // DO NOT wait cart update
          app.getCart().remoteFetch();
          resolve();
        })
        .catch(function (data: any, status: any) {
          app.alert();
          customer.trigger("logoutFail", customer, data);
          reject();
        });
    });
  }
  getEmail() {
    return this.get("email");
  }
  getFirstname() {
    return this.get("firstname") || "";
  }
  getLastname() {
    return this.get("lastname") || "";
  }
  getPoints() {
    return parseInt(this.getNeolaneParam("nbPoints", "0") as string);
  }
  getCountBonus() {
    // la valeur "0" n'est pas une valeur valide
    return +(
      this.getNeolaneParam("countBonus", 0) ||
      GATSBY_CONF_MY_SUSHI_SHOP_ORDER_BONUS_COUNT
    );
  }
  getCountOrderMonth12() {
    return parseInt(this.getNeolaneParam("countOrderMonth12", "0"));
  }

  getCountOrderFidBonus() {
    // nombre de commandes fidélité éligibles (au-dessus de 20€)
    return parseInt(this.getNeolaneParam("countOrderFidBonus", "0"));
  }
  hasNeverOrderFidBonus() {
    return this.getCountOrderFidBonus() === 0;
  }
  getFullname() {
    return this.getFirstname() + " " + this.getLastname();
  }
  isNewsletter() {
    return this.get("mail_alert") === 1;
  }
  isSms() {
    return this.get("sms") === 1;
  }
  // Neolane
  getNeolaneParam<T extends keyof CustomerNeolaneParams>(
    key: T,
    defaultValue?: CustomerNeolaneParams[T]
  ) {
    const params = this.get("NeolaneParams");
    let value = defaultValue;
    if (params && params[key]) {
      value = params[key];
    } else if (_.isUndefined(value) && this.isLogged()) {
      value = "";
      app.error("NeolaneParam " + key + " is missing", this.id);
    }
    return value as NonNullable<CustomerNeolaneParams[T]>;
  }
  //
  likeHobby(slug: string) {
    return this.get(slug as any);
  }
  getHobbies() {
    // initialement ils viennent de Neolane | Comein

    const hobbies = _(hobbiesSlugs).map((slug, index) => {
      return {
        slug: slug,
        label: app.t(hobbiesNames[index], "Account"),
        polygon: 1 + (index % 3),
        checked: this.likeHobby(slug),
      };
    });
    return _(hobbies);
  }

  getOrderState() {
    return this.get("orderState") || {};
  }

  hasOrder() {
    return this.getOrdering().size() > 0 || this.getOrders().size() > 0;
  }

  //
}
CustomerModel.STATUS_LIST = statusList;
CustomerModel.prototype.name = "CustomerModel";
CustomerModel.prototype.idAttribute = "id_customer";
CustomerModel.prototype.url = function () {
  return "/customer/current";
};

export default CustomerModel;
