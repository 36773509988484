//
// prototype ObjectCollection
//

import Backbone from "@src/backbone/prototypes/Backbone";
import _ from "underscore";
import { alwaysOnXhr, ExecuteEachFunction } from "./ObjectPrototype";
import store from "@src/store";

abstract class ObjectCollection<
  TModel extends Backbone.Model<any, any, any> = Backbone.Model<any, any, any>
> extends Backbone.Collection<TModel> {
  backbonePattern: string = "Collection";
  route: string = "";
  beforeInitialize = {};
  privateApi: boolean = false; // défini si une API est privée ou publique (utilisée pour vérifier l'authentification)
  afterInitialize = {};
  abstract name: string;
  country_based: boolean = true; // défini un objet qui va varier d’une instance pays à une autre
  translated: boolean = true; // défini un objet qui va varier d'une langue à une autre
  remoteCache: string = "no-cache"; // défini un niveau de cache distant

  initialize(models?: TModel[] | Record<string, any>[], options?: any): void {
    //   TODO: implement
    var self = this;

    _.each(self.beforeInitialize, function (func, funcName) {
      self.executeEachFunction(func, funcName, self);
    });

    self.initAppHttpHeadersChange();

    _.each(self.afterInitialize, function (func, funcName) {
      self.executeEachFunction(func, funcName, self);
    });
  }

  getLangIsoCode() {
    return store.getState().app.isoLang || "";
  }

  executeEachFunction(func: any, funcName: any, self: any) {
    ExecuteEachFunction(func, funcName, self);
  }

  initApp() {
    //   TODO: initapp
    // if (!app) app = this;
  }
  initAppHttpHeadersChange() {
    var self = this;
    if (self.privateApi) {
      self.on("request", function (o, xhr, options) {
        try {
          xhr.always(alwaysOnXhr.bind(self));
        } catch (err) {}
      });
    }
  }
  pipeAppHttpHeaders(xhr: any) {
    // var self = this;
    // xhr.always(alwaysOnXhr.bind(self));
    return xhr;
  }
}

export default ObjectCollection;
