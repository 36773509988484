import { combineReducers } from "@reduxjs/toolkit";
import { rolligStartApi } from "@src/services/rollingStartApi";
import { addressSlice } from "./AddressSlice";
import AppSlice from "./AppSlice";
import AuthSlice from "./AuthSlice/index";
import backboneSlice from "./BackboneSlice";
import CartSlice from "./CartSlice";
import { categorySlice } from "./CategorySlice";
import CustomerSlice from "./CustomerSlice";
import { filterSlice } from "./FilterSlice";
import HybridRoutingSlice from "./HybridRoutingSlice";
import { PageSlice } from "./PageSlice";
import ProductSlice from "./ProductSlice";
import StoreLocatorSlice from "./StoreLocatorSlice";

const rootReducer = combineReducers({
  [rolligStartApi.reducerPath]: rolligStartApi.reducer,
  app: AppSlice,
  auth: AuthSlice,
  customer: CustomerSlice,
  product: ProductSlice,
  cart: CartSlice,
  hybridRouting: HybridRoutingSlice,
  storeLocator: StoreLocatorSlice,
  address: addressSlice.reducer,
  filter: filterSlice.reducer,
  category: categorySlice.reducer,
  [PageSlice.name]: PageSlice.reducer,
  [backboneSlice.name]: backboneSlice.reducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
