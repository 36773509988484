import React from "react";
import { IUseAuthenticationLogic } from "@src/components/feedback/AuthenticationContent/UseAuthenticationLogic";
import { useTranslation } from "@src/hooks/useTranslation";
import FlexBox from "design-system/src/Components/FlexBox/FlexBox";
import { Controller } from "react-hook-form";
import { AlternateSmallButton } from "@src/components/feedback/AuthenticationContent/Form/AlternateSmallButton";
import { EAuthentificationStep } from "@src/components/feedback/AuthenticationContent/AuthenticationContent";
import InputRender from "@src/components/feedback/AuthenticationContent/Form/InputRender";
import SubmitButton from "@src/components/feedback/AuthenticationContent/Form/SubmitButton";

interface IResetPasswordStepProps {
  authentificationLogic: IUseAuthenticationLogic;
}
const ResetPasswordStep = (props: IResetPasswordStepProps) => {
  const { t } = useTranslation(["Account", "UI"]);
  const { authentificationLogic } = props;
  const { control, setStep, onSubmit, isLoading, isValid } =
    authentificationLogic;
  return (
    <FlexBox direction="column" gap={12} flex={1}>
      <Controller
        name="email"
        control={control}
        rules={{
          required: t("Merci de rentrer un email valide", "Account") as string,
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: t("Merci de rentrer un email valide", "Account") as string,
          },
        }}
        render={({ field, fieldState }) => (
          <InputRender
            fieldState={fieldState}
            icon="message"
            label={t("Saisissez votre adresse email", "Account")}
            {...field}
          />
        )}
      />
      <SubmitButton loading={isLoading} onClick={onSubmit}>
        {t("Valider", "UI")}
      </SubmitButton>
      <AlternateSmallButton
        onClick={() => {
          setStep(EAuthentificationStep.SIGN_IN_EMAIL);
        }}
        style={{ margin: "auto auto 0" }}
      >
        {t("Annuler", "UI")}
      </AlternateSmallButton>
    </FlexBox>
  );
};

export default ResetPasswordStep;
