import Button, {
  IButtonProps,
} from "design-system/src/Foundation/Button/Button";
import React from "react";

const SubmitButton = (props: IButtonProps) => {
  return <Button type={"submit"} {...props} />;
};

export default SubmitButton;
