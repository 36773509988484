import { AxiosRequestConfig, AxiosError } from "axios";
import { BaseQueryFn } from "@reduxjs/toolkit/dist/query";
import { BaseAxiosApi } from "@src/hooks/useAxiosInstance";

export const baseQueryFn: BaseQueryFn<
  {
    url: string;
    baseUrl?: string;
    method?: AxiosRequestConfig["method"];
    body?: AxiosRequestConfig["data"];
    params?: AxiosRequestConfig["params"];
  },
  unknown,
  unknown,
  {}
> = async ({ url, method, body: data, params, baseUrl }) => {
  try {
    let headers: AxiosRequestConfig["headers"] = {};

    const result = await BaseAxiosApi({
      url,
      method,
      data,
      params,
      headers,
    });

    return {
      data: result.data,
      error: undefined,
    };
  } catch (axiosError) {
    const err = axiosError as AxiosError;
    return {
      error: { status: err.response?.status, data: err.response?.data },
      data: undefined,
    };
  }
};
