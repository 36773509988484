import styled, { keyframes } from "styled-components";

const spinnerBorderAnim = keyframes`
  to { transform: rotate(360deg); }
`;

export const SpinnerRoot = styled.div<{
  $weight?: React.CSSProperties["borderWidth"];
}>`
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-bottom;
  border: ${({ $weight }) => $weight}em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: ${spinnerBorderAnim} 0.75s linear infinite;
`;

export const SpinnerRootAbsolute = styled(SpinnerRoot)<{}>`
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const SpinnerSRStatus = styled("span")<{}>(() => ({
  position: "absolute",
  width: "1px",
  height: "1px",
  padding: 0,
  overflow: "hidden",
  clip: "rect(0,0,0,0)",
  whiteSpace: "nowrap",
  border: 0,
}));
