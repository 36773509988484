import React, { ReactNode } from "react";

interface IOptionProps<T> {
  value: T;
  children: ReactNode;
  onClick?: (value: T) => void;
}

const Option = <T extends any>(props: IOptionProps<T>) => {
  const { children, value, onClick } = props;
  return (
    <div
      style={{ cursor: "pointer" }}
      onClick={() => onClick && onClick(value)}
    >
      {children}
    </div>
  );
};

export default Option;
