import "./src/assets/css/global.css";
import { GatsbyBrowser } from "gatsby";
import wrapWithProvider from "./wrap-with-provider";
import wrapPage from "./wrap-page-element";
// import { createRoot, hydrateRoot } from "react-dom/client";

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] =
  wrapWithProvider;

export const wrapPageElement = wrapPage;

//
// Ceci est un patch pour l'erreur sur le build de production avec l'erreur React :
// > Hydration failed because the initial UI does not match what was rendered on the server.
// voir https://github.com/gatsbyjs/gatsby/discussions/31943#discussioncomment-2880513
//
// export const replaceHydrateFunction: GatsbyBrowser["replaceHydrateFunction"] =
//   () => {
//     return (element: any, container: any) => {
//       // console.log("element", element);
//       // console.log("container", container);
//       // const isL = (window?.navigator?.userAgent || "").includes("lighthouse");
//       // if (!isL) {
//       // console.log("replaceHydrateFunction", container?.id);
//       if (container.id === "___gatsby") {
//         try {
//           return hydrateRoot(container, element);
//         } catch (error) {
//           const root = createRoot(container);
//           root.render(element);
//           return root;
//         }
//       }
//       // return root;
//       // const div = document.createElement("div");
//       // div.id = "___gatsby2";
//       // const root = createRoot(div);
//       // root.render(element);
//       // console.log("div", div);
//       // }
//     };
//   };
