import React, { ButtonHTMLAttributes, LegacyRef } from "react";
import { IDefaultTheme } from "../../../Themes/defaultTheme";
import { RollingStartLinkProps } from "../Link";
import FlexBox, { IFlexBoxProps } from "../../../Components/FlexBox/FlexBox";

export type FlexBoxLinkProps<D extends any = any> = IFlexBoxProps &
  RollingStartLinkProps<D> & {
    to?: string;
    href?: string;
    type?: "button" | "submit";
  };

const DefaultFlexBoxLink = (props: FlexBoxLinkProps) => {
  const { children, type = "button", to, href = "", ref, ...rest } = props;
  const route = to || href;
  const javascript_uri = /^javascript:(.*)/.exec(route);
  if (!route || javascript_uri) {
    const { onClick, color, ...buttonRest } = rest as ButtonHTMLAttributes<any>;
    const evalJs = () => {
      eval(javascript_uri ? javascript_uri[1] : "()=>{}");
    };
    return (
      <FlexBox
        {...buttonRest}
        ref={ref as any}
        as={"button"}
        onClick={onClick || evalJs}
        type={type}
      >
        {children}
      </FlexBox>
    );
  }
  return (
    <FlexBox as={"a"} ref={ref as any} href={href !== "" ? href : to} {...rest}>
      {children}
    </FlexBox>
  );
};

export default DefaultFlexBoxLink;
