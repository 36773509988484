import React, { useMemo, useState, useEffect, forwardRef, useRef } from "react";
import Divider from "../../Components/Divider/Divider";
import Input, { InputProps } from "../Input/Input";
import PhoneNumberDropdown from "./PhoneNumberDropdown";
import { TCountryCode, ICountryOption } from "./PhoneNumberInput.d";

enum PhoneNumberFormat {
  E164,
  INTERNATIONAL,
  NATIONAL,
  RFC3966,
}
export interface PhoneNumberInputProps
  extends Omit<InputProps, "icon" | "onChange"> {
  customOptions?: ICountryOption[];
  defaultCountry?: TCountryCode;
  value?: string;
  onChange?: (value: string) => void;
}
export interface PhoneNumberUtil {
  extractCountryCode(fullNumber: any, nationalNumber: any): number;
  format(phoneNumber: any, format: any): string;
  formatInOriginalFormat(phoneNumber: any, regionDialingFrom?: string): string;
  formatOutOfCountryCallingNumber(
    phoneNumber: any,
    regionDialingFrom?: string
  ): string;
  getNddPrefixForRegion(
    regionCode?: string,
    stripNonDigits?: boolean
  ): string | undefined;
  getNumberType(phoneNumber: any): any;
  getCountryCodeForRegion(supportedRegion: string): number;
  getExampleNumber(regionCode: string): any;
  getExampleNumberForType(regionCode: string, type: any): any;
  getRegionCodeForCountryCode(countryCallingCode: number): any;
  getRegionCodeForNumber(phoneNumber: any): any | undefined;
  getSupportedRegions(): any[];
  isAlphaNumber(number: string): boolean;
  isLeadingZeroPossible(countryCallingCode: number): boolean;
  isNANPACountry(regionCode?: string): boolean;
  isPossibleNumber(number: any): boolean;
  isPossibleNumber(phoneNumber: any): boolean;
  isPossibleNumberForType(number: any, type: any): boolean;
  isPossibleNumberForTypeWithReason(number: any, type: any): any;
  isPossibleNumberString(number: string, regionDialingFrom: string): boolean;
  isPossibleNumberWithReason(number: any): any;
  isPossibleNumberWithReason(phoneNumber: any): any;
  isValidNumber(phoneNumber: any): boolean;
  isValidNumberForRegion(phoneNumber: any, region?: string): boolean;
  parse(number?: string, region?: string): any;
  parseAndKeepRawInput(number: string, regionCode?: string): any;
  truncateTooLongNumber(number: any): boolean;
  isNumberMatch(firstNumber: string | any, secondNumber: string | any): any;
  getLengthOfGeographicalAreaCode(number: any): number;
  getNationalSignificantNumber(number: any): string;
  getLengthOfNationalDestinationCode(number: any): number;
}

let instance: PhoneNumberUtil;
const preloadGoogleLibPhoneNumber = async function () {
  if (!instance) {
    console.log("preloadGoogleLibPhoneNumber PhoneNumberInput");
    const libphoneNumber = await import("google-libphonenumber");
    instance = libphoneNumber.PhoneNumberUtil.getInstance();
    return instance;
  }
  return instance;
};

const PhoneNumberInput = forwardRef<HTMLInputElement, PhoneNumberInputProps>(
  (props, inputRef) => {
    const {
      customOptions,
      onChange,
      value,
      defaultCountry = "fr",
      ...rest
    } = props;
    const [selectedCountry, setSelectedCountry] =
      useState<TCountryCode>(defaultCountry);
    const phoneNumberInstanceRef = useRef<PhoneNumberUtil | null>(null);
    const [currentValue, setCurrentValue] = useState<string>("");
    const [inputValue, setInputValue] = useState<string>("");
    const [supportedRegions, setSupportedRegion] = useState<ICountryOption[]>(
      []
    );

    const options: ICountryOption[] = useMemo(() => {
      if (customOptions) {
        return customOptions;
      }

      return supportedRegions;
    }, [supportedRegions, customOptions]);

    useEffect(() => {
      preloadGoogleLibPhoneNumber().then((res) => {
        phoneNumberInstanceRef.current = res;
        const regions: string[] = res.getSupportedRegions();
        setSupportedRegion(
          (regions || []).map<ICountryOption>((code: any) => {
            return {
              type: "option",
              value: code.toLowerCase() as TCountryCode,
              label: code,
            };
          })
        );
      });
    }, []);

    const handleChange = (val: string) => {
      setCurrentValue(val);
      onChange && onChange(val);
    };
    const generateValue = (code: TCountryCode, num: string): string => {
      try {
        const parsed = phoneNumberInstanceRef.current?.parseAndKeepRawInput(
          num,
          code
        );
        return (
          phoneNumberInstanceRef.current?.format(
            parsed,
            PhoneNumberFormat.INTERNATIONAL
          ) || ""
        );
      } catch (error) {
        return num;
      }
    };

    const handleChangeCountry = (val: TCountryCode) => {
      setSelectedCountry(val);
      handleChange(generateValue(val, inputValue));
    };
    const handleInputChange: React.FormEventHandler<HTMLInputElement> = (e) => {
      setInputValue(e.currentTarget.value);
      handleChange(generateValue(selectedCountry, e.currentTarget.value));
    };

    useEffect(() => {
      if (typeof value !== "undefined" && value !== inputValue) {
        try {
          const parsed =
            phoneNumberInstanceRef.current?.parseAndKeepRawInput(value);
          const countryC =
            phoneNumberInstanceRef.current?.getRegionCodeForNumber(parsed);

          if (countryC) {
            setSelectedCountry(countryC.toLowerCase() as TCountryCode);
          }

          const num = parsed.getNationalNumber();
          if (num) {
            const formattedNational = phoneNumberInstanceRef.current?.format(
              parsed,
              PhoneNumberFormat.NATIONAL
            );
            setInputValue(`${formattedNational}`);
          }
        } catch (error) {
          setInputValue(value);
        }
      }
    }, [value]);

    const countryCodeNumber =
      phoneNumberInstanceRef.current?.getCountryCodeForRegion(
        selectedCountry
      ) || "";
    const prefixLabel = `+${countryCodeNumber}`;

    return (
      <Input
        type="text"
        value={inputValue}
        ref={inputRef}
        icon={() => {
          return (
            <>
              <PhoneNumberDropdown
                {...{
                  options,
                  selectedCountry,
                  value: currentValue,
                  prefixLabel: prefixLabel,
                  handleChangeCountry,
                }}
              />
              <Divider weight={1} />
            </>
          );
        }}
        onChange={handleInputChange}
        {...rest}
      />
    );
  }
);

export default PhoneNumberInput;
