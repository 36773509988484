import React, { ButtonHTMLAttributes, createContext, ReactNode } from "react";
import { TRollingStartLink } from "../Link";

const DefaultLinkWrapper: TRollingStartLink = (props) => {
  const { children, to, href = "", ...rest } = props || {};
  const route = to || href;
  const javascript_uri = /^javascript:(.*)/.exec(route);
  if (!route || javascript_uri) {
    const { onClick, ...buttonRest } = rest as ButtonHTMLAttributes<any>;
    const evalJs = () => {
      eval(javascript_uri ? javascript_uri[1] : "()=>{}");
    };
    return (
      <button {...buttonRest} onClick={onClick || evalJs} type="button">
        {children as ReactNode}
      </button>
    );
  }
  return (
    <a href={href !== "" ? href : to} {...rest}>
      {children as ReactNode}
    </a>
  );
};

export default DefaultLinkWrapper;
