import LocalStorageCollection from "../prototypes/LocalStorageCollection";
import AddressModel, { EAddressSource } from "@src/backbone/model/AddressModel";
import PlaceModel from "@src/backbone/model/PlaceModel";
import { app } from "@src/backbone/model/AppModel";
import { deburr, escapeRegExp } from "lodash";

class AddressCollection extends LocalStorageCollection<AddressModel> {
  name = "AddressCollection";
  initialize(models?: Record<string, any>[], options?: any) {
    // ObjectCollection constructor heritage
    const collection = this;
    super.initialize.apply(collection, [models, options]);
    //
    collection.on("change:deleted", collection.changeDeleted, collection);
    //
  }
  changeDeleted(addressModel: AddressModel, deleted: string | number) {
    if (deleted === 0) {
      // address undelete
      this.trigger("undelete", addressModel);
    }
  }
  size(withDeleted?: boolean) {
    if (withDeleted) {
      return super.size.apply(this);
    }
    return this.where({ deleted: 0 }).length;
  }
  containsLocality(locality: string) {
    locality = locality.toLowerCase();
    return this.some((address: AddressModel) => {
      const city = address.get("city") || "";
      return city.toLowerCase() === locality;
    });
  }
  searchAddresses(
    input: string = "",
    forceMatch: boolean = false
  ): AddressModel[] {
    const inputRegExp = RegExp("^" + escapeRegExp(deburr(input)), "i");
    return this.filter((address: AddressModel): boolean => {
      return (
        !address.isDeleted() && // si l'adresse n'est pas supprimée
        (forceMatch || // on peut forcer, par exemple quand l'input est vide
          (!!input && inputRegExp.test(deburr(address.getAlias()))) ||
          inputRegExp.test(deburr(address.getDescription()))) // ou bien chercher dans la description et l'alias
      );
    });
  }
  createAddressFromPlace(
    place: PlaceModel,
    attach_to_cart: boolean = false,
    data: string = "",
    customer_primary: boolean = false,
    source: EAddressSource = EAddressSource.NONE
  ) {
    //
    // instancie une adresse et la sauvegarde à partir d'un place_id
    //
    const collection = this;
    const existingAddress: AddressModel | undefined = collection.findWhere({
      place_id: place.id,
    });
    const address: AddressModel = existingAddress
      ? existingAddress
      : AddressModel.createAddressFromPlace(
          place,
          attach_to_cart,
          data,
          customer_primary,
          source
        );

    const AppendAddressToCollection = () => {
      if (!collection.findWhere({ id_address: address.id })) {
        collection.add(address);
      }
    };

    function SyncAddress() {
      // attention n'ajouter l'adresse que si elle ne préexiste pas dans le carnet d'adresse.
      AppendAddressToCollection();
      address.pendingSync = false;
      if (attach_to_cart) {
        app.getCart().set("id_address_delivery", address.id);
      }
      if (customer_primary) {
        app.getCustomer().set("id_address_primary", address.id + "");
      }
    }

    // sauf pour le cas où le placeModel n'est pas défini
    if (address.validateBeforeSave()) {
      if (!address.pendingSync) {
        // on la sauvegarde
        address.once("sync", SyncAddress);
        // la collection est obligatoire pour créer une address, pour obtenir l'url
        address.save();
        address.pendingSync = true;
        AppendAddressToCollection();
      }
    } else {
      AppendAddressToCollection();
    }

    return address;
  }
}

AddressCollection.prototype.url = () => `/address/`;
AddressCollection.prototype.model = AddressModel;
AddressCollection.prototype.privateApi = true;

export default AddressCollection;
