import { startTransition, useEffect, useMemo, useState } from "react";
import { useCurrentPageContextIntl } from "@src/store/reducers/PageSlice/selectors";

const usePageLang = () => {
  const [lang, setLang] = useState(
    process.env.GATSBY_ENV_COUNTRY_CODE?.toLocaleLowerCase()
  );

  const pageContext = useCurrentPageContextIntl();

  useEffect(() => {
    if (lang !== window.___gatsbyIntl.language) {
      startTransition(() => {
        setLang(window.___gatsbyIntl.language);
      });
    }
  }, [lang]);

  const langResponse = useMemo(
    () => (pageContext && pageContext.lang ? pageContext.lang : lang),
    [pageContext, lang]
  );

  return langResponse;
};

export default usePageLang;
