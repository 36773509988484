import { vsprintf } from "sprintf-js";
import LocalStorageModel from "../prototypes/LocalStorageModel";

//
// Model Translation
//

class TranslationModel extends LocalStorageModel {
  name = "TranslationModel";
  route = "apiTranslationModel";
  translate(key: string, context: string, parameters?: any) {
    let contextBase: Record<string, string> = this.get(context);
    if (!contextBase) contextBase = this.get("default") || {};
    let translation = contextBase[key];
    // si la traduction n'existe pas, on retourne la clé de traduction
    if (!translation) {
      translation = key;
    }

    // Traduction simple sans paramètres
    if (!parameters) {
      return translation;
    }
    //
    switch (typeof parameters) {
      case "boolean":
      case "string":
      case "number":
        const string = parameters.toString();
        parameters = [];
        parameters.push(string);
        break;
    }
    //
    //
    try {
      const r = vsprintf(translation, parameters);
      return r;
    } catch (err) {
      return translation;
    }
  }
}

export default TranslationModel;
