import ObjectCollection from "../prototypes/ObjectCollection";
import AdvantageModel from "./AdvantageModel";
import _ from "underscore";

//
// Collection AdvantageCollection
//

class AdvantageCollection extends ObjectCollection<AdvantageModel> {
  name = "AdvantageCollection";
  group: any;
  idAttribute = "id_advantage";

  initialize(
    models?: Record<string, any>[] | AdvantageModel[],
    options?: any
  ): void {
    const collection = this;

    // if (!collection.group) {
    //   collection.group = options.group;
    // }

    super.initialize.apply(collection, [models, options]);
  }

  getUsableAdvantages(onCart: boolean = false) {
    return _(
      this.filter(function (advantage) {
        return advantage.isUsable(onCart);
      })
    );
  }
}
AdvantageCollection.prototype.name = "AdvantageCollection";
AdvantageCollection.prototype.model = AdvantageModel;

export default AdvantageCollection;
