//
// Collection Allergen Group

import LocalStorageCollection from "../prototypes/LocalStorageCollection";
import AllergenGroupModel from "./AllergenGroupModel";
import _ from "underscore";

class AllergenGroupCollection extends LocalStorageCollection<AllergenGroupModel> {
  name = "AllergenGroupCollection";
  route = "apiAllergenGroupCollection";
  idAttribute = "id_allergen_group";
  model = AllergenGroupModel;
  initialize(
    models?: Record<string, any>[] | AllergenGroupModel[],
    options?: any
  ): void {
    // ObjectCollection constructor heritage
    super.initialize.apply(this, [models, options]);
  }
  whereIds(ids: any) {
    var collection = new AllergenGroupCollection();
    collection.reset();
    this.each(function (allergenGroupModel) {
      if (_(ids).indexOf(allergenGroupModel.get("id_allergen_group")) >= 0) {
        collection.add(allergenGroupModel);
      }
    });
    return collection;
  }

  isAllChecked() {
    var allChecked = true;
    this.each(function (model) {
      allChecked = allChecked && model.isChecked();
    });
    return allChecked;
  }

  isNoneChecked() {
    var noneChecked = true;
    this.each(function (model) {
      noneChecked = noneChecked && !model.isChecked();
    });
    return noneChecked;
  }

  isFilterChecked() {
    return !(this.isNoneChecked() || this.isAllChecked());
  }

  getCheckedCount() {
    var collection = this;
    return collection.isFilterChecked() ? collection.getChecked().length : 0;
  }

  getChecked() {
    return new AllergenGroupCollection(
      this.filter(function (model) {
        return model.isChecked();
      })
    );
  }
}
AllergenGroupCollection.prototype.name = "AllergenGroupCollection";
AllergenGroupCollection.prototype.route = "apiAllergenGroupCollection";
AllergenGroupCollection.prototype.url = "/allergengroup";
AllergenGroupCollection.prototype.model = AllergenGroupModel;
export default AllergenGroupCollection;
