//
import moment from "moment";
import _ from "underscore";
import LocalStorage from "../prototypes/LocalStorageModel";
import ObjectModel from "../prototypes/ObjectModel";
import { app } from "./AppModel";

export type EMessageLoction =
  | "home_top_banner"
  | "home_header_widget"
  | "listing_top_banner"
  | "listing_info_bar"
  | "shop_info";
export interface IMessageAttributes extends Backbone.ObjectHash {
  id_sushi_message: string;
  timemode: string;
  from: string;
  to: string;
  location: EMessageLoction;
  title: string;
  message: string;
  link: string;
  active_vae: number;
  active_vad: number;
  active_web: number;
  active_app: number;
  norestriction: number;
  priority: number;
  color?: string;
  icon?: string;
  // TODO: define zone
  zone?: any;
  schedule?: any;

  //
  dismissed?: boolean;
  priority_with_id?: number;
}
// Model Message
//

// HOME
const HOME_HEADER_MESSAGE: EMessageLoction = "home_header_widget";
const HOME_BANNER_MESSAGE: EMessageLoction = "home_top_banner";

// LISTING
const LISTING_BANNER_MESSAGE: EMessageLoction = "listing_top_banner";
const LISTING_INFO_BAR_MESSAGE: EMessageLoction = "listing_info_bar";

// SHOP
const SHOP_INFO_MESSAGE: EMessageLoction = "shop_info";

const MESSAGES_LOCAL_STORAGE_KEY = "SushiShopMessagesDismissed";
const MESSAGE_DATE_FORMAT = "YYYY-MM-DD hh:mm:ss";
const MESSAGE_TIME_FORMAT = "hh:mm";

class MessageModel extends ObjectModel<IMessageAttributes> {
  stores(stores: any, id_store: any) {
    throw new Error("Method not implemented.");
  }
  name = "MessageModel";
  route = "apiMessageModel";
  idAttribute = "id_sushi_message";
  defaults() {
    return {
      message: "",
      link: "",
      dismissed: false,
      color: undefined,
      priority: 0,
      priority_with_id: 0,
    };
  }
  initialize(attributes?: any, options?: any): void {
    const model = this;
    // ObjectModel constructor heritage
    super.initialize.apply(model, [attributes, options]);
    model.set({
      dismissed: LocalStorage.isInList(MESSAGES_LOCAL_STORAGE_KEY, model.id),
    });
    model.on("change:priority", function () {
      model.updateComparator();
    });
    model.updateComparator();
    //
  }

  updateComparator() {
    const intId = parseInt(this.id + ""),
      priority_with_id = this.getPriority() + 1 + 10000 / (-10000 - intId);
    this.set("priority_with_id", priority_with_id);
  }

  getPriority() {
    return parseInt(this.get("priority") + "") || 0;
  }

  dismiss() {
    // TODO
    this.set("dismissed", true);
    LocalStorage.addInList(MESSAGES_LOCAL_STORAGE_KEY, this.id);
  }

  isDismissed() {
    return this.get("dismissed");
  }

  validateContext() {
    return this.validateTime() && this.validateRestrictions();
  }

  validateRestrictions() {
    const model = this,
      active_web = model.get("active_web") + "" === "1",
      norestriction = model.get("norestriction") + "" === "1";
    let validate;

    if (!(validate = active_web)) {
      // ignore non active_web
    } else if (!(validate = norestriction)) {
      // si il y a des restrictions
      const cart = app.getCart();
      const isVAE = cart.isVAE();
      const vae_vad = isVAE ? "vae" : "vad";
      const zone = model.get("zone")[vae_vad];
      const isModeActive = model.get(isVAE ? "active_vae" : "active_vad");
      if (isVAE) {
        // restriction Click & Collect
        const store = cart.getStore();
        validate = isModeActive && !!store && _.contains(zone.store, store.id);
      } else {
        // restriction Livraison
        const district = cart.getDistrictId();
        if (district) {
          validate = isModeActive && _.contains(zone.district, district);
        } else {
          validate =
            isModeActive && _.contains(zone.postcode, cart.getPostcodeId());
        }
      }
    }

    return validate;
  }

  validateTime() {
    // vérifie si le message doit être affiché dans le temps actuel

    const timemode = this.get("timemode");
    // suivant le mode de message
    if (timemode === "ponct") {
      // ponctuel
      return this.validateFromTo();
    }
    // périodique
    return this.validatePeriod();
  }

  validateFromTo() {
    const from = moment(this.get("from"), MESSAGE_DATE_FORMAT),
      to = moment(this.get("to"), MESSAGE_DATE_FORMAT);
    //
    return from.isBefore() && to.isAfter();
  }

  validatePeriod() {
    const schedule = this.get("schedule"),
      scheduleDay = schedule ? schedule[moment().day()] : false,
      isNowLunch = app.isNowLunch(),
      am_pm = isNowLunch ? "am" : "pm";
    const validate =
      !!scheduleDay && // si on a des données sur le jour
      // et que ou bien
      (scheduleDay["all_" + am_pm] === "1" || // tout le service
        // ou bien on est entre le début et la fin de la période défini par le message
        (moment(
          scheduleDay["time_deb_" + am_pm],
          MESSAGE_TIME_FORMAT
        ).isBefore() &&
          moment(
            scheduleDay["time_end_" + am_pm],
            MESSAGE_TIME_FORMAT
          ).isAfter()));

    return validate;
  }

  getPage(): string {
    switch (this.get("location")) {
      case HOME_HEADER_MESSAGE:
      case HOME_BANNER_MESSAGE:
        return "home";
      case LISTING_BANNER_MESSAGE:
      case LISTING_INFO_BAR_MESSAGE:
        return "listing";
      case SHOP_INFO_MESSAGE:
        return "shop";
      default:
        return "";
    }
  }

  getCssColorAttr(prop: string = "background-color") {
    // return html attr style string
    const color = this.get("color") || "";

    return color ? ' style="' + prop + ":" + color + '" ' : "";
  }

  getIconHtml(classes: string, defaultIcon?: string): string {
    // return html attr style string
    const icon = this.get("icon") || defaultIcon || "";

    if (icon) {
      classes = classes || "";
      return (
        '<span class="notifications ' + classes + " icon-" + icon + '"></span>'
      );
    }
    return "";
    //icon ? ' style="'+prop+':'+color+'" ' :'';
  }
  getMessageHtml() {
    const message = this.get("message"),
      link = this.get("link");
    if (!!link) {
      return '<a href="' + link + '">' + message + "</a>";
    }
    return message;
  }
  clickClass() {
    return !!this.get("link") ? "message--clickable" : "";
  }
}

MessageModel.prototype.name = "MessageModel";
MessageModel.prototype.url = function () {
  return `/sushimessage/${this.id}`;
};
MessageModel.prototype.idAttribute = "id_sushi_message";
export default MessageModel;
