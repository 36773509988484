import { startTransition, useEffect, useState } from "react";

const useLoadAfterInteraction = ({ touch = false }: { touch?: boolean }) => {
  const [initialLoad, setInitialLoad] = useState(false);

  useEffect(() => {
    const handle = () => {
      if (!initialLoad) {
        startTransition(() => {
          setInitialLoad(true);
        });
      }
    };
    if (!initialLoad) {
      window.addEventListener("scroll", handle);
      window.addEventListener("mousemove", handle);
      window.addEventListener("touchstart", handle);
    }
    return () => {
      window.removeEventListener("scroll", handle);
      window.removeEventListener("mousemove", handle);
      window.removeEventListener("touchstart", handle);
    };
  }, [initialLoad, touch]);

  return initialLoad;
};

export default useLoadAfterInteraction;
