import LocalStorageModel from "../prototypes/LocalStorageModel";
import _ from "underscore";
import { app } from "./AppModel";
import Modernizr from "modernizr";
import {
  GATSBY_CURRENCY_SIGN,
  GATSBY_PRICE_DISPLAY_PRECISION,
} from "@src/backbone/prototypes/formatPrice";

//
// Model Country
//

class CurrencyModel extends LocalStorageModel {
  name = "CurrencyModel";
  idAttribute = "id_currency";

  initialize(attributes?: any, options?: any): void {
    // ObjectModel constructor heritage
    super.initialize.apply(this, [attributes, options]);
    //
  }

  modernizr() {
    const sign = this.getSign();
    // Modernizr.addTest("currency-sign-small", function () {
    //   return sign.length < 2;
    // });
  }
  getSign() {
    const model = this;
    let s = model.get("s");
    if (!s) {
      // on cache le signe
      s = model.get("sign");
      if (!_.isString(s)) {
        s = GATSBY_CURRENCY_SIGN;
      }
      model.set("s", s);
    }
    return s;
  }
  getIsoCode() {
    return this.get("iso_code");
  }
  getDecimals() {
    const model = this;
    let d = model.get("d");
    if (!d) {
      // on calcule et on cache la valeur numérique des décimales
      d = parseInt(this.get("decimals"));
      if (_.isNaN(d)) {
        d = GATSBY_PRICE_DISPLAY_PRECISION; // valeur par défaut, 2 décimales
      }
      model.set("d", d);
    }
    return d;
  }
}
CurrencyModel.prototype.name = "CurrencyModel";
CurrencyModel.prototype.idAttribute = "id_country";

export default CurrencyModel;
