//
// Model Configuration

import LocalStorageModel from "../prototypes/LocalStorageModel";

class ConfigurationModel extends LocalStorageModel {
  name = "ConfigurationModel";
  backbonePattern = "Model";
  idAttribute = "name";
  defaults() {
    return { value: "" };
  }
  initialize(attributes: any, options: any) {
    // ObjectModel constructor heritage
    super.initialize.apply(this, [attributes, options]);
    //
  }
}

export default ConfigurationModel;
