import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CustomerData } from "@src/interface/Customer";

interface CustomerState {
  current: CustomerData;
}

const initialState: CustomerState = { current: {} };

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setCurrentCustomer(state, action: PayloadAction<CustomerData>) {
      state.current = {};
      state.current = JSON.parse(JSON.stringify(action.payload)) as any;
      return state;
    },
  },
});

const reducer = customerSlice.reducer;

export const { setCurrentCustomer } = customerSlice.actions;
export default reducer;
