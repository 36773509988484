import useSetup from "@src/components/general/Setup/useSetup";
import React, {
  createContext,
  // startTransition,
  // useCallback,
  useEffect,
  // useMemo,
  useRef,
  // useState,
} from "react";
// import { merge, tap } from "rxjs";
import AppModel, { app, AppAttributes } from "../model/AppModel";
import CartModel from "../model/CartModel";
import SetupModel, { setupPrivate } from "../model/SetupModel";
import SetupPrivateModel from "../model/SetupPrivateModel";
import { useDispatch } from "react-redux";
import { initAppEvents } from "./initAppEvents";
import CustomerModel from "@src/backbone/model/CustomerModel";
// import store from "@src/store";
import { AppSlice } from "@src/store/reducers/AppSlice";
// import { cartSlice } from "@src/store/reducers/CartSlice";
// import { customerSlice } from "@src/store/reducers/CustomerSlice";
import { useAlertNoticeContext } from "@src/lib/alert-notice/useAlertNoticeContext";
// import { RunContext } from "@src/AppProvider";
import { useRollingStartNavigation } from "@src/lib/rolling-start-navigation";
import { IRollingStartNavigation } from "@src/lib/rolling-start-navigation/useRollingStartNavigation";
import { useAppSelector } from "@src/store/hooks";
import { useAllProduct } from "@src/hooks/query/useAllProduct";
import { useAllCategory } from "@src/hooks/query/useAllCategory";
import useAllStore from "@src/hooks/query/useAllStore";
import { useConfiguration } from "@src/hooks/query/useConfiguration";
import useAllIngredient from "@src/hooks/query/useAllIngredient";
// import { useConfiguration } from "@src/hooks/query/useConfiguration";
// import { useTranslation } from "@src/hooks/useTranslation";
// import { useAppSelector } from "@src/store/hooks";

// @ts-ignore

export const mainCartInstance = new CartModel();
export const mainCustomerInstance = new CustomerModel();
export let rollingStartNavigation: IRollingStartNavigation | undefined;

export function defaultCart(cart?: CartModel): CartModel {
  return cart ? cart : app.getCart();
}

export type IBackboneContext = typeof backboneMap;

const backboneMap: {
  app: AppModel;
  // app$: Observable<AppModel | undefined>;
  cart: CartModel;
  setupPrivate?: SetupPrivateModel;
  customer: CustomerModel;
  setup?: SetupModel;
  models?: AppAttributes;
  loaded: boolean;
  updateChange: number;
  forceUpdate: () => void;
} = {
  app,
  cart: mainCartInstance,
  customer: mainCustomerInstance,
  setupPrivate: setupPrivate,
  loaded: false,
  updateChange: 0,
  forceUpdate: () => +1,
};

export const ConnectBackboneToReactContext =
  createContext<IBackboneContext>(backboneMap);

export const BackboneProvider = ConnectBackboneToReactContext.Provider;

const ModelProvider = (props: {
  // runContext: RunContext;
  children: React.ReactNode;
}) => {
  const { children /* runContext */ } = props;
  // const appRef = useRef<AppModel>(app);
  // const { cart: cartState, customer: customerState } = useAppSelector(
  //   ({ cart, customer }) => ({
  //     cart,
  //     customer: customer.current,
  //   })
  // );
  // const [updateChanges, setUpdateChanges] = useState(backboneMap.updateChange);
  // const [object, setObject] = useState<AppAttributes | undefined>(undefined);
  const setupRef = useRef<SetupModel | null>(null);
  const setupInitialized = useAppSelector(
    (state) => state.app.setupInitialized
  );
  const dispatch = useDispatch();
  const alertNotice = useAlertNoticeContext();
  const navigation = useRollingStartNavigation();
  // const isSSR = useIsSSR();
  useSetup();

  // const forceRender = useCallback(() => {
  //   setUpdateChanges((c) => c + 1);
  // }, []);
  const { data: allProducts } = useAllProduct();
  const { data: allCategories } = useAllCategory();
  const { data: allStores } = useAllStore();
  const { data: allConfigurations } = useConfiguration();
  const allIngredient = useAllIngredient();

  useEffect(() => {
    // dispatch(AppSlice.actions.setRunContext(runContext));
    if (
      !setupInitialized &&
      allProducts.length &&
      allProducts.length &&
      allStores.length &&
      allConfigurations.length &&
      allIngredient.length
    ) {
      console.log("first");
      // dispatch(AppSlice.actions.setSetupReady(false));
      setupRef.current = new SetupModel({
        product: JSON.parse(JSON.stringify(allProducts)),
        category: JSON.parse(JSON.stringify(allCategories)),
        store: JSON.parse(JSON.stringify(allStores)),
        configuration: JSON.parse(JSON.stringify(allConfigurations)),
        ingredient: JSON.parse(JSON.stringify(allIngredient)),
      });
      dispatch(AppSlice.actions.setSetupInitialized(true));
    }

    // const sub$ = appObjects$.pipe(
    //   tap((o) => {
    //     setObject(o);
    //     // setAppInstance(app);
    //   })
    // );

    // const subscription = merge(sub$).subscribe();
    // return () => {
    // subscription.unsubscribe();
    // dispatch(AppSlice.actions.setSetupInitialized(false));
    // };
  }, [
    allCategories,
    allProducts,
    allStores,
    allIngredient,
    dispatch,
    setupInitialized,
    allConfigurations,
  ]);

  // useEffect(() => {
  // // const handler = () => {
  // //   startTransition(() => {
  // //     forceRender();
  // //   });
  // // };
  // const handlerCart = () => {
  //   console.log("======> handlerCart");
  //   const cartData = app.getCart()?.attributes;
  //   if (JSON.stringify(cartData) !== JSON.stringify(cartState.currentCart)) {
  //     startTransition(() => {
  //       dispatch(cartSlice.actions.updateCurrentCart(cartData));
  //       // forceRender();
  //     });
  //   }
  // };
  // // startTransition(() => {
  // handlerCart();
  // // });
  // // handler();
  // // app.on("change:*", handler);
  // app.getCart()?.on("change:*", handlerCart);
  // return () => {
  //   // app.off("change:*", handler);
  //   app.getCart()?.off("change:*", handlerCart);
  // };
  // }, []);

  useEffect(() => {
    if (app) {
      const off = initAppEvents(app, alertNotice);
      return off;
    }
  }, [alertNotice]);

  useEffect(() => {
    rollingStartNavigation = navigation;
  }, [navigation]);

  // listen on CustomerModel change
  // useEffect(() => {
  //   const handleCustomerChange = (...args: any[]) => {
  //     // forceRender();
  //     if (
  //       JSON.stringify(customerState) !==
  //       JSON.stringify(app.getCustomer().attributes)
  //     ) {
  //       // console.log("======> handleCustomerChange");
  //       startTransition(() => {
  //         dispatch(
  //           customerSlice.actions.setCurrentCustomer(
  //             app.getCustomer().attributes
  //           )
  //         );
  //       });
  //     }
  //   };
  //   // first init
  //   // handleCustomerChange();

  //   // listner
  //   app.getCustomer().on("all", handleCustomerChange);

  //   return () => {
  //     app.getCustomer().off("all", handleCustomerChange);
  //   };
  // }, []);

  // const value = useMemo(
  //   () => {
  //     return {
  //       ...backboneMap,
  //       // forceUpdate: forceRender,
  //       app: appRef.current || app,
  //       // updateChange: updateChanges,
  //     };
  //   },
  //   [
  //     /* forceRender, updateChanges */
  //   ]
  // );

  return <BackboneProvider value={backboneMap} children={children} />;
};

export default React.memo(ModelProvider);
