import useBreakpoint from "design-system/src/Components/Grid/hooks/useBreakpoint";

const useResponsive = () => {
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const isMobile = (xs || sm || md) && !lg && !xl && !xxl,
    isDesktop = lg || xl || xxl;
  return {
    isMobile,
    isDesktop,
  };
};

export default useResponsive;
