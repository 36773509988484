import ObjectCollection from "../prototypes/ObjectCollection";
import OfferedProductModel from "./OfferedProductModel";
import CartModel from "@src/backbone/model/CartModel";
import ApiRequest from "../prototypes/ApiRequest";
import { app } from "./AppModel";
import axios, { AxiosError, CancelToken, CancelTokenSource } from "axios";
// axios.CancelToken.source();

const AjaxFail = (error: AxiosError) => {
  console.log("AjaxFail", error);
  if (!axios.isCancel(error)) {
    app.alert();
  }
};

class OfferedProductCollection extends ObjectCollection<OfferedProductModel> {
  name = "OfferedProductCollection";
  model = OfferedProductModel;
  idAttribute = "slug";
  public cart: CartModel | undefined = undefined;
  cancelSource?: CancelTokenSource = undefined;

  initialize(
    models?: Record<string, any>[] | OfferedProductModel[],
    options?: any
  ): void {
    const collection = this;
    super.initialize.apply(collection, [models, options]);
    // if (options.cart) {
    //   // this.cart = options.cart;
    //   this.attachCart(options.cart);
    // }
    collection.on("change", collection.updateQuantityFree);
  }

  attachCart(cart: CartModel) {
    const collection = this;
    collection.cart = cart;
    // cart.on(
    //   "change:consumables_qty",
    //   collection.onChangeCartQuantity,
    //   collection
    // );
    collection.onChangeCartQuantity();
  }
  onChangeCartQuantity() {
    this.each(function (model) {
      model.setup();
    });
    this.updateQuantityFree();
  }
  updateQuantityFree() {
    this.each(function (model) {
      model.set("free", model.getQuantityFree());
    });
  }
  getModelBySlug(slug: string) {
    return this.findWhere({ slug: slug });
  }
  getQuantityBySlug(slug: string) {
    const offeredProductModel = this.getModelBySlug(slug);
    if (offeredProductModel) {
      return offeredProductModel.getQuantity();
    }
    return 0;
  }
  // isPresetValues() {
  //   // renvoie true si toutes les valeurs sont en accord avec le preset
  //   let valid = true;
  //   this.each(function (model) {
  //     valid &&= model.getQuantity() === model.getPreset();
  //   });
  //   return valid;
  // }
  // resetPreset() {
  //   // reset all quantity to preset value
  //   this.each(function (model) {
  //     model.resetPreset();
  //   });
  // }
  cancelRequest(): CancelToken {
    this.cancelSource?.cancel();
    const cancelSource: CancelTokenSource = (this.cancelSource =
      axios.CancelToken.source());
    return cancelSource.token;
  }
  // requestPreset() {
  //   const collection = this;
  //   const cart = this.cart;
  //
  //   if (cart) {
  //     // update remote value as preset
  //     const cancelToken = collection.cancelRequest();
  //
  //     ApiRequest(
  //       "CartConsumableReset",
  //       null,
  //       {
  //         id_cart: cart.id,
  //       },
  //       {
  //         cancelToken,
  //       }
  //     )
  //       .then((data) => {
  //         cart.set(data);
  //         cart.trigger("offeredProductOptionsUpdate", cart);
  //       })
  //       .catch(AjaxFail);
  //   }
  // }
  async requestSet() {
    const collection = this,
      cart = collection.cart || app.getCart();
    let quantities: Record<string, any> = {};
    quantities["extra"] = [];
    if (cart) {
      collection.each(function (model) {
        const suffix = (model.id + "").replace("_", "");
        if (model.get("fix") === true) {
          quantities["nbr" + suffix] = model.getQuantity();
        } else {
          quantities["extra"].push({
            id_product: model.get("id_product"),
            qty: model.getQuantity(),
          });
        }
      });
      const cancelToken = collection.cancelRequest();

      return collection
        .pipeAppHttpHeaders(
          ApiRequest(
            "CartConsumableOptions",
            quantities,
            { id_cart: cart.id },
            {
              cancelToken,
            }
          )
        )
        .then((data: any) => {
          // if (jqxhr && !!jqxhr.canceled) return;
          if (!data.total_price_ttc_consumables) {
            cart.unset("total_price_ttc_consumables");
          }
          cart.setAndSave(data);
          cart.trigger("offeredProductOptionsUpdate", data);
        })
        .catch(AjaxFail);
    } else {
      return Promise.reject("no cart");
    }
  }
}
OfferedProductCollection.prototype.name = "OfferedProductCollection";
OfferedProductCollection.prototype.model = OfferedProductModel;

export default OfferedProductCollection;
