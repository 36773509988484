import clsx from "clsx";
import styled, { keyframes } from "styled-components";
import { $white } from "../../Utils/color";

interface ISwitcherRootProps {
  fullWidth?: boolean;
  size?: "default" | "extra-large";
  background?: "opaque" | "translucent";
  loading?: boolean;
  selectedIndex: 0 | 1;
}

interface IButtonProps {
  className?: string;
  onClick?: () => void;
  loading?: boolean;
  selected?: boolean;
}

const afterSpin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const SwitcherRoot = styled.div.attrs<
  ISwitcherRootProps,
  ISwitcherRootProps
>(({ loading, ...props }) => {
  return {
    ...props,
    className: clsx("ro-switcher", {
      loading: loading,
    }),
    $loading: loading,
  };
})(
  ({
    fullWidth,
    // size = "default",
    background = "opaque",
  }) => {
    const isTranslucent = background === "translucent";
    return {
      position: "relative",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      height: "45px",
      width: fullWidth ? "100%" : undefined,
      padding: "4px",
      borderRadius: "48px",
      textDecoration: "none",
      background: isTranslucent ? "rgba(0,0,0,0.5)" : "#494949",
      cursor: "pointer",
      overflow: "hidden",
      userSelect: "none",
      [".switcher-indicator"]: {
        zIndex: 0,
        position: "absolute",
        content: "''",
        left: "4px",
        height: "calc(100% - 8px)",
        top: "4px",
        background: $white,
        borderRadius: "48px",
        transition: "transform 0.3s",
      },
    };
  }
);

export const SwitcherButton = styled.button.attrs<IButtonProps, IButtonProps>(
  ({ selected, ...props }) => {
    return {
      ...props,
      className: clsx("ro-switcher", {
        selected,
      }),
    };
  }
)(() => {
  return {
    zIndex: 1,
    display: "flex",
    alignItems: "center",
    height: "100%",
    padding: "0 16px",
    background: "none",
    border: "none",
    boxShadow: "none",
    cursor: "pointer",
    borderRadius: "48px",
    ["&[disabled] span"]: {
      opacity: 0.5,
    },
    ["&.selected"]: {
      padding: "0 16px 0 10px",
      ["i"]: {
        display: "block",
        marginRight: "4px",
      },
    },
    ["&:focus"]: {},
    ["span"]: {
      transform: "translateY(-1px)",
    },
    ["i"]: {
      display: "none",
    },
  };
});
