//
// Collection Category

import { app } from "./AppModel";
import CategoryModel from "./CategoryModel";
import LocalStorageCollection from "../prototypes/LocalStorageCollection";
import {
  GATSBY_CONF_CONSUMABLE_CATEGORY_ID,
  GATSBY_CONF_REORDER_CATEGORY_ID,
} from "@src/utils/constants";

export enum ECategorySlug {
  CONSUMABLE = 1,
  REORDER = 2,
}

export const CATEGORY_SLUGS = {
  [ECategorySlug.CONSUMABLE]: GATSBY_CONF_CONSUMABLE_CATEGORY_ID,
  [ECategorySlug.REORDER]: GATSBY_CONF_REORDER_CATEGORY_ID,
  // [ECategorySlug.BESTSELLER]: GATSBY_CONF_BESTSELLER_CATEGORY_ID,
  // [ECategorySlug.FAVORITES]: GATSBY_CONF_FAVORITES_CATEGORY_ID,
};

class CategoryCollection extends LocalStorageCollection<CategoryModel> {
  name = "CategoryCollection";
  route = "apiCategoryCollection";
  idAttribute = "id_category";
  comparator = "position";

  initialize(attributes: any, options: any) {
    var model = this;
    // ObjectCollection constructor heritage
    super.initialize.apply(model, [attributes, options]);
  }
  allMenuCategories(): CategoryCollection {
    return new CategoryCollection(
      this.filter((c) => {
        return !!c.get("active_menu") || !!c.get("is_navigation_by_desire");
      })
    );
  }
  menuCategories(): CategoryCollection {
    return new CategoryCollection(this.where({ active_menu: true }));
  }
  getActiveMenuInContext(): CategoryCollection {
    return new CategoryCollection(
      this.filter((c) => {
        return (
          !!c.get("active_menu") && c.isActiveInContext(true, app.getCart())
        );
      })
    );
  }
  navigationbyDesireCategories(): CategoryCollection {
    return new CategoryCollection(
      this.where({ is_navigation_by_desire: true })
    );
  }
  filterByProduct(id_product: string): CategoryModel[] {
    return this.filter(function (category) {
      return category.containsId(id_product);
    });
  }
  firstActiveInContext() {
    var cart = app.getCart();
    return this.filter(function (category) {
      return (
        category.isVisibleInContext(cart) &&
        !category.isEmpty2(true, true, cart)
      );
    }).shift();
  }

  onSync() {}
}
CategoryCollection.prototype.idAttribute = "id_category";
CategoryCollection.prototype.model = CategoryModel;
CategoryCollection.prototype.url = function () {
  return "/category";
};

export default CategoryCollection;
