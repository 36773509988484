import { useCallback, useEffect, useState } from "react";
// import { gapi, loadClientAuth2 } from "gapi-script";
import { GATSBY_CONF_GOOGLE_API_CONNECT_ID } from "@src/utils/constants";

const googlePlatformSrc = "https://apis.google.com/js/platform.js";

const useGoogleConnectSdk = () => {
  const [buttonState, setButtonState] = useState<{
    button: HTMLButtonElement;
    onSuccess: (token_id: string) => void;
    onError: (error: any) => void;
  }>();
  const [auth2, setAuth2] = useState<any | null>(null);

  const getGoogleAuth2 = useCallback(() => {
    // console.log("getGoogleAuth2", auth2);
    if (auth2) return;
    //
    try {
      const gapi: any = window.gapi;
      // console.log("gapi", gapi);
      if (!gapi || !gapi.load) return;
      //
      console.info("Google API auth2 loading...");
      gapi.load("auth2", function () {
        console.info("Google API auth2 loaded");
        // Retrieve the singleton for the GoogleAuth library and set up the client.
        setAuth2(
          gapi.auth2.init({
            client_id: GATSBY_CONF_GOOGLE_API_CONNECT_ID,
            cookiepolicy: "single_host_origin", // Request scopes in addition to 'profile' and 'email'
            //scope: 'additional_scope'
          })
        );
      });
    } catch (err: any) {
      console.log("google auth2 error", err);
      buttonState?.onError(err);
    }
  }, [auth2, buttonState]);

  const handleClientLoad = useCallback(() => {
    console.log("handleClientLoad");
    getGoogleAuth2();
    // window.gapi?.load("client:auth2", (auth2) => {
    //   console.log("handleClientLoad Google SDK loaded", auth2);
    // });
  }, [getGoogleAuth2]);

  useEffect(() => {
    const d = document;
    const id = "google-connect-sdk";
    if (d.getElementById(id)) return;

    const script = d.createElement("script") as HTMLScriptElement;
    script.id = id;
    script.src = googlePlatformSrc;
    script.defer = true;
    script.type = "text/javascript";
    script.onload = handleClientLoad;
    setTimeout(function () {
      d.head.append(script);
    }, 300);
  }, [handleClientLoad]);

  useEffect(() => {
    if (buttonState?.button && auth2) {
      const button = buttonState?.button;
      if (!button) {
        console.warn("googleButton not exists");
        return;
      } else if (button.dataset.googleAttached === "attached") {
        return;
      } else {
        button.dataset.googleAttached = "attached";
      }
      console.info("attachGoogleSignin");
      auth2.attachClickHandler(
        button,
        {},
        function (googleUser: any) {
          try {
            const id_token = googleUser.getAuthResponse().id_token;
            buttonState.onSuccess(id_token);
          } catch (err: any) {
            buttonState.onError(err);
          }
        },
        function (error: any) {
          console.log("button error", error);
          buttonState.onError(error);
          //alert(JSON.stringify(error, undefined, 2));
        }
      );
    }
  }, [buttonState, auth2]);

  const attachGoogleSignIn = (
    button: HTMLButtonElement,
    onSuccess: (id_token: string) => void,
    onError: (err: any) => void
  ) => {
    getGoogleAuth2();
    if (!buttonState) {
      setButtonState({ button, onError, onSuccess });
    }
  };

  return {
    attachGoogleSignIn,
  };
};

export default useGoogleConnectSdk;
