import { SourceHTMLAttributes } from "react";
import { Layout } from "gatsby-plugin-image";

export enum ImageFit {
  COVER = "cover",
  CONTAIN = "contain",
  FILL = "fill",
}
export enum ImageLayout {
  FIXED = "fixed",
  CONSTRAINED = "constrained",
  FULL_WIDTH = "fullWidth",
}

export enum ImageFormat {
  AUTO = "auto",
  JPEG = "jpeg",
  JPG = "jpg",
  PNG = "png",
  WEBP = "webp",
}

export enum ImagePosition {
  CENTER = "center",
  TOP = "top",
  RIGHT_TOP = "right_top",
  RIGHT = "right",
  RIGHT_BOTTOM = "right_bottom",
  BOTTOM = "bottom",
  LEFT_BOTTOM = "left_bottom",
  LEFT = "left",
  LEFT_TOP = "left_top",
}

export interface ImageSize {
  aspectRatio?: number;
  outputPixelDensities?: number[];
  width?: number;
  height?: number;
}

export interface ImageUrlParams {
  _rawUrl?: string;
  id: string | number;
  fit?: ImageFit;
  width?: number | "auto";
  height?: number | "auto";
  position?: ImagePosition;
  format?: ImageFormat;
  quality?: number | "auto";

  rewrite?: string;
  extension?: "jpg" | "png" | "";
}

export interface ImageData {
  id?: string | number;
  fit?: ImageFit;
  layout?: Layout;
  position?: ImagePosition;
  formats?: ImageFormat[];
  quality?: number;
  width?: number | "auto";
  height?: number | "auto";
  aspectRatio?: number;
  outputPixelDensities?: number[]; // layout = "fixed" | "constrained"
  breakpoints?: number[]; // layout = "fullWidth"
  media?: string;
}
