import { PageProps } from "gatsby";
import { IsoLangWrapPageElement } from "@src/backbone/provider/IsoLangContext";
import React, { PropsWithChildren, startTransition, useEffect } from "react";
import {
  PageContextIntl,
  PageContextProvider,
} from "@src/components/general/WrapPageElement/PageContext";
import usePageLoad from "@src/hooks/usePageLoad";
import { useDispatch } from "react-redux";
import { PageSlice } from "@src/store/reducers/PageSlice";

type WrapPageElementProps = PropsWithChildren<PageProps<any, PageContextIntl>>;

// interface IDataTcValue {
//   value: string;
// }

// interface IDataTcUrls {
//   _TAGCOMMANDERURLHEADER_: IDataTcValue;
//   _TAGCOMMANDERURLFOOTER_: IDataTcValue;
//   site: {
//     siteMetadata: {
//       siteUrl: string;
//     };
//   };
// }

const WrapPageElement = React.memo((props: WrapPageElementProps) => {
  const { children, pageContext, location } = props;
  const dispatch = useDispatch();

  const { TrackPageView } = usePageLoad();

  useEffect(() => {
    const initialPageLoad = !location?.state;
    //const initialPageLoad = true;
    startTransition(() => {
      requestAnimationFrame(() => {
        TrackPageView(pageContext.pageTemplate, pageContext, initialPageLoad);
      });
      dispatch(PageSlice.actions.setCurrentPageContextIntl(pageContext));
    });
  }, [pageContext]);

  return (
    <div>
      <IsoLangWrapPageElement pageContext={pageContext}>
        <PageContextProvider value={{ pageContext }}>
          {children}
        </PageContextProvider>
      </IsoLangWrapPageElement>
    </div>
  );
});

export default WrapPageElement;
