import {
  IAlertNoticeContextState,
  IDisplayModalContentType,
} from "@src/lib/alert-notice/AlertNoticeContext";
import { IConfirmOptions } from "../interface/Alert";
import AppModel from "../model/AppModel";
import { nanoid } from "nanoid";
import store from "@src/store";
import { AppSlice } from "../../store/reducers/AppSlice/index";

export const initAppEvents = (
  app: AppModel,
  notification: IAlertNoticeContextState
) => {
  const callback =
    (type: "alert" | "confirm") =>
    (data: IConfirmOptions, _callback?: (result?: any) => void) => {
      const { title, subtitle, validation, cancel, template } = data;

      notification.displayMsg({
        type: type,
        title: title,
        message: subtitle,
        template,
        onOkText: validation,
        onCancelText: cancel,
        onOk: () => {
          _callback && _callback(true);
          return true;
        },
        onCancel: () => {
          _callback && _callback(false);
        },
      });
    };

  const onConfirm = (
    data: IConfirmOptions,
    _callback?: (value: boolean) => void
  ) => {
    const { title, subtitle, validation, cancel, template } = data;
    notification.displayMsg({
      type: "confirm",
      title: title,
      message: subtitle,
      onOkText: validation,
      onCancelText: cancel,
      template,
      onOk: () => {
        _callback && _callback(true);
        return true;
      },
      onCancel: () => {
        _callback && _callback(false);
      },
    });
  };
  const onAlertNotice = (data: IConfirmOptions, cb?: (result: any) => void) => {
    callback("alert")(data, cb);
  };
  const promptNotice = (data: IConfirmOptions, cb?: (result: any) => void) => {
    callback("alert")(data, cb);
  };
  const bottomNotice = (data: IConfirmOptions) => {
    const id = nanoid();
    notification.bottomNotice?.open({
      key: id,
      placement: "bottomLeft",
      content: data.subtitle,
    });
  };
  const banNotice = (data: IConfirmOptions) => {
    callback("alert")(data);
  };

  app.on("alertNotice", onAlertNotice);
  app.on("promptNotice", promptNotice);
  app.on("confirmNotice", onConfirm);
  app.on("bottomNotice", bottomNotice);
  app.on("banNotice", banNotice);

  // displayModal
  const handleDisplayModal = (content: IDisplayModalContentType) => {
    notification.displayMsg({
      type: "modal",
      content,
    });
  };
  app.on("displayModal", handleDisplayModal);
  //   router.on("ajaxloaded", appView.ajaxLoaded, appView);
  //   router.on("templateCategory", appView.templateCategory, appView);
  //   router.on("displayMicrotemplating", appView.displayMicrotemplating, appView);
  //
  //   app.on("ws__notice", appView.wsNotice, appView);

  //   app.on("networkError", appView.onNetworkError, appView);

  //   app.on("submitForm", appView.submitForm, appView);

  //   app.on(
  //     "openSubscribePostcodeView",
  //     appView.openSubscribePostcodeView,
  //     appView
  //   );
  app.on("openConfirmationCodePromptDialogView", (data, _callback) => {
    store.dispatch(
      AppSlice.actions.openSmsMode({
        data,
        callback: _callback,
      })
    );
  });
  //   app.on("toggleTopBanner", appView.onToogleTopBanner, appView);

  return () => {
    app.off("alertNotice", onAlertNotice);
    app.off("promptNotice", promptNotice);
    app.off("confirmNotice", onConfirm);
    app.off("bottomNotice", bottomNotice);
    app.off("banNotice", banNotice);
    app.off("displayModal", handleDisplayModal);
  };
};
