//
// Collection CartProduct
//

import ObjectCollection from "../prototypes/ObjectCollection";
import CartProductModel from "./CartProductModel";
import CartModel, { ICartProductSidedish } from "@src/backbone/model/CartModel";
import _ from "underscore";
import { ICartData_Products } from "@src/interface/Cart";
import ApiRequest from "../prototypes/ApiRequest";
import { app } from "./AppModel";
import OrderModel from "./OrderModel";

export type CartProductType =
  | "products"
  | "consumables"
  | "unavailable_products"
  | "extraPrice"
  | "accompagnements";

class CartProductCollection extends ObjectCollection<CartProductModel> {
  name = "CartProductCollection";
  route = "apiCartProductCollection";
  idAttribute = "id_cart_product";
  cart: CartModel | OrderModel;
  type: CartProductType = "products";
  constructor(
    models: Record<string, any>[] | CartProductModel[],
    options: { cart: CartModel | OrderModel; type?: CartProductType }
  ) {
    super(models, options);
    this.cart = options.cart;
    if (options.type) {
      this.type = options.type;
    }
  }

  initialize(
    models?: Record<string, any>[] | CartProductModel[],
    options?: any
  ) {
    // ObjectCollection constructor heritage
    super.initialize.apply(this, [models, options]);
    //
    this.on("sync", this.onSync, this);
    //
  }
  onSync(arg0: string, onSync: any, arg2: this) {
    // throw new Error("Method not implemented.");
  }
  attachCart(
    cart: CartModel | OrderModel,
    type: CartProductType,
    model?: CartProductModel
  ) {
    // attache une référence panier au Produit
    // obligatoire pour obtenir des prix contextualisés
    //
    const collection = this;
    // baseModel, permet de gérer le cas général (cart)
    // et le cas d'un produit attaché à un autre (accompagnement)
    let wrappedProducts: object[] = []; // récupère les produits

    if (model) {
      wrappedProducts = model.get(type) || [];
      model.on("change:" + type, collection.changeModelProducts, collection);
    } else {
      // @ts-ignore
      wrappedProducts = cart.get(type as any) || [];
      switch (type) {
        case "products":
        case "unavailable_products":
        case "consumables":
        case "extraPrice":
          collection.listenTo(
            cart,
            "change:" + type,
            collection.changeModelProducts
          );
          break;
      }
    }
    //
    collection.cart = cart;
    collection.type = type;
    collection.on("change add remove delete", collection.onChange, collection);
    //
    const models =
      // map la référence du panier sur les produits
      wrappedProducts.map(function (product) {
        return { ...product, cart: cart, type: type };
      });
    //
    // ajoutes les models à la collection
    //
    collection.reset(models);
    //
    // suppression des références circulaire au panier
    _(models).each(function (ref) {
      // @ts-ignore
      delete ref.cart;
    });
  }
  getProductsByUniqueId(id_product: string | number) {
    const collection = this;
    const products: CartProductModel[] = [];
    collection.each(function (p) {
      if (p.id === id_product || p.getProduct()?.id === id_product) {
        products.push(p);
      }
      _(p.get("accompagnements")).each((a) => {
        if (a.id_product === id_product) {
          products.push(p);
        }
      });
    });
    return products;
  }
  onChange() {
    // quand la collection change...
    // on trigger sur le panier un productsChange
    this.cart.trigger("productsChange", this.cart);
  }
  changeModelProducts(
    baseModel: CartModel | any,
    products: ICartData_Products[]
  ) {
    // baseModel peut être ou bien le panier, ou bien un menu
    this.set(products);
  }
  updateProduct(
    method: "POST" | "DELETE",
    id_cart_product: string | number,
    quantity?: number,
    options?: {
      sidedish: ICartProductSidedish;
    }
  ) {
    id_cart_product = id_cart_product + "";

    const collection = this,
      cart = collection.cart,
      product = collection.findWhere({ id_cart_product: id_cart_product });
    let data: any = {},
      sidedish: ICartProductSidedish;

    if (product) {
      data = product.pick(["id_product", "id_cart_product"]);
    } else {
      data.id_product = id_cart_product;
    }

    if (!_.isUndefined(options)) {
      sidedish = options.sidedish;
      if (!_.isUndefined(sidedish)) {
        data.sidedish = sidedish;
      }
    }

    // si une quantité est envoyée, même en delete,
    // l'objet ne sera pas supprimé
    if (quantity !== 0) {
      // quantity est une différence pas une valeur
      data.quantity = quantity;
    }
    if (method === "DELETE") {
      // l'operation delete supprime tous les items
      delete data.quantity;
      // TODO: Tag commander
      // TagCommander.tc_delete_product(this,id_cart_product, quantity, 'block_checkout_products');
    } else {
      // TODO: Tag commander
      // TagCommander.tc_add_product(this,id_cart_product, quantity, 'block_product_options');
    }
    return new Promise((resolve, reject) => {
      ApiRequest(
        "CartProductCollection",
        data,
        { id_cart: cart.id || "current" },
        { method: method }
      )
        .then((data) => {
          setTimeout(() => {
            // @ts-ignore
            cart.set(data);
          }, 0);
          resolve(data);
        })
        .catch((err) => {
          /* switch (err.status) {
            case 0:
              app.trigger("networkError", err);
              break;
            default:
              app.alert(app.t("Le produit n'a pas pu être ajouté", "Cart"));
              break;
          } */
          app.alert({
            title: app.t("Le produit n'a pas pu être ajouté", "Cart"),
            subtitle: "",
          });
          cart.trigger("addProductFail", data);
          // TODO translation
          reject(err);
        });
    });
  }
  mulipleUpdateProduct(
    method: "DELETE" | "POST",
    multipleProduct: ICartData_Products[]
  ): Promise<any> {
    const collection = this,
      cart = collection.cart,
      products: {
        products: ICartData_Products[];
      } = { products: [] };

    _(multipleProduct).each((product) => {
      const id_cart_product = product.id_cart_product + "",
        quantity = product.quantity,
        data: any = {
          id_product: id_cart_product,
        };

      if (quantity !== 0) {
        data.quantity = quantity;
      }
      if (method === "DELETE") {
        // l'operation delete supprime tous les items
        delete data.quantity;
      }

      if (method === "DELETE") {
        //   TODO: Tag commander
        /* TagCommander.tc_delete_product(
          this,
          id_cart_product,
          quantity,
          "block_checkout_products"
        ); */
      } else {
        //   TODO: Tag commander
        /* TagCommander.tc_add_product(
          this,
          id_cart_product,
          quantity,
          "block_product_options"
        ); */
      }
      products.products.push(data);
    }, collection);
    return new Promise((resolve, reject) => {
      ApiRequest(
        "CartMultipleProductCollection",
        products,
        { id_cart: cart.id || "current" },
        { method: method }
      )
        .then(function (data) {
          // success
          // @ts-ignore
          cart.set(data);
          resolve(data);
        })
        .catch((error) => {
          // en cas d'erreur serveur
          // TODO translation
          app.alert({
            title: app.t("Le produit n'a pas pu être ajouté", "Cart"),
            subtitle: "",
          });
          cart.trigger("addProductFail", products);
          reject(error);
        });
    });
  }

  deleteProduct(id_cart_product: string | number, quantity?: number) {
    return this.updateProduct("DELETE", id_cart_product, quantity);
  }

  addMultipleProduct(multipleProduct: ICartData_Products[]) {
    return this.mulipleUpdateProduct("POST", multipleProduct);
  }

  addProduct(
    id_cart_product: string | number,
    quantity?: number,
    options?: any
  ) {
    return this.updateProduct("POST", id_cart_product, quantity || 1, options);
  }
  updateQuantityProduct(id_cart_product: string | number, quantity?: number) {
    return this.updateProduct("POST", id_cart_product, quantity);
  }
  async updateProductSidedish(
    id_cart_product: string | number,
    sidedish: ICartProductSidedish
  ): Promise<any> {
    const cartProduct = this.get(id_cart_product);
    if (cartProduct) {
      return await this.updateProduct("POST", id_cart_product, 0, {
        sidedish: sidedish,
      });
    }
  }
}

CartProductCollection.prototype.name = "CartProductCollection";
CartProductCollection.prototype.route = "apiCartProductCollection";
CartProductCollection.prototype.model = CartProductModel;
CartProductCollection.prototype.url = function () {
  return `"cart/${this.cart.id || "current"}/product`;
};
export default CartProductCollection;
