import React from "react";
import clsx from "clsx";
import { classPrefix, iconDb } from "../../lib/svgIcon/icon-db";
import { IIconProps } from "../../Interface/IconProps";
import { FontIconRoot } from "./FontIcon.styled";
import { FontIconName } from "../../lib/svgIcon/components";

export interface FontIconProps extends Omit<IIconProps, "icon"> {
  icon: FontIconName;
}

const FontIcon = (props: FontIconProps) => {
  const { icon, size = 24, color = "", className } = props;
  const iconDetails = iconDb[icon];

  return (
    <FontIconRoot
      $size={size}
      $color={color}
      className={clsx(
        classPrefix,
        `${classPrefix}-${iconDetails.filename || ""}`,
        className
      )}
    ></FontIconRoot>
  );
};

export default React.memo(FontIcon);
