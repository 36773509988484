import {
  useLazyGetHybridRoutingQuery,
  // useLazyGetSetupV2Query,
  // useLazyGetStockoutProductQuery,
  // useLazyGetStoreItemQuery,
} from "@src/services/rollingStartApi";
// import { useSelectedStoreId } from "@src/store/reducers/CartSlice/selectors";
import { useEffect } from "react";
// import IsoLangContext from "@src/backbone/provider/IsoLangContext";

const useSetup = () => {
  // const { isoLang } = useContext(IsoLangContext);

  // const selectedStoreId = useSelectedStoreId();
  const [fetchHybridRouting] = useLazyGetHybridRoutingQuery();
  // const [fetchSetupV2] = useLazyGetSetupV2Query();
  // const [fetchStockoutProduct] = useLazyGetStockoutProductQuery();
  // const [fetchSelectedStore] = useLazyGetStoreItemQuery();
  // useAllProduct(true);

  useEffect(() => {
    // if (!isoLang) return;
    console.log("isoLangContext");
    //   initialize hybrid Routing base data
    fetchHybridRouting(undefined, true);

    // setup private
    // fetchSetupV2(undefined, true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* useEffect(() => {
    if (cart.id_store) {
      fetchStockoutProduct({ id_store: cart.id_store });
      fetchSelectedStore({ id_store: cart.id_store });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStoreId]);*/
};

export default useSetup;
