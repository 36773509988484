import clsx from "clsx";
import React, {
  ReactNode,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from "react";
import FlexBox from "../../../Components/FlexBox/FlexBox";
import Tag from "../../../Components/Tag/Tag";
import useBreakpoint from "../../../Components/Grid/hooks/useBreakpoint";
import Typography from "../../../Foundation/Typography/Typography";
import {
  _ProductDetailsSelectionBody,
  _ProductDetailsSelectionRoot,
  _ProductDetailsSliderWrapper,
} from "./ProductDetailsSelection.styled";
import ProductDetailsSelectionProduct, {
  IProductDetailsSelectionProductProps,
} from "./ProductDetailsSelectionProduct/ProductDetailsSelectionProduct";

export interface IProductDetailsSelectionProduct {
  picture: {
    id: string;
    alt: string;
  };
  id: string;
  name: string;
  subtitle?: ReactNode;
  price?: string;
  max?: number;
  disabled?: boolean;
  editable?: boolean;
}

export type ISelectionValue = Record<string, number>;

export interface IProductDetailsSelectionProps<T extends any> {
  title?: ReactNode;
  mt?: number;
  mb?: number;
  size?: IProductDetailsSelectionProductProps["size"];
  tag?: {
    text: string;
    variant?: "selection-default" | "selection-danger";
  };
  options: IProductDetailsSelectionProduct[];
  value?: ISelectionValue;
  onChange?: (value: ISelectionValue, metadata?: T) => void;
  isMultiple?: boolean;
  min?: number;
  max?: number;
  type?: "default" | "slider";
  metadata?: T;
  renderMinErrorLabel?: (rest: number) => string;
  htmlId?: string;
  showValidation?: boolean;
  hasError?: boolean;
}

const ProductDetailsSelection = <T extends any = any>(
  props: IProductDetailsSelectionProps<T>
) => {
  const {
    title,
    mt,
    mb,
    tag,
    options,
    size,
    value: defaultValue = {},
    onChange,
    isMultiple,
    max = 1,
    min = 0,
    type = "default",
    renderMinErrorLabel,
    metadata,
    htmlId,
    showValidation,
    hasError,
  } = props;
  const isSlider = type === "slider";
  const [value, setValue] = useState<ISelectionValue>(defaultValue);
  const { isMobile } = useBreakpoint();

  const totalSelected: number = useMemo(() => {
    return Object.values(value).reduce((p, c) => p + c, 0);
  }, [value]);
  const rest: number = useMemo(() => {
    return Math.max(max - totalSelected, 0);
  }, [value]);

  useEffect(() => {
    if (
      defaultValue &&
      JSON.stringify(defaultValue) !== JSON.stringify(value)
    ) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const handleQuantityChange: IProductDetailsSelectionProductProps["onQuantityChange"] =
    useCallback(
      (data: { id: string; quantity: number }) => {
        setValue((currentValue) => {
          let _newValue = { ...currentValue };
          const { id, quantity } = data;
          if (isMultiple) {
            _newValue[id] = quantity;
          } else {
            if (_newValue[id]) {
              _newValue = { [id]: 0 };
            } else {
              _newValue = { [id]: 1 };
            }
          }
          setTimeout(() => {
            onChange && onChange(_newValue, metadata);
          }, 0);
          return _newValue;
        });
      },
      [onChange, metadata]
    );

  // slider render
  if (isSlider) {
    return (
      <_ProductDetailsSliderWrapper id={htmlId}>
        {options &&
          options.map((item) => {
            const optionQuantity = value[item.id] || 0;
            return (
              <ProductDetailsSelectionProduct
                key={item.id}
                size={"medium"}
                product={item}
                quantity={optionQuantity}
                selected={optionQuantity > 0}
                onQuantityChange={handleQuantityChange}
                max={
                  item.max && item.max > 0
                    ? Math.min(rest + optionQuantity, item.max)
                    : rest + optionQuantity
                }
                alwayshowQuantity={isMultiple}
                vertical={true}
              />
            );
          })}
      </_ProductDetailsSliderWrapper>
    );
  }

  // default render
  return (
    <_ProductDetailsSelectionRoot
      className={clsx("sidedish-selection", {
        "sidedish-error": hasError,
      })}
      id={htmlId}
      $mt={mt}
      $mb={mb}
    >
      {!isSlider && (
        <FlexBox
          justify="space-between"
          align={"center"}
          wrap={isMobile ? "wrap" : undefined}
        >
          <Typography
            variant="h7"
            className={"sidedish-selection__title"}
            mb={4}
          >
            {title}
          </Typography>
          {min && totalSelected < min && max !== 1 && min !== 1 ? (
            <Tag
              variant={
                showValidation && hasError
                  ? "selection-danger"
                  : "selection-default"
              }
            >
              {renderMinErrorLabel
                ? renderMinErrorLabel(min - totalSelected)
                : `Ajoutez encore ${min - totalSelected} produit`}
            </Tag>
          ) : (
            tag && (
              <Tag variant={tag.variant || "selection-default"}>{tag.text}</Tag>
            )
          )}
        </FlexBox>
      )}
      <_ProductDetailsSelectionBody>
        {options &&
          options.map((item) => {
            const optionQuantity = value[item.id] || 0;
            return (
              <ProductDetailsSelectionProduct
                key={item.id}
                size={size}
                product={item}
                quantity={optionQuantity}
                selected={optionQuantity > 0}
                onQuantityChange={handleQuantityChange}
                max={
                  item.max && item.max > 0
                    ? Math.min(rest + optionQuantity, item.max)
                    : rest + optionQuantity
                }
                alwayshowQuantity={isMultiple}
              />
            );
          })}
      </_ProductDetailsSelectionBody>
    </_ProductDetailsSelectionRoot>
  );
};

export default ProductDetailsSelection;
