import {
  TRollingStartFlexBoxLink,
  TRollingStartLink,
  TRollingStartStyledLink,
  TRollingStartTypographyLink,
} from "../Link";
import { Context, createContext } from "react";
import DefaultLinkWrapper from "./DefaultLinkWrapper";
import DefaultStyledLink from "./DefaultStyledLink";
import DefaultTypographyLink from "./DefaultTypographyLink";
import DefaultFlexBoxLink from "./DefaultFlexBoxLink";

type LinkContextType<D = any> = {
  LinkWrapper: TRollingStartLink<D>;
  StyledLink: TRollingStartStyledLink<D>;
  TypographyLink: TRollingStartTypographyLink<D>;
  FlexBoxLink: TRollingStartFlexBoxLink<D>;
};

const LinkContext = createContext<LinkContextType>({
  LinkWrapper: DefaultLinkWrapper,
  StyledLink: DefaultStyledLink,
  TypographyLink: DefaultTypographyLink,
  FlexBoxLink: DefaultFlexBoxLink,
});
export default LinkContext;
