//
// Model Setup Private
//

import ObjectModel from "../prototypes/ObjectModel";
import CartModelModule from "./CartModelModule";
import AddressCollection from "@src/backbone/model/AddressCollection";
import CustomerModel from "@src/backbone/model/CustomerModel";
import CartModel from "@src/backbone/model/CartModel";
import SyncModel from "@src/backbone/model/SyncModel";
import MessageModel from "@src/backbone/model/MessageModel";
import store from "@src/store";
import ObjectCollection from "../prototypes/ObjectCollection";
import { mainCustomerInstance } from "../provider/ModelProvider";

async function loadClassDynamically(filename: string) {
  try {
    const module = await import(`@src/backbone/model/${filename}`);
    return module.default;
  } catch (error) {
    return undefined;
  }
}

export let setupPrivate: SetupPrivateModel;
export interface SetupPrivateObjects extends Backbone.ObjectHash {
  address?: AddressCollection;
  cart?: CartModel;
  customer?: CustomerModel;
  sync?: SyncModel;
  message?: MessageModel;
}
export const privateObjects: SetupPrivateObjects = {};

class SetupPrivateModel extends ObjectModel<any> {
  name = "SetupPrivateModel";
  route = "apiSetupPrivateModel";
  isSync = false;
  privateApi = true;
  defaults() {
    return {
      address: [],
      cart: { id_cart: "current" },
      customer: {},
      sync: false,
      message: [],
    };
  }

  initialize(attributes?: any, options?: any): void {
    // ObjectModel constructor heritage
    super.initialize.apply(this, [attributes, options]);
    setupPrivate = this;
    // setupPrivate.on("sync", setupPrivate.onSetupPrivate, setupPrivate);
    // force la synchronisation distante
  }
  setup() {
    return new Promise<SetupPrivateObjects>((resolve, reject) => {
      const {
        app: { runContext },
      } = store.getState();
      //console.log("SetupPrivateModel.setup", runContext);
      if (runContext === "ssr") {
        console.error("SetupPrivateModel.setup: runContext === ssr");
        throw new Error("SetupPrivateModel.setup: runContext === ssr");
      } else if (!setupPrivate.get("sync")) {
        setupPrivate
          .fetch()
          .then((d) => {
            // console.log("---private-done", d);
            try {
              this.onSetupPrivate()
                .then((dp) => {
                  resolve(dp);
                })
                .catch(reject);
            } catch (error) {}
          })
          .catch(reject);
      } else {
        setupPrivate.trigger("setupPrivate", privateObjects);
        resolve(privateObjects);
      }
    });
  }
  /* url() {
    var store_preset = site_vars.store_preset;
    let url = super.url();
    if (store_preset) {
      url += "?store_preset=" + store_preset;
    }
    return url;
  } */
  updateSync(wsMessage: { data: any }) {
    var sync = privateObjects.sync;
    if (sync) {
      sync.set(wsMessage.data);
      //  TODO: implement reload sync
      //   window.location.reload(true);
    }
  }
  async onSetupPrivate() {
    setupPrivate.isSync = true;
    // instancie tous les objets issus du JSON en model et collection Backbone
    const tasks = Object.entries(setupPrivate.attributes).map(
      async ([key, object]) => {
        // essaie de fabriquer le constructeur des collections
        let constructorName: string = key[0].toUpperCase() + key.slice(1);
        let o;
        switch (key) {
          case "message":
          case "address":
            constructorName = constructorName + "Collection";
            break;
          default: // cart, customer, sync
            constructorName = constructorName + "Model";
        }
        try {
          if (key === "cart") {
            //
            // le constructeur panier est contextualisé
            // chaque panier a un constructeur unique, si on instancie plusieurs panier
            //
            // TODO: implement cart custructor
            // constructor = CartModel.getConstructor();
            o = privateObjects[key] = CartModelModule.getMainInstant(object);
            o.set(object);
            o.trigger("sync", o);
          } else {
            switch (key) {
              case "customer":
                o = privateObjects[key] = mainCustomerInstance.setData(object);
                o.set(object);
                o.trigger("sync", o);
                break;
              case "sync":
              case "message":
              case "cart":
              case "address":
                let ClassName = await loadClassDynamically(
                  constructorName + ".ts"
                );
                if (ClassName) {
                  o = privateObjects[key] = new ClassName(object);
                  o.trigger("sync", o);
                }
            }
          }
        } catch (err) {
          console.warn("[SetupPrivate]", err);
          const _constructor = class Collection extends ObjectCollection {
            name = constructorName;
          };
          //return;
          if (typeof _constructor == "function") {
            // si le constructeur existe
            // on construit la collection
            o = privateObjects[key] = new _constructor(object);
            o.trigger("sync", o);
          }
        }
      }
    );

    await Promise.allSettled(tasks);

    setupPrivate.trigger("setupPrivate");
    return privateObjects;
  }
}
SetupPrivateModel.prototype.url = () => "/setupprivate";
SetupPrivateModel.prototype.name = "SetupPrivateModel";
export default SetupPrivateModel;
