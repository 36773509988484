import * as React from "react";
import clsx from "clsx";
import RowContext from "../RowContext";
import { tuple } from "../_utils/type";
import ResponsiveObserve, {
  Breakpoint,
  ScreenMap,
  responsiveArray,
} from "../_utils/responsiveObserve";
import { _RowStyle } from "./Row.styled";
import { $rowPrefix } from "../GridGlobalCss";

const RowAligns = tuple("top", "middle", "bottom", "stretch");
const RowJustify = tuple(
  "start",
  "end",
  "center",
  "space-around",
  "space-between"
);

export type Gutter = number | Partial<Record<Breakpoint, number>>;
export interface RowProps extends React.HTMLAttributes<HTMLDivElement> {
  gutter?: Gutter | [Gutter, Gutter];
  align?: typeof RowAligns[number];
  justify?: typeof RowJustify[number];
  prefixCls?: string;
  wrap?: boolean;
}

const Row = React.forwardRef<HTMLDivElement, RowProps>((props, ref) => {
  const {
    justify,
    align,
    className,
    style,
    children,
    gutter = [
      {
        xs: 4,
        sm: 4,
        md: 8,
        lg: 8,
        xl: 8,
        xxl: 8,
      },
      {
        xs: 4,
        sm: 4,
        md: 8,
        lg: 8,
        xl: 8,
        xxl: 8,
      },
    ],
    wrap,
    ...others
  } = props;

  const [screens, setScreens] = React.useState<ScreenMap>({
    xs: true,
    sm: true,
    md: true,
    lg: true,
    xl: true,
    xxl: true,
  });

  const gutterRef = React.useRef<Gutter | [Gutter, Gutter]>(gutter);

  React.useEffect(() => {
    const token = ResponsiveObserve.subscribe((screen: any) => {
      const currentGutter = gutterRef.current || 0;
      if (
        (!Array.isArray(currentGutter) && typeof currentGutter === "object") ||
        (Array.isArray(currentGutter) &&
          (typeof currentGutter[0] === "object" ||
            typeof currentGutter[1] === "object"))
      ) {
        setScreens(screen);
      }
    });
    return () => ResponsiveObserve.unsubscribe(token);
  }, []);

  const getGutter = (): [number, number] => {
    const results: [number, number] = [0, 0];
    const normalizedGutter = Array.isArray(gutter) ? gutter : [gutter, 0];
    normalizedGutter.forEach((g, index) => {
      if (typeof g === "object") {
        for (let i = 0; i < responsiveArray.length; i++) {
          const breakpoint: Breakpoint = responsiveArray[i];
          if (screens[breakpoint] && g[breakpoint] !== undefined) {
            results[index] = g[breakpoint] as number;
            break;
          }
        }
      } else {
        results[index] = g || 0;
      }
    });
    return results;
  };

  const prefixCls = $rowPrefix;
  const gutters = getGutter();

  const classes = clsx(
    prefixCls,
    {
      [`${prefixCls}-no-wrap`]: wrap === false,
      [`${prefixCls}-${justify}`]: justify,
      [`${prefixCls}-${align}`]: align,
      [`${prefixCls}-rtl`]: false,
    },
    className
  );
  const rowStyle = {
    ...(gutters[0]! > 0
      ? {
          marginLeft: gutters[0]! / -2,
          marginRight: gutters[0]! / -2,
          // paddingLeft: Math.min(24, gutters[0]! * 4),
          // paddingRight: Math.min(24, gutters[0]! * 4),
        }
      : {}),
    ...(gutters[1]! > 0
      ? {
          marginTop: gutters[1]! / -2,
          marginBottom: gutters[1]! / 2,
        }
      : {}),
    ...style,
  };

  return (
    <RowContext.Provider value={{ gutter: gutters, wrap }}>
      <_RowStyle
        {...others}
        className={classes}
        style={rowStyle}
        ref={ref}
        $prefixCls={prefixCls}
        $justify={justify}
        $align={align}
      >
        {children}
      </_RowStyle>
    </RowContext.Provider>
  );
});

Row.displayName = "Row";

export default Row;
