import { graphql, useStaticQuery } from "gatsby";
import { UseAllIngredientQuery } from "@src/../graphql-types";
import { ArrayElement } from "@src/interface/utils";
import usePageLang from "../usePageLang";

export type IngredientItem = ArrayElement<
  UseAllIngredientQuery["allIngredient"]["nodes"]
>;

const useAllIngredient = (
  filter?: {
    lang?: string;
    id_ingredient?: string;
    in_glossary?: boolean;
  },
  predicate?: (ingredient: IngredientItem) => boolean
): IngredientItem[] => {
  const currentLang = usePageLang();

  const { allIngredient } = useStaticQuery<UseAllIngredientQuery>(graphql`
    query UseAllIngredient {
      allIngredient(sort: { name: ASC }) {
        nodes {
          id_ingredient
          in_glossary
          lang
          name
          description
          relatedProducts {
            id_product
            name
            uri
          }
        }
      }
    }
  `);

  const data = allIngredient.nodes.filter((node) => node.lang === currentLang);

  return filter || predicate
    ? data.filter((ingredient) => {
        let matchFilter = true;
        Object.entries(filter || {}).forEach(([k, v]) => {
          if (v && ingredient[k as keyof typeof ingredient] !== v) {
            matchFilter = false;
          }
        });

        return matchFilter && (predicate ? predicate(ingredient) : true);
      })
    : data;
};

export default useAllIngredient;
