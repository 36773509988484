import { ReactNode } from "react";

export enum EIconFamily {
  NAVIGATION = "navigation",
  BUSINESS = "business",
  GEOMETRICAL = "geometrical",
  MATHS = "maths",
  ARROWS = "arrows",
  MISC = "misc",
  LOCATION = "location",
  IDENTITY = "identity",
  BRANDS = "brands",
  OLD = "old",
  INFORMATION = "information",
  FINANCIAL = "financial",
  MY_SUSHI_SHOP = "my-sushi-shop",
}

export type EIconSize =
  | 14
  | 16
  | 18
  | 24
  | 32
  | 40
  | 54
  | 56
  | 64
  | 66
  | 76
  | 77
  | 80
  | 84
  | 86
  | 96
  | 98
  | 132
  | 156
  | 208;
export interface Icon {
  name: string;
  filename: string;
  /**
   * Fill if you want to generate his font type (0xe000 )
   */
  unicode?: number;
  family: EIconFamily;
  variant: EIconSize;
}

export interface IconComponent extends Icon {
  svgPath?: ReactNode;
}
