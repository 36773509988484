//
// Collection PaymentBrand

import PaymentBrandModel from "./PaymentBrandModel";
import LocalStorageCollection from "../prototypes/LocalStorageCollection";

//

class PaymentBrandCollection extends LocalStorageCollection<PaymentBrandModel> {
  name = "PaymentBrandCollection";
  route = "apiPaymentBrandCollection";
  idAttribute = "id_payment_brand";

  initialize(
    models?: Record<string, any>[] | PaymentBrandModel[],
    options?: any
  ): void {
    //   initialize() {
    // ObjectCollection constructor heritage

    super.initialize.apply(this, [models, options]);
    //
  }
  byIds(idBrands: Array<string | number>) {
    var filtred = this.filter(function (paymentBrand) {
      return idBrands.indexOf(paymentBrand.id) >= 0;
    });
    return new PaymentBrandCollection(filtred);
  }
}

PaymentBrandCollection.prototype.model = PaymentBrandModel;
PaymentBrandCollection.prototype.name = "PaymentBrandCollection";
PaymentBrandCollection.prototype.route = "apiPaymentBrandCollection";
PaymentBrandCollection.prototype.model = PaymentBrandModel;
export default PaymentBrandCollection;
