import React from "react";
import { PropsWithChildren } from "react";
import Typography, {
  ITypographyProps,
} from "design-system/src/Foundation/Typography/Typography";
import SpanButton from "design-system/src/General/Reset/SpanButton/SpanButton";
import styled from "styled-components";
import { defaultTheme } from "design-system/src/Themes/defaultTheme";
import { TypographyScale } from "design-system/src/Foundation/Typography/Typography.styles";

const _AlternateSmallButton = styled(Typography)((props) => {
  return {
    padding: "min(12px, 1vh) 0",
    width: "100%",
    fontSize: TypographyScale.button2.fontSize,
    color: defaultTheme.color.champagne3,
    cursor: "pointer",
  };
});

const AlternateSmallButton = (props: PropsWithChildren<ITypographyProps>) => {
  return (
    <_AlternateSmallButton
      as={SpanButton}
      variant="button2"
      {...props}
      align={"center"}
    />
  );
};

export { AlternateSmallButton };
