import React, { ComponentProps, CSSProperties } from "react";
import styled from "styled-components";
import Typography from "../../Foundation/Typography/Typography";

export interface DividerProps {
  direction?: "vertical" | "horizontal";
  size?: number;
  weight?: number;
  mt?: CSSProperties["marginTop"];
  mb?: CSSProperties["marginBottom"];
  text?: string;
  color?: string;
  opacity?: CSSProperties["opacity"];
  bg?: string;
}

const DividerStyled = styled("div")<DividerProps>(
  ({
    direction = "vertical",
    size,
    weight = 2,
    mt,
    mb,
    color = "#E8E8E8",
    opacity,
  }) => {
    const length = size ? size : "100%";
    const fat = weight;
    let width = direction === "vertical" ? fat : length;
    let height = direction === "vertical" ? length : fat;
    return {
      width,
      height,
      backgroundColor: color,
      marginBottom: mb,
      marginTop: mt,
      position: "relative",
      opacity,
    };
  }
);

const Divider = (props: DividerProps) => {
  const { text, bg = "#fff", ...rest } = props;
  return (
    <DividerStyled {...rest}>
      {text && (
        <Typography
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: bg,
            padding: "0 15px",
          }}
          variant="body2"
          color="greyText"
        >
          {text}
        </Typography>
      )}
    </DividerStyled>
  );
};

export default Divider;
