import styled, { CSSProperties } from "styled-components";
import { useTheme } from "../../Themes/defaultTheme";
import { CheckBoxProps } from "./CheckBox";

export const _CheckBoxRoot = styled("label")<{
  $disabled?: boolean;
  $linkInLabel?: boolean;
  $paddingLeft?: number;
  $align: CheckBoxProps["align"];
}>(({ $disabled, $align, $linkInLabel, $paddingLeft = 32 }) => {
  const { color } = useTheme();
  const width = 16;
  const checkmarkPlacement: CSSProperties = {
    top: "50%",
    transform: "translateY(-50%)",
    left: 0,
  };

  if ($align === "top") {
    checkmarkPlacement.top = 8;
    checkmarkPlacement.left = ($paddingLeft - width) / 2;
    checkmarkPlacement.transform = undefined;
  }

  return {
    position: "relative",
    paddingLeft: $paddingLeft,
    cursor: $linkInLabel ? "default" : "pointer",
    display: "inline-block",
    opacity: $disabled ? "0.5" : 1,
    userSelect: "none",
    ["& input"]: {
      position: "absolute",
      opacity: 0,
      cursor: "pointer",
      height: 0,
      width: 0,
    },

    ["& .checkmark"]: {
      position: "absolute",
      ...checkmarkPlacement,
      width: width,
      height: width,
      border: `1px solid ${color.greyText}`,
      borderRadius: 2,
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      ["& .checkmark_icon"]: {
        display: "none",
      },
    },

    ["& input:checked ~ .checkmark"]: {
      backgroundColor: color.champagne,
      borderColor: color.champagne,
      ["& .checkmark_icon"]: {
        display: "block",
        path: {
          fill: "white",
        },
      },
    },
    ["a"]: {
      color: "inherit",
      textDecoration: "underline",
      "&:hover": {
        color: color.champagne,
        textDecoration: "underline",
      },
    },
  };
});
