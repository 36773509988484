import React from "react";

export default function useObserver(
  elRef: React.RefObject<HTMLElement>,
  onResize: ResizeObserverCallback
) {
  const observer = React.useRef(
    typeof ResizeObserver !== "undefined" ? new ResizeObserver(onResize) : null
  );

  React.useEffect(() => {
    if (elRef.current && observer.current) {
      observer.current.observe(elRef.current);
    }

    return () => {
      if (elRef.current && observer.current) {
        observer.current.unobserve(elRef.current);
      }
    };
  }, [elRef, observer]);
}
