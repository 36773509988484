import React, { ElementType } from "react";
import { ITheme, IThemeMode, IThemeVariant } from "../../Themes/defaultTheme";
import Icon, { IconProps } from "../Icon/Icon";
import { useButtonContext } from "./Button.context";
import {
  ButtonRoot,
  IButtonStyleConfig,
  SubmitButtonTheme,
} from "./Button.styles";

export interface IButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  theme?: ITheme;
  variant?: IThemeVariant;
  mode?: IThemeMode;
  color?: keyof SubmitButtonTheme;
  children: React.ReactNode;
  className?: string;
  as?: ElementType;
  href?: string;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
  buttonType?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
  rounded?: boolean;
  active?: boolean;
  textTransform?: "lowercase" | "uppercase";
  style?: React.CSSProperties;
  disabled?: boolean;
  loading?: boolean;
  fullWidth?: boolean;
  size?: "default" | "small" | "extra-large";
  ariaLabel?: string;
  icon?: IconProps["icon"];
  iconProps?: Omit<IconProps, "icon">;
  align?: React.CSSProperties["justifyContent"];
  [_: string]: any;
  beforeContent?: React.ReactNode;
  customize?: {
    color?: string;
    bg?: string;
    disabledBg?: string;
  } & Partial<IButtonStyleConfig>;
}

const Button = React.forwardRef<HTMLButtonElement, IButtonProps>(
  (
    {
      children,
      theme,
      variant,
      mode,
      icon,
      iconProps,
      loading,
      buttonType,
      beforeContent,
      style,
      as,
      customize,
      ...props
    },
    ref
  ) => {
    const buttonState = useButtonContext();
    if (theme) {
      variant = theme.split("_")[0] as IThemeVariant;
      mode = theme.split("_")[1] as IThemeMode;
    }
    const { color, hoverColor, bg, hoverBg, border, hoverBorder } = {
      ...customize,
      color: buttonState.textColor,
      hoverColor: buttonState.textColor,
      bg: buttonState.backgroundColor,
      hoverBg: buttonState.backgroundColor,
      border: buttonState.backgroundColor,
      hoverBorder: buttonState.backgroundColor,
      ...customize,
    };
    return (
      <ButtonRoot
        as={as as any}
        type={buttonType}
        ref={ref}
        disabled={props.disabled || loading}
        $mode={mode}
        $loading={loading}
        variant={variant}
        style={{
          ...style,
          background: undefined,
          border: undefined,
          textAlign: undefined,
          padding: undefined,
        }}
        customize={{ color, hoverColor, bg, hoverBg, border, hoverBorder }}
        {...props}
      >
        {icon ? <Icon icon={icon} {...iconProps} /> : beforeContent}
        {children && <span>{children}</span>}
      </ButtonRoot>
    );
  }
);

export default Button;
