import { Model, ModelSetOptions } from "backbone";
import ObjectCollection from "../prototypes/ObjectCollection";
import WidgetGroupModel from "./WidgetGroupModel";
//
// Collection WidgetGroup
//

class WidgetGroupCollection extends ObjectCollection<WidgetGroupModel> {
  name = "WidgetGroupCollection";
  route = "apiWidgetGroupCollection";
  idAttribute = "id_widget_group";
  model = WidgetGroupModel;

  initialize(
    models?: Record<string, any>[] | Model<any, ModelSetOptions, any>[],
    options?: any
  ): void {
    // ObjectCollection constructor heritage
    super.initialize.apply(this, [models, options]);
    //
  }

  getAllUrlRewrite(onlyUseJS: boolean): string[] {
    var urlRewrite: string[] = [];
    this.each(function (widgetGroup) {
      if (onlyUseJS !== true || widgetGroup.get("use_js")) {
        urlRewrite.push(widgetGroup.get("indentifiant"));
      }
    });
    return urlRewrite;
  }
}

WidgetGroupCollection.prototype.name = "WidgetGroupCollection";
WidgetGroupCollection.prototype.route = "apiWidgetGroupCollection";
WidgetGroupCollection.prototype.model = WidgetGroupModel;

export default WidgetGroupCollection;
