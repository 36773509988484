import Input, {
  EInputState,
  EInputVariant,
  InputProps,
} from "design-system/src/Form/Input/Input";
import { ControllerFieldState, FormState } from "react-hook-form";
import React, { FC } from "react";
import PhoneNumberInput from "design-system/src/Form/PhoneNumberInput/PhoneNumberInput";
import InputDate from "@src/components/DataDisplay/InputDate/InputDate";
import { FormRules } from "@src/components/feedback/AuthenticationContent/UseAuthenticationLogic";
import Typography from "design-system/src/Foundation/Typography/Typography";

export interface InputRenderProps extends InputProps {
  rules?: FormRules;
  fieldState: ControllerFieldState;
  MessageRender?: MessageRenderFC;
}
export type MessageRenderFC = FC<Omit<InputRenderProps, "MessageRender">>;

const DefaultMessageRender: MessageRenderFC = ({ fieldState }) => {
  const message = fieldState.error?.message;
  return !!message ? (
    <Typography variant={"ui4"} color={"error"}>
      {message}
    </Typography>
  ) : null;
};
const InputRender = (props: InputRenderProps) => {
  const {
    fieldState,
    variant = EInputVariant.FILL,
    MessageRender = DefaultMessageRender,
    rules,
    ...field
  } = props;
  const { name } = field;
  const InputFC: FC<any> =
    name === "birthday"
      ? InputDate
      : name === "phone"
      ? PhoneNumberInput
      : Input;
  const state = fieldState.error ? EInputState.ERROR : EInputState.DEFAULT;
  const message = <MessageRender {...{ fieldState, rules, ...field }} />;
  return (
    <InputFC
      state={state}
      message={message}
      variant={variant}
      data-cs-mask
      {...field}
    />
  );
};

export default InputRender;
