import { IMenu } from "@src/interface/Menu";
import { RollingStartEndpointBuilder } from "..";

export const createMenuEndpoints = (builder: RollingStartEndpointBuilder) => ({
  getMenuCollection: builder.query<IMenu[], void>({
    query: () => ({
      url: `/menu`,
      method: "GET",
    }),
  }),
});
