import ImageUrlContext from "design-system/src/Components/Image/Context/ImageUrlContext";
import React from "react";
import { PropsWithChildren } from "react";
import useSushiShopImage from "../hooks/useSushiShopImage";

export const ImageUrlProvider = ({ children }: PropsWithChildren<any>) => {
  const { generateImageUrl } = useSushiShopImage();
  return (
    <ImageUrlContext.Provider
      value={{
        generateImageUrl: generateImageUrl,
      }}
      children={children}
    />
  );
};
