import { useContext } from "react";
import { FallbackProps, SourceProps } from "./Picture/Picture";
import { ImageData, ImageFormat, ImageUrlParams } from "./interface/ImageData";
import ImageUrlContext from "./Context/ImageUrlContext";
import { maxSize } from "../Grid/_utils/size";

const AUTO = "auto";
const defaultImageFormats = [ImageFormat.WEBP, ImageFormat.AUTO];

const useImageLogic = () => {
  const { generateImageUrl } = useContext(ImageUrlContext);

  const generateImageAspect = (data: ImageData) => {
    const {
      id,
      aspectRatio,
      width = AUTO,
      height = AUTO,
      formats = defaultImageFormats,
      quality,
      layout = "constrained",
      breakpoints = [750, 1080, 1366, maxSize],
      outputPixelDensities = [],
      media,
    } = data;
    const sources: SourceProps[] = [];
    let fallbackSrc = "";
    const fallbackSrcSet: string[] = [];

    let autoAspectRatio: number | "auto" = aspectRatio || AUTO;
    let autoWidth: number | "auto" = width;
    let autoHeight: number | "auto" = height;
    let widthAttr: number | undefined = undefined;
    let heightAttr: number | undefined = undefined;
    if (width !== AUTO && height !== AUTO) {
      autoAspectRatio = width / height;
    }
    if (width === AUTO && height !== AUTO && autoAspectRatio !== AUTO) {
      autoWidth = Math.round(height * autoAspectRatio);
    } else if (height === AUTO && width !== AUTO && autoAspectRatio !== AUTO) {
      autoHeight = Math.round(width / autoAspectRatio);
    }
    if (autoAspectRatio !== AUTO) {
      autoHeight !== AUTO && (heightAttr = autoHeight);
      autoWidth !== AUTO && (widthAttr = autoWidth);
    }
    const imageParams: ImageUrlParams = {
      id: id || "-1",
      width: autoWidth,
      height: autoHeight,
      quality,
    };
    const hasAspectRatio = autoAspectRatio !== AUTO;
    const ratio = autoAspectRatio !== AUTO ? autoAspectRatio : 1;
    formats?.forEach((format) => {
      const isWebp = format === ImageFormat.WEBP;
      let sizes;
      const srcSet: string[] = [];
      let largestBreakpointSrc: string = "";
      function AppendSource(width: number, pixelDensity?: number) {
        const height = hasAspectRatio ? Math.round(width / ratio) : AUTO;
        const src = /http(s)?:\/\//.test(String(id))
          ? (largestBreakpointSrc = String(id))
          : (largestBreakpointSrc = generateImageUrl({
              ...imageParams,
              format,
              width,
              height,
            }));

        if (!isWebp) {
          if (pixelDensity === 1 || !fallbackSrc) {
            fallbackSrc = largestBreakpointSrc;
          }
          fallbackSrcSet.push(`${src} ${width}w`);
        } else {
          srcSet.push(`${src} ${width}w`);
        }
      }

      if (layout === "fullWidth") {
        sizes = "100vw";
        breakpoints.sort((a, b) => a - b);
        breakpoints.forEach((breakpoint) => {
          AppendSource(breakpoint, 1);
        });
      } else {
        const width100vw = autoWidth === AUTO ? 1920 : autoWidth;
        sizes =
          layout === "constrained"
            ? `(min-width: ${width100vw}px) ${width100vw}px, 100vw` // constrained
            : `${width100vw}px`; // fixed
        if (!outputPixelDensities.length) {
          if (layout === "constrained") {
            outputPixelDensities.unshift(0.25, 0.5, 1);
          } else {
            outputPixelDensities.unshift(1, 2);
          }
        }
        outputPixelDensities.forEach((pixelDensity) => {
          AppendSource(pixelDensity * width100vw, pixelDensity);
        });
      }
      if (isWebp) {
        const type = "image/" + format;
        sources.push({
          media,
          type,
          sizes,
          srcSet: srcSet.join(", "),
        });
      }
    });

    const fallback: FallbackProps = {
      src: fallbackSrc,
      srcSet: fallbackSrcSet.join(", "),
    };

    const isFullWidth = layout === "fullWidth";
    const isConstrained = layout === "constrained";
    const isFixed = layout === "fixed";

    return {
      sources,
      fallback,
      autoAspectRatio,
      autoWidth,
      autoHeight,
      widthAttr,
      heightAttr,
      wrapperStyle: {
        "--layout": layout,
        width: isFullWidth ? "100%" : isFixed ? autoWidth : undefined,
        maxWidth: isFullWidth ? "100%" : isConstrained ? autoWidth : undefined,
        maxHeight: isFullWidth
          ? undefined
          : isConstrained
          ? autoHeight
          : undefined,
        ...(isConstrained
          ? {
              maxWidth: "100%",
              width: "100%",
              height: "100%",
              maxHeight: "none !important",
            }
          : {}),
      },
      imgStyle: {
        width: widthAttr,
        height: heightAttr,
        ...(isConstrained
          ? { maxWidth: "100%", width: "100%", height: undefined }
          : {}),
      },
    };
  };

  return {
    generateImageAspect,
  };
};

export default useImageLogic;
