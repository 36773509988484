import React from "react";
import { IUseAuthenticationLogic } from "@src/components/feedback/AuthenticationContent/UseAuthenticationLogic";
import { useTranslation } from "@src/hooks/useTranslation";
import FlexBox from "design-system/src/Components/FlexBox/FlexBox";
import { Controller } from "react-hook-form";
import Input, {
  EInputState,
  EInputType,
  EInputVariant,
} from "design-system/src/Form/Input/Input";
import Button from "design-system/src/Foundation/Button/Button";
import { AlternateSmallButton } from "@src/components/feedback/AuthenticationContent/Form/AlternateSmallButton";
import { EAuthentificationStep } from "@src/components/feedback/AuthenticationContent/AuthenticationContent";
import InputRender from "@src/components/feedback/AuthenticationContent/Form/InputRender";
import SubmitButton from "@src/components/feedback/AuthenticationContent/Form/SubmitButton";
import CustomerFields from "@src/components/feedback/AuthenticationContent/Form/CustomerFields";

interface IResetPasswordStepProps {
  authentificationLogic: IUseAuthenticationLogic;
}
const SubmitPasswordStep = (props: IResetPasswordStepProps) => {
  const { t } = useTranslation(["Account", "UI"]);
  const { authentificationLogic } = props;
  const { control, setStep, onSubmit, isLoading, isValid, customerFields } =
    authentificationLogic;
  return (
    <FlexBox direction="column" gap={12} flex={1}>
      {<CustomerFields customerFields={customerFields} control={control} />}
      <SubmitButton loading={isLoading} onClick={onSubmit}>
        {t("Valider", "UI")}
      </SubmitButton>
    </FlexBox>
  );
};

export default SubmitPasswordStep;
