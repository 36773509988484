import styled from "styled-components";
import { IAccordionItemProps } from "./AccordionItem";

export const _AccordionItemRoot = styled("div")<{
  $first?: boolean;
  $borderColor?: string;
  $variant: IAccordionItemProps["variant"];
}>(({ theme, $first, $borderColor, $variant }) => {
  const isSolid = $variant === "beige";
  const isBeigeActive = $variant === "beige-active";
  const isBeigeDisabled = $variant === "beige-disabled";
  return {
    ...(!isSolid && {
      borderBottom: `1px solid ${$borderColor || theme.color.inputBorder}`,
      borderTop: $first ? `1px solid ${theme.color.inputBorder}` : undefined,
    }),

    ...(isSolid && {
      [".ro-accordion-item__header"]: {
        backgroundColor: theme.color.beigeBg,
        paddingLeft: 16,
        paddingRight: 10,
        borderRadius: 4,
      },
    }),
    ...(isBeigeActive && {
      padding: "24px 16px",
      [".ro-accordion-item__header"]: {
        padding: "0px",
      },
      backgroundColor: theme.color.beigeBg,
      borderRadius: 4,
    }),
    ...(isBeigeDisabled && {
      padding: "0px 16px",
      [".ro-accordion-item__header"]: {
        padding: "0px",
      },
      backgroundColor: "transparent",
      borderRadius: 4,
    }),

    [".icon-arrowhead"]: {
      marginRight: 4,
      transition: "all 0.35s ease",
    },

    ["&.active .icon-arrowhead"]: {
      transition: "all 0.35s ease",
      transform: "rotateZ(180deg)",
    },
  };
});

export const _AccordionItemHeader = styled("div")<{}>(({ theme }) => {
  return {
    padding: "16px 0px",
    cursor: "pointer",
    userSelect: "none",
  };
});
export const _AccordionItemPanel = styled("div")<{}>(() => {
  return {
    overflow: "hidden",
    maxHeight: 0,
    // transition: "max-height 0.5s cubic-bezier(0, 1, 0, 1)",

    ["&.active"]: {
      maxHeight: "initial",
      // transition: "max-height 1s ease-in-out",
    },
  };
});
