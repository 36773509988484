import styled, { CSSProperties } from "styled-components";
import { ITagProps, mapVariant } from "./Tag";
import { defaultTheme } from "../../Themes/defaultTheme";

export const _TagRoot = styled("div")<{
  $size: ITagProps["size"];
  $variant: ITagProps["variant"];
}>(({ $size = "small", $variant = "yellow", onClick }) => {
  const { color } = defaultTheme;
  const mapSize: Record<NonNullable<ITagProps["size"]>, CSSProperties> = {
    medium: {
      height: 36,
    },
    small: {
      height: 25,
    },
  };

  return {
    display: "inline-flex",
    justifyContent: "center",
    cursor: typeof onClick !== "undefined" ? "pointer" : undefined,
    alignItems: "center",
    borderRadius: 24,
    padding: "4px 16px",
    userSelect: "none",
    ...mapSize[$size],
    ...mapVariant[$variant],
  };
});
