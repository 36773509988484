import React, { lazy, Suspense } from "react";
import {
  LogoFacebookColorizedSvg,
  LogoGoogleColorizedSvg,
  LogoAppleColorizedSvg,
} from "../../Assets/images";
import { IIconProps } from "../../Interface/IconProps";
import { IconName } from "../../lib/svgIcon/components";
import FontIcon from "../FontIcon/FontIcon";
const SvgIcon = lazy(() => import("../SvgIcon/SvgIcon"));

type IconImage =
  | "logo_facebook_colorized"
  | "logo_google_colorized"
  | "logo_apple_colorized";

export interface IconProps extends Omit<IIconProps, "icon"> {
  type?: "svg" | "font";
  icon: IconName | IconImage;
}

const mapIconImage: Record<IconImage, string> = {
  logo_facebook_colorized: LogoFacebookColorizedSvg,
  logo_google_colorized: LogoGoogleColorizedSvg,
  logo_apple_colorized: LogoAppleColorizedSvg,
};

const Icon = ({ type = "svg", icon, ...rest }: IconProps) => {
  if (
    icon === "logo_facebook_colorized" ||
    icon === "logo_google_colorized" ||
    icon === "logo_apple_colorized"
  ) {
    return <img src={mapIconImage[icon]} style={{ width: rest.size }} />;
  }
  // -3.4 -3.4 30 30
  return type === "svg" ? (
    <Suspense fallback={<span data-icon-tofu />}>
      <SvgIcon icon={icon} {...rest} />
    </Suspense>
  ) : (
    <FontIcon icon={icon} {...rest} />
  );
};

export default Icon;
