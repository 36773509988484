import React from "react";
import {
  SpinnerRoot,
  SpinnerRootAbsolute,
  SpinnerSRStatus,
} from "./Spinner.styles";

export interface SpinnerPros {
  weight?: number;
  className?: string;
  absolute?: boolean;
  srText?: string;
  style?: React.CSSProperties;
}

const Spinner = (props: SpinnerPros) => {
  const {
    weight = 0.12,
    className,
    absolute = false,
    srText = "Loading...",
    style,
  } = props;
  const SpinnerRootComponent = absolute ? SpinnerRootAbsolute : SpinnerRoot;
  return (
    <SpinnerRootComponent className={className} $weight={weight} {...{ style }}>
      <SpinnerSRStatus>{srText}</SpinnerSRStatus>
    </SpinnerRootComponent>
  );
};

export default Spinner;
