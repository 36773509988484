import { ICartData } from "@src/interface/Cart";
import { CustomerData } from "@src/interface/Customer";
import { ProductApiData } from "@src/interface/Product";
import {
  setCartLoaded,
  updateCurrentCart,
} from "@src/store/reducers/CartSlice";
import { setCurrentCustomer } from "@src/store/reducers/CustomerSlice";
import { setProducts } from "@src/store/reducers/ProductSlice";
import { RollingStartEndpointBuilder } from "..";

export interface ISetupPrivateResponse {
  cart?: ICartData;
  customer?: CustomerData;
  sync?: any;
}

export interface ISetupV2Response {
  product?: ProductApiData[];
}

export const createSetupEndpoints = (builder: RollingStartEndpointBuilder) => ({
  getSetupPrivate: builder.query<ISetupPrivateResponse, void>({
    query: () => ({
      url: `/setupprivate`,
      method: "GET",
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      try {
        const res = await queryFulfilled;
        if (res.data.cart) {
          dispatch(updateCurrentCart(res.data.cart));
          dispatch(setCartLoaded(true));
        }

        if (res.data.customer) {
          dispatch(setCurrentCustomer(res.data.customer));
        }
      } catch (error) {}
    },
  }),
  getSetupV2: builder.query<ISetupV2Response, void>({
    query: () => ({
      url: `/setupv2`,
      method: "GET",
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      try {
        const res = await queryFulfilled;
        if (res.data.product) {
          dispatch(setProducts(res.data.product));
        }
      } catch (error) {}
    },
  }),
});
