import { useEffect, useState } from "react";
import decodeParamForKey from "./decodeParamForKey";
import {
  GATSBY_CONF_FACEBOOK_APP_ID_WEB,
  GATSBY_CONF_FACEBOOK_APP_VERSION,
} from "@src/utils/constants";

declare global {
  interface Window {
    FB: any;
    fbAsyncInit?: () => void;
  }
}

export interface IFacebookLoginResponse {
  authResponse?: {
    accessToken: string;
    data_access_expiration_time: number;
    expiresIn: number;
    graphDomain: string;
    signedRequest: string;
    userID: string;
  };
  status: string;
}

export interface IFacebookLoginProps {
  callback?: (response: IFacebookLoginResponse) => void;
  language?: string;
}

// TODO: Facebook Login
const useFacebookConnectSdk = (props: IFacebookLoginProps) => {
  const { callback, language = "en_US" } = props;
  // states
  const [isSdkLoaded, setIsSdkLoaded] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const initScript = () => {
    const elementTagName = "script";
    ((d, id) => {
      const element = d.getElementsByTagName(
        elementTagName
      )[0] as HTMLScriptElement;
      const fjs = element;
      let js: HTMLScriptElement = element;
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(elementTagName) as HTMLScriptElement;
      js.id = id;
      js.async = true;
      js.crossOrigin = "anonymous";
      js.src = `https://connect.facebook.net/${language}/sdk.js`;
      if (fjs.parentNode) {
        fjs.parentNode.insertBefore(js, fjs);
      }
    })(document, "facebook-connect-sdk");
  };

  const responseApi = (authResponse: any) => {
    /* window.FB.api(
      "/me",
      { locale: language, fields: this.props.fields },
      (me) => {
        Object.assign(me, authResponse);
        this.props.callback(me);
      }
    ); */
  };

  const onLoginResponse = (response: IFacebookLoginResponse) => {
    if (isMounted) {
      setIsProcessing(false);
    }
    if (response.authResponse) {
      callback && callback(response);
    }
  };

  const checkLoginAfterRefresh = (response: IFacebookLoginResponse) => {
    if (response.status === "connected") {
      onLoginResponse(response);
    } else {
      login();
    }
  };

  const login = () => {
    setIsProcessing(true);
    window.FB.login((loginResponse: any) => onLoginResponse(loginResponse), {
      scope: "email,public_profile,user_birthday",
    });
  };

  const isRedirectedFromFb = () => {
    const params = window.location.search;
    return (
      decodeParamForKey(params, "state") === "facebookdirect" &&
      (decodeParamForKey(params, "code") ||
        decodeParamForKey(params, "granted_scopes"))
    );
  };

  const setFbAsyncInit = () => {
    const fbAsyncInit = () => {
      window.FB?.init({
        appId: GATSBY_CONF_FACEBOOK_APP_ID_WEB,
        cookie: true,
        status: true,
        version: GATSBY_CONF_FACEBOOK_APP_VERSION,
      });
      setIsSdkLoaded(true);
      if (isRedirectedFromFb()) {
        window.FB.getLoginStatus(checkLoginAfterRefresh);
      }
    };
    window.fbAsyncInit = fbAsyncInit;
    fbAsyncInit();
  };

  useEffect(() => {
    setIsMounted(true);
    setFbAsyncInit();
    initScript();
    return () => {
      setIsMounted(false);
    };
  }, []);

  return {
    login,
    isSdkLoaded,
    isProcessing,
    isMounted,
  };
};

export default useFacebookConnectSdk;
