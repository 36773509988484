import SidedishModel, {
  SidedishSelection,
} from "@src/backbone/model/SidedishModel";
import { useTranslation } from "@src/hooks/useTranslation";
import ProductDetailsSelection, {
  IProductDetailsSelectionProduct,
  IProductDetailsSelectionProps,
  ISelectionValue,
} from "design-system/src/Core/ProductDetails/ProductDetailsSelection/ProductDetailsSelection";
import React, { useMemo, useCallback, useState, useEffect } from "react";

interface IProductSidedishItemProps {
  model: SidedishModel;
  onChange?: (attributes: SidedishModel) => void;
  showValidation?: boolean;
}

export const displayMapper: Record<
  0 | 1 | 2,
  IProductDetailsSelectionProps<any>["size"]
> = {
  0: "small",
  1: "medium",
  2: "large",
};

const ProductSidedishItem = (props: IProductSidedishItemProps) => {
  const { model, onChange, showValidation } = props;
  const { t } = useTranslation(["Product"]);
  const [isValide, setIsValide] = useState(true);
  const [sidedishSelection, setSidedishSelection] = useState<
    SidedishSelection[]
  >([]);

  const isMultiple: boolean = model.getIsMultipleByProduct();
  const max = model.getMaximum();

  const options: IProductDetailsSelectionProduct[] = useMemo(() => {
    const _list: IProductDetailsSelectionProduct[] = [];
    const products = model.getCategoryProducts();

    products?.each((product) => {
      const id_product = product.get("id_product");
      if (id_product) {
        const selection = sidedishSelection.find(
          (sel) => sel.id === id_product
        );
        const _picture = product.getPicture();
        _list.push({
          id: id_product,
          picture: _picture
            ? { id: _picture.id_object_picture, alt: _picture.legend }
            : { id: "-1", alt: "" },
          name: product.getName() || "",
          disabled: product.isStockout(),
          price: product.formatSidedishPrice("%s", model),
          editable: selection && selection.immutable === 1 ? false : true,
        });
      }
    });

    return _list;
  }, [model, sidedishSelection]);

  const handleChange = useCallback(
    (_value: ISelectionValue) => {
      Object.keys(_value).forEach((k) => {
        if (_value[k] >= 0) {
          model.changeSelection(_value[k], k);
        }
      });
      onChange && onChange(model);
    },
    [model, onChange]
  );

  useEffect(() => {
    setSidedishSelection(model.getSelection());
    const handleSelectionChange = () => {
      setSidedishSelection(model.getSelection());
    };
    model.on("change:selection", handleSelectionChange);
    return () => {
      model.off("change:selection", handleSelectionChange);
    };
  }, [model]);

  useEffect(() => {
    if (showValidation) {
      const validation = model.validateSelection();
      if (isValide !== validation) {
        setIsValide(validation);
      }
    }
  }, [showValidation, model, isValide]);

  const selection: ISelectionValue = useMemo(() => {
    const _selection: ISelectionValue = {};
    sidedishSelection.forEach((item) => {
      if (item.id) {
        _selection[item.id] = item.quantity;
      }
    });
    return _selection;
  }, [sidedishSelection]);

  if (!options.length) {
    return null;
  }
  return (
    <ProductDetailsSelection
      mt={39}
      tag={
        !!model.get("is_required") || model.getQuantityMin() > 0
          ? {
              text: t("Requis", "Product"),
              variant:
                showValidation && !isValide
                  ? "selection-danger"
                  : "selection-default",
            }
          : undefined
      }
      max={max}
      isMultiple={isMultiple}
      title={model.formatTitle() || ""}
      size={displayMapper[model.getDisplaySize()] || displayMapper[0]}
      options={options}
      value={selection}
      onChange={handleChange}
    />
  );
};

export default ProductSidedishItem;
