import React, { ButtonHTMLAttributes } from "react";
import { appConfig } from "@src/config/appConfig";
import { useRollingStartNavigation } from "@src/lib/rolling-start-navigation/index";
import { Link } from "gatsby";
import { TypographyLinkProps } from "design-system/src/Core/Link/Context/DefaultTypographyLink";
import Typography from "design-system/src/Foundation/Typography/Typography";

const TypographyLink = (props: TypographyLinkProps) => {
  const { sushishopBaseUrl } = appConfig;
  const { isRollingStart } = useRollingStartNavigation();
  const {
    children,
    to,
    href = "",
    color: colorProps,
    onClick,
    type,
    ...anchorRest
  } = props;
  const re = new RegExp(`^${sushishopBaseUrl}`, "i");
  const route = (to || href || "").replace(re, "");
  const javascript_uri = /^javascript:(.*)/.exec(route);
  const isInternal = /^\/(?!\/)/.test(route);
  const useRollingStartRouting = isInternal && isRollingStart(route);
  if (isInternal && useRollingStartRouting) {
    return (
      <Typography as={Link} to={route} onClick={onClick} {...anchorRest}>
        {children}
      </Typography>
    );
  }
  if (!route || javascript_uri) {
    const { onClick, color, ...buttonRest } =
      anchorRest as ButtonHTMLAttributes<any>;
    const evalJs = () => {
      eval(javascript_uri ? javascript_uri[1] : "()=>{}");
    };
    return (
      <Typography
        as={"button"}
        {...buttonRest}
        color={colorProps}
        onClick={onClick || evalJs}
        type={type}
      >
        {children}
      </Typography>
    );
  }
  return (
    <Typography as={"a"} href={route} color={colorProps} {...anchorRest}>
      {children}
    </Typography>
  );
};

export default TypographyLink;
