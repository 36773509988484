import styled from "styled-components";

export const _ProductDetailsSelectionRoot = styled.div<{
  $mt?: number;
  $mb?: number;
}>(({ $mt, $mb }) => {
  return {
    // Use padding to fix scroll to view
    paddingTop: $mt,
    marginBottom: $mb,
    [".sidedish-selection__title"]: {
      flex: 1,
      display: "block",
    },
  };
});

export const _ProductDetailsSelectionBody = styled.div<{}>(() => {
  return {
    paddingTop: 17,
    display: "flex",
    flexDirection: "column",
    gap: 2,
  };
});
export const _ProductDetailsSliderWrapper = styled.div<{}>(() => {
  return {
    display: "flex",
    overflow: "auto",
    gap: 16,
    padding: "0 16px",
    scrollSnapType: "x mandatory",
    scrollPadding: "0 16px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    " > div": {
      display: "flex",
      gap: 8,
      padding: "0",
      flex: "1",
      minWidth: "calc(40% - 6.4px)",
      scrollSnapAlign: "start",
      bottom: 0,
      top: 0,
      height: "auto",
    },
  };
});
export const _ProductDetailsSelectionItemWrapper = styled.div<{
  selected?: boolean;
}>(({}) => {
  return {
    paddingTop: 17,
  };
});
