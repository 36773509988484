import Backbone from "@src/backbone/prototypes/Backbone";
import ObjectPrototype from "./ObjectPrototype";
import _ from "underscore";
abstract class ObjectModel<
  T extends Backbone.ObjectHash = any,
  S = Backbone.ModelSetOptions,
  E = any
> extends ObjectPrototype<T, S, E> {
  initialize(attributes?: any, options?: any): void {
    const self = this;

    if (self) {
      _.each(self.beforeInitialize, function (func, funcName) {
        self.executeEachFunction(func, funcName, self);
      });

      self.initAppHttpHeadersChange();

      _.each(self.afterInitialize, function (func, funcName) {
        self.executeEachFunction(func, funcName, self);
      });
    }
  }
  public get<A extends Backbone._StringKey<T>>(
    attributeName: A
  ): T[A] | undefined {
    return super.get(attributeName);
  }

  setData(data: object): this {
    Object.entries(data).forEach(([key, value]) => {
      this.set(key, value);
    });
    return this;
  }
}

export default ObjectModel;
