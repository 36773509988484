import { FC, ReactElement, ReactNode } from "react";
import useLoadAfterRequired from "./useLoadAfterRequired";

interface ISuspenseAfterInteractionProps {
  required?: boolean;
  fallback?: ReactNode;
  children: ReactNode;
}

const SuspenseAfterRequired: FC<ISuspenseAfterInteractionProps> = (props) => {
  const { fallback, children, required } = props;
  const hasInteraction = useLoadAfterRequired({ required });
  return (hasInteraction ? children : fallback || null) as ReactElement;
};

export default SuspenseAfterRequired;
