import styled, { css, CSSObject } from "styled-components";
import { RowProps } from "./Row";

interface _RowStyle {
  $prefixCls: string;
  $justify: RowProps["justify"];
  $align: RowProps["align"];
}

const mapJustify: Record<
  NonNullable<_RowStyle["$justify"]>,
  CSSObject["justifyContent"]
> = {
  start: "flex-start",
  end: "flex-end",
  center: "center",
  "space-around": "space-around",
  "space-between": "space-between",
};

const mapAlign: Record<
  NonNullable<_RowStyle["$align"]>,
  CSSObject["alignItems"]
> = {
  top: "flex-start",
  middle: "center",
  bottom: "flex-end",
  stretch: "stretch",
};

export const _RowStyle = styled("div")<_RowStyle>(({ $justify, $align }) => {
  return {
    flexFlow: "row wrap",
    display: "flex",
    minWidth: 0,

    ["&:before, &:after"]: {
      display: "flex",
    },

    ["&"]: {
      justifyContent: $justify ? mapJustify[$justify] : undefined,
      alignItems: $align ? mapAlign[$align] : undefined,
    },

    ["&-no-wrap"]: {
      flexWrap: "nowrap",
    },
  };
});
