import styled from "styled-components";
import FlexBox from "design-system/src/Components/FlexBox/FlexBox";

const _BackgroundImageWrapper = styled.div`
  &,
  .ro-image-responsive,
  picture,
  img {
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease-in-out;
  }
`;

const _ForegroundImageWrapper = styled(FlexBox)((props) => {
  return {
    img: {
      aspectRatio: "320/290 !important",
    },
  };
});

export { _BackgroundImageWrapper, _ForegroundImageWrapper };
