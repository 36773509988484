import clsx from "clsx";
import React, { ReactNode, CSSProperties, useState, useCallback } from "react";
import useBreakpoint from "../../Components/Grid/hooks/useBreakpoint";
import Tag, { ITagProps } from "../../Components/Tag/Tag";
import Icon from "../../Foundation/Icon/Icon";
import Spinner from "../../Foundation/Spinner/Spinner";
import {
  _ProductDetailsRoot,
  _ProductDetailsCol,
  _ProductDetailsMainTagContainer,
  _ProductDetailsInfoContainer,
  _ProductDetailsClose,
  _ProductDetailsAddToCardContainer,
  _ProductDetailsSidedishContainer,
  _ProductDetailsLoader,
} from "./ProductDetails.styled";
import ProductDetailsAddToCard, {
  IProductDetailsAddToCardProps,
} from "./ProductDetailsAddToCard/ProductDetailsAddToCard";
import ProductDetailsImages, {
  IProductDetailsImagesProps,
} from "./ProductDetailsImages/ProductDetailsImages";
import ProductDetailsSelection, {
  IProductDetailsSelectionProps,
} from "./ProductDetailsSelection/ProductDetailsSelection";
import ProductSelectionModal from "./ProductSelectionModal/ProductSelectionModal";

export interface IProductDetailsProps<T extends any> {
  imageProps: IProductDetailsImagesProps;
  mainTag?: {
    text: ReactNode;
    props?: ITagProps;
  };
  children?: ReactNode;
  accompagnements?: IProductDetailsSelectionProps<T>[];
  style?: CSSProperties;
  onClose?: () => void;
  addToCardProps?: Omit<IProductDetailsAddToCardProps, "override">;
  composeLabel?: string;
  startComposeLabel?: string;
  title?: string;
  onAccompagnementChange?: IProductDetailsSelectionProps<T>["onChange"];
  isComposeBox?: boolean;
  loading?: boolean;
  className?: string;
}

const ProductDetails = <T extends any = any>(
  props: IProductDetailsProps<T>
) => {
  const {
    style,
    imageProps,
    mainTag,
    children,
    addToCardProps,
    accompagnements,
    onClose,
    composeLabel = "Composer",
    title,
    onAccompagnementChange,
    isComposeBox,
    loading,
    className,
  } = props;

  const { customBox } = imageProps;
  const { isMobile } = useBreakpoint();
  const isCustomBox: boolean = !!customBox;
  const isCustomBoxMobile: boolean = isCustomBox && isMobile;
  const [showComposeModal, setShowComposeModal] = useState<boolean>(false);

  const handleShowBox = useCallback(() => {
    setShowComposeModal(true);
  }, []);
  const handleCloseBox = useCallback(() => {
    if (isCustomBoxMobile) {
      onClose?.();
    } else {
      setShowComposeModal(false);
    }
  }, [isCustomBoxMobile, onClose]);

  if (loading) {
    return (
      <_ProductDetailsLoader
        className={clsx("product-loader", { box: isComposeBox })}
      >
        <Spinner className="spinner" />
      </_ProductDetailsLoader>
    );
  }

  return (
    <_ProductDetailsRoot style={style} gutter={0} className={className}>
      <ProductSelectionModal<T>
        show={
          showComposeModal || (isComposeBox && isMobile) || isCustomBoxMobile
        }
        disableAnimation={isComposeBox}
        title={title}
        imageProps={imageProps}
        addToCardProps={addToCardProps}
        accompagnements={accompagnements}
        // composeLabel={composeLabel}
        // startComposeLabel={startComposeLabel}
        // startChildren={children}
        onChange={onAccompagnementChange}
        onClose={handleCloseBox}
      />

      <_ProductDetailsCol
        className={clsx("main-content", { box: isComposeBox })}
        $left={true}
      >
        <ProductDetailsImages {...imageProps}>
          {mainTag && (
            <_ProductDetailsMainTagContainer>
              <Tag variant="product-primary" {...mainTag.props}>
                {mainTag.text}
              </Tag>
            </_ProductDetailsMainTagContainer>
          )}
        </ProductDetailsImages>
      </_ProductDetailsCol>
      <_ProductDetailsCol
        className={clsx("main-content", { box: isComposeBox })}
        id="sidedish-container-scroll"
        $showScrollBar={true}
      >
        <_ProductDetailsInfoContainer>
          {children}
          {!isCustomBoxMobile || !isCustomBox ? (
            <_ProductDetailsSidedishContainer>
              {accompagnements?.map((item, index) => {
                return (
                  <ProductDetailsSelection
                    key={index}
                    {...item}
                    onChange={onAccompagnementChange}
                  />
                );
              })}
            </_ProductDetailsSidedishContainer>
          ) : null}
          {isCustomBoxMobile ? (
            <_ProductDetailsSidedishContainer>
              {accompagnements
                ?.slice(2, accompagnements.length || 2)
                ?.map((item, index) => {
                  return (
                    <ProductDetailsSelection
                      key={index}
                      {...item}
                      onChange={onAccompagnementChange}
                    />
                  );
                })}
            </_ProductDetailsSidedishContainer>
          ) : null}
          {addToCardProps ? (
            <_ProductDetailsAddToCardContainer>
              {isCustomBoxMobile ? (
                <ProductDetailsAddToCard
                  label={composeLabel || ""}
                  override={
                    isCustomBoxMobile
                      ? {
                          label: composeLabel,
                          onClick: handleShowBox,
                        }
                      : undefined
                  }
                />
              ) : (
                <ProductDetailsAddToCard {...addToCardProps} />
              )}
            </_ProductDetailsAddToCardContainer>
          ) : null}
        </_ProductDetailsInfoContainer>
      </_ProductDetailsCol>
      <_ProductDetailsClose onClick={onClose}>
        <Icon icon="cross_close" size={24} color={"#1B1A16"} />
      </_ProductDetailsClose>
    </_ProductDetailsRoot>
  );
};

export default ProductDetails;
