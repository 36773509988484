import { useConfiguration } from "@src/hooks/query/useConfiguration";
import FlexBox from "design-system/src/Components/FlexBox/FlexBox";
import Container from "design-system/src/Components/Grid/Container/Container";
import Typography from "design-system/src/Foundation/Typography/Typography";
import { IWithDrawerProps } from "design-system/src/HOC/withDrawer";
import React, { useMemo, ReactNode, ReactChild } from "react";
import { useAuthenticationLogic } from "./UseAuthenticationLogic";
import { AuthenticationOptions } from "@src/store/reducers/AuthSlice";
import SocialConnectStep from "@src/components/feedback/AuthenticationContent/Steps/SocialConnectStep";
import SignInEmailStep from "@src/components/feedback/AuthenticationContent/Steps/SignInEmailStep";
import FontIcon from "design-system/src/Foundation/FontIcon/FontIcon";
import SubmitPasswordStep from "@src/components/feedback/AuthenticationContent/Steps/SubmitPasswordStep";
import ImageResponsive from "@src/lib/render-image/ImageResponsive";
import {
  _BackgroundImageWrapper,
  _ForegroundImageWrapper,
} from "@src/components/feedback/AuthenticationContent/AuthenticationContent.styled";
import { ResponsivePartialProps } from "design-system/src/Components/Image/ImageResponsive";
import SpanButton from "design-system/src/General/Reset/SpanButton/SpanButton";
import CustomerFieldsStep from "@src/components/feedback/AuthenticationContent/Steps/CustomerFieldsStep";
import useResponsive from "@src/hooks/useResponsive";
import ResetPasswordStep from "@src/components/feedback/AuthenticationContent/Steps/ResetPasswordStep";
import SubmitButton from "@src/components/feedback/AuthenticationContent/Form/SubmitButton";
import SuccessStep from "@src/components/feedback/AuthenticationContent/Steps/SuccessStep";
import LoyaltyOptInStep from "@src/components/feedback/AuthenticationContent/Steps/LoyaltyOptInStep";
import { LoyaltyOptInStepBackground } from "@src/components/feedback/AuthenticationContent/Steps/LoyaltyOptInStep.styled";
import LoyaltyOptInSuccessStep from "@src/components/feedback/AuthenticationContent/Steps/LoyaltyOptInSuccessStep";
export interface IAuthenticationContentProps extends IWithDrawerProps {
  options?: AuthenticationOptions | null;
  onSuccess?: () => void;
}
interface IOptionRenderData {
  title?: string;
  subtitle?: string;
  isResetPassword?: boolean;
  hasBackgroundImage?: boolean;
  backgroundElement?: ReactNode;
  content?: ReactNode;
  hasScroll?: boolean;
  renderAction?: () => ReactChild;
  renderFields?: () => ReactNode;
  onClick?: () => void;
  backStep?: EAuthentificationStep;
}

export enum EAuthentificationStep {
  SOCIAL_CONNECT = "social-connect",
  SIGN_IN_EMAIL = "sign-in-email",
  RESET_PASSWORD_REQUEST = "reset-password-request",
  RESET_PASSWORD_SUCCESS = "reset-password-success",
  NEW_PASSWORD_REQUEST = "new-password-request",
  NEW_PASSWORD_SUCCESS = "new-password-success",
  SIGN_UP = "sign-up",
  MISSING_FIELDS = "missing-fields",
  LOYALTY_OPT_IN = "loyalty-opt-in",
  LOYALTY_OPT_IN_SUCCESS = "loyalty-opt-in-success",
  DEFAULT = SOCIAL_CONNECT,
}

const AUTH_BACKGROUND_ID_PICTURE =
  process.env.GATSBY_CONF_AUTH_BACKGROUND_ID_PICTURE || "";
const AUTH_FOREGROUND_ID_PICTURE =
  process.env.GATSBY_CONF_AUTH_FOREGROUND_ID_PICTURE || "";

const AuthenticationContent = (props: IAuthenticationContentProps) => {
  const { getConfiguration: c } = useConfiguration();
  const _NEWSLETTER_MENTIONS_TEXT_ = c("_NEWSLETTER_MENTIONS_TEXT_", "");
  const { onClose } = props;
  const authentificationLogic = useAuthenticationLogic(props);
  const { onSubmit, step, clearErrors, goBack, isLoading, t } =
    authentificationLogic;

  const subtitleForm = t("Veuillez saisir les champs ci-dessous", "Account");

  const renderOptionData = useMemo((): IOptionRenderData => {
    const isResetPassword =
      step === EAuthentificationStep.RESET_PASSWORD_REQUEST;
    const title = t("Connectez-vous", "Account");
    const hasBackgroundImage = step !== EAuthentificationStep.SIGN_UP;
    const baseProps: Pick<
      IOptionRenderData,
      "title" | "isResetPassword" | "hasBackgroundImage" | "hasScroll"
    > = {
      title,
      isResetPassword,
      hasBackgroundImage,
      hasScroll: false,
    };
    switch (step) {
      case EAuthentificationStep.RESET_PASSWORD_REQUEST:
        return {
          ...baseProps,
          title: t("Mot de passe oublié ?", "Account"),
          subtitle: subtitleForm,
          content: (
            <ResetPasswordStep authentificationLogic={authentificationLogic} />
          ),
          backStep: EAuthentificationStep.SIGN_IN_EMAIL,
        };
      case EAuthentificationStep.RESET_PASSWORD_SUCCESS:
        return {
          ...baseProps,
          title: t("Mot de passe oublié ?", "Account"),
          subtitle: t(
            "Un e-mail vous a été envoyé. Veuillez suivre ses instructions.",
            "Account"
          ),
          content: (
            <SuccessStep authentificationLogic={authentificationLogic} />
          ),
        };
      case EAuthentificationStep.NEW_PASSWORD_REQUEST:
        return {
          ...baseProps,
          title: t("Nouveau mot de passe", "Account"),
          subtitle: subtitleForm,
          content: (
            <SubmitPasswordStep authentificationLogic={authentificationLogic} />
          ),
        };
      case EAuthentificationStep.NEW_PASSWORD_SUCCESS:
        return {
          ...baseProps,
          title: t("Nouveau mot de passe", "Account"),
          subtitle: t("Votre mot de passe est réinitialisé", "Account"),
          content: (
            <SuccessStep authentificationLogic={authentificationLogic} />
          ),
        };
      case EAuthentificationStep.SIGN_UP:
        return {
          ...baseProps,
          title: t("Créer un compte", "Account"),
          subtitle: subtitleForm,
          backStep: EAuthentificationStep.SOCIAL_CONNECT,
          hasScroll: true,
          content: (
            <CustomerFieldsStep authentificationLogic={authentificationLogic} />
          ),
        };
      case EAuthentificationStep.MISSING_FIELDS:
        return {
          ...baseProps,
          title: t("Informations obligatoires", "Account"),
          subtitle: t(
            "Pour continuer veuillez valider les informations suivantes",
            "Account"
          ),
          hasScroll: true,
          hasBackgroundImage: false,
          content: (
            <CustomerFieldsStep authentificationLogic={authentificationLogic} />
          ),
        };
      case EAuthentificationStep.LOYALTY_OPT_IN:
        return {
          backgroundElement: <LoyaltyOptInStepBackground />,
          content: (
            <LoyaltyOptInStep authentificationLogic={authentificationLogic} />
          ),
        };
      case EAuthentificationStep.LOYALTY_OPT_IN_SUCCESS:
        return {
          backgroundElement: <LoyaltyOptInStepBackground />,
          content: (
            <LoyaltyOptInSuccessStep
              authentificationLogic={authentificationLogic}
            />
          ),
        };
      case EAuthentificationStep.SIGN_IN_EMAIL:
        return {
          ...baseProps,
          content: <SignInEmailStep {...{ authentificationLogic }} />,
          subtitle: subtitleForm,
          backStep: EAuthentificationStep.SOCIAL_CONNECT,
        };
      case EAuthentificationStep.SOCIAL_CONNECT:
      default:
        return {
          ...baseProps,
          content: <SocialConnectStep {...{ authentificationLogic }} />,
        };
    }
  }, [_NEWSLETTER_MENTIONS_TEXT_, clearErrors, step, t, onClose]);

  const {
    title,
    subtitle,
    content,
    backStep,
    hasBackgroundImage,
    hasScroll,
    backgroundElement,
  } = renderOptionData;
  const foregroundImageProps: ResponsivePartialProps = {
    image: {
      id: AUTH_FOREGROUND_ID_PICTURE,
      width: 320,
      height: 290,
      outputPixelDensities: [1, 2],
    },
  };
  const backgroundImageProps: ResponsivePartialProps = {
    image: {
      id: AUTH_BACKGROUND_ID_PICTURE,
      width: 640,
      height: 840,
    },
  };
  const { isMobile } = useResponsive();
  // main step
  return (
    <div
      style={{ ...(isLoading && { pointerEvents: "none" }), height: "100%" }}
    >
      {!!AUTH_BACKGROUND_ID_PICTURE && (
        <_BackgroundImageWrapper
          style={{
            ...(!hasBackgroundImage && {
              opacity: 0,
              visibility: "hidden",
            }),
          }}
        >
          <ImageResponsive
            desktop={backgroundImageProps}
            mobile={backgroundImageProps}
          />
        </_BackgroundImageWrapper>
      )}
      {backStep && (
        <SpanButton
          aria-label={"back"}
          className={"ro-cart-back-button"}
          onClick={() => {
            goBack(backStep);
          }}
        >
          <FontIcon icon={"arrow_small_left"} size={24} />
        </SpanButton>
      )}
      {backgroundElement}
      <Container
        as={"form"}
        method={"post"}
        onSubmit={onSubmit}
        style={{
          position: "relative",
          height: "100%",
          paddingBottom: isMobile ? 140 : 54,
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <FlexBox
          direction="column"
          justify={"start"}
          flex={1}
          gap={36}
          style={{
            maxWidth: 386,
            width: "100%",
            margin: "auto",
            ...(!isMobile && {
              paddingTop: 36,
              paddingBottom: 36,
            }),
            ...(step === EAuthentificationStep.LOYALTY_OPT_IN && {
              paddingTop: 0,
              paddingBottom: 0,
            }),
            ...(isMobile &&
              !hasScroll && {
                maxHeight: "calc(100vh - 150px)",
              }),
          }}
        >
          {!!title && (
            <_ForegroundImageWrapper direction="column" justify={"start"}>
              {!!AUTH_FOREGROUND_ID_PICTURE && (
                <ImageResponsive
                  desktop={foregroundImageProps}
                  mobile={foregroundImageProps}
                  style={{
                    width: 320,
                    maxWidth: "100%",
                    margin: "0 auto",
                    transition: "height 0.3s",
                    ...(!isMobile && { order: 3 }),
                    ...(isMobile &&
                      hasBackgroundImage && {
                        maxWidth: "calc(40vh - 20px)",
                        margin: `min(-8vh,-30px) auto 0`,
                      }),
                    ...(!hasBackgroundImage && { height: 0 }),
                  }}
                />
              )}
              <Typography variant="h5" color="placeholder" align="center">
                {title}
              </Typography>
              {subtitle && (
                <Typography variant="body2" color="champagne3" align="center">
                  {subtitle}
                </Typography>
              )}
            </_ForegroundImageWrapper>
          )}
          {content}
        </FlexBox>
      </Container>
    </div>
  );
};

AuthenticationContent.displayName = "AuthenticationContent";

export default AuthenticationContent;
