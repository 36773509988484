import styled from "styled-components";
import { CSSProperties } from "react";

export const _ProductSelectionModalRoot = styled.div<{}>(() => {
  return {
    position: "relative",
  };
});
export const _ProductSelectionModalWrapper = styled.div<{
  $show?: boolean;
  $disableAnimation?: boolean;
  zIndex?: number;
  height?: CSSProperties["height"];
}>(({ $show, $disableAnimation = false, zIndex = 1100, height = "100%" }) => {
  return {
    position: "fixed",
    top: "env(safe-area-inset-top)",
    left: 0,
    width: "100vw",
    height: $show ? height : 0,
    transform: !$show ? "translateY(100%)" : "translateY(0)",
    transition: $disableAnimation ? "" : "transform 0.5s ease 0.3s",
    visibility: $show ? "visible" : "hidden",
    zIndex: $show ? zIndex : 0,
  };
});

export const _ProductSelectionModalTitleContainer = styled.div<{}>(() => {
  return {
    position: "absolute",
    left: 0,
    top: 0,
    padding: "28px 28px 0",
    width: "100%",
  };
});
