import styled from "styled-components";

export const _ProductDetailsAddToCardRoot = styled.div<{}>(() => {
  return {
    position: "relative",
    width: "100%",
  };
});
export const _ProductDetailsAddToCard_QuantifierWrapper = styled.div<{}>(() => {
  return {
    position: "absolute",
    left: 6,
    top: "50%",
    transform: "translateY(-50%)",
  };
});
