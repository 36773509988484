import React from "react";
import { IUseAuthenticationLogic } from "@src/components/feedback/AuthenticationContent/UseAuthenticationLogic";
import { useTranslation } from "@src/hooks/useTranslation";
import FlexBox from "design-system/src/Components/FlexBox/FlexBox";
import { Controller } from "react-hook-form";
import { AlternateSmallButton } from "@src/components/feedback/AuthenticationContent/Form/AlternateSmallButton";
import { EAuthentificationStep } from "@src/components/feedback/AuthenticationContent/AuthenticationContent";
import InputRender from "@src/components/feedback/AuthenticationContent/Form/InputRender";
import SubmitButton from "@src/components/feedback/AuthenticationContent/Form/SubmitButton";
import Typography from "design-system/src/Foundation/Typography/Typography";

interface ISuccessStepProps {
  authentificationLogic: IUseAuthenticationLogic;
  successMessage?: string;
  actionLabel?: string;
}
const SuccessStep = (props: ISuccessStepProps) => {
  const { t } = useTranslation(["Account", "UI"]);
  const { authentificationLogic, actionLabel, successMessage } = props;
  const { onSubmit } = authentificationLogic;
  return (
    <FlexBox direction="column" gap={12} flex={1}>
      {successMessage && <Typography variant="h4">{successMessage}</Typography>}
      <SubmitButton onClick={onSubmit}>
        {actionLabel || t("J’ai compris", "UI")}
      </SubmitButton>
    </FlexBox>
  );
};

export default SuccessStep;
