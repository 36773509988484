import React, {
  CSSProperties,
  ElementType,
  ImgHTMLAttributes,
  startTransition,
  useEffect,
} from "react";
import { ImageData } from "./interface/ImageData";
import { ImageDataSources, Picture, SourceProps } from "./Picture/Picture";
import { _ImageRoot } from "./Image.styled";
import useOnScreen from "../../Hooks/useOnScreen";
import { preloadImage } from "./Image.utils";
import useImageLogic from "./Image.hooks";
import { CSSObject } from "styled-components";
import clsx from "clsx";

export interface IImageProps
  extends Omit<
    ImgHTMLAttributes<HTMLImageElement>,
    "placeholder" | "onLoad" | "src" | "srcSet" | "width" | "height"
  > {
  alt?: string;
  as?: ElementType;
  href?: string;
  className?: string;
  imgClassName?: string;
  image: ImageData;
  imgStyle?: CSSProperties;
  wrapperStyle?: CSSProperties;
  linkBreakpointToWrapper?: boolean;
  preload?: boolean;
  additionalImage?: Array<
    NonNullable<Pick<IImageProps, "image" | "wrapperStyle" | "imgStyle">>
  >;
  // backgroundColor?: string;
  // objectFit?: CSSProperties["objectFit"];
  // objectPosition?: CSSProperties["objectPosition"];
  // onLoad?: (props: { wasCached: boolean }) => void;
  // onError?: ReactEventHandler<HTMLImageElement>;
  // onStartLoad?: (props: { wasCached: boolean }) => void;
}

// const defaultImageFormats = [ImageFormat.WEBP, ImageFormat.AUTO];
// const AUTO = "auto";

// main component
const Image = (props: IImageProps) => {
  const {
    image: imageIn,
    alt = "",
    as,
    href,
    wrapperStyle = {},
    className,
    imgClassName,
    imgStyle,
    loading = "lazy",
    preload,
    additionalImage,
  } = props;
  const { layout = "constrained" } = imageIn;

  const { generateImageAspect } = useImageLogic();

  const additionalSource: Array<
    SourceProps & Pick<IImageProps, "wrapperStyle" | "imgStyle">
  > = [];
  const additionalImageMapped = additionalImage?.map((img) => {
    const { sources, wrapperStyle, imgStyle } = generateImageAspect(img.image);
    additionalSource.push(
      ...(sources.map((s) => ({ ...s, wrapperStyle, imgStyle })) || [])
    );

    return {
      ...img,
      wrapperStyle: { ...img.wrapperStyle, ...wrapperStyle },
      imgStyle: { ...img.imgStyle, ...imgStyle },
    };
  });

  const [loaded, setLoaded] = React.useState(false);
  const [rootRef, isOnScreen] = useOnScreen();

  const {
    sources,
    fallback,
    wrapperStyle: mainWrapperStyle,
    imgStyle: mainImgStyle,
  } = generateImageAspect(imageIn);

  // compile source
  const imageDatas: ImageDataSources = { sources, fallback };

  let responsiveStyle: CSSObject = {};
  const $sources = [
    {
      image: imageIn,
      wrapperStyle: { ...mainWrapperStyle, ...wrapperStyle },
      imgStyle: { ...mainImgStyle, ...imgStyle },
    },
    ...(additionalImageMapped || []),
  ];
  $sources?.forEach((item) => {
    if (item.image.media) {
      responsiveStyle[`@media ${item.image.media}`] = {
        ...item.wrapperStyle,
        img: { ...item.imgStyle },
      };
    } else {
      responsiveStyle = {
        ...responsiveStyle,
        ...item.wrapperStyle,
        img: { ...item.imgStyle },
      };
    }
  });

  useEffect(() => {
    if (preload) {
      preloadImage(imageDatas.fallback?.src || "");
    }
  }, [preload]);

  const onLoadPicture = (e: React.SyntheticEvent<HTMLImageElement>) => {
    startTransition(() => {
      setLoaded(true);
    });
  };

  // const isFullWidth = layout === "fullWidth";
  // const isConstrained = layout === "constrained";
  // const isFixed = layout === "fixed";

  // const placeholder =
  //   (autoAspectRatio !== AUTO && (
  //     <div
  //       aria-hidden={true}
  //       className={clsx("ro-image__placeholder")}
  //       style={{
  //         paddingTop: `${100 / autoAspectRatio}%`,
  //         opacity: loaded ? 0 : 1,
  //       }}
  //     ></div>
  //   )) ||
  //   null;

  const pictureSources = [
    ...(imageDatas.sources || []),
    ...(additionalSource || []),
  ];

  const shouldLoad = loading === "lazy" ? loaded || isOnScreen : true;

  return (
    <_ImageRoot
      ref={rootRef}
      {...{ layout, href, className: clsx(className, { loading: !loaded }) }}
      as={as as any}
      $css={responsiveStyle}
      // style={{
      //   width: isFullWidth ? "100%" : isFixed ? autoWidth : undefined,
      //   maxWidth: isConstrained ? autoWidth : undefined,
      //   maxHeight: isConstrained ? autoHeight : undefined,
      //   // ...wrapperStyle,
      // }}
    >
      {/* {!loaded && placeholder} */}
      <Picture
        onLoad={onLoadPicture}
        sources={pictureSources}
        fallback={imageDatas.fallback}
        className={imgClassName}
        style={{ ...imgStyle, opacity: loaded ? 1 : undefined }}
        loading={loading}
        alt={alt}
        // width={widthAttr}
        // height={heightAttr}
        shouldLoad={shouldLoad}
      />
    </_ImageRoot>
  );
};

export default Image;
