import { useState, useEffect, useRef, startTransition } from "react";

const useOnScreen = <T extends Element>(
  options: IntersectionObserverInit = {
    root: null,
    threshold: 0.1,
  },
  params?: {
    ignoreReverse?: boolean;
  }
): [React.MutableRefObject<T | null>, boolean] => {
  const ref = useRef<T | null>(null);
  const [isIntersecting, setIntersecting] = useState(false);

  const updateValue = (newValue: boolean) => {
    if (isIntersecting !== newValue) {
      // If ignoreReverse is true and newValue is false, we don't want to update our state
      // use to trigger one time only, at the first time the element is visible
      if (!!params?.ignoreReverse && !newValue) return;
      // Update our state when observer callback fires
      startTransition(() => {
        setIntersecting(newValue);
      });
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      updateValue(entry.isIntersecting);
    }, options);

    // console.log("ref.current", ref.current);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [updateValue]); // Empty array ensures effect is only run on mount and unmount

  return [ref, isIntersecting];
};

export default useOnScreen;
