import React from "react";
import { _Dot, _SliderDotRoot } from "./SliderDot.styled";

interface ISliderDotProps {
  total: number;
  current: number;
  className?: string;
}

const SliderDot = (props: ISliderDotProps) => {
  const { total, current = 0, className } = props;
  return (
    <_SliderDotRoot className={className}>
      {Array.from(Array(total)).map((_, i) => {
        return <_Dot key={i} $active={i === current} />;
      })}
    </_SliderDotRoot>
  );
};

export default SliderDot;
