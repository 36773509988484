import styled from "styled-components";
import { hexToRGB } from "../../Utils/font";

export const _FlagRoot = styled("span")<{}>(({}) => {
  return {
    display: "inline-flex",
    alignItems: "center",
    width: 24,
    height: 24,
    padding: "0 2px",
  };
});

export const _FlagImage = styled("span").attrs<{ flag: string }>(({ flag }) => {
  return {
    style: {
      background: `url(${flag})`,
    },
  };
})(() => {
  return {
    //...($flag && { background: `url(${$flag})` }),
    backgroundColor: hexToRGB("#777777", 0.6),
    backgroundRepeat: "no-repeat",
    display: "inline-block",
    width: "100%",
    paddingTop: "75%",
    borderRadius: 2.28571,
    boxShadow: "0px 0px 0px 0.7272px #f5f5f5",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
});
