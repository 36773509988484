import { graphql, useStaticQuery } from "gatsby";

const useDefaultLayoutDatas = () => {
  const data = useStaticQuery<Queries.DefaultLayoutWidgetsQuery>(graphql`
    query DefaultLayoutWidgets {
      menu_burger: widgetGroup(indentifiant: { eq: "menu-burger" }) {
        ...widgetGroupFields
      }
      footer_top2: widgetGroup(indentifiant: { eq: "footer_top2" }) {
        ...widgetGroupFields
      }
      footer_top3: widgetGroup(indentifiant: { eq: "footer_top3" }) {
        ...widgetGroupFields
      }
      footer_bottom_3: widgetGroup(indentifiant: { eq: "footer_bottom_3" }) {
        ...widgetGroupFields
      }
      footer_storeloc_list: widgetGroup(
        indentifiant: { eq: "footer_storeloc_list" }
      ) {
        ...widgetGroupFields
      }
      allSiteSushishop(filter: { enable_in_footer: { eq: true } }) {
        nodes {
          international_name
          front_url
          default_language
          id_site
          lang
        }
      }
    }
  `);
  return data;
};
export default useDefaultLayoutDatas;
type DefaultLayoutDatas = ReturnType<typeof useDefaultLayoutDatas>;
type FooterStorelocList = DefaultLayoutDatas["footer_storeloc_list"];
type FooterBottom3 = DefaultLayoutDatas["footer_bottom_3"];
type FooterTop3 = DefaultLayoutDatas["footer_top3"];
type FooterTop2 = DefaultLayoutDatas["footer_top2"];
type MenuBurger = DefaultLayoutDatas["menu_burger"];
type AllSiteSushishop = DefaultLayoutDatas["allSiteSushishop"]["nodes"];
export type {
  DefaultLayoutDatas,
  FooterStorelocList,
  FooterBottom3,
  FooterTop3,
  FooterTop2,
  MenuBurger,
  AllSiteSushishop,
};
