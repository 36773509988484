import React, { CSSProperties, useEffect, useRef, useState } from "react";
import {
  CUSTOM_BOX_LOCATION_BASE_H,
  CUSTOM_BOX_LOCATION_BASE_W,
  getBoxLocations,
  IBound,
  IBoxLocation,
  LocationId,
  SUSHI_H,
  SUSHI_W,
} from "./ProductDetailsCustomBox.config";
import {
  _CustomBoxAreas,
  _CustomBoxAreasLocation,
  _CustomBoxAreasLocationBound,
  _CustomBoxCanvas,
  _CustomBoxCanvasWrapper,
} from "./ProductDetailsCustomBox.styled";

export type BoxLocationImageId = LocationId;
export type ImageByLocation = {
  [position in BoxLocationImageId]?: string;
};

export interface IProductDetailsCustomBoxProps {
  baseImage?: string;
  imageByLocation?: ImageByLocation;
  style?: CSSProperties;
  renderItem?: (item: {
    bound: IBound;
    location: IBoxLocation;
    image?: string;
    bId: string;
    parentSize: number;
  }) => React.ReactNode;
  debug?: boolean;
}

const ProductDetailsCustomBox = (props: IProductDetailsCustomBoxProps) => {
  const {
    baseImage,
    imageByLocation,
    style,
    renderItem,
    debug = false,
  } = props;
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [parentSize, setParentSize] = useState<number>(0);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (parentSize !== entry.target.clientWidth) {
          setParentSize(entry.target.clientWidth);
        }
      }
    });

    if (wrapperRef.current) {
      observer.observe(wrapperRef.current);
    }

    return () => {
      if (wrapperRef.current) {
        observer.unobserve(wrapperRef.current);
      }
    };
  });

  return (
    <_CustomBoxCanvasWrapper
      style={style}
      ref={wrapperRef}
      className="sidedish-custom-box__canvas-wrapper"
    >
      <_CustomBoxCanvas
        ref={canvasRef}
        className="sidedish-custom-box__canvas"
        width="800"
        height="800"
        $backgroundImage={baseImage}
      ></_CustomBoxCanvas>
      <_CustomBoxAreas className="sidedish-custom-box__areas">
        {/* Render locations */}
        {getBoxLocations()
          .sort((a, b) => a.z - b.z)
          .map((item) => {
            const { id, bounds, type } = item;
            return (
              <_CustomBoxAreasLocation
                className="sidedish-custom-box__location"
                data-location={id}
                key={id}
                $id={id}
                $debug={debug}
              >
                {!!bounds &&
                  bounds.map((bound, index) => {
                    const { style } = bound;
                    const bId = id + (index + 1);
                    const isSushi = type === "sushis";
                    let w = isSushi ? SUSHI_W : CUSTOM_BOX_LOCATION_BASE_W,
                      h = isSushi ? SUSHI_H : CUSTOM_BOX_LOCATION_BASE_H,
                      a = item.o === "h" ? 90 : 0,
                      isDivided = /\-div$/.test(type);
                    if (isDivided) {
                      w = CUSTOM_BOX_LOCATION_BASE_W / 2;
                    }
                    return renderItem ? (
                      renderItem({
                        location: item,
                        bound,
                        bId,
                        image:
                          (!!imageByLocation && imageByLocation[id]) ||
                          undefined,
                        parentSize,
                      })
                    ) : (
                      <_CustomBoxAreasLocationBound
                        type="button"
                        key={bId}
                        className="sidedish-custom-box__location__bounds"
                        data-bid={bId}
                        $w={(w * parentSize) / 800}
                        $h={(h * parentSize) / 800}
                        $a={a}
                        $isSushi={isSushi || type === "sushis-div"}
                        $isDivided={isDivided}
                        $sourceImage={
                          (!!imageByLocation && imageByLocation[id]) ||
                          undefined
                        }
                        style={{
                          ...style,
                        }}
                      ></_CustomBoxAreasLocationBound>
                    );
                  })}
              </_CustomBoxAreasLocation>
            );
          })}
      </_CustomBoxAreas>
    </_CustomBoxCanvasWrapper>
  );
};

export default ProductDetailsCustomBox;
