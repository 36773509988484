import { ICartData } from "@src/interface/Cart";
import {
  setCartLoaded,
  updateCurrentCart,
  setIsLoadingCart,
} from "@src/store/reducers/CartSlice";
import { RollingStartEndpointBuilder } from "..";

export const createCartEndpoint = (builder: RollingStartEndpointBuilder) => ({
  getCart: builder.query<ICartData, void | string | number>({
    query: (id_card = "current") => ({
      url: `/cart/${id_card}`,
      method: "GET",
    }),
    async onQueryStarted(id_card, { dispatch, queryFulfilled }) {
      dispatch(setIsLoadingCart(true));
      try {
        if (id_card === "current") {
          const res = await queryFulfilled;
          dispatch(updateCurrentCart(res.data));
          dispatch(setCartLoaded(true));
        }
      } catch (error) {
      } finally {
        dispatch(setIsLoadingCart(false));
      }
    },
  }),
  addProductToCurrentCart: builder.mutation<
    ICartData,
    {
      body: { id_product: number; id_cart_product: number; quantity: number };
    }
  >({
    query: (args) => ({
      url: `/cart/current/product`,
      method: "POST",
      body: args.body,
    }),
    async onQueryStarted(args, { dispatch, queryFulfilled }) {
      try {
        const res = await queryFulfilled;
        dispatch(updateCurrentCart(res.data));
        // TODO: handle error
      } catch (error) {}
    },
  }),
  deleteProductFromCurrentCart: builder.mutation<
    ICartData,
    {
      body: { id_product: number | string; id_cart_product: number | string };
    }
  >({
    query: (args) => ({
      url: `/cart/current/product`,
      method: "DELETE",
      body: args.body,
    }),
    async onQueryStarted(args, { dispatch, queryFulfilled }) {
      try {
        const res = await queryFulfilled;
        dispatch(updateCurrentCart(res.data));
        // TODO: handle error
      } catch (error) {}
    },
  }),
  postCartDeliveryOptions: builder.mutation<
    ICartData,
    {
      id_cart?: string | number;
      options: {
        vae: number;
        id_store: string | null | undefined;
        order_date?: string | undefined;
        order_time?: string | undefined;
        now?: number | undefined;
      };
    }
  >({
    query: ({ id_cart = "current", options = {} }) => ({
      url: `cart/${id_cart}/deliveryoptions`,
      method: "POST",
      body: options,
    }),
    async onQueryStarted(args, { dispatch, queryFulfilled }) {
      try {
        const res = await queryFulfilled;
        dispatch(updateCurrentCart(res.data));
        // TODO: handle error
      } catch (error) {}
    },
  }),
  addDiscount: builder.mutation<
    ICartData,
    {
      body: { discount_name: string };
    }
  >({
    query: ({ body }) => ({
      url: `cart/current/discount`,
      method: "POST",
      body: body,
    }),
    async onQueryStarted(args, { dispatch, queryFulfilled }) {
      try {
        const res = await queryFulfilled;
        dispatch(updateCurrentCart(res.data));
        // TODO: handle error
      } catch (error) {}
    },
  }),
  removeDiscount: builder.mutation<
    ICartData,
    {
      body: { id_cart_discount: number | string };
    }
  >({
    query: ({ body }) => ({
      url: `cart/current/discount/remove`,
      method: "POST",
      body: body,
    }),
    async onQueryStarted(args, { dispatch, queryFulfilled }) {
      try {
        const res = await queryFulfilled;
        dispatch(updateCurrentCart(res.data));
        // TODO: handle error
      } catch (error) {}
    },
  }),
  postDiscountFreeProduct: builder.mutation<
    ICartData,
    {
      id_cart_product: string;
    }
  >({
    query: ({ id_cart_product }) => ({
      url: `/cart/current/discountfreeproduct`,
      method: "POST",
      body: {
        id_cart_product,
      },
    }),
    async onQueryStarted(args, { dispatch, queryFulfilled }) {
      try {
        const res = await queryFulfilled;
        dispatch(updateCurrentCart(res.data));
        // TODO: handle error
      } catch (error) {}
    },
  }),
  postCartReorder: builder.query<
    ICartData,
    {
      id_cart: string;
      id_parent_cart: string;
    }
  >({
    query: ({ id_cart, id_parent_cart }) => ({
      url: `/cart/${id_cart}/reorder/${id_parent_cart}`,
      method: "POST",
    }),
    async onQueryStarted(args, { dispatch, queryFulfilled }) {
      try {
        const res = await queryFulfilled;
        dispatch(updateCurrentCart(res.data));
        // TODO: handle error
      } catch (error) {}
    },
  }),
});
