import styled from "styled-components";
import { responsiveMap } from "../../../Components/Grid/_utils/responsiveObserve";

export const __ProductDetailsImagesContainer = styled.div<{}>(() => {
  return {
    position: "relative",
    [".product-details__slider-dot"]: {
      position: "absolute",
      bottom: 23,
      [`@media ${responsiveMap.lg}`]: {
        display: "none",
      },
    },
  };
});

export const _ProductDetailsImagesRoot = styled.div<{
  $sliderInitialized?: boolean;
}>(({ $sliderInitialized }) => {
  return {
    display: $sliderInitialized ? "block" : "flex",
    overflow: "hidden",
    [`@media ${responsiveMap.lg}`]: {
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      flexWrap: "wrap",
      gridGap: 5,
    },
  };
});
export const _ProductDetailsImageWrapper = styled.div<{ $first?: boolean }>(
  ({ $first }) => {
    return {
      display: "inline-block",
      background: "#f9f8f5",
      flexBasis: "100%",
      aspectRatio: "1/1",
      overflow: "hidden",
      width: "100vw",
      minWidth: "100vw",
      [`@media ${responsiveMap.lg}`]: {
        flexBasis: $first ? "100%" : "50%",
        borderRadius: $first ? "8px 5px 5px 8px" : "0px",
        gridArea: $first ? "1 / 1 / 2 / 3" : undefined,
        minWidth: "unset",
        width: "unset",
      },
    };
  }
);
