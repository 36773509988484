import { INews, INewsFlag } from "@src/interface/News";
import { RollingStartEndpointBuilder } from "..";

export const createNewsEndpoints = (builder: RollingStartEndpointBuilder) => ({
  getNewsFlagItem: builder.query<INewsFlag, { id: string }>({
    query: ({ id }) => ({
      url: `/newsflag/${id}`,
      method: "GET",
    }),
  }),
  getNewsItem: builder.query<INews, { id: string }>({
    query: ({ id }) => ({
      url: `/news/${id}`,
      method: "GET",
    }),
  }),
  getNewsCollection: builder.query<INews[], void>({
    query: () => ({
      url: `/news`,
      method: "GET",
    }),
  }),
});
