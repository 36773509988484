//
// Model CartProduct
//

import ObjectModel from "../prototypes/ObjectModel";
import { app } from "./AppModel";
import CartModel from "./CartModel";
import _ from "underscore";
import ProductModel from "./ProductModel";
import formatPrice from "../prototypes/formatPrice";
import CartProductCollection from "./CartProductCollection";
import { sprintf } from "sprintf-js";
import SidedishCollection from "./SidedishCollection";
import OrderModel from "./OrderModel";
import ProductPageView from "@src/backbone/view/ProductPageView/ProductPageView";
import React from "react";

class CartProductModel extends ObjectModel<{
  [_ in string]: any;
}> {
  name = "CartProductModel";
  idAttribute = "id_cart_product";
  route = "apiCartProductModel";
  productExtraPrice?: ProductModel;
  p?: ProductModel;
  // collection: CartProductCollection<this>;
  accompagnements?: CartProductCollection;

  defaults() {
    return { type: "default", sync: true, deleted: false };
  }

  initialize(attributes?: any, options?: any): void {
    const model = this,
      cart = model.attributes.cart;
    // attribution automatique du type à partir de celui de la collection
    /* if (model.collection && model.collection.type) {
      model.attributes.type = model.collection.type;
      if (collection && collection.add) {
        collection = model.collection;
      }
    } */

    /* if (!cart && collection && collection.cart) {
      // utilise le panier de la collection si pas de panier
      cart = collection.cart;
    }
      */
    const type: string =
      // @ts-ignore
      attributes?.type || model.collection?.type || "default";
    model.set("type", type);

    // ObjectModel constructor heritage
    super.initialize.apply(this, [attributes, options]);
    //
    //
    const id_product = model.get("id_product");

    if (model.get("id_order_detail")) {
      // si on est sur une conversion Order detail > CartProductModel
      model.setupFromOrderProduct();
    } else if (model.getType() === "extraPrice") {
      model.setupExtraPrice();
    } else if (!model.get("id_cart_product") && id_product) {
      // si il n'y a pas d'id_cart_product
      model.setupFromProduct(id_product, cart);
    } else if (!id_product) {
    }
    //model.set({quantitySync:model.getQuantity()});
    model.unset("cart");
    delete model.attributes.cart;

    //model.on('change:quantity',model.onChangeQuantity,model);
    //model.on('change:deleted',model.onRestore,model);
    model.on("remove", model.onRemove, model);
    //
  }
  isEditable() {
    const model = this,
      id_cart_discount = parseInt(model.get("id_cart_discount")) || 0;
    return !id_cart_discount;
    // return true;
  }

  getDiscount() {
    const model = this,
      id_cart_discount = parseInt(model.get("id_cart_discount")) || 0;
    return id_cart_discount
      ? app.getCart().getDiscount(`${id_cart_discount}`)
      : undefined;
  }
  isQuantifiable() {
    // produit supportant la modification de quantité, autre que 1
    // donc non formule, non produit offert

    return !this.isMenu() && !this.isFree();
  }
  isMenu(ignoreSidedish?: boolean): boolean {
    const product = this.getProduct();
    return product ? product.isMenu(ignoreSidedish) : false;
  }
  isSync() {
    return this.get("sync");
  }
  setupFromProduct(id_product: string | number, cart: CartModel) {
    // on le génère client side
    // c'est le cas pour l'ajout d'un nouveau produit
    const model = this,
      id_cart_product = id_product,
      product = app.getProduct(id_product),
      quantity = model.getQuantity();
    let options: any = { id_cart_product: id_cart_product };
    if (product) {
      try {
        const price_ttc = product.getPrice("ttc", true, cart),
          total_price_ht = product.getPrice("ht", true, cart) * quantity;
        let total_price_ttc = price_ttc * quantity;
        options = _(
          _(options).extend(
            model.pick("price_ttc", "total_price_ht", "total_price_ttc")
          )
        ).defaults({
          price_ttc: price_ttc,
          total_price_ht: total_price_ht,
          total_price_ttc: total_price_ttc,
        });
        //
        //

        if (product.isMenu()) {
          //
          // gestion des identifiants en XXX-n
          //
          let n = 0,
            available = false,
            collection = cart.getProducts();
          let id_test: string = "";

          while (!available) {
            n++;
            let unavailable = false;
            id_test = `${product.id}-${n}`;
            collection.each((p) => {
              if (p.id === id_test) {
                unavailable = true;
              }
            });
            available = !unavailable;
          }
          options.id_cart_product = id_test;
          //
          // gestion de la conversion "sidedish" en "accompagnements"
          // un petit peu bullshit, mais bon...
          //
          const sidedish = model.get("sidedish"),
            productSidedishes = product.getSidedishes();
          let accompagnements: Partial<
            Pick<
              any,
              | "id_product"
              | "price_ttc"
              | "quantity"
              | "total_price_ht"
              | "total_price_ttc"
            >
          >[] = [];

          _(sidedish).each(function (id_product_sidedish, id_cross_selling) {
            _(id_product_sidedish).each(function (id_product) {
              const productSidedish = productSidedishes.findWhere({
                  id_cross_selling: id_cross_selling,
                }),
                accompagnement = new CartProductModel({
                  id_product: id_product,
                  quantity: 1,
                  cart: cart,
                });
              accompagnements.push(
                accompagnement.pick(
                  "id_product",
                  "price_ttc",
                  "quantity",
                  "total_price_ht",
                  "total_price_ttc"
                )
              );
              //
              if (!productSidedish.isFree()) {
                // si le produit n'est pas gratuit, on ajoute le prix à la formule
                total_price_ttc += accompagnement.getPrice("ttc");
              }
            });
            //
          });
          /**/
          options.total_price_ttc = total_price_ttc;
          options.accompagnements = accompagnements;
        }
      } catch (err) {
        // gestion d'erreur si le produit n'existe pas ou comporte un problème
        options = _(options).extend({
          type: "unavailable_products",
          total_price_ht: NaN,
          total_price_ttc: NaN,
        });
      }
      model.set(options);
    }
  }
  setupFromOrderProduct() {
    // converti un order detail en CartProductModel
    const options = {
      type: "order",
      id_cart_product: this.get("id_order_detail"),
      name: this.get("product_name"),
    };
    this.set(options);
  }
  setupExtraPrice() {
    //
    //
    // transformation d'un prix supplémentaire en produit
    //
    const args = this.toJSON();
    this.productExtraPrice = new ProductModel(args);
    this.set({
      id_cart_product: "extraPrice_" + this.cid,
      quantity: false,
      total_price_ttc: this.get("price_ttc"),
      total_price_ht: this.get("price_ht"),
    });
  }
  getType() {
    return this.get("type");
  }
  isProductLikeOffered() {
    return this.id === app.getCart().getProductOffered();
  }
  getProduct(): ProductModel | undefined {
    let p,
      id_product,
      type = this.getType();
    switch (type) {
      // transformation d'un prix supplémentaiC en produit
      case "extraPrice":
        p = this.productExtraPrice;
        break;
      default:
        id_product = this.get("id_product");
        p = app.getProduct(id_product);
    }
    if (!p && type === "order") {
      // créé un produit virtuel temporaire "deleted" à partir des infos de l'historique de commande
      p = this.p;
      if (!p)
        p = this.p = new ProductModel(
          _.extend(this.toJSON(), { deleted: true })
        );
    }
    return p;
  }
  editableQuantity() {
    // retourne si le produit est modifiable en quantité
    const type = this.getType();
    const product = this.getProduct();
    if (
      type === "unavailable_products" ||
      type === "order" ||
      (product && product.get("is_menu"))
    ) {
      return false; // si le produit est déjà commandé, on ne peut pas modifier la quantité
    } else {
      // si le produit a une quantité,
      return this.getQuantity() > 0;
    }
  }

  openProductPageView({ handleOnClose }: { handleOnClose?: () => void } = {}) {
    const cartProduct = this;
    const productModel = cartProduct.getProduct();
    if (productModel) {
      app.displayModal(({ onClose }) =>
        ProductPageView({
          id_product: productModel.id,
          cartProduct,
          onClose: () => {
            onClose && onClose();
            handleOnClose && handleOnClose();
          },
        })
      );
    }
  }
  deletable() {
    return true;
  }
  isCartProduct() {
    return true;
  }
  isDeleted() {
    return this.get("deleted");
  }
  getQuantity() {
    return parseInt(this.get("quantity"));
  }
  getPictureUrl() {
    return this.getProduct()?.getPictureUrl("line") || "";
  }
  displayQuantity() {
    var product = this,
      q = product.getQuantity() || 0;
    return q > 0 && product.isAvailable()
      ? q + "x"
      : app.t("Indisponible", "Product");
  }
  // getWebappsActionUrl(action,option){
  //     var options = {
  //         action:action,
  //         id_product:this.id,
  //         option:option
  //     }
  //     return UrlFactory.getUrl('webapps-action-product',options,false,true);
  // }
  // Ajout au panier instantané
  //getQuantity(sync){
  //    var model = this;
  //    //return model.isDeleted() ? 0 :
  //    return model.get('quantity'+(sync ? 'Sync':''));
  //}
  //addQuantitySync(q){
  //    // cette fonction sert à modifier la quantité de manière synchrone
  //    // q représente une différence et non une valeur
  //    var cartProduct = this;
  //    cartProduct.setQuantitySync(cartProduct.getQuantity(true)+q);
  //}
  //deleteSync(){
  //    // cette fonction supprime un produit de manière synchrone
  //    var cartProduct = this;
  //    cartProduct.set({
  //        sync:false,
  //        deleted:true,
  //        total_price_ht:0,
  //        total_price_ttc:0,
  //        price_ttc:0,
  //        quantity:0,
  //        quantitySync:0,
  //    });
  //    cartProduct.trigger('delete',cartProduct);
  //    //cartProduct.collection.trigger('remove',cartProduct);
  //}
  //setQuantitySync(q){
  //    // cette fonction sert à setter la quantité de manière synchrone
  //    var cartProduct = this,
  //        cart = cartProduct.getCart(),
  //        product = cartProduct.getProduct(),
  //        price_ttc = product.getPrice('ttc',true,cart),
  //        total_price_ttc = q*price_ttc,
  //        total_price_ht = q*product.getPrice('ht',true,cart);
  //    //
  //    if(q == 0){
  //        // si la quantité est nulle, on supprime le produit
  //        return cartProduct.deleteSync();
  //    }
  //    //
  //    cartProduct.set({
  //        quantity:q,
  //        quantitySync:q,
  //        deleted:false,
  //        total_price_ttc:total_price_ttc,
  //        total_price_ht:total_price_ht,
  //        price_ttc:price_ttc
  //    });
  //    cartProduct.set('sync',false);
  //}
  //onChangeQuantity(model,quantity,status){
  //
  //    if(!model.isSync()){
  //        // si le model n'est pas synchronisé
  //        var quantitySync = model.getQuantity(true);
  //        // si la quantité est différente, entre la version synchronisée et la version cible,
  //        // on concerve la version cible
  //        if(quantitySync!=quantity){
  //            model.setQuantitySync(quantitySync,false);
  //        }else{
  //            // sinon on déclare le model comme synchronisé
  //            model.set('sync',true);
  //        }
  //    }
  //
  //}
  //onRestore(model,deleted){
  //    if(!deleted){
  //        model.collection.trigger('restore',model);
  //    }
  //}
  isAvailable() {
    return this.getType() !== "unavailable_products";
  }
  onRemove(model: unknown) {}
  getPrice(type: string = "total_price_ttc") {
    return parseFloat(this.get(type));
  }
  isFree() {
    return this.getPrice() === 0;
  }
  getExtraClass() {
    const model = this,
      product = model.getProduct();
    let classes = "";
    if (!!product) {
      classes = product.getExtraClass();
    }
    if (model.isProductLikeOffered()) {
      classes += " product-like-offered";
    }
    return classes;
  }
  formatPriceQuantity(q: number, type?: string) {
    const quantity = this.getQuantity(),
      price = this.getPrice(type),
      unitPrice = (q * price) / quantity;
    return formatPrice(unitPrice);
  }
  formatPrice(type?: string) {
    const model = this;
    let price;
    if (model.getType() === "extraPrice" && model.get("free") === true) {
      // utilisé initialement pour les frais de gestion offerts
      return model.get("freeLabel");
    } else if (model.isProductLikeOffered()) {
      return app.t("Offert", "Product");
    }
    price = model.getPrice(type);
    /*if(price === 0){
          return app.t('Offert','Cart');
      }*/
    return formatPrice(price);
  }
  formatNameWithPrice() {
    // affiche le nom avec le prix, si il n'est pas gratuit
    var model = this,
      n = model.getName(),
      p = model.getPrice();
    if (p > 0) {
      n +=
        " " +
        app.t(
          "(+ %s)",
          "Cart",
          formatPrice(model.getPrice(), { auto_round_decimal: true })
        );
    }
    return n;
  }
  getCart(): CartModel | OrderModel {
    // TODO: fix get collection cart
    return this.collection
      ? (this.collection as unknown as CartProductCollection).cart
      : new CartModel();
  }
  getAccompagnement() {
    let accompagnements = this.accompagnements;

    // si la collection n'existe pas encore
    if (accompagnements) return accompagnements;

    const cart = this.getCart();
    // instancie les accompagnements en tant que collection
    accompagnements = new CartProductCollection([], {
      cart: cart,
      type: "accompagnements",
    });
    accompagnements.attachCart(cart, "accompagnements", this);
    //
    return (this.accompagnements = accompagnements);
  }
  formatAccompagnements(options?: { wrapper?: string }) {
    // retourne le label des accompagnement,
    // permet de wrapper ceux-ci dans un template wrapper, via sprintf
    //
    const cartProduct = this;
    const {
      wrapper = '<span class="%s" title="%s"> %s</span>',
      separator = ", ",
    } = {
      ...options,
    };
    const product = cartProduct.getProduct();
    const _list = cartProduct
      .getAccompagnement()
      .map(function (accompagnement, index) {
        let accompagnementText = accompagnement.formatNameWithPrice(),
          classList: string[] = ["accompagnement"],
          title = accompagnementText,
          quantity = accompagnement.getQuantity();
        console.log("accompagnement", accompagnement, accompagnementText);
        if (!accompagnement.get("visible")) {
          classList.push("unavailable");
          title =
            app.t("Produit indisponible", "Product") +
            " : " +
            accompagnementText;
        }
        if (quantity > 1) {
          accompagnementText = quantity + "x " + accompagnementText;
        }
        accompagnement
          .getExtraDisplayPrice()
          .each((extraPrice: { name: any; price_ttc: any }) => {
            accompagnementText += app.t("avec %s %s", "cart", [
              extraPrice.name,
              product?.formatPriceValue(extraPrice.price_ttc) || "",
            ]);
          });
        return sprintf(wrapper, classList.join(" "), title, accompagnementText);
      });
    return _list.join(separator);
  }
  formatPriceValue(value: string | number) {
    return formatPrice(value);
  }
  getExtraDisplayPrice() {
    const product = this.getProduct(),
      extraDisplayPrice = !!product ? product.getExtraDisplayPrice() : _([]);

    return extraDisplayPrice;
  }
  getSidedishes(): SidedishCollection {
    //
    // retourne tous les accompagnements (même non affectés)
    // et défini les selections
    //
    const model = this,
      accompagnements = model.getAccompagnement(),
      sidedishes =
        model.getProduct()?.resetSidedishes(accompagnements) ||
        new SidedishCollection([]);
    return sidedishes;
  }
  getSidedish(id: string | number) {
    return this.getSidedishes().findWhere({ id_cross_selling: id });
  }
  isBuyableInContext() {
    const product = this.getProduct();
    return !!product && product.isBuyableInContext();
  }
  getName() {
    try {
      return this.getProduct()?.getName() || "";
    } catch (err) {
      app.error("Produit Indisponible", { id_cart_product: this.id });
      return app.t("Produit Indisponible", "Product");
    }
  }

  getId() {
    return this.get("id_cart_product");
  }
}
// CartProductModel.prototype.collection = CartProductCollection;
CartProductModel.prototype.idAttribute = "id_cart_product";
CartProductModel.prototype.url = function () {
  const cart = app.getCart();
  return `cart/${cart.get("id_cart") || "current"}/product/${this.id}`;
};

export default CartProductModel;
