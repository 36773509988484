import React from "react";
import { appConfig } from "@src/config/appConfig";
import { useRollingStartNavigation } from "@src/lib/rolling-start-navigation/index";
// import { Link } from "gatsby";
import { FlexBoxLinkProps } from "design-system/src/Core/Link/Context/DefaultFlexBoxLink";
import FlexBox from "design-system/src/Components/FlexBox/FlexBox";
import { navigate } from "gatsby-plugin-intl-sushishop";

const FlexBoxLink = (props: FlexBoxLinkProps) => {
  const { sushishopBaseUrl } = appConfig;
  const { isRollingStart } = useRollingStartNavigation();
  const {
    children,
    to,
    href = "",
    color: colorProps,
    ref,
    onClick,
    type,
    ...anchorRest
  } = props;
  const re = new RegExp(`^${sushishopBaseUrl}`, "i");
  const route = (to || href).replace(re, "");
  const javascript_uri = /^javascript:(.*)/.exec(route);
  const isInternal = /^\/(?!\/)/.test(route);
  const useRollingStartRouting = isInternal && isRollingStart(route);
  // if (isInternal && useRollingStartRouting) {
  //   return (
  //     <FlexBox as={Link} to={route} onClick={onClick} {...anchorRest}>
  //       {children}
  //     </FlexBox>
  //   );
  // }
  // if (!route || javascript_uri) {
  //   const { onClick, color, ...buttonRest } =
  //     anchorRest as ButtonHTMLAttributes<any>;
  //   const evalJs = () => {
  //     eval(javascript_uri ? javascript_uri[1] : "()=>{}");
  //   };
  //   return (
  //     <FlexBox
  //       as={"button"}
  //       {...buttonRest}
  //       color={colorProps}
  //       onClick={onClick || evalJs}
  //       type={type}
  //     >
  //       {children}
  //     </FlexBox>
  //   );
  // }

  const handleClick = (
    e:
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
      | React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (isInternal && useRollingStartRouting) {
      navigate(route);
    } else if (!route || javascript_uri) {
      eval(javascript_uri ? javascript_uri[1] : "()=>{}");
    } else {
      window.location.href = route;
    }
    onClick?.(e as any);
  };

  return (
    <FlexBox as={"a"} href={route} onClick={handleClick} {...anchorRest}>
      {children}
    </FlexBox>
  );
};

export default FlexBoxLink;
