import styled, { css, CSSObject, CSSProperties } from "styled-components";
import { createElement, FC, forwardRef, PropsWithChildren } from "react";
import { IImageProps } from "./Image";
import { Layout } from "gatsby-plugin-image";
import clsx from "clsx";
import {
  responsiveMobileBreakpoint,
  responsiveDesktopBreakpoint,
} from "../Grid/_utils/responsiveObserve";

const ImageWrapper: FC<
  PropsWithChildren<{
    as: IImageProps["as"];
    style?: CSSProperties;
    className?: string;
    layout: Layout;
    $css?: CSSObject;
  }>
> = forwardRef(function ImageWrapper(
  { as = `div`, layout, style, className, children, $css },
  ref
) {
  return createElement(as, {
    style,
    className: clsx(className, "ro-image", "ro-image--" + layout),
    children,
    "data-layout": layout,
    ref: ref,
    $css,
  });
});

export const _ImageRoot = styled(ImageWrapper)<{
  $css?: CSSObject;
}>(
  ({ $css }) => {
    return $css;
  },
  css`
    position: relative;
    overflow: hidden;
    img {
      position: relative;
      display: block;
      // height: auto;
      transition-duration: 0.5s;
      // top: 0;
      object-fit: cover;
      // opacity: 0;
    }
    img[data-src] {
      opacity: 0 !important;
    }
    @keyframes shimmer {
      100% {
        transform: translateX(100%);
      }
    }

    &.loading::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(230, 230, 230, 0) 0,
        rgba(230, 230, 230, 0.3) 20%,
        rgba(230, 230, 230, 0.6) 60%,
        rgba(230, 230, 230, 0)
      );
      //animation: shimmer 2s infinite;
      content: "";
    }

    img {
      transition-duration: 0.5s;
      transition-timing-function: linear;
      transition-property: opacity;
    }
    // .ro-image__placeholder {
    //   top: 0;
    //   box-sizing: border-box;
    //   min-height: 100%;
    //   overflow: hidden;
    //   position: relative;
    //   &::after {
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     bottom: 0;
    //     left: 0;
    //     transform: translateX(-100%);
    //     background-image: linear-gradient(
    //       90deg,
    //       rgba(255, 255, 255, 0) 0,
    //       rgba(255, 255, 255, 0.2) 20%,
    //       rgba(255, 255, 255, 0.5) 60%,
    //       rgba(255, 255, 255, 0)
    //     );
    //     animation: shimmer 2s infinite;
    //     content: "";
    //   }

    //   @keyframes shimmer {
    //     100% {
    //       transform: translateX(100%);
    //     }
    //   }
    // }
    // .ro-image__placeholder + picture > img {
    //   position: absolute;
    //   width: 100%;
    //   height: 100%;
    // }
    // &.ro-image--fullWidth {
    // max-width: 100%;
    // width: 100%;
    // }
    // &.ro-image--constrained {
    // max-width: 100%;
    // width: auto;
    // height: 100%;
    // max-height: none !important;
    // img {
    //   max-width: 100%;
    // }
    // }
  `
);

export const _ImageResponsiveRoot = styled("div")<{}>(() => {
  return {
    width: "100%",
    position: "relative",

    [`@media ${responsiveMobileBreakpoint}`]: {
      ".ro-image-responsive__desktop": {
        display: "none",
      },
    },
    [`@media ${responsiveDesktopBreakpoint}`]: {
      ".ro-image-responsive__mobile": {
        display: "none",
      },
    },
  };
});
