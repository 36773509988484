import useDesignSystemNotification from "design-system/src/Core/Notification/useNotification";
import { nanoid } from "nanoid";
import React, { ReactNode, useState, useMemo, useCallback } from "react";
// import AlertNotice from "./AlertNotice";
import AlertNoticeContext, {
  IAlertNoticeContextState,
  IDisplayOption,
} from "./AlertNoticeContext";

const AlertNoticeProvider = (props: { children?: ReactNode }) => {
  const { children } = props;
  const { notice, contextHolder } = useDesignSystemNotification({
    type: "bottom-header",
    getContainer:
      typeof document !== "undefined"
        ? () => {
            const layoutContainer: HTMLElement | null = document.querySelector(
              ".default-notice-wrapper"
            ) as HTMLElement;
            return layoutContainer || document.body;
          }
        : undefined,
  });
  const { notice: bottomNotice, contextHolder: bottomContextHolder } =
    useDesignSystemNotification({
      // type: "bottom-header",
      getContainer:
        typeof document !== "undefined"
          ? () => {
              const layoutContainer: HTMLElement | null =
                document.querySelector(".layout-content") as HTMLElement;
              return layoutContainer || document.body;
            }
          : undefined,
    });
  const [notices, setNotices] = useState<
    Array<IDisplayOption & { id: string }>
  >([]);

  const removeNotice = useCallback((id: string) => {
    setNotices((list) => list.filter((item) => item.id !== id));
  }, []);

  const createNotice = useCallback((data: IDisplayOption) => {
    const id = nanoid();
    setNotices((list) => [
      ...list,
      {
        ...data,
        id: id,
      },
    ]);
  }, []);

  const displayHandler: IAlertNoticeContextState["displayMsg"] = useCallback(
    (options) => {
      createNotice(options);
    },
    [createNotice]
  );

  const value = useMemo(
    () => ({
      notice,
      // ----
      displayMsg: displayHandler,
      removeNotice,
      createNotice,
      notices,
    }),
    [notice, displayHandler, removeNotice, createNotice, notices]
  );

  return (
    <AlertNoticeContext.Provider value={value}>
      {children}
      {contextHolder}
      {bottomContextHolder}
      {/* <AlertNotice notices={notices} removeNotice={removeNotice} /> */}
    </AlertNoticeContext.Provider>
  );
};

export default AlertNoticeProvider;
