import { ButtonTheme } from "../Foundation/Button/Button.styles";
import { css } from "styled-components";

export type ITheme =
  | "main_dark"
  | "main_light"
  | "champagne_dark"
  | "champagne_light"
  | "mono_dark"
  | "mono_light";

export type IThemeMode = "dark" | "light";
export enum EThemeVariant {
  MAIN = "main",
  CHAMPAGNE = "champagne",
  MONO = "mono",
  CHAMPAGNE_LIGHT = "champagne_light",
}
export type IThemeVariant =
  | EThemeVariant
  | "main"
  | "champagne"
  | "mono"
  | "champagne_light";

export const ThemeMode = (theme?: ITheme): IThemeMode | undefined => {
  if (!theme) return undefined;
  const split = (theme || "").split("_");
  return split ? (split[1] as IThemeMode) : undefined;
};
export const isDarkTheme = (theme: ITheme): boolean =>
  ThemeMode(theme) === "dark";
export const ThemeVariant = (theme: ITheme): IThemeVariant => {
  return theme.split("_")[0] as IThemeVariant;
};

// export const ThemeButtonVariant = (theme: ITheme)
export interface IDefaultTheme {
  theme?: ITheme;
  mode?: IThemeMode;
  variant?: IThemeVariant;
  button?: Partial<ButtonTheme>;
  mono?: boolean;
  color: {
    black: string;
    white: string;
    yellow: string;
    yellow2: string;
    orangeMySushiShop: string;

    beigeBg: string;

    champagne: string;
    champagne2: string;
    champagne3: string;
    champagne4: string;
    champagne7: string;
    disabled: string;

    greyText: string;
    greyText2: string;
    greyText3: string;
    greyTextDisabled: string;

    searchColor: string;

    success: string;
    success2: string;
    error: string;
    warning: string;

    blackIcon: string;

    placeholder: string;
    inputBorder: string;

    white2: string;
    containerBg: string;

    productTagSecondary: string;

    blueExplorer: string;
    orangeGourmet: string;
    redEpicurian: string;
  };
}

export const defaultTheme: IDefaultTheme = {
  mode: "light",
  button: {},
  color: {
    black: "#000000",
    white: "#FFFFFF",
    yellow: "#EAC55E",
    yellow2: "#CFA52E",
    orangeMySushiShop: "#FF6100",

    blueExplorer: "#A6B9C7",
    orangeGourmet: "#E4B89A",
    redEpicurian: "#DD9385",

    beigeBg: "#F4F3F1",

    champagne: "#b4a895",
    champagne2: "#908271",
    champagne3: "#93826f",
    champagne4: "#726151",
    champagne7: "#1b1a16",
    disabled: "#ebe1d1",

    greyText: "#777777",
    greyText2: "#6C6C6C",
    greyText3: "#D9D9D9",
    greyTextDisabled: "#999",

    searchColor: "#908271",

    success: "#009146",
    success2: "#0BC264",
    error: "#E60000",
    warning: "#E66100",

    blackIcon: "#343434",
    placeholder: "#020202",

    white2: "#F4F4F4",

    inputBorder: "#E8E8E8",
    containerBg: "#E4DBC5",

    productTagSecondary: "#F4F3F1",
  },
};

export const resetButton = () => css`
  & {
    background: none;
    border: none;
    padding: initial;
    cursor: pointer;
    display: inherit;
    text-align: inherit;
  }
`;

export const useTheme = (): IDefaultTheme => defaultTheme;
