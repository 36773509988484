import {
  NotificationConfig,
  useNotification as useRcNotification,
} from "rc-notification";
import "./index.css";
import React from "react";
import FlexBox from "../../Components/FlexBox/FlexBox";
import Icon from "../../Foundation/Icon/Icon";
import { useTheme } from "../../Themes/defaultTheme";
import motion from "./motion";
import clsx from "clsx";

const useNotification = (
  rootConfig?:
    | (NotificationConfig & {
        type?: "absolute" | "bottom-header";
      })
    | undefined
) => {
  const { color } = useTheme();
  const [notice, contextHolder] = useRcNotification({
    ...rootConfig,
    className: (placement) =>
      clsx(rootConfig?.className ? rootConfig.className(placement) || "" : "", {
        "rc-notification-absolute":
          rootConfig && rootConfig.type === "absolute",
        "rc-notification-bottom-header":
          rootConfig && rootConfig.type === "bottom-header",
      }),
    motion,
    closable: true,
    closeIcon: (
      <FlexBox style={{ cursor: "pointer" }} justify="center" align="center">
        <Icon icon="cross_close" color={color.black} />
      </FlexBox>
    ),
  });

  return {
    notice,
    contextHolder,
  };
};

export default useNotification;
