import ObjectModel from "../prototypes/ObjectModel";
import { app } from "./AppModel";
import _ from "underscore";
import OfferedProductCollection from "./OfferedProductCollection";
import CartModel from "./CartModel";
import {
  GATSBY_CONF_BAGUETTES,
  GATSBY_CONF_SAUCE_SUCREE,
  GATSBY_CONF_SAUCE_SALEE,
  GATSBY_CONF_GINGEMBRE,
  GATSBY_CONF_WASABI,
} from "@src/utils/constants";

export enum EOfferedProductSlug {
  BAGUETTES = "baguettes",
  SAUCE_SUCREE = "sauce_sucree",
  SAUCE_SALEE = "sauce_salee",
  GINGEMBRE = "gingembre",
  WASABI = "wasabi",
  SAUCE_MAYO_SPICY = "sauce-mayo-spicy",
}

export const CONSUMABLES_PRODUCT_IDS = {
  [EOfferedProductSlug.BAGUETTES]: GATSBY_CONF_BAGUETTES,
  [EOfferedProductSlug.SAUCE_SUCREE]: GATSBY_CONF_SAUCE_SUCREE,
  [EOfferedProductSlug.SAUCE_SALEE]: GATSBY_CONF_SAUCE_SALEE,
  [EOfferedProductSlug.GINGEMBRE]: GATSBY_CONF_GINGEMBRE,
  [EOfferedProductSlug.WASABI]: GATSBY_CONF_WASABI,
};

const getSlugFromId = (id_product: string) => {
  return Object.keys(CONSUMABLES_PRODUCT_IDS).find(
    // @ts-ignore
    (key) => CONSUMABLES_PRODUCT_IDS[key] === id_product
  );
};

const sauceTestSlug = /^sauce_/;
const icons = {
  baguettes: "rods",
  sauce_sucree: "sweet-sauce",
  sauce_salee: "salty-sauce",
  gingembre: "ginger",
  wasabi: "wasabi",
  "sauce-mayo-spicy": "sweet-sauce",
  default: "sweet-sauce",
};

const idExistsInQuantityList = (id: string, list: any) => {
  let q = 0;
  if (
    !_(list).isUndefined() &&
    !_(list.list).isUndefined() &&
    !_(list.list[id]).isUndefined()
  ) {
    q += parseInt(list.list[id]);
  }
  return q;
};

class OfferedProductModel extends ObjectModel {
  name = "OfferedProductModel";
  idAttribute = "slug";

  //   @ts-ignore
  //   collection!: OfferedProductCollection<this>;

  initialize(attributes?: any, _options?: any): void {
    const model = this;
    super.initialize.apply(model, [attributes, _options]);

    const id_product = model.get("id_product");
    const slug = getSlugFromId(id_product);
    let options = {
      slug: id_product,
      fix: false,
      isSauce: false,
      quantity: false,
      free: false,
    };
    if (!!slug) {
      const isSauce = sauceTestSlug.test(slug);
      options = {
        slug: slug,
        fix: true,
        isSauce: isSauce,
        quantity: false,
        free: false,
      };
    }
    model.set(options);
  }
  setup() {
    const model = this,
      slug = model.id,
      cart = this.getCart(),
      idProduct = model.get("id_product"),
      findProp = { id_product: idProduct };
    let quantity = 0;

    if (cart) {
      if (slug === EOfferedProductSlug.BAGUETTES) {
        const baguettes = cart.getConsumables().findWhere(findProp);
        quantity = baguettes ? parseInt(baguettes.getQuantity() + "") : 0;
      } else {
        if (model.get("fix") === true) {
          //   const static_suffix = model.isSauce() ? "" : "_static";
          const isSauce = model.isSauce();
          let offeredProducts_qty = cart.get("consumables_qty"),
            offeredProducts_qty_gratuit = isSauce
              ? offeredProducts_qty?.gratuit
              : offeredProducts_qty?.gratuit_static,
            offeredProducts_qty_payant = isSauce
              ? offeredProducts_qty?.payant
              : offeredProducts_qty?.payant_static;
          quantity += idExistsInQuantityList(
            idProduct + "-1",
            offeredProducts_qty_gratuit
          );
          quantity += idExistsInQuantityList(
            idProduct,
            offeredProducts_qty_payant
          );
        } else {
          const product = cart.getProducts().findWhere(findProp);
          if (product) {
            quantity = product.getQuantity();
          } else {
            quantity = 0;
          }
        }
      }
      model.setQuantity(quantity);
    }
  }
  getCart(): CartModel {
    return app.getCart();
    //
    // return (
    //   (this.collection as unknown as OfferedProductCollection)?.cart ||
    //   new CartModel()
    // );
  }
  isSauce() {
    return this.get("isSauce");
  }
  onUpdate(e: any, a: any) {
    //console.log("onUpdate", e, a.id);
  }
  getProduct() {
    return app.getProduct(this.get("id_product"));
  }
  getPictureUrl(useAlt?: boolean): string {
    const product = this.getProduct();
    if (product) {
      let picture = product.getPicture();
      if (useAlt) {
        picture = product.getPictureByType("productCustom") || picture;
      }
      return product.getPictureUrl("consumable", picture);
    }
    return "";
  }
  getPrice() {
    return this.getProduct()?.getPrice("ttc", true) || "";
  }
  isBuyableInContext() {
    return this.getProduct()?.isBuyableInContext() || false;
  }
  isVisibleInContext() {
    return this.getProduct()?.isVisibleInContext() || false;
  }
  getQuantity() {
    return this.isBuyableInContext() ? this.get("quantity") || 0 : 0;
  }
  setQuantity(q: number) {
    this.set("quantity", q);
  }
  getQuantityMax() {
    const model = this,
      cart = app.getCart();
    let qty = 0,
      adding_payment = 0;

    if (!model.isBuyableInContext()) {
      return 0;
    }
    if (model.get("fix") === true) {
      switch (model.id) {
        case EOfferedProductSlug.GINGEMBRE:
        case EOfferedProductSlug.WASABI:
          adding_payment = 4;
          break;
        case EOfferedProductSlug.SAUCE_SUCREE:
        case EOfferedProductSlug.SAUCE_SALEE:
          adding_payment = 5;
          return model.getSauceQuantityMax(adding_payment);
      }
    } else {
      const presetConsumables = cart.get("presetConsumables") || {},
        suffix = "available_nb_baguettes";
      const max = parseInt(presetConsumables[suffix]);
      const product = cart
        .getProducts()
        // @ts-ignore
        .find({ id_product: model.get("id_product") });
      if (product && product.getQuantity() > max) {
        return product.getQuantity();
      } else {
        return max;
      }
    }

    qty += model.getQuantityFree();
    qty += adding_payment;
    return qty || 0;
  }
  getPreset() {
    const presetConsumables = this.getCart().get("presetConsumables") || {},
      suffix = (this.id + "").replace("_", "");
    return parseInt(presetConsumables["preset" + suffix]);
  }
  resetPreset() {
    this.set("quantity", this.getPreset());
  }
  getQuantityFree() {
    const model = this,
      slug = model.id + "",
      collection = model.collection as unknown as OfferedProductCollection,
      cart = this.getCart();
    let qty = 0;
    let suffix = slug;
    let freeQuantity;
    //
    if (!model.isBuyableInContext()) {
      return 0;
    }
    if (sauceTestSlug.test(slug)) suffix = "sauce";

    try {
      freeQuantity =
        cart?.get("presetConsumables")["available_nb_" + suffix] || 0;
      //
    } catch (err) {
      freeQuantity = 0;
    }
    qty += freeQuantity;

    if (
      slug === EOfferedProductSlug.SAUCE_SUCREE ||
      slug === EOfferedProductSlug.SAUCE_SALEE
    ) {
      // free 8 sauces
      const sauce_salee_qty = collection.getQuantityBySlug(
        EOfferedProductSlug.SAUCE_SALEE
      ); // 3
      const sauce_sucree_qty = collection.getQuantityBySlug(
        EOfferedProductSlug.SAUCE_SUCREE
      ); // 3
      const sauce_total_qty = sauce_salee_qty + sauce_sucree_qty; // 6
      const free_rest = freeQuantity - sauce_total_qty; // 1

      if (sauce_total_qty > freeQuantity) {
        // quantité de sauce la plus petite
        const min = Math.min(sauce_salee_qty, sauce_sucree_qty); // 3
        // quantité gratuite divisable par 2 (=répartition égale)
        const free_equal = 2 * Math.floor(freeQuantity / 2); // 6
        // moitié de la quantité gratuite (=répartition égale par sauce)
        const free_equal_half = free_equal / 2; // 3
        // partie également répartie,
        // ou la plus petite quantité, ou la moitié de la quantité gratuite (arrondi inférieur)
        const equal_sharing = Math.min(min, free_equal_half); // 3
        // quantité gratuite à répartir
        const unequal_rest = freeQuantity - equal_sharing * 2; // 1
        const is_sauce_salee = slug === EOfferedProductSlug.SAUCE_SALEE;
        const qty_ref = is_sauce_salee ? sauce_salee_qty : sauce_sucree_qty; // 2
        if (sauce_salee_qty === sauce_sucree_qty) {
          // en cas d'égalité, on répartit une sauce sur la sauce salée
          // 3 & 3
          qty = equal_sharing + (is_sauce_salee ? 0 : unequal_rest);
          // salee = 3 + 1 = 4
          // sucree = 3 + 0 = 3
          //console.log("sauce", slug, qty, { equal_sharing });
        } else if (min === qty_ref) {
          qty = equal_sharing;
          //console.log("sauce", slug, qty, { equal_sharing });
        } else {
          qty = equal_sharing + unequal_rest; // 7
          //console.log("sauce", slug, qty, { free_equal_half, unequal_rest });
        }
      } else if (slug === EOfferedProductSlug.SAUCE_SUCREE) {
        qty = sauce_sucree_qty + free_rest;
      } else {
        qty = sauce_salee_qty + free_rest;
      }
      qty = qty < 0 ? 0 : qty;
    }

    return qty || 0;
  }
  getSauceQuantityMax(adding_payment: number = 0) {
    const freeQuantity =
      this.getCart().get("presetConsumables")?.["available_nb_sauce"] || 0;
    return parseInt(freeQuantity + adding_payment);
  }
  getIcon() {
    return (icons as any)[(this.id + "") as string] || icons["default"];
  }
  getName(withSauceAsterisk: boolean = false) {
    let name = this.getProduct()?.getName() || "";
    if (this.isSauce() && withSauceAsterisk) name += "*";
    return name;
  }
  formatAdditionalPrice(
    withPlus: boolean = true,
    quantity: number = 1
  ): string {
    const product = this.getProduct();
    return product && product.getPrice("ttc", true) > 0
      ? (withPlus ? "+ " : "") + product.formatPrice("ttc", true)
      : "";
  }
  formatPrice(displayIncluded?: boolean) {
    return this.formatPriceQuantity(this.getQuantity(), true, displayIncluded);
  }
  formatPriceType() {
    const model = this;
    return !!model.formatPrice(true)
      ? !!model.formatPrice()
        ? model.isBuyableInContext()
          ? "pay"
          : "unavailable"
        : "included"
      : "empty";
  }
  formatPriceQuantity(
    qty: number,
    hideQuantity: boolean,
    displayIncluded?: boolean
  ): string {
    // gère le formatage quantité + prix supplémentaire
    const model = this,
      free = model.get("free") || 0,
      fix = model.get("fix") || 0,
      buyable = model.isBuyableInContext();
    let label = hideQuantity ? "" : qty + "";
    let additionalQty = 0;
    const product = model.getProduct();
    if (!buyable) {
      return product?.getUnavailableMessage() || "";
    }
    if (!!fix) {
      if (qty > free && qty !== 0) {
        additionalQty = qty - free;
      }
    } else {
      additionalQty = qty;
    }
    let hasExtraPrice = false,
      hasAdditionalQty = additionalQty > 0,
      price = product?.getPrice("ttc", true) || 0,
      additionalCost =
        product?.formatPriceQty("ttc", additionalQty, true) || "";
    if (hasAdditionalQty) {
      // formatage du coût additionnel
      hasExtraPrice = price > 0;
      if (hasExtraPrice) {
        // si le produit est payant et qu'il y a un coût supplémentaire
        label += " " + app.t("(+%s)", "Cart", additionalCost);
      }
    }

    if (displayIncluded && !hasExtraPrice) {
      if (fix && !!free) {
        label = app.t("inclus", "Cart");
      } else {
        // on affiche le prix unitaire si le premier est payant
        label = product?.formatPriceQty("ttc", 1, true) || "";
      }
    }
    return label;
  }
  formatOfferedQuantity(ignoreNull?: boolean, quantity?: number) {
    const model = this,
      slug = this.id,
      baseQuantity =
        typeof quantity !== "undefined" && quantity >= 0
          ? quantity
          : model.getQuantity(),
      plural = baseQuantity > 1,
      notNull = baseQuantity > 0,
      context = "Cart";
    let word = "",
      translation = "";
    if (ignoreNull === true && !notNull) return "";
    switch (slug) {
      case EOfferedProductSlug.BAGUETTES:
        word = plural
          ? "%s paires de baguettes"
          : notNull
          ? "une paire de baguettes"
          : "pas de baguette";
        break;
      case EOfferedProductSlug.SAUCE_SUCREE:
        word = plural
          ? "%s sauces sucrées"
          : notNull
          ? "une sauce sucrée"
          : "pas de sauce sucrée";
        break;
      case EOfferedProductSlug.SAUCE_SALEE:
        word = plural
          ? "%s sauces salées"
          : notNull
          ? "une sauce salée"
          : "pas de sauce salée";
        break;
      case EOfferedProductSlug.GINGEMBRE:
        word = plural
          ? "%s gingembres"
          : notNull
          ? "un gingembre"
          : "pas de gingembre";
        break;
      case EOfferedProductSlug.WASABI:
        word = plural ? "%s wasabis" : notNull ? "un wasabi" : "pas de wasabi";
        break;
      case EOfferedProductSlug.SAUCE_MAYO_SPICY:
        word = plural
          ? "%s sauces mayo spicy"
          : notNull
          ? "%s sauce mayo spicy"
          : "pas de sauce mayo spicy";
        break;
      default:
        return quantity + "  " + model.getProduct()?.getName();
    }

    translation = app.t(word, context, quantity);
    return translation;
  }
}

OfferedProductModel.prototype.idAttribute = "slug";
OfferedProductModel.prototype.name = "OfferedProductModel";

export default OfferedProductModel;
