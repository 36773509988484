import { ImageData, ImageFit, ImagePosition } from "../interface/ImageData";
import { ImageFormat } from "@src/lib/render-image/interface/ImageData";
import { ImageUrlParams } from "design-system/src/Components/Image/interface/ImageData";

// CloudFront base URL
// URL basée sur asset prefix
const cdnBaseUrl = (process.env.GATSBY_WEB_ASSET_PREFIX || "/").replace(
  /\/rollingstart\/$/g,
  ""
);

export type IGenerateImageUrlImage = Omit<
  ImageData,
  "breakpoints" | "format"
> & {
  format?: ImageFormat;
};

const AUTO = "auto";

const useSushiShopImage = () => {
  const generateImageUrl = ({
    id,
    width = AUTO,
    height = AUTO,
    fit = ImageFit.COVER,
    position = ImagePosition.CENTER,
    format = ImageFormat.AUTO,
    quality = AUTO,
    rewrite = "",
    extension = "",
  }: ImageUrlParams) => {
    const id_picture = +id > 0 ? id : "0",
      { round } = Math,
      filename = rewrite || +id > 0 ? id : "default",
      h = height === AUTO ? "auto" : round(height || 0).toFixed(0),
      w = width === AUTO ? "auto" : round(width || 0).toFixed(0),
      ext =
        extension || format === ImageFormat.PNG
          ? ImageFormat.PNG
          : format !== "auto"
          ? format
          : ImageFormat.JPG,
      url = `${cdnBaseUrl}/img2/${id_picture}/${w}/${h}/${fit}/${position}/${format}/${quality}/${filename}.${ext}`;
    return url;
  };
  return {
    generateImageUrl,
  };
};

export default useSushiShopImage;
