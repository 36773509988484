import styled from "styled-components";
import LinkWrapper from "./LinkWrapper";
import {
  _LinkAnchorStyles,
  AnchorStylesProps,
} from "design-system/src/Core/Link/Link.styled";

const StyledLink = styled(LinkWrapper)<AnchorStylesProps>((props) => {
  return _LinkAnchorStyles(props);
});

export default StyledLink;
