// TODO: implement LocalStorage

import Backbone from "@src/backbone/prototypes/Backbone";
import _ from "underscore";
import { site_vars } from "../provider/siteVars";
import ObjectCollection from "./ObjectCollection";
import { GATSBY_ENV_COUNTRY_CODE } from "@src/utils/constants";

let ls: Storage;
try {
  ls = window.localStorage;
} catch (err) {
  ls = {
    clear: function () {},
    removeItem: function () {},
    setItem: function () {},
    getItem: function () {
      return null;
    },
    key: function (index: number) {
      return null;
    },
    length: 0,
  };
}

abstract class LocalStorageCollection<
  TModel extends Backbone.Model<
    any,
    Backbone.ModelSetOptions,
    any
  > = Backbone.Model<any, Backbone.ModelSetOptions, any>
> extends ObjectCollection<TModel> {
  public static ls = ls;
  abstract name: string;
  isRemoteFetching: boolean = false;
  backbonePattern = "Collection";
  remoteFetch!: (
    options?: Backbone.ModelFetchOptions | Backbone.CollectionFetchOptions
  ) => JQueryXHR | undefined;
  id: any;

  onRemoteFetch(ressource: any, response: any, promise: any) {
    const self = this;
    self.isRemoteFetching = false;
    if (response && response.length >= 0) {
      self.setLocalStorageItem(response);
    }
  }
  softFetch() {
    // effectue une mise à jour des données à partir du localStorage sans notion d'expiration
    // utilisé au démarrage pour ne pas perdre des données qui sont sensées être expirées,
    // mais qui n'existe pas server side
    // le fetch classique (remote ou local) ne supprimera pas ces données si elles sont absentes
    try {
      const key = this.getLocalStorageKey(),
        item = ls.getItem(key);
      if (item) {
        let dataSet = JSON.parse(item);
        dataSet = _.defaults(this.toJSON(), dataSet);
        this.set(dataSet);
      }
    } catch (err) {
      // cas où le localStorage n'est pas opérationnel
    }
  }
  resetLocalStorageItem() {
    try {
      ls.removeItem(this.getLocalStorageKey());
      ls.removeItem(this.getLocalStorageKeyExpires());
    } catch (err) {}
  }
  localFetch() {
    try {
      var self = this,
        expiresKey = self.getLocalStorageKeyExpires(),
        expires = ls.getItem(expiresKey),
        itemKey = self.getLocalStorageKey(),
        item = ls.getItem(itemKey),
        expirationDelay =
          expires == "0" || !expires
            ? -1
            : new Date(expires).getTime() - new Date().getTime();
      // TODO : expires managment, with XHR Header Expires
      if (expirationDelay < 0) {
        self.resetLocalStorageItem();
      } else if (!_.isUndefined(item)) {
        if (item) {
          let dataSet = JSON.parse(item);
          self.set(dataSet);
          self.trigger("sync", self, dataSet);
          return true;
        }
      }
    } catch (err) {
      // cas où le localStorage n'est pas opérationnel
      return false;
    }
    return false;
  }

  localStorageSetup() {
    this.isRemoteFetching = false;
    this.on("sync", this.onRemoteFetch, this);
  }
  getLocalStorageKey() {
    const self = this,
      __ = "_";
    const { name, country_based = true, translated = true, id } = self;
    let key = name;
    if (country_based) {
      key += __ + GATSBY_ENV_COUNTRY_CODE;
    }
    if (translated) {
      key += __ + this.getLangIsoCode();
    }
    if (id) {
      key += __ + id;
    }
    return key;
  }
  getLocalStorageKeyExpires() {
    return this.getLocalStorageKey() + "_expires";
  }
  getExpiresFromXHR(xhr: any) {
    var Expires = "0";
    try {
      Expires = xhr.getResponseHeader("Expires") || "0";
    } catch (err) {}
    return Expires;
  }

  setLocalStorageItem(json?: any, expires?: string) {
    if (!expires) {
      expires = new Date(
        new Date().setFullYear(new Date().getFullYear() + 1)
      ).toString();
    }
    if (!json) {
      json = this.toJSON();
    }
    try {
      ls.setItem(this.getLocalStorageKeyExpires(), expires);
    } catch (err) {
      this.trigger("QuotaExceededError", this.name);
    }
    this.updateLocalStorageItem(json);
  }

  updateLocalStorageItem(json?: any) {
    if (!json) {
      json = this.toJSON();
    }
    try {
      ls.setItem(this.getLocalStorageKey(), JSON.stringify(json));
    } catch (err) {
      this.trigger("QuotaExceededError", this.name);
    }
  }
}

LocalStorageCollection.prototype.remoteFetch = function (
  options:
    | Backbone.ModelFetchOptions
    | Backbone.CollectionFetchOptions
    | undefined
) {
  this.isRemoteFetching = true;
  switch (this.backbonePattern) {
    case "Model":
      return Backbone.Model.prototype.fetch.call(this, options);
    case "Collection":
      return Backbone.Collection.prototype.fetch.call(this, options);
  }
};

export default LocalStorageCollection;
