import formatPrice from "../prototypes/formatPrice";
import ObjectModel from "../prototypes/ObjectModel";
import _ from "underscore";
import { app } from "./AppModel";
import { vsprintf } from "sprintf-js";
import SidedishModel, { SidedishSelection } from "./SidedishModel";

//
// ProductBoost Model
//

class ProductBoostModel extends ObjectModel {
  _p(_t: string) {
    return app.getProduct(this.get(_t));
  }
  // getSourceProduct() {
  //   return this._p("id_product_source");
  // }
  getTargetProduct() {
    return this._p("id_product_target");
  }
  // getTargetProductMapping() {
  //   return this._p("id_product_target_mapping");
  // }
  // getMenuType() {
  //   var menuType = this.get("type"),
  //     type = "";
  //   if (menuType === 1) {
  //     type = "Poke";
  //   } else if (menuType === 2) {
  //     type = "Smart";
  //   } else if (menuType === 3) {
  //     type = "Sando";
  //   } else if (menuType === 4) {
  //     type = "Gourmet";
  //   }
  //   return type;
  // }
  // getPriceDifference() {
  //   // fix float round value diff
  //   return (
  //     Math.round(
  //       (this.getTargetProduct()?.getPrice() || 0) * 1000 -
  //         (this.getSourceProduct()?.getPrice() || 0) * 1000
  //     ) / 1000
  //   );
  // }
  // formatPriceDifference(options?: object) {
  //   const opt = { ignore_space: true, auto_round_decimal: true, ...options },
  //     diff = this.getPriceDifference();
  //   return formatPrice(diff, opt);
  // }
  // label(key: string) {
  //   const model = this;
  //   return vsprintf(model.get(key), [model.formatPriceDifference()]);
  // }
  availableInContext() {
    // TODO gérer la disponibilité du produit de destination, dans le contexte.
    const model = this,
      targetProduct = model.getTargetProduct(),
      id_store = app.getCart().get("id_store");
    let stores_exclusion = model.get("stores_exclusion"),
      is_store_excluded = false;
    if (id_store === "0") {
      is_store_excluded = !!model.get("stores_exclusion_no_store");
    } else if (stores_exclusion > 0) {
      const stores_exclusion_ids = model.get("stores_exclusion_ids") || [];
      is_store_excluded = stores_exclusion_ids.includes(id_store);
      if (stores_exclusion === 2) is_store_excluded = !is_store_excluded;
    }
    return targetProduct?.isBuyableInContext() && !is_store_excluded;
  }
  // requireProductBoost(sidedishModel?: SidedishModel) {
  //   // TODO à finaliser
  //   const model = this,
  //     id_cross_selling_trigger = model.get("id_cross_selling_trigger");
  //   let validateCrossSelling;
  //   if (sidedishModel) {
  //     // affichage sur une étape de déclenchement de la fiche produit
  //     validateCrossSelling = id_cross_selling_trigger === sidedishModel.id;
  //   } else {
  //     // validation au moment de l'ajout du panier
  //     validateCrossSelling = id_cross_selling_trigger === "0";
  //   }
  //   return validateCrossSelling && this.availableInContext();
  // }
  // applyBoostSelection() {
  //   const productBoostModel = this,
  //     sourceProduct = productBoostModel.getSourceProduct(),
  //     sourceProductIsMenu = !!sourceProduct?.isMenu(),
  //     targetProduct = productBoostModel.getTargetProduct(),
  //     targetProductMapping = productBoostModel.getTargetProductMapping();
  //   let initialSerializedSelection = "",
  //     useProductMapping = false;
  //   targetProduct?.resetSidedishes();
  //
  //   function ApplyProductBoostMapping(sourceSidedish: SidedishModel) {
  //     const sourceCategory = sourceSidedish.getCategory();
  //     const sourceSelection = sourceSidedish.get("selection");
  //     if (sourceCategory) {
  //       // si on a une catégorie produits (ça n'est pas le cas pour un cross-selling groupé)
  //       targetProduct
  //         ?.getSidedishes()
  //         .each(function ApplyMapping(targetSidedish) {
  //           var targetCategory = targetSidedish.getCategory();
  //           if (targetCategory) {
  //             // si la catégorie correspond, on applique la même selection
  //             if (
  //               targetCategory.id === sourceCategory.id &&
  //               sourceProductIsMenu
  //             ) {
  //               targetSidedish.set("selection", sourceSelection);
  //             } else {
  //               // sinon on essaie de trouver une correspondance dans la catégorie…
  //               let targetIdProduct;
  //               // à partir de la liste de ses id_product
  //               const categoryProductsIds = targetSidedish
  //                 .getCategoryProducts()
  //                 .pluck("id_product");
  //
  //               if (
  //                 targetProductMapping &&
  //                 categoryProductsIds.includes(targetProductMapping.id)
  //               ) {
  //                 // sinon, si il y a un mapping produit (targetProductMapping)
  //                 // on fabrique une selection à partir de cet id_product
  //                 targetIdProduct = targetProductMapping.id;
  //                 useProductMapping = true;
  //               } else if (
  //                 sourceProduct &&
  //                 categoryProductsIds.includes(sourceProduct.id)
  //               ) {
  //                 // ou une équivalence du produit de base, (gestion automatique)
  //                 targetIdProduct = sourceProduct.id;
  //                 useProductMapping = true;
  //               }
  //               if (targetSidedish) {
  //                 targetSidedish.set("selection", [
  //                   {
  //                     id: targetIdProduct,
  //                     quantity: 1,
  //                   },
  //                 ] as SidedishSelection[]);
  //               }
  //             }
  //           }
  //         });
  //     }
  //   }
  //
  //   // mapping selection source -> target
  //   if (sourceProductIsMenu) {
  //     sourceProduct?.getSidedishes().each(ApplyProductBoostMapping);
  //   } else {
  //     targetProduct?.getSidedishes().each(ApplyProductBoostMapping);
  //   }
  //   initialSerializedSelection =
  //     targetProduct?.getSidedishes().getSerializedSelection() || "";
  //   targetProduct?.set(
  //     "initialSerializedSelection",
  //     initialSerializedSelection
  //   );
  //   targetProduct?.set("useProductMapping", useProductMapping);
  // }
}

export default ProductBoostModel;
