import FlexBox from "design-system/src/Components/FlexBox/FlexBox";
import React from "react";
import { IUseAuthenticationLogic } from "@src/components/feedback/AuthenticationContent/UseAuthenticationLogic";
import { useTranslation } from "@src/hooks/useTranslation";
import { AlternateSmallButton } from "@src/components/feedback/AuthenticationContent/Form/AlternateSmallButton";
import SubmitButton from "@src/components/feedback/AuthenticationContent/Form/SubmitButton";
import { useConfiguration } from "@src/hooks/query/useConfiguration";
import Typography from "design-system/src/Foundation/Typography/Typography";
import Icon from "design-system/src/Foundation/Icon/Icon";
import { LoyaltyOptInStepWrapper } from "@src/components/feedback/AuthenticationContent/Steps/LoyaltyOptInStep.styled";
import { IconName } from "design-system/src/lib/svgIcon/components";
import { IDefaultTheme } from "design-system/src/Themes/defaultTheme";
import LogoMySushiShop from "@src/components/branding/LogoMySushiShop/LogoMySushiShop";
import { useRollingStartNavigation } from "@src/lib/rolling-start-navigation";
import ImageResponsive from "../../../../lib/render-image/ImageResponsive";

interface ILoyaltyOptInSuccessStepProps {
  authentificationLogic: IUseAuthenticationLogic;
}

const LoyaltyOptInSuccessStep = (props: ILoyaltyOptInSuccessStepProps) => {
  const { t } = useTranslation(["UI"]);
  const { getConfiguration } = useConfiguration();
  const { navigate } = useRollingStartNavigation();
  const { authentificationLogic } = props;
  const { isLoading, isValid, onSubmit, closeSuccessModal } =
    authentificationLogic;
  const _OPTIN_MY_SUSHI_SHOP_SUCCESS_TITLE_TEXT_ = getConfiguration<string>(
      "_OPTIN_MY_SUSHI_SHOP_SUCCESS_TITLE_TEXT_",
      ""
    ),
    _OPTIN_MY_SUSHI_SHOP_LANDING_URL_ = getConfiguration<string>(
      "_OPTIN_MY_SUSHI_SHOP_LANDING_URL_",
      ""
    );

  const OPTIN_MY_SUSHI_SHOP_SUCCESS_ID_PICTURE =
    process.env.GATSBY_CONF_OPTIN_MY_SUSHI_SHOP_SUCCESS_ID_PICTURE || "";

  const foregroundImageProps = {
    image: {
      id: OPTIN_MY_SUSHI_SHOP_SUCCESS_ID_PICTURE,
      width: 320,
      height: 240,
    },
  };

  return (
    <LoyaltyOptInStepWrapper direction="column" gap={12} flex={1}>
      <FlexBox flex={5} direction={"column"} gap={12}>
        <LogoMySushiShop />
        <Typography
          variant={"h4"}
          color={"champagne7"}
          align={"center"}
          className={"loyalty-opt-in__h4"}
          style={{ margin: "auto auto 0", width: "90%" }}
        >
          {_OPTIN_MY_SUSHI_SHOP_SUCCESS_TITLE_TEXT_}
        </Typography>

        {!!OPTIN_MY_SUSHI_SHOP_SUCCESS_ID_PICTURE && (
          <ImageResponsive
            desktop={foregroundImageProps}
            mobile={foregroundImageProps}
            style={{
              width: "100%",
              margin: "0 auto auto",
              transition: "height 0.3s",
              maxWidth: "40vh",
              filter: "drop-shadow(20px 20px 50px rgba(0, 0, 0, 0.1))",
            }}
          />
        )}
      </FlexBox>
      <FlexBox flex={2} direction={"column"} gap={12}>
        <SubmitButton
          type="submit"
          disabled={!isValid}
          loading={isLoading}
          onClick={onSubmit}
          style={{ margin: "0", minHeight: 48 }}
        >
          {t("Continuer", "UI")}
        </SubmitButton>
        <AlternateSmallButton
          style={{ margin: "0 auto 0" }}
          onClick={() => {
            _OPTIN_MY_SUSHI_SHOP_LANDING_URL_ &&
              navigate(_OPTIN_MY_SUSHI_SHOP_LANDING_URL_);
            closeSuccessModal();
          }}
        >
          {t("Plus d’information", "UI")}
        </AlternateSmallButton>
      </FlexBox>
    </LoyaltyOptInStepWrapper>
  );
};

export default LoyaltyOptInSuccessStep;
