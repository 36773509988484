//
// Collection Order

import moment from "moment";
import LocalStorageCollection from "../prototypes/LocalStorageCollection";
import OrderModel from "./OrderModel";

//
var CENTURY_IN_MS = 1000 * 60 * 60 * 24 * 365.25 * 100;

class OrderCollection extends LocalStorageCollection<OrderModel> {
  name = "OrderCollection";
  route = "apiOrderCollection";
  idAttribute = "id_order";
  model = OrderModel;
  privateApi = true;

  initialize(
    models?: Record<string, any>[] | OrderModel[],
    options?: any
  ): void {
    // ObjectCollection constructor heritage
    var collection = this;
    super.initialize.apply(collection, [models, options]);
    //
    collection.on("sync", collection.onSync, collection);
    //
  }

  setComparatorReorder() {
    var collection = this;
    collection.comparator = function (order: OrderModel) {
      var size = collection.size(),
        date = order.getMoment(),
        fromNow = date.diff(moment()),
        fromNowCentury = 1 - fromNow / CENTURY_IN_MS,
        isNowService = order.isNowService() ? 0 : 1 * size;
      return isNowService + fromNowCentury;
    };
    collection.sort();
  }

  filterReorder() {
    return this.where({ reorder_available: true });
  }

  getLastOrder() {
    return this.first();
  }
  getOrderById(orderId?: any) {
    return this.getLastOrder();
  }
  onSync() {
    //   TODO: on OrderCollection syng
  }
}

OrderCollection.prototype.url = function () {
  return "/order";
};
OrderCollection.prototype.model = OrderModel;
export default OrderCollection;
