import { BaseAxiosApi } from "@src/hooks/useAxiosInstance";
import { AxiosError, AxiosRequestConfig, AxiosResponse, Method } from "axios";
import Cookies from "js-cookie";

let pendingIframe = false;
let pendingRequests: Array<() => Promise<any>> = [];

function unleashAllPendingRequests() {
  // request all pending queries
  pendingIframe = false;
  while (pendingRequests.length > 0) {
    let item = pendingRequests.pop();
    item && item();
  }
}

class ChallengeJsException<D = any> extends Error implements AxiosError<D> {
  name: string = "ChallengeJsException";
  isAxiosError: boolean = false;
  config: AxiosRequestConfig<D> = {};
  response: AxiosResponse<D>;
  constructor(response: AxiosResponse<D>) {
    super("ChallengeJs must be resolved");
    this.response = response;
  }
  toJSON() {
    return {
      name: this.name,
      message: this.message,
      stack: this.stack,
    };
  }
}

enum ChallengeJsStatus {
  NO_CHALLENGE = 0,
  CHALLENGE_V1 = 1,
  CHALLENGE_V2 = 2,
}

const isChallengeJs = (response: AxiosResponse): ChallengeJsStatus => {
  const XCCSIdCookiePattern = /X-CCS-Id=(([a-zA-Z0-9_\-]+); path=\/)/;
  const {
    data: text = "",
    statusText = "",
    status = 500,
    headers = {},
  } = { ...response };
  if (status === 200 && statusText === "" && XCCSIdCookiePattern.test(text)) {
    // (unsafe) résolution historique via interception du Cookie X-CCS-Id
    return ChallengeJsStatus.CHALLENGE_V1;
  } else if (headers["x-webfence-response"] === "challengejs") {
    // WAF Claranet v2 use header x-webfence-response
    return ChallengeJsStatus.CHALLENGE_V2;
  }
  return ChallengeJsStatus.NO_CHALLENGE;
};

// wrap a jQuery ajax Deferred to manage Challenge JS
function ChallengeJs(
  settings: JQuery.AjaxSettings & {
    cancelToken?: AxiosRequestConfig<any>["cancelToken"];
  } = {}
) {
  let tryChallenge = false;

  function wrapAjax(): Promise<any> {
    if (pendingIframe) {
      // if iframe challengeJs resolution is pending, we don't feed more request to prevent WAF block
      // waiting for a resolution ...
      return new Promise((resolve, reject) => {
        pendingRequests.push(() =>
          wrapAjax()
            .then((data) => resolve(data))
            .catch((error) => reject(error))
        );
      });
    }
    const method = (settings.method || settings.type) as Method;
    return BaseAxiosApi({
      url: settings.url,
      method: method,
      headers: { ...((settings.headers as object) || {}) },
      data: settings.data,
      cancelToken: settings.cancelToken,
      params: method === "GET" ? settings.data : {},
    })
      .then((response: AxiosResponse) => {
        if (isChallengeJs(response) !== ChallengeJsStatus.NO_CHALLENGE) {
          throw new ChallengeJsException(response);
        } else if (settings.success && typeof settings.success === "function") {
          settings.success(response.data, "success" as any, {} as any);
        }
        return response.data;
      })
      .catch((error: AxiosError) => {
        const { response = {} as AxiosResponse } = error || {};
        const text = response.data || "";
        const XCCSIdCookiePattern = /X-CCS-Id=(([a-zA-Z0-9_\-]+); path=\/)/;
        if (
          !tryChallenge &&
          isChallengeJs(response) === ChallengeJsStatus.CHALLENGE_V1
        ) {
          // (unsafe) résolution historique via le Cookie X-CCS-Id
          tryChallenge = true;
          const Cookie = XCCSIdCookiePattern.exec(text);
          if (Cookie && Cookie[2]) Cookies.set("X-CCS-Id", Cookie[2]);
          console.info("ChallengeJs legacy");
          return wrapAjax();
        } else if (isChallengeJs(response) === ChallengeJsStatus.CHALLENGE_V2) {
          // si le challenge est présent,
          // on attend de résoudre le challenge via une iframe
          console.info("ChallengeJs resolution");
          return new Promise(
            (resolve, reject: (reason: AxiosError) => void) => {
              const onMessage = (e: MessageEvent) => {
                const data = e.data;
                if (
                  !!data &&
                  data.status === "ok" &&
                  data.type === "ChallengeJS"
                ) {
                  window?.removeEventListener("message", onMessage);
                  // replay a totally new Ajax request
                  unleashAllPendingRequests();
                  wrapAjax().then(resolve).catch(reject);
                }
              };
              if (typeof window === "undefined") {
                error.name = "ChallengeJsError";
                error.message =
                  "ChallengeJS resolution unavailable because window is undefined";
                reject(error);
              } else {
                window?.addEventListener<"message">("message", onMessage);
                const iframe = document?.createElement("iframe");
                iframe.setAttribute("id", "iframe-challenge-js");
                // cette route est absolue /api/toolssecurity/challengejs
                iframe.setAttribute("src", "/api/toolssecurity/challengejs");
                document?.body?.appendChild(iframe);
              }
            }
          );
        } else {
          // si le challenge n'est pas présent, on rejette la requête
          return new Promise((resolve, reject) => {
            reject(error);
          });
        }
      });
  }
  // Return a promise, so that you can chain methods
  // as you would with regular jQuery ajax calls
  return wrapAjax();
}

export default ChallengeJs;
