import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EAuthentificationStep } from "@src/components/feedback/AuthenticationContent/AuthenticationContent";

export interface AuthenticationOptions {
  preload?: boolean;
  modalOpen?: boolean;
  step?: EAuthentificationStep;
  resetPasswordLink?: string;
  onModalClose?: (success?: boolean) => void;
  onSuccess?: () => void;
  id_customer_comin?: string;
}

interface AuthState {
  openAuthModal?: AuthenticationOptions;
}

const initialState: AuthState = {
  openAuthModal: { preload: false, modalOpen: false },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setPreload(state, action: PayloadAction<boolean>) {
      state.openAuthModal = { ...state.openAuthModal, preload: action.payload };
      return state;
    },
    setOpenAuthModel(state, action: PayloadAction<AuthenticationOptions>) {
      const { payload } = action;
      state.openAuthModal = payload;
      return state;
    },
    setResetPasswordLink(state, action: PayloadAction<string>) {
      state.openAuthModal = {
        ...state.openAuthModal,
        resetPasswordLink: action.payload,
      };
      return state;
    },
  },
});

const reducer = authSlice.reducer;

export const { setOpenAuthModel, setResetPasswordLink } = authSlice.actions;
export default reducer;
