import React, {
  useState,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import FlexBox from "../../../Components/FlexBox/FlexBox";
import Switcher from "../../../Foundation/Switcher/Switcher";
import Typography from "../../../Foundation/Typography/Typography";
// import Modal from "../../Modal/Modal";
import ProductComposeModal from "../ProductComposeModal/ProductComposeModal";
import {
  _ProductDetailsAddToCardContainer,
  _ProductDetailsInfoContainer,
} from "../ProductDetails.styled";
import ProductDetailsAddToCard, {
  IProductDetailsAddToCardProps,
} from "../ProductDetailsAddToCard/ProductDetailsAddToCard";
import ProductDetailsImages, {
  IProductDetailsImagesProps,
} from "../ProductDetailsImages/ProductDetailsImages";
import ProductDetailsSelection, {
  IProductDetailsSelectionProps,
} from "../ProductDetailsSelection/ProductDetailsSelection";
import {
  _ProductSelectionModalRoot,
  _ProductSelectionModalTitleContainer,
  _ProductSelectionModalWrapper,
} from "./ProductSelectionModal.styled";

interface IProductSelectionModalProps<T extends any> {
  imageProps?: IProductDetailsImagesProps;
  accompagnements?: IProductDetailsSelectionProps<T>[];
  title?: string;
  show?: boolean;
  addToCardProps?: IProductDetailsAddToCardProps;
  onClose?: () => void;
  composeLabel?: string;
  startComposeLabel?: string;
  startChildren?: ReactNode;
  onChange?: IProductDetailsSelectionProps<T>["onChange"];
  disableAnimation?: boolean;
}

const ProductSelectionModal = <T extends any = any>(
  props: IProductSelectionModalProps<T>
) => {
  const {
    imageProps,
    accompagnements,
    title,
    show: defaultShow = false,
    addToCardProps,
    // composeLabel,
    // startComposeLabel,
    onClose,
    // startChildren,
    onChange,
    disableAnimation,
  } = props;
  const [currentTab, setCurrentTab] = useState<0 | 1>(0);
  const [starter, setStarter] = useState(true);
  const [show, setShow] = useState(defaultShow);
  const labels: Array<{
    label: ReactNode;
  }> = accompagnements
    ? accompagnements.map(({ title }) => {
        return {
          label: title,
        };
      })
    : [];

  const {
    invalidLabel,
    validLabel,
    allValid,
  }: { invalidLabel: string[]; validLabel: string[]; allValid: boolean } =
    useMemo(() => {
      const _invalidLabel: string[] = [];
      const _validLabel: string[] = [];
      let _allValid: boolean = true;

      accompagnements?.forEach((_item) => {
        const { renderMinErrorLabel, min, max, tag, hasError } = _item;
        const _totalSelected = _item.value
          ? Object.values(_item.value).reduce((p, c) => p + c, 0)
          : 0;

        // invalid label
        _invalidLabel.push(
          min && _totalSelected < min && max !== 1 && min !== 1
            ? renderMinErrorLabel
              ? renderMinErrorLabel(min - _totalSelected)
              : `Ajoutez encore ${min - _totalSelected} produit`
            : ""
        );
        // valid label
        _validLabel.push(tag?.text || "");

        // all Valid
        _allValid = _allValid ? !hasError : false;
      });
      return {
        invalidLabel: _invalidLabel,
        validLabel: _validLabel,
        allValid: _allValid,
      };
    }, [accompagnements]);

  useEffect(() => {
    if (defaultShow !== show) {
      setShow(defaultShow);
    }
  }, [defaultShow]);

  const handleChange = useCallback((v: 0 | 1) => {
    setCurrentTab(v);
  }, []);

  const render = (index: number) => {
    return !!accompagnements && !!accompagnements[index] ? (
      <ProductDetailsSelection
        {...accompagnements[index]}
        type="slider"
        onChange={onChange}
      />
    ) : null;
  };

  return (
    <div>
      <_ProductSelectionModalWrapper
        $show={show}
        $disableAnimation={disableAnimation}
      >
        <ProductComposeModal
          footer={
            addToCardProps && (
              <_ProductDetailsAddToCardContainer>
                <_ProductDetailsInfoContainer
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                >
                  {allValid ? (
                    <ProductDetailsAddToCard {...addToCardProps} />
                  ) : (
                    <ProductDetailsAddToCard
                      label={""}
                      disabled={!!invalidLabel[currentTab]}
                      variant={
                        !invalidLabel[currentTab]
                          ? addToCardProps.variant
                          : "champagne_light"
                      }
                      customize={
                        !!invalidLabel[currentTab]
                          ? {
                              bg: "#F4F3F1",
                              disabledBg: "#F4F3F1",
                              color: "#726151",
                            }
                          : undefined
                      }
                      override={{
                        label:
                          invalidLabel[currentTab] || validLabel[currentTab],
                        onClick: () => {
                          setCurrentTab(currentTab === 1 ? 0 : 1);
                        },
                      }}
                    />
                  )}
                </_ProductDetailsInfoContainer>
              </_ProductDetailsAddToCardContainer>
            )
          }
          style={{
            padding: 0,
          }}
          onClose={onClose}
        >
          <_ProductSelectionModalRoot>
            {imageProps && (
              <ProductDetailsImages {...imageProps}>
                <_ProductSelectionModalTitleContainer>
                  {title && (
                    <Typography variant="h7" style={{ textAlign: "center" }}>
                      {title}
                    </Typography>
                  )}
                </_ProductSelectionModalTitleContainer>
              </ProductDetailsImages>
            )}
            <FlexBox direction="column">
              <div
                style={{
                  textAlign: "center",
                  padding: "10px 0 15px",
                  margin: "-33px 0 0 0",
                  // paddingTop: 25,
                  // paddingBottom: 35,
                }}
              >
                <Switcher
                  default={currentTab}
                  option1={labels[0] || { label: "" }}
                  option2={labels[1] || { label: "" }}
                  onChange={handleChange}
                />
              </div>

              <div
                style={{
                  width: "200vw",
                  position: "relative",
                  transform:
                    currentTab === 0 ? "translateX(0)" : "translateX(-50%)",
                  transition: "transform 0.5s ease",
                }}
              >
                <div style={{ width: "50%", float: "left" }}>{render(0)}</div>
                <div style={{ width: "50%", float: "left" }}>{render(1)}</div>
              </div>
            </FlexBox>
          </_ProductSelectionModalRoot>
        </ProductComposeModal>
      </_ProductSelectionModalWrapper>

      {/* <Modal
        type="transparent-modal"
        show={show && starter}
        bodyStyle={{
          width: "100%",
          height: "75vh",
          marginTop: "auto",
          maxWidth: "100%",
          padding: 0,
        }}
        content={
          <ProductComposeModal
            footer={
              <_ProductDetailsAddToCardContainer>
                <_ProductDetailsInfoContainer
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                >
                  <ProductDetailsAddToCard
                    label={startComposeLabel || ""}
                    override={{
                      label: startComposeLabel || "",
                      onClick: () => setStarter(false),
                    }}
                  />
                </_ProductDetailsInfoContainer>
              </_ProductDetailsAddToCardContainer>
            }
            onClose={() => setStarter(false)}
          >
            {startChildren}
          </ProductComposeModal>
        }
      ></Modal> */}
    </div>
  );
};

export default ProductSelectionModal;
