import ObjectCollection from "../prototypes/ObjectCollection";
import { app } from "./AppModel";
import MessageModel from "./MessageModel";
import _ from "underscore";

class MessageCollection extends ObjectCollection<MessageModel> {
  name = "MessageCollection";
  route = "apiMessageCollection";
  idAttribute = "id_sushi_message";
  comparator = "priority_with_id"; // basé sur la proprité `priority` et l'id le plus récent
  // attention j'ai fait ce comparator mais juste pour pouvoir jouer avec les id
  initialize(
    models?: Record<string, any>[] | MessageModel[],
    options?: any
  ): void {
    const collection = this;
    super.initialize.apply(collection, [models, options]);
    collection.listenTo(app, "ws__new_shop_message", collection.wsNewMessage);
    collection.listenTo(
      app,
      "ws__remove_shop_message",
      collection.wsRemoveMessage
    );
  }
  fetchAndReset() {
    this.fetch({ reset: true });
  }
  wsNewMessage(wsMessage: { data: any }) {
    // ajoute merge le message à la pile (add|update).
    var newMessage = new MessageModel(wsMessage.data);
    if (newMessage.validateContext()) {
      this.set([wsMessage.data], { remove: false });
    } else {
      this.wsRemoveMessage(wsMessage);
    }
  }
  wsRemoveMessage(wsMessage: { data: any }) {
    // suppression d'un message via WS
    this.remove([wsMessage.data]);
  }
  getMessageById(id: string) {
    return this.get(id + "");
    //return this.findWhere({id_sushi_message: id.toString()});
  }
  getByLocation(location: string, options?: any) {
    // TODO peut-être ajouté des notions de filtre (vu/pas vu) ?
    const collection = this;
    if (location === "shop_info") {
      return collection.find(function (message) {
        return message.validateTime();
      });
    } else {
      return collection.find(function (message) {
        const isMatch = _.isMatch(message.attributes, {
          location: location,
          dismissed: false,
        });
        return isMatch && message.validateTime();
      });
    }
  }
}
MessageCollection.prototype.model = MessageModel;
MessageCollection.prototype.url = () => "/sushimessage/";
export default MessageCollection;
