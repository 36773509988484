import { useEffect } from "react";
import "./recaptcha.scss";
import {
  GATSBY_CONF_RECAPTCHA_DESKTOP,
  GATSBY_CONF_RECAPTCHA_DESKTOP_ORDER,
  GATSBY_CONF_RECAPTCHA_DESKTOP_SITE_KEY,
} from "@src/utils/constants";

const useReCaptcha = () => {
  const reCaptchaEnable =
    (GATSBY_CONF_RECAPTCHA_DESKTOP || GATSBY_CONF_RECAPTCHA_DESKTOP_ORDER) &&
    GATSBY_CONF_RECAPTCHA_DESKTOP_SITE_KEY;

  const reCaptchaLink = `https://www.google.com/recaptcha/api.js?render=${GATSBY_CONF_RECAPTCHA_DESKTOP_SITE_KEY}`;
  const scriptId = "google-recaptcha";
  useEffect(() => {
    const d = document;
    const isScriptAlreadyExist = !!d?.getElementById(scriptId);
    if (reCaptchaEnable && !isScriptAlreadyExist && reCaptchaLink) {
      const script = d.createElement("script") as HTMLScriptElement;
      script.src = reCaptchaLink;
      script.defer = true;
      script.id = scriptId;
      d.body.append(script);
    }
  }, [reCaptchaEnable, reCaptchaLink]);

  return (action: string) => {
    if (!reCaptchaEnable) {
      return Promise.resolve("");
    }
    return new Promise<string>((resolve, reject) => {
      grecaptcha.ready(function () {
        grecaptcha
          .execute(GATSBY_CONF_RECAPTCHA_DESKTOP_SITE_KEY, { action: action })
          .then(resolve, reject);
      });
    });
  };
};

export type ReCaptchaAction = (action: string) => Promise<string>;

export default useReCaptcha;
