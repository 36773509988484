import React, { useEffect } from "react";
import store from "@src/store";
import { AppSlice } from "@src/store/reducers/AppSlice";
import { AxiosRequestConfig } from "axios";
import { BaseAxiosApi } from "@src/hooks/useAxiosInstance";
import { PageContextIntl } from "@src/components/general/WrapPageElement/PageContext";

export interface IIsoLangContext {
  isoLang?: string;
  setIsoLang: (iso_lang: string) => void;
}

const IsoLangContext = React.createContext<IIsoLangContext>({
  setIsoLang: (iso_lang) => {
    console.warn("noop", iso_lang);
  },
});
export default IsoLangContext;

export type IsoLangWrapPageElementProps = React.PropsWithChildren<{
  pageContext: PageContextIntl;
}>;

const IsoLangWrapPageElement = ({
  pageContext,
  children,
}: IsoLangWrapPageElementProps) => {
  const { language } = pageContext.intl;
  useEffect(() => {
    language && store.dispatch(AppSlice.actions.setIsoLang(language));
    const axiosInterceptor = UseIsoLangAxiosInterceptor(language);
    return () => {
      EjectIsoLangAxiosInterceptor(axiosInterceptor);
    };
  }, [language]);
  return <>{children}</>;
};
const UseIsoLangAxiosInterceptor = (isoLang: string) => {
  return BaseAxiosApi.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
      const { url } = config;
      let prefixedUrl = url && isoLang && "/" + isoLang + url;
      return { ...config, ...{ url: prefixedUrl } };
    }
  );
};

const EjectIsoLangAxiosInterceptor = (interceptorId: number) => {
  console.log("EjectIsoLangAxiosInterceptor", interceptorId);
  BaseAxiosApi.interceptors.request.eject(interceptorId);
};

export {
  IsoLangWrapPageElement,
  UseIsoLangAxiosInterceptor,
  EjectIsoLangAxiosInterceptor,
};
