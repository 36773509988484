import { startTransition, useEffect, useState } from "react";

const useLoadAfterRequired = ({ required = false }: { required?: boolean }) => {
  const [initialRequired, setInitialRequired] = useState(required);

  useEffect(() => {
    if (!initialRequired && required) {
      startTransition(() => {
        setInitialRequired(required);
      });
    }
  }, [required]);

  return initialRequired;
};

export default useLoadAfterRequired;
