import clsx from "clsx";
import React, { useState, useCallback, useEffect, useRef } from "react";
import { useTheme } from "styled-components";
import FlexBox from "../../../../Components/FlexBox/FlexBox";
import Image from "../../../../Components/Image/Image";
import Tag from "../../../../Components/Tag/Tag";
import { ImageFit } from "../../../../Components/Image/interface/ImageData";
import Select, { ISelectProps } from "../../../../Form/Select/Select";
import Typography from "../../../../Foundation/Typography/Typography";
import Icon from "../../../../Foundation/Icon/Icon";
import { IProductDetailsSelectionProduct } from "../ProductDetailsSelection";
import {
  _AnimatedIconWrapper,
  _IconWhiteWrapper,
  _ProductDetailsSelectionProductActionsWrapper,
  _ProductDetailsSelectionProductRoot,
  _ProductDetailsSelectionProduct_CheckIconWrapper,
  _ProductDetailsMaxWarning,
} from "./ProductDetailsSelectionProduct.styled";
import { QuantifierMobileButton } from "../../../../Foundation/Quantifier/Quantifier.styles";
import Quantifier from "../../../../Foundation/Quantifier/Quantifier";

export interface IProductDetailsSelectionProductProps {
  product: IProductDetailsSelectionProduct;
  quantity?: number;
  selected?: boolean;
  onQuantityChange?: (data: {
    id: IProductDetailsSelectionProduct["id"];
    quantity: number;
  }) => void;
  size?: "small" | "medium" | "large";
  max?: number;
  alwayshowQuantity?: boolean;
  vertical?: boolean;
}
const ProductDetailsSelectionProduct = (
  props: IProductDetailsSelectionProductProps
) => {
  const {
    product: {
      id,
      name,
      subtitle,
      price,
      picture,
      disabled: _disabled,
      editable = true,
    },
    max = 1,
    quantity: defaultQuantity = 0,
    selected,
    onQuantityChange,
    size = "medium",
    alwayshowQuantity,
    vertical = false,
  } = props;
  const [quantity, setQuantity] = useState(defaultQuantity);
  const isMedium = size === "medium";
  const isLarge = size === "large";
  const isMax = quantity === max && quantity > 0;
  const [warnMax, setWarnMax] = useState<number>(0);
  const disabled = max <= 0 || _disabled;
  const hasQuantitySelection = max > 1 && alwayshowQuantity;
  const { color } = useTheme();

  const countOptions: ISelectProps<number>["options"] = [
    // ...(vertical ? [{ label: 0, value: 0 }] : []),
    ...Array.from({ length: max + 1 }, (_, i) => ({
      label: i,
      value: i,
    })),
  ];

  useEffect(() => {
    if (defaultQuantity !== quantity) {
      setQuantity(defaultQuantity);
    }
  }, [defaultQuantity]);

  const handleQuantityChange = useCallback(
    (value: number) => {
      setQuantity(value);
      onQuantityChange && onQuantityChange({ id: id, quantity: value });
    },
    [onQuantityChange]
  );

  const handleSelect = useCallback(() => {
    if (isMax && max > 1) {
      setWarnMax(new Date().getTime());
    } else {
      if (!disabled) {
        handleQuantityChange(
          quantity === max ? (max === 1 ? 0 : quantity) : quantity + 1
        );
      }
    }
  }, [quantity, disabled, handleQuantityChange]);

  const quantifier = vertical
    ? !disabled && (
        <Quantifier
          quantity={quantity}
          maxQuantity={max}
          useMobileTemplate={true}
          className={"quantifier"}
          onChange={handleQuantityChange}
          disabled={disabled}
          editable={editable}
          removeProductLabel={"0"}
          variant={"dark"}
          size={"small"}
        />
      )
    : selected &&
      hasQuantitySelection && (
        <Select
          value={quantity}
          size={"small"}
          options={countOptions}
          onChange={handleQuantityChange}
          placement="topLeft"
          disabled={!editable}
        />
      );
  const selectedIcon = !vertical && (
    <_IconWhiteWrapper>
      <Icon icon="check_circle_fill" color={color.champagne3} size={24} />
    </_IconWhiteWrapper>
  );
  const pictureSize = isLarge ? 80 : 60;
  return (
    <_ProductDetailsSelectionProductRoot
      $selected={selected}
      $size={size}
      $disabled={disabled}
      $editable={editable}
      $vertical={vertical}
      className={clsx({
        disabled,
        editable,
      })}
      onClick={handleSelect}
    >
      {isMedium || isLarge ? (
        <FlexBox
          style={{
            minWidth: pictureSize,
            ...(vertical
              ? {
                  backgroundColor: vertical ? "#F9F8F4" : undefined,
                  width: vertical ? "100%" : undefined,
                  aspectRatio: "1/1",
                  borderRadius: 4,
                  overflow: "hidden",
                  position: "relative",
                }
              : {}),
          }}
        >
          <_ProductDetailsSelectionProduct_CheckIconWrapper
            $top={isMedium ? 5 : 8}
          >
            <_AnimatedIconWrapper $visible={selected} $transitionMode="fade-in">
              {selectedIcon}
            </_AnimatedIconWrapper>
          </_ProductDetailsSelectionProduct_CheckIconWrapper>

          <Image
            alt={picture.alt}
            loading="eager"
            image={{
              id: picture.id || "-1",
              aspectRatio: 1,
              fit: ImageFit.COVER,
              width: pictureSize,
              height: pictureSize,
              layout: "fullWidth",
            }}
            imgStyle={
              (vertical && { width: "100%", height: "auto" }) || undefined
            }
            wrapperStyle={
              vertical
                ? {
                    width: "100%",
                    height: "100%",
                    aspectRatio: "1/1",
                    overflow: "hidden",
                  }
                : undefined
            }
          />

          {vertical && isMax && <WarnMax warnMax={warnMax} />}
          {vertical && quantifier}
        </FlexBox>
      ) : (
        <_AnimatedIconWrapper $visible={selected}>
          {selectedIcon}
        </_AnimatedIconWrapper>
      )}
      <FlexBox
        direction="column"
        gap={2}
        style={vertical ? { flexGrow: 1 } : { paddingTop: 8, paddingBottom: 8 }}
      >
        <Typography variant={isLarge ? "body" : "body2"}>{name}</Typography>
        {isLarge && subtitle && (
          <Typography variant="body2" color="greyText2">
            {subtitle}
          </Typography>
        )}
      </FlexBox>
      <_ProductDetailsSelectionProductActionsWrapper
        style={{
          marginLeft: "auto",
          minWidth: hasQuantitySelection ? 58 : undefined,
          ...(vertical
            ? {
                marginRight: "auto",
                marginLeft: "unset",
              }
            : {}),
        }}
      >
        {!vertical && quantifier}
        {!isLarge && !!price && (
          <Typography variant="body2">{price}</Typography>
        )}
      </_ProductDetailsSelectionProductActionsWrapper>
    </_ProductDetailsSelectionProductRoot>
  );
};

const WarnMax = ({ warnMax }: { warnMax: number }) => {
  const [warns, setWarns] = useState<number[]>([]);
  useEffect(() => {
    const t = new Date().getTime();
    setWarns((prev) => [
      ...prev.filter((w) => {
        return w > t - 1000;
      }),
      warnMax > t - 1000 ? warnMax : 0,
    ]);
  }, [warnMax]);
  return (
    <>
      {warns.map(
        (warn, i) =>
          !!warn && (
            <_ProductDetailsMaxWarning key={`${i}_${warn}`}>
              <Typography variant={"ui"} color={"white"}>
                max
              </Typography>
            </_ProductDetailsMaxWarning>
          )
      )}
    </>
  );
};

export default ProductDetailsSelectionProduct;
