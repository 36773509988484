import { EInputType } from "design-system/src/Form/Input/Input";
import { Control, Controller } from "react-hook-form";
import InputRender from "@src/components/feedback/AuthenticationContent/Form/InputRender";
import React from "react";
import {
  CustomerField,
  FormData,
} from "@src/components/feedback/AuthenticationContent/UseAuthenticationLogic";
type CustomerFieldsProps = {
  customerFields: CustomerField[];
  control: Control<FormData, any>;
};

const CustomerFields = (props: CustomerFieldsProps) => {
  const { customerFields, control } = props;
  return (
    <>
      {customerFields.map(
        ({
          name,
          label,
          icon,
          type = EInputType.TEXT,
          rules,
          MessageRender,
        }) => (
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field, fieldState }) => (
              <InputRender
                {...{
                  fieldState,
                  label,
                  type,
                  MessageRender,
                  rules,
                  ...(icon && { icon }),
                  ...field,
                }}
              />
            )}
          />
        )
      )}
    </>
  );
};

export default CustomerFields;
