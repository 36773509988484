export const routesApi: Record<string, string> = {
  // SetupModel: "setup",
  // SetupPrivateModel: "setupprivate",

  // CategoryCollection: "category",
  // CategoryModel: "category/<%= id_category %>",

  // ConfigurationCollection: "configuration",

  // CountryCollection: "country",
  // CountryModel: "country/<%= id_country %>",

  // MacaronCollection: "macaron",
  // MacaronModel: "macaron/<%= id_macaron %>",

  // PaymentMethodCollection: "paymentmethod",
  // PaymentMethodModel: "paymentmethod/<%= id_payment_method %>",

  // PostcodeCollection: "postcode",
  // PostcodeModel: "postcode/<%= id_postcode %>",
  PostcodeSubscribe: "postcode/<%= postcode %>/subscribe",

  // ProductCollection: "product",
  // ProductModel: "product/<%= id_product %>",

  // StoreCollection: "store",
  // StoreModel: "store/<%= id_store %>",
  StoreClosure: "storeclosure/<%= id_store %>",

  // StoreServiceCollection: "storeservice",
  // StoreServiceModel: "storeservice/<%= id_store_service %>",
  // Job: "jobs",
  // WidgetJob: "jobwidget",
  // TranslationModel: "translation",
  LoginFacebook: "signin/facebook",
  LoginGoogle: "signin/google",
  LoginApple: "signin/apple",
  Login: "signin",
  Logout: "signout",
  RegisterNewsLetter: "customer/new/registernewsletter",
  Register: "customer/new/add",
  // AdvantageModel: "advantagewidget/<%= id_widget_instance %>",
  // IngredientCollection: "ingredient",
  // IngredientModel: "ingredient/<%= id_ingredient %>",

  // AdvancedTemplatingWidget: "advancedtemplatingwidget/<%= ressource %>",

  // TagCollection: "tag",
  // TagModel: "tag/<%= id_ingredient %>",

  // UniversalLinkCollection: "universallink",

  ErrorModel: "error",

  // AllergenGroupCollection: "allergengroup",
  // AllergenGroupModel: "allergengroup/<%= id_allergen_group %>",
  // EstimatedTime: "estimatedtime",

  // CustomerPasswordUpdate: "customer/current/password",
  // CustomerNeolaneComein: "neolane/signup/validation",
  // CustomerModel: "customer/current",
  // CustomerModelComein: "customer/current/comein",
  CustomersetSmsAlert: "customer/current/setsmsalert",
  // CustomersetMailAlert: "customer/current/setmailalert",
  CustomersetNewsletter: "customer/current/setnewsletter",
  CustomerLikeProduct: "customer/current/likeproduct",
  CustomerRemoveCard: "customer/current/removecard",
  CustomersetResetPassword: "customer/current/resetpassword",
  CustomerSponsorshipSendByEmail: "customer/current/sponsorshipsendbyemail",
  CartModel: "cart/<%= id_cart %>",
  // CartSet: "cart/<%= id_cart %>/set",
  CartDeliveryOptions: "cart/<%= id_cart %>/deliveryoptions",
  CartCheckDeliveryOptions: "cart/<%= id_cart %>/checkdeliveryoptions",
  CartProductCollection: "cart/<%= id_cart %>/product",
  CartMultipleProductCollection: "cart/<%= id_cart %>/multipleproduct",
  // CartProductModel: "cart/<%= id_cart %>/product/<%= id_product %>",
  // CartAddProduct: "cart/<%= id_cart %>/add",
  // CartDeleteProduct: "cart/<%= id_cart %>/remove",
  // CartPostcode: "cart/<%= id_cart %>/postcode",
  CartDatetime: "cart/<%= id_cart %>/datetime",
  // CartStore: "cart/<%= id_cart %>/store",
  CartConsumableReset: "cart/<%= id_cart %>/resetpreset",
  CartConsumableOptions: "cart/<%= id_cart %>/consumable",
  CartVoucherAdd: "cart/<%= id_cart %>/discount",
  CartSetProductLikeOffered: "cart/<%= id_cart %>/discountfreeproduct",
  CartVoucherRemove: "cart/<%= id_cart %>/discount/remove",
  // CartSideDishAdd: "cart/<%= id_cart %>/sidedish",
  CartReorder: "cart/<%= id_cart %>/reorder/<%= id_parent_cart %>",
  CartAddToCart: "cart/<%= id_cart %>/addtocart/<%= id_parent_cart %>",
  CartSetCurrent: "cart/<%= id_cart %>/setcurrent",
  // CartCagnotte: "cart/<%= id_cart %>/cagnotte",

  // ContactModel: "contact/<%= id_contact %>",
  // ContactCollection: "contact",

  // ZendeskSendContact: "zendeskcontact<%= params %>",
  // ArticleSupportModel: "zendeskarticles/<%= id %>",
  // ArticleSupportCollection: "zendeskarticles",
  // Zendeskarticles: "zendeskarticles",
  // Zendesksections: "zendesksection",
  // Zendeskcategory: "zendeskcategory",

  // Zendeskfields: "zendeskfield",
  // Zendeskforms: "zendeskform",

  // ZendeskBundle: "zendeskbundle",
  // ZendeskArticle: "zendeskarticle",
  // ZendeskSection: "zendesksection",
  // ZendeskCategory: "zendeskcategory",
  // ZendeskField: "zendeskfield",
  // ZendeskForm: "zendeskform",

  // OrderCollection: "order",
  // OrderModel: "order/<%= id_order %>",
  // OrderFromCart: "order/<%= id_cart %>/cart",
  OrderValidate: "order/<%= id_cart %>/orderpayed",
  OrderPending: "orderpending/<%= payment_action_id %>/<%= action %>",

  ToolsPaymentMethod: "toolspayment/<%= method %>",
  EdenredData: "toolspayment/getEdenredData",
  EdenredLogout: "toolspayment/logoutEdenred",

  // AddressCollection: "address",
  // AddressModel: "address/<%= id_address %>",

  PlaceModel: "place/<%= place_id %>/estimations/<%= base64address %>",

  // WidgetGroupCollection: "widgetgroup",
  // WidgetGroupModel: "widgetgroup/<%= id_widget_group %>",
  // WidgetLayoutFormatCollection: "widgetlayoutformat",
  // WidgetLayoutFormatModel: "widgetlayoutformat/<%= id_widget_layout_format %>",
  // WidgetCollectionCollection: "widgetcollection",
  // WidgetCollectionModel: "widgetcollection/<%= id_widget_collection %>",
  // WidgetInstanceCollection: "widgetinstance",
  // WidgetInstanceModel: "widgetinstance/<%= id_widget_instance %>",

  // SyncModel: "sync",

  // MessageCollection: "sushimessage",
  // StoreAvisVerifie:
  //   "avisverifie/store/<%= id_store %>?<%= stars ? 'stars='+stars : '' %>",
  // StoreMessageCollection: "storemessage/<%= id_store %>",
  // StockoutProductModel: "stockoutproduct/<%= id_store %>",
  // StoreLocatorSearch: "storelocatorsearch?search=<%= search %>",
  // StoreLocatorSearchCollection: "storelocatorsearch?search=<%= search %>",

  // StoreLocatorBundle: "storelocatorbundle/",
  // StateCollection: "<%= c('_STORE_BASE_URL_') +'/'+ store_rewrite %>",
};
export const routes: Record<string, string> = {
  _BASE_URL: "api/<%= iso_lang %>/",

  app: "app",

  picture:
    "<%= model +'-'+ id_picture +'-'+ suffix +'/'+ rewrite +'.'+ extension %>",

  gui: "gui",

  home: "",

  logout: "<%= c('_LOGOUT_REWRITE_') %>",

  accountDashboard: "<%= c('_ACCOUNT_BASE_URL_') %>",
  accountLink:
    "<%= c('_ACCOUNT_BASE_URL_') +'/'+ c('_ACCOUNT_LOYALTY_REWRITE_') %>",
  accountOrders:
    "<%= c('_ACCOUNT_BASE_URL_') +'/'+ c('_ACCOUNT_ORDERS_REWRITE_') %>",
  accountPage: "<%= c('_ACCOUNT_BASE_URL_') +'/'+ account_rewrite %>",

  reorder: "<%= c('_PAGE_REORDER_BASE_URL_') %>",

  cart: "<%= c('_CHECKOUT_BASE_URL_') %>/<%= c('_CART_BASE_URL_') %>",
  checkout: "<%= c('_CHECKOUT_BASE_URL_') %>",
  checkoutOpc: "<%= c('_CHECKOUT_BASE_URL_') %>/<%= rewrite %>",
  product:
    "<%= c('_CATEGORY_BASE_URL_') +'/'+ category_link_rewrite %>/<%= product_link_rewrite %>-<%= id_product %>",
  authentification: "<%= c('_AUTHENTIFICATION_BASE_URL_') %>",
  supportCenter: "<%= c('_SUPPORT_CENTER_BASE_URL_') %>",
  supportCenterResource:
    "<%= c('_SUPPORT_CENTER_BASE_URL_') + '/' + type + '-' + id %>",
  supportCenterSearch: "<%= c('_SUPPORT_CENTER_BASE_URL_') + '/' + type %>",
  supportCenterSection:
    "<%= c('_SUPPORT_CENTER_BASE_URL_') +'/'+ section_rewrite %>",
  recruitment: "<%= c('_RECRUITMENT_URL_') %>",

  widgetGroup: "<%= widget_group_rewrite %>",

  stores: "<%= c('_STORES_BASE_URL_') %>",
  storeLocatorSearch: "<%= c('_STORES_BASE_URL_') %>/<%= search %>",
  storeLocatorState:
    "<%= c('_STORES_BASE_URL_') %>/<%= c('_STORELOC_STATE_PREFIX_', 'r') %>/<%= state %>",
  storeLocatorPosition:
    "<%= c('_STORES_BASE_URL_') %>/<%= c('_STORELOC_POSITION_PREFIX_','pos') %>/<%= lat %>_<%= lng %>",
  storeLocatorBounds:
    "<%= c('_STORES_BASE_URL_') %>/<%= c('_STORELOC_BOUNDS_PREFIX_','bounds') %>/<%= bounds %>",
  storeLocatorPostcode:
    "<%= c('_STORES_BASE_URL_') %>/<%= city %>/<%= postcode %>",
  storeLocatorShop:
    "<%= c('_STORES_BASE_URL_') %>/<%= c('_STORELOC_SHOP_PREFIX_', 's') %>/<%= shop %>",
  store: "<%= c('_STORE_BASE_URL_') +'/'+ store_rewrite %>",

  // kei: "<%= c('_KEI_URL_') %>",
  // carte2017: "<%= c('_CARTE_2017_URL_') %>",

  newsHome: "<%= c('_PAGE_NEWS_BASE_URL_') %>",
  newsFlag: "<%= c('_PAGE_NEWS_BASE_URL_') %>/flag/<%= url_rewrite %>",
  newsSingle: "<%= c('_PAGE_NEWS_BASE_URL_') %>/<%= url_rewrite %>",

  category: "<%= c('_CATEGORY_BASE_URL_') +'/'+ category_link_rewrite %>",
  allCategories: "<%= c('_ALL_CATEGORIES_BASE_URL_') %>",

  // "webapps-action": "webapps-action/<%= action %>/",
  // "webapps-action-product":
  //   "webapps-action/<%= action %>/<%= id_product %>/<%= option %>",
  // "webapps-action-redirect-category": "webapps-action/redirect/category",

  video:
    "USER/video/exports/<%= id_video %>.<%= size %>.<%= type %>.<%= format %>",
  // videoHD: "USER/video/HD/<%= id_video %>.mp4",

  // tc_vars: "FRONTOFFICE/ajax/tc_vars.js.php?contentType=json&",
  // tc_url: "<%= c('_TAGCOMMANDERURLFOOTER_') %>",

  // googlePlacesAPIAutocomplete:
  //   "https://maps.googleapis.com/maps/api/place/autocomplete/json?<%= params %>&key=AIzaSyDh7EIKHic3C2vXpw0IwWq471DX0TEmJgI",
};
