import { useStaticQuery } from "gatsby";
import { graphql } from "gatsby";
import { useMemo } from "react";
import usePageLang from "../usePageLang";

// interface IConfigurationOptions {
//   lang?: string;
// }

export type GetConfiguration = <T extends string | number | boolean = string>(
  key: string,
  defaultValue: T,
  options?: {
    lang?: string;
  }
) => T;

export const useConfiguration = () => {
  const currentLang = usePageLang();
  const { configuration, configuration_lang } =
    useStaticQuery<Queries.UseConfigurationQuery>(graphql`
      query UseConfiguration {
        #        allConfiguration {
        #          nodes {
        #            value
        #            name
        #            lang
        #          }
        #        }
        configuration: allConfiguration(
          filter: { use_lang: { eq: false }, critical: { eq: true } }
        ) {
          nodes {
            name
            value
          }
        }
        configuration_lang: allConfiguration(
          filter: { use_lang: { eq: true }, critical: { eq: true } }
        ) {
          nodes {
            name
            value
            lang
          }
        }
      }
    `);

  const data = useMemo(() => {
    const _list = [
      ...configuration.nodes,
      ...configuration_lang.nodes.filter((c) => c.lang === currentLang),
    ];

    return _list;
  }, [configuration.nodes, configuration_lang.nodes, currentLang]);

  return useMemo(
    () => ({
      data,
      getConfiguration<T extends string | number | boolean>(
        key: string,
        defaultValue: T,
        options: { lang?: string } = {}
      ): T {
        const conf = data.find((c) => c.name === key);
        const confValue = conf?.value;
        switch (typeof defaultValue) {
          case "boolean":
            return (confValue === "1" ||
              (!!defaultValue && confValue !== "0")) as T;
          case "number":
            return confValue ? (parseFloat(confValue) as T) : defaultValue;
          default:
            return (confValue || defaultValue) as T;
        }
      },
    }),
    [data]
  );
};
