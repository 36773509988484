import LocalStorageCollection from "../prototypes/LocalStorageCollection";
import PostcodeModel, { IDistrict } from "@src/backbone/model/PostcodeModel";
import { urlWithSyncVersion } from "@src/backbone/prototypes/ObjectPrototype";

const formatLocality = (locality: string) => locality.toLowerCase();

class PostcodeCollection extends LocalStorageCollection<PostcodeModel> {
  name = "PostcodeCollection";
  idAttribute = "id_postcode";
  model = PostcodeModel;

  initialize(models?: PostcodeModel[] | Record<string, any>[], options?: any) {
    const collection = this;
    super.initialize(models, options);
    collection.localStorageSetup();
    collection.softFetch();
    collection.on("sync", collection.buildLocalitySimplification, collection);
  }

  localitySimplificationExp?: RegExp = undefined;

  buildLocalitySimplification() {
    //
    // fabrique l'expression régulière pour la recherche de localité
    //
    const self = this;
    const words: string[] = [];
    self.each((postcode) => {
      const simplification = postcode.getLocalitySimplification(true);
      simplification.forEach((word) => {
        if (!words.includes(word)) words.push(word);
      });
    });
    self.localitySimplificationExp = new RegExp(`(${words.join("|")})`, "i");
  }

  // isLocalityMatching(locality: string): boolean {
  //   const formattedLocality = formatLocality(locality),
  //     localitySimplificationExp = this.localitySimplificationExp;
  //   // si les codes ne sont pas encore disponibles, on considère tout comme vrai.
  //   if (!localitySimplificationExp) return true;
  //
  //   return !!formattedLocality.match(localitySimplificationExp);
  // }

  filterByLocalityName(locality: string) {
    if (!locality) return [];
    const formattedLocality = formatLocality(locality);
    return this.filter(function (postcode) {
      return postcode.testFormattedLocality(formattedLocality);
    });
  }

  getByLatLng(lat: number, lng: number) {
    // attention cette méthode retourne le premier résultat valide
    return this.find(function (postcode) {
      return postcode.isLatLngMatching(lat, lng);
    });
  }

  getDistrict(id_district: string): IDistrict | undefined {
    let district: IDistrict | undefined;
    this.each(function (postcode) {
      if (!district && postcode.hasDistricts()) {
        district = postcode.getDistrict(id_district);
      }
    });
    return district;
  }

  getByLinkSEO(link_rewrite_seo: string) {
    return this.findWhere({ link_rewrite_seo: link_rewrite_seo });
  }
}

PostcodeCollection.prototype.url = () => urlWithSyncVersion(`/postcode/`);
PostcodeCollection.prototype.model = PostcodeModel;
PostcodeCollection.prototype.name = "PostcodeCollection";
PostcodeCollection.prototype.translated = true;

export default PostcodeCollection;
