import React from "react";
import AccordionItem, {
  IAccordionItemProps,
} from "../../../Components/Accordion/AccordionItem/AccordionItem";
import { produtDetailConfig } from "../ProductDetails.config";

interface IProductDetailsAccordianProps
  extends Omit<IAccordionItemProps, "borderColor" | "headerConfig"> {}

const ProductDetailsAccordian = (props: IProductDetailsAccordianProps) => {
  return (
    <AccordionItem
      borderColor={produtDetailConfig.color.border}
      headerConfig={{
        iconProps: {
          icon: "caret_down_16",
          size: 16,
          style: {
            marginRight: 14,
          },
        },
      }}
      {...props}
    />
  );
};

export default ProductDetailsAccordian;
