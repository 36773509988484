import { SourceHTMLAttributes } from "react";

export enum ImageFit {
  COVER = "cover",
  CONTAIN = "contain",
  FILL = "fill",
}

export enum ImageFormat {
  AUTO = "auto",
  JPEG = "jpeg",
  JPG = "jpg",
  PNG = "png",
  WEBP = "webp",
}

export enum ImagePosition {
  CENTER = "center",
  TOP = "top",
  RIGHT_TOP = "right_top",
  RIGHT = "right",
  RIGHT_BOTTOM = "right_bottom",
  BOTTOM = "bottom",
  LEFT_BOTTOM = "left_bottom",
  LEFT = "left",
  LEFT_TOP = "left_top",
}

export interface ImageSize {
  aspectRatio?: string;
  outputPixelDensities?: number[];
  width?: number | "auto";
  height?: number | "auto";
}

export interface ImageData {
  width?: number | "auto";
  height?: number | "auto";
  id: string | number;
  fit?: ImageFit | "cover" | "contain" | "fill";
  position?:
    | ImagePosition
    | "center"
    | "top"
    | "right_top"
    | "right"
    | "right_bottom"
    | "bottom"
    | "left_bottom"
    | "left"
    | "left_top";
  format?: ImageFormat[];
  quality?: number;
  breakpoints?: Array<{
    /**
     * Source element media attribut
     * @ref https://developer.mozilla.org/en-US/docs/Web/HTML/Element/Source#attr-media
     */
    media: NonNullable<SourceHTMLAttributes<HTMLSourceElement>["media"]>;
    size?: number;
    imageParams: Omit<ImageData, "id" | "format" | "breakpoints"> & {
      format?: ImageFormat;
      id?: string | number;
    };
  }>;
}

export interface ImageDataResponsive extends ImageSize, ImageData {}
