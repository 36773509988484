import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface BackboneState {
  isSetup?: boolean;
}

const initialState: BackboneState = {
  isSetup: false,
};

const backboneSlice = createSlice({
  name: "backbone",
  initialState,
  reducers: {
    setIsSetup(state, action: PayloadAction<boolean>) {
      state.isSetup = action.payload;
      return state;
    },
  },
});

export default backboneSlice;
