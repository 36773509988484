import styled from "styled-components";
import { _LinkAnchorStyles, AnchorStylesProps } from "../Link.styled";
import DefaultLinkWrapper from "./DefaultLinkWrapper";

const _LinkAnchorStylesWrapper = ({ $hover, color }: AnchorStylesProps) => {
  return _LinkAnchorStyles({ $hover, color });
};

const DefaultStyledLink = styled(DefaultLinkWrapper)<AnchorStylesProps>(
  // @ts-ignore
  _LinkAnchorStylesWrapper
);

export default DefaultStyledLink;
